import { useState } from "react";

import DarkTable from "components/common/DarkTable";
import Text from "components/text/Text";
import { IncidentSummary } from "generated/graphql";

const LIMIT = 5;

export default function IncidentsTable({ incidents, loading, error }) {
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });

  const columns = [
    {
      title: "Number",
      render: (record: IncidentSummary) => <Text>{record.number}</Text>,
    },
    {
      title: "Priority",
      render: (record: IncidentSummary) => <Text>{record.priority}</Text>,
    },
    {
      title: "Description",
      render: (record: IncidentSummary) => <Text>{record.description}</Text>,
    },
    {
      title: "Opened At",
      render: (record: IncidentSummary) => <Text>{record.openedAt}</Text>,
    },
    {
      title: "State",
      render: (record: IncidentSummary) => <Text>{record.state}</Text>,
    },
  ];

  return (
    <div style={{ marginTop: 24 }}>
      <DarkTable
        loading={loading}
        dataSource={incidents as IncidentSummary[]}
        columns={columns}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: incidents?.length,
        }}
        emptyText={error ? "Error getting Incidents" : undefined}
      />
    </div>
  );
}
