import { useGetManyOrganizationsQuery, Organization, useImpersonateOrganizationMutation } from "generated/graphql";
import DarkTable from "components/common/DarkTable";
import { useHistory } from "react-router";
import Caption from "components/text/Caption";
import Loading from "components/common/Loading";
import Text from "components/text/Text";
import ButtonText from "components/common/ButtonText";
import theme from "lib/theme";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useState } from "react";

const LIMIT = 5;

export default function OrganizationsTable() {
  const { data, loading, error } = useGetManyOrganizationsQuery();
  const history = useHistory();
  const [impersonateOrganization, { loading: impersonating }] = useImpersonateOrganizationMutation();
  const organizations = data?.getManyOrganizations || [];

  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });

  const columns = [
    {
      title: "Name",
      render: (record: Organization) => <Text onClick={() => history.push(`/admin/organizations?organizationId=${record.id}`)}>{record.name}</Text>,
    },
    {
      title: "Billing ID",
      render: (record: Organization) => <Text>{record.billingId}</Text>,
    },
    {
      title: "",
      render: (record: Organization) => {
        return (
          <ButtonText
            disabled={impersonating}
            onClick={async () => {
              try {
                await impersonateOrganization({
                  variables: {
                    organizationId: record.id,
                  },
                });
                console.log(`${window?.location?.origin}/app`);
                window.open(`${window?.location?.origin}/app`, "_blank")?.focus();
              } catch (err) {}
            }}
            style={{ color: theme.colors.primary3 }}
          >
            Client View{" "}
            <ArrowRightOutlined
              style={{
                fontSize: 12,
                marginLeft: 4,
                position: "relative",
                top: -1,
              }}
            />
          </ButtonText>
        );
      },
    },
  ];

  if (loading) return <Loading />;

  if (error) return <Caption>Error</Caption>;

  return (
    <div style={{ marginTop: 24 }}>
      <DarkTable
        dataSource={organizations as Organization[]}
        columns={columns}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: organizations?.length,
        }}
      />
    </div>
  );
}
