import boldFont from "lib/fonts/Roboto-Bold.ttf";
import regularFont from "lib/fonts/Roboto-Regular.ttf";
import italicFont from "lib/fonts/Roboto-Italic.ttf";
import {
    Font,
} from "@react-pdf/renderer";

export enum FontFamily {
    bold = "Roboto Bold",
    regular = "Roboto Regular",
    italic = "Roboto Italic",
}

export const registerPdfFont = () => {
    // https://stackoverflow.com/questions/66255816/cannot-read-property-hasglyphforcodepoint-of-null-react-pdf
    // https://github.com/diegomura/react-pdf/issues/777
    Font.register({
        family: FontFamily.bold,
        format: "truetype",
        src: boldFont,
    });

    Font.register({
        family: FontFamily.regular,
        format: "truetype",
        src: regularFont,
    });

    Font.register({
        family: FontFamily.italic,
        format: "truetype",
        src: italicFont,
    });
};