import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DarkTable from "components/common/DarkTable";
import { Sku, useGetManySkusQuery } from "generated/graphql";
import { useState } from "react";
import Button from "components/common/Button";
import Drawer from "components/common/Drawer";
import SkuForm from "components/forms/SkuForm";

const DEFAULT_LIMIT = 7;

export default function AdminInvoices() {
  const [addSku, setAddSku] = useState(false);
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: DEFAULT_LIMIT,
  });

  const { data, loading } = useGetManySkusQuery({
    variables: {
      skip: filters.skip,
      limit: filters.limit,
    },
  });

  const columns = [
    {
      key: "id",
      title: "id",
      render: (record: Sku) => record.id,
    },
    {
      title: "Description",
      render: (record: Sku) => record.SKU_desc,
    },
    {
      title: "Rollup Code",
      render: (record: Sku) => record.Rollup_code,
    },
    {
      title: "Rollup Description",
      render: (record: Sku) => record.Rollup_desc,
    },
    {
      title: "Price",
      render: (record: Sku) => record.Price,
    },
  ];

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 16,
        }}
      >
        <PageTitle>Apiphani Skus</PageTitle>
        <Button onClick={() => setAddSku(true)}>New Sku</Button>
      </div>
      <div style={{ marginTop: 16 }}>
        <DarkTable
          loading={loading}
          dataSource={data?.getManySkus?.results}
          columns={columns}
          onChange={(pagination: any, filters: any, sorter: any) => {
            setFilters({
              ...filters,
              current: pagination?.current,
              limit: pagination?.pageSize,
              skip: pagination?.pageSize * (pagination.current - 1),
            });
          }}
          pagination={{
            pageSize: filters.limit,
            current: filters.current,
            total: data?.getManySkus?.count || 0,
          }}
        />
      </div>
      <Drawer title="Create New Sku" visible={addSku ? true : false} onClose={() => setAddSku(false)}>
        <SkuForm onCancel={() => setAddSku(false)} onSubmit={() => console.log("test")} />
      </Drawer>
    </PageContainer>
  );
}
