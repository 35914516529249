import styled from "styled-components";
import { CloseCircleFilled } from "@ant-design/icons";

const ErrorBlockContainer = styled.div`
  background: ${(p) => p.theme.colors.red10};
  border-left: 4px solid ${(p) => p.theme.colors.red3};
  padding: 8px;
  border-radius: 5px;
  color: ${(p) => p.theme.colors.red1};
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  position: relative;
`;

interface ErrorBlockProps {
  errors?: string[];
  error?: string;
  style?: React.CSSProperties;
}

export default function ErrorBlock(props: ErrorBlockProps) {
  const { errors = [], error, style } = props;
  if (!error && errors.length === 0) return null;

  return (
    <ErrorBlockContainer style={style} data-testid={props["data-testid"]}>
      <CloseCircleFilled style={{ marginRight: 6, fontSize: 16, position: "relative", top: 2 }} />
      {errors && errors.map((item) => item)}
      {error && error}
    </ErrorBlockContainer>
  );
}
