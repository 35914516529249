
import styled from 'styled-components';

const StatusCircle = styled.div<{ size?: number, isRunning?: boolean | undefined | null, fixed?: boolean, offColor?: string }>`
  height: ${({ size }) => size ? `${size}px` : '16px'};
  width: ${({ size }) => size ? `${size}px` : '16px'};;
  position: ${({ fixed = true, style }) => {
    if (style?.position) return style?.position;
    if (fixed) return 'absolute';
    return 'inherit';
  }};
  right: 16px;
  display: inline-block;
  top: 16px;
  border-radius: 50%;
  background-color: ${({ theme, isRunning, offColor }) => {
    if (isRunning) return theme.colors.success6;
    return offColor || theme.colors.red6;
  }};
`;

export default StatusCircle