import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { useGetManyInstanceTypesQuery } from "generated/graphql";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function InstanceSystemTypeSelectInput(props) {
  const { data, loading, error } = useGetManyInstanceTypesQuery();

  const { onChange, value } = props;

  return (
    <Select
      style={{ width: "100%" }}
      {...props}
      size="large"
      onChange={onChange}
      value={value || undefined}
      optionFilterProp="children"
    >
      {!loading &&
        !error &&
        data?.getManyInstanceTypes?.map((label) => {
          if (!label) return null;
          return (
            <Option key={label} value={label}>
              {label}
            </Option>
          );
        })}
    </Select>
  );
}
