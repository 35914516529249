import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import EnvironmentTypeSelectInput from "components/inputs/EnvironmentTypeSelectInput";
import InstancesMultiSelect from "components/inputs/InstancesMultiSelect";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface ApplicationFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  instances?: any[] | [];
  defaultValues?: {
    name?: string | null;
    vendor?: string | null;
    environmentType?: string | null;
    instanceIds?: string[] | [];
  };
}

// instanceIds: [String];

export default function ApplicationForm({
  onSubmit,
  onCancel,
  loading,
  instances,
  defaultValues,
}: ApplicationFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
  });

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Name" required error={errors?.name?.message}>
        <TextInput
          {...register("name", {
            required: "Name is required.",
          })}
        />
      </FormItem>
      {/* <FormItem label="Vendor" error={errors?.vendor?.message}>
        <Controller
          name="vendor"
          control={control}
          rules={{ required: "This is required." }}
          render={({ field }) => (
            <VendorSelectInput
              {...field}
              onChange={(vendor) => field.onChange(vendor)}
            />
          )}
        />
      </FormItem> */}

      <FormItem
        label="Environment"
        required
        error={errors?.environmentType?.message}
      >
        <Controller
          name="environmentType"
          control={control}
          rules={{ required: "This is required." }}
          render={({ field }) => (
            <EnvironmentTypeSelectInput
              {...field}
              onChange={(environmentType) => field.onChange(environmentType)}
            />
          )}
        />
      </FormItem>
      <FormItem label="Instances">
        <Controller
          name="instanceIds"
          control={control}
          rules={{ required: "This is required." }}
          render={({ field }) => (
            <InstancesMultiSelect
              {...field}
              instances={instances}
              onChange={(instanceIds) => field.onChange(instanceIds)}
            />
          )}
        />
      </FormItem>

      {/* <FormItem label="Tier" required error={errors?.name?.message}>
        <TextInput
          {...register("tier", {
            required: "Tier is required.",
          })}
        />
      </FormItem> */}
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ width: 100, marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          style={{ width: 100 }}
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
