import Select from "antd/lib/select";
import "antd/lib/select/style/css";
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function OrganizationsMultiSelect(props) {
  const { onChange, organizations, value } = props;
  console.log(value);
  return (
    <Select
      style={{ width: "100%" }}
      {...props}
      size="large"
      mode="multiple"
      onChange={onChange}
      value={value || []}
      optionFilterProp="children"
    >
      {organizations?.map(({ name, id }) => {
        if (!id) return null;
        return (
          <Option key={id} value={id}>
            {name}
          </Option>
        );
      })}
    </Select>
  );
}
