import theme, { GlobalStyle } from "lib/theme";
import { ThemeProvider } from "styled-components";
import AppRoutes from "./pages/index";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "ApolloClient/index";
import { registerPdfFont } from "lib/pdfs";
import { useEffect } from "react";
import ErrorBoundary from "components/common/ErrorBoundaryProps";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import config from "lib/config";

export default function App() {
  useEffect(() => {
    registerPdfFont();
  }, []);

  useEffect(() => {
    if (config.NODE_ENV !== "development" && config.SENTRY_DSN) {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        ignoreErrors: ["ResizeObserver loop limit exceeded"],
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <GlobalStyle />
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </ApolloProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
