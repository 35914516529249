import storage from 'local-storage-fallback';
import iOS from './iOS';



export default function getLocalStorage(valueName: string) {
    try {
        if (!iOS()) {
            return window.localStorage.getItem(valueName);
        } else {
            return storage.getItem(valueName);
        }
    } catch (err) {
        throw new Error(err.message);
    }
}