import React from 'react';
import styled from 'styled-components';

const ButtonText = styled.button<{style?: React.CSSProperties}>`
  border: 0px;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  color: ${({theme, style}) => {
    if (style?.color) return style.color;
    return theme.colors.red5;
  }};
`;

export default ButtonText;
