import { DataPoint } from "generated/graphql";
// COMPONETS
import moment from "moment";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { toNumber } from "lodash";
import numeral from "numeral";
import theme from "lib/theme";

interface ChartProps {
  title?: string;
  data?: DataPoint[] | any;
  valueKeys?: string[];
  yAxisDomain?: any; // https://recharts.org/en-US/api/YAxis#domain
}

const formatData = (data, valueKey?: string) => {
  return (
    data
      ?.map((item) => {
        return {
          time: moment(item?.timestamp).valueOf(),
          [valueKey || "value"]: toNumber(item?.value || 0),
        };
      })
      // for some reason, or datapoints are not in order (in terms of time), so we sort them
      .sort((a, b) => {
        if (a.time < b.time) {
          return -1;
        }
        if (a.time > b.time) {
          return 1;
        }
        return 0;
      })
  );
};

export default function TwoLineChart({
  title,
  data,
  yAxisDomain,
  valueKeys = [],
}: ChartProps) {
  const formatted = Array.isArray(data[0])
    ? data?.map((item, index) => formatData(item, valueKeys[index]))
    : [formatData(data)];

  const chartdata: any[] = [];

  formatted?.[0]?.forEach((item, index) => {
    chartdata.push({
      ...item,
      [valueKeys?.[0] || "value"]: toNumber(
        item?.[valueKeys[0] || "value"] || 0
      ),
      [valueKeys?.[1] || "value"]: toNumber(
        formatted?.[1]?.[index]?.[valueKeys[1] || "value"] || 0
      ),
    });
  });

  if (!formatted?.[0]) return null;

  return (
    <ResponsiveContainer width="90%" height={200}>
      <LineChart data={chartdata}>
        <CartesianGrid stroke={theme.colors.background3} />
        {formatted?.map((item, index) => {
          return (
            <Line
              key={index + "line"}
              type="monotone"
              dataKey={valueKeys[index] || "value"}
              stroke={
                index === 0 ? theme.colors.yellow4 : theme.colors.primary4
              }
              dot={false}
            />
          );
        })}
        <XAxis
          dataKey="time"
          name="Time"
          tickFormatter={(unixTime) => moment(unixTime).format("h:mma")}
          type="number"
          tickSize={6}
          domain={["dataMin", "dataMax"]} // domain={['auto', 'auto']}
          tickLine={false}
          stroke={theme.colors.background3}
          tick={{ stroke: "#fff", strokeWidth: 0.5, fontSize: 10 }}
        />
        {formatted?.map((item, index) => {
          return (
            <YAxis
              key={index + "axis"}
              dataKey={valueKeys[index] || "value"}
              tick={{ stroke: "#fff", strokeWidth: 0.5, fontSize: 10 }}
              axisLine={false}
              tickSize={0}
              type="number"
              tickCount={3}
              tickMargin={4}
              domain={yAxisDomain}
              tickFormatter={(tick) => {
                return numeral(tick).format("0a");
              }}
            />
          );
        })}

        <Tooltip
          formatter={(value, props) => {
            return [numeral(value).format("0.0a"), props];
          }}
          contentStyle={{
            backgroundColor: theme.colors.background2,
            borderColor: theme.colors.background2,
            padding: 8,
          }}
          labelStyle={{
            color: theme.colors.text,
            fontSize: 10,
          }}
          itemStyle={{
            color: theme.colors.text,
            fontSize: 10,
          }}
          labelFormatter={(value) => moment(value).format("ddd, MMM DD, H:mm")}
        />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
}
