import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type Access = {
  __typename?: "Access";
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<AccessNameEnum>;
};

export type AccessItem = {
  __typename?: "AccessItem";
  key?: Maybe<AccessNameEnum>;
  name?: Maybe<Scalars["String"]>;
};

export enum AccessNameEnum {
  Admin = "admin",
  AppdApplications = "appd_applications",
  AppdMetricsBasis = "appd_metrics_basis",
  AppdMetricsSystem = "appd_metrics_system",
  AwsBackups = "aws_backups",
  AwsCosts = "aws_costs",
  AwsDocuments = "aws_documents",
  AwsFile = "aws_file",
  AwsInstances = "aws_instances",
  AwsMetricsCpu = "aws_metrics_cpu",
  AwsMetricsDisk = "aws_metrics_disk",
  AwsMetricsNetwork = "aws_metrics_network",
  AwsMetricsVpn = "aws_metrics_vpn",
  Billings = "billings",
  DocumentsContracts = "documents_Contracts",
  DocumentsMinutes = "documents_Minutes",
  DocumentsProjects = "documents_Projects",
  DocumentsReports = "documents_Reports",
  DocumentsSoPs = "documents_SOPs",
  Keys = "keys",
  Notifications = "notifications",
  NotificationsOrganization = "notifications_organization",
  NotificationsUser = "notifications_user",
  Organization = "organization",
  OrganizationPermission = "organization_permission",
  OrganizationTransferSu = "organization_transfer_su",
  SamanageAttachments = "samanage_attachments",
  SamanageAudit = "samanage_audit",
  SamanageChanges = "samanage_changes",
  SamanageConfigurationItems = "samanage_configuration_items",
  SamanageDepartments = "samanage_departments",
  SamanageIncidents = "samanage_incidents",
  SamanageIncidentsComments = "samanage_incidents_comments",
  SamanageProblems = "samanage_problems",
  SamanageServiceCatalogs = "samanage_service_catalogs",
  SamanageServiceRequests = "samanage_service_requests",
  SamanageSites = "samanage_sites",
  SamanageSla = "samanage_sla",
  SamanageSolutions = "samanage_solutions",
  SamanageTasks = "samanage_tasks",
}

export type AccessSection = {
  __typename?: "AccessSection";
  items?: Maybe<Array<Maybe<AccessItem>>>;
  sectionName?: Maybe<Scalars["String"]>;
};

export type AddressLocation = {
  __typename?: "AddressLocation";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  postal?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  street1?: Maybe<Scalars["String"]>;
  street2?: Maybe<Scalars["String"]>;
};

export type AddressLocationInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  postal?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  street1?: InputMaybe<Scalars["String"]>;
  street2?: InputMaybe<Scalars["String"]>;
};

export enum ApmServiceEnum {
  Dynatrace = "dynatrace",
}

export type Application = {
  __typename?: "Application";
  environments?: Maybe<Array<Maybe<Environment>>>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type ApplicationInput = {
  name?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
};

export type AuthenticateParamsInput = {
  access_token?: InputMaybe<Scalars["String"]>;
  access_token_secret?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  provider?: InputMaybe<Scalars["String"]>;
  token?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<UserInput>;
};

export type Backup = {
  __typename?: "Backup";
  detail?: Maybe<Scalars["String"]>;
  durationHours?: Maybe<Scalars["String"]>;
  durationMinutes?: Maybe<Scalars["String"]>;
  durationSeconds?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["String"]>;
  /** e.g. '2020-12-28 06:17:14' */
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<BackupStatusEnum>;
  type?: Maybe<BackupTypeEnum>;
};

export enum BackupStatusEnum {
  Error = "Error",
  State = "STATE",
  Failed = "failed",
  Failure = "failure",
  Successful = "successful",
}

export enum BackupTypeEnum {
  Db = "DB",
  Os = "OS",
}

export type BusinessDocument = {
  __typename?: "BusinessDocument";
  /** If the document is of BusinessDocumentTypeEnum.directory, that means it is a folder with child documents. BusinessDocumentTypeEnum.file are documents and have no children. */
  documents?: Maybe<Array<Maybe<BusinessDocument>>>;
  id?: Maybe<Scalars["String"]>;
  lastModified?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  size?: Maybe<Scalars["String"]>;
  type?: Maybe<BusinessDocumentTypeEnum>;
  /** The URL for downloading the document, if it is of BusinessDocumentTypeEnum.file type */
  url?: Maybe<Scalars["String"]>;
};

export type BusinessDocumentCategory = {
  __typename?: "BusinessDocumentCategory";
  documents?: Maybe<Array<Maybe<BusinessDocument>>>;
  /** a unique string of some sort, or id */
  id?: Maybe<Scalars["String"]>;
  /** Name of the document, e.g. SOPs, etc */
  name?: Maybe<Scalars["String"]>;
};

export enum BusinessDocumentTypeEnum {
  Directory = "directory",
  File = "file",
}

export enum CloudVendorTypeEnum {
  Aws = "aws",
  Azure = "azure",
  Google = "google",
}

export type CostAnalysisResult = {
  __typename?: "CostAnalysisResult";
  id?: Maybe<Scalars["String"]>;
  values?: Maybe<Array<Maybe<CostAnalysisValue>>>;
};

export type CostAnalysisValue = {
  __typename?: "CostAnalysisValue";
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type CreateUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  password?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type CreateUserResult = {
  __typename?: "CreateUserResult";
  loginResult?: Maybe<LoginResult>;
  userId?: Maybe<Scalars["ID"]>;
};

export type DataPoint = {
  __typename?: "DataPoint";
  timestamp?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type EmailRecord = {
  __typename?: "EmailRecord";
  address?: Maybe<Scalars["String"]>;
  verified?: Maybe<Scalars["Boolean"]>;
};

export type Environment = {
  __typename?: "Environment";
  application?: Maybe<Application>;
  applicationId?: Maybe<Scalars["String"]>;
  environmentType?: Maybe<EnvironmentTypeEnum>;
  id?: Maybe<Scalars["String"]>;
  instances?: Maybe<Array<Maybe<Instance>>>;
  isProduction?: Maybe<Scalars["Boolean"]>;
  isRunning?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  tiers?: Maybe<Array<Maybe<Scalars["String"]>>>;
  vendor?: Maybe<CloudVendorTypeEnum>;
};

export type EnvironmentInput = {
  applicationId: Scalars["String"];
  environmentType?: InputMaybe<EnvironmentTypeEnum>;
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  name?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  tiers?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vendor?: InputMaybe<CloudVendorTypeEnum>;
};

export enum EnvironmentTypeEnum {
  Customizing = "customizing",
  Development = "development",
  Production = "production",
  Qa = "qa",
  Sandbox = "sandbox",
  Staging = "staging",
  Test = "test",
  Unknown = "unknown",
}

export type ImpersonateReturn = {
  __typename?: "ImpersonateReturn";
  authorized?: Maybe<Scalars["Boolean"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type ImpersonationUserIdentityInput = {
  email?: InputMaybe<Scalars["String"]>;
  userId?: InputMaybe<Scalars["String"]>;
  username?: InputMaybe<Scalars["String"]>;
};

export type IncidentSummary = {
  __typename?: "IncidentSummary";
  description?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  openedAt?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  sysId?: Maybe<Scalars["ID"]>;
};

export type Instance = {
  __typename?: "Instance";
  /** Data from the CDMB (samanage) */
  additionalInfo?: Maybe<Scalars["JSON"]>;
  application?: Maybe<Application>;
  architecture?: Maybe<Scalars["String"]>;
  backups?: Maybe<Array<Maybe<Backup>>>;
  changeLogs?: Maybe<Array<Maybe<InstanceChangeLog>>>;
  cpu?: Maybe<Scalars["String"]>;
  customDeviceId?: Maybe<Scalars["String"]>;
  customFields?: Maybe<Array<Maybe<InstanceCustomField>>>;
  description?: Maybe<Scalars["String"]>;
  dynatraceHostId?: Maybe<Scalars["String"]>;
  environment?: Maybe<Environment>;
  externalId?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  imageId?: Maybe<Scalars["String"]>;
  instanceSize?: Maybe<Scalars["String"]>;
  /** Information from a data dump on the environemnt specs */
  instanceSpecs?: Maybe<Scalars["JSON"]>;
  instanceType?: Maybe<Scalars["String"]>;
  isProduction?: Maybe<Scalars["Boolean"]>;
  isRunning?: Maybe<Scalars["Boolean"]>;
  lastInstanceSpecs?: Maybe<Scalars["JSON"]>;
  name?: Maybe<Scalars["String"]>;
  networkIn?: Maybe<InstanceNetworkItem>;
  networkOut?: Maybe<InstanceNetworkItem>;
  numberOfMetrics?: Maybe<Scalars["Int"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  osType?: Maybe<Scalars["String"]>;
  osVersion?: Maybe<Scalars["String"]>;
  pattern?: Maybe<Scalars["String"]>;
  privateIPAddress?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  resourceGroup?: Maybe<Scalars["String"]>;
  rootDeviceType?: Maybe<Scalars["String"]>;
  state?: Maybe<InstanceStateEnum>;
  subnet?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  vendor?: Maybe<CloudVendorTypeEnum>;
  /** e.g. t2.micro on AWS or Standard_B1ls on Azure */
  vendorInstanceType?: Maybe<Scalars["String"]>;
};

export type InstanceChangeLog = {
  __typename?: "InstanceChangeLog";
  changedBy?: Maybe<UserProfile>;
  changedById?: Maybe<Scalars["String"]>;
  changedByString?: Maybe<Scalars["String"]>;
  dateOfChange?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  instanceId?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  oldValue?: Maybe<Scalars["String"]>;
  typeOfChange?: Maybe<InstanceChangeLogTypeEnum>;
};

export enum InstanceChangeLogTypeEnum {
  /** A change that was detected through one of our automated/cron job scripts that runs and checks for changes */
  DetectedChange = "detectedChange",
  /** The change was manually made by a user (i.e. updated via the UI) */
  ManualChange = "manualChange",
}

export type InstanceCustomField = {
  __typename?: "InstanceCustomField";
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
};

export type InstanceEditInput = {
  customDeviceId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dynatraceHostId?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  instanceType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  pattern?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  resourceGroup?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<CloudVendorTypeEnum>;
};

export type InstanceInput = {
  customDeviceId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dynatraceHostId?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  instanceType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  pattern?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  resourceGroup?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<CloudVendorTypeEnum>;
};

export type InstanceKey = {
  __typename?: "InstanceKey";
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  instances?: Maybe<Instance>;
  name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  use?: Maybe<InstanceKeyUseEnum>;
  vendor?: Maybe<CloudVendorTypeEnum>;
};

/** Only certain fields can be edited for a key */
export type InstanceKeyEditInput = {
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name: Scalars["String"];
  region?: InputMaybe<Scalars["String"]>;
  use?: InputMaybe<InstanceKeyUseEnum>;
  vendor: CloudVendorTypeEnum;
};

export type InstanceKeyInput = {
  /** applicationId: for Azure only */
  applicationId?: InputMaybe<Scalars["String"]>;
  /** Add a description about why the key is being used */
  description?: InputMaybe<Scalars["String"]>;
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  name: Scalars["String"];
  organizationId: Scalars["ID"];
  private_key?: InputMaybe<Scalars["String"]>;
  public_key?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  /** resourceGroup: for Azure only */
  resourceGroup?: InputMaybe<Scalars["String"]>;
  /** subscriptionId: for Azure only */
  subscriptionId?: InputMaybe<Scalars["String"]>;
  use?: InputMaybe<InstanceKeyUseEnum>;
  vendor: CloudVendorTypeEnum;
};

export enum InstanceKeyUseEnum {
  /** This key is used for accessing backup information */
  Backups = "backups",
  /** This key is used for accessing documents */
  Documents = "documents",
  /** This key is used for general purpose (e.g. some orgs may have one key to access monitoring, backups and documents) */
  GeneralUse = "general_use",
}

export type InstanceNetworkItem = {
  __typename?: "InstanceNetworkItem";
  data?: Maybe<Array<Maybe<InstanceNetworkItemData>>>;
  mean?: Maybe<Scalars["String"]>;
};

export type InstanceNetworkItemData = {
  __typename?: "InstanceNetworkItemData";
  timestamp?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export enum InstanceStateEnum {
  Pending = "pending",
  Running = "running",
  /** maps to AWS's shutting-down instance state */
  ShuttingDown = "shuttingDown",
  Stopped = "stopped",
  Stopping = "stopping",
  Terminated = "terminated",
}

export enum InstanceSystemTypeEnum {
  ApplicationServer = "APPLICATION_SERVER",
  Ascs = "ASCS",
  CentralInstance = "CENTRAL_INSTANCE",
  CiDb = "CI_DB",
  DatabaseServer = "DATABASE_SERVER",
  HanaStudio = "HANA_STUDIO",
  JumpHostBastion = "JUMP_HOST_BASTION",
  LiveCache = "LIVE_CACHE",
  Optimizer = "OPTIMIZER",
  Other = "OTHER",
  RdpServer = "RDP_SERVER",
  Sandbox = "SANDBOX",
  Saprouter = "SAPROUTER",
  UtilityServer = "UTILITY_SERVER",
  WebDispatcher = "WEB_DISPATCHER",
  WebServer = "WEB_SERVER",
}

export type Invoice = {
  __typename?: "Invoice";
  Amount_Paid?: Maybe<Scalars["String"]>;
  ClientId?: Maybe<Scalars["String"]>;
  Date_Paid?: Maybe<Scalars["String"]>;
  Invoice_Date?: Maybe<Scalars["String"]>;
  Invoice_Notes?: Maybe<Scalars["String"]>;
  Invoice_amount?: Maybe<Scalars["String"]>;
  Invoice_due_date?: Maybe<Scalars["String"]>;
  Invoiceno?: Maybe<Scalars["String"]>;
  attention?: Maybe<Scalars["String"]>;
  balance?: Maybe<Scalars["String"]>;
  billingDetails?: Maybe<InvoiceBillingDetails>;
  id?: Maybe<Scalars["String"]>;
  lineItems?: Maybe<Array<InvoiceLineItem>>;
  netTerms?: Maybe<Scalars["String"]>;
  organization?: Maybe<ShortOrganization>;
};

export type InvoiceBillingDetails = {
  __typename?: "InvoiceBillingDetails";
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
  bankAccount?: Maybe<Scalars["String"]>;
  bankRouting?: Maybe<Scalars["String"]>;
  bankSwift?: Maybe<Scalars["String"]>;
  ein?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

export type InvoiceLineItem = {
  __typename?: "InvoiceLineItem";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  price?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["String"]>;
  sku?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["String"]>;
};

export type InvoiceResult = {
  __typename?: "InvoiceResult";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<Invoice>>>;
};

export type InvoiceSkuBreakdown = {
  __typename?: "InvoiceSkuBreakdown";
  SKU_code?: Maybe<Scalars["String"]>;
  Sold_unit_price?: Maybe<Scalars["String"]>;
};

export type InvoiceSkuBreakdownResult = {
  __typename?: "InvoiceSkuBreakdownResult";
  dates?: Maybe<StartEndDate>;
  results?: Maybe<Array<Maybe<InvoiceSkuBreakdown>>>;
};

/** Overwrite accountsjs login result to do our own custom login */
export type LoginResponse = {
  __typename?: "LoginResponse";
  mfaRequired?: Maybe<Scalars["Boolean"]>;
  sessionId?: Maybe<Scalars["String"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type LoginResult = {
  __typename?: "LoginResult";
  sessionId?: Maybe<Scalars["String"]>;
  tokens?: Maybe<Tokens>;
  user?: Maybe<User>;
};

export type MetricResult = {
  __typename?: "MetricResult";
  metric?: Maybe<Scalars["String"]>;
  metricDescription?: Maybe<Scalars["String"]>;
  metricName?: Maybe<Scalars["String"]>;
  metricType: MetricUnitTypeEnum;
  metricValues?: Maybe<Array<Maybe<DataPoint>>>;
};

export enum MetricUnitTypeEnum {
  Byte = "Byte",
  Count = "Count",
  Kilobytes = "Kilobytes",
  Milliseconds = "Milliseconds",
  Percent = "Percent",
  Ratio = "Ratio",
  /** 1 or zero */
  UpDown = "UpDown",
}

export type Mutation = {
  __typename?: "Mutation";
  addEmail?: Maybe<Scalars["Boolean"]>;
  authenticate?: Maybe<LoginResult>;
  changePassword?: Maybe<Scalars["Boolean"]>;
  /** Create an application */
  createApplication?: Maybe<Application>;
  /** Create an environment */
  createEnvironment?: Maybe<Environment>;
  createInstanceKey?: Maybe<InstanceKey>;
  createNewUser?: Maybe<UserProfile>;
  createOneInstance?: Maybe<Instance>;
  createOneScriptTemplate?: Maybe<ScriptTemplate>;
  createOrganization?: Maybe<Organization>;
  createPermission?: Maybe<Permission>;
  createSuperAdminUser?: Maybe<UserProfile>;
  createUser?: Maybe<CreateUserResult>;
  deactivateOrganization?: Maybe<MutationResponse>;
  /** Delete an instance key */
  deleteInstancekey?: Maybe<MutationResponse>;
  /** Delete a user */
  deleteUser?: Maybe<MutationResponse>;
  impersonate?: Maybe<ImpersonateReturn>;
  impersonateOrganization?: Maybe<UserProfile>;
  login?: Maybe<LoginResponse>;
  logout?: Maybe<Scalars["Boolean"]>;
  refreshInstanceData?: Maybe<Instance>;
  refreshTokens?: Maybe<LoginResult>;
  requestMagicLinkEmail?: Maybe<Scalars["Boolean"]>;
  /** Resend invite email */
  resendInviteEmail?: Maybe<MutationResponse>;
  resendMfa?: Maybe<MutationResponse>;
  resetPassword?: Maybe<LoginResult>;
  sendResetPasswordEmail?: Maybe<Scalars["Boolean"]>;
  sendVerificationEmail?: Maybe<Scalars["Boolean"]>;
  testKey?: Maybe<MutationResponse>;
  twoFactorSet?: Maybe<Scalars["Boolean"]>;
  twoFactorUnset?: Maybe<Scalars["Boolean"]>;
  updateOneApplication?: Maybe<Application>;
  updateOneEnvironment?: Maybe<Environment>;
  updateOneInstance?: Maybe<Instance>;
  updateOneInstanceKey?: Maybe<InstanceKey>;
  /** Update an organization */
  updateOneOrganization?: Maybe<Organization>;
  updateOneScriptTemplate?: Maybe<ScriptTemplate>;
  updateOneUser?: Maybe<UserProfile>;
  updateOneUserActiveOrg?: Maybe<UserProfile>;
  updateOrganizationSuperAdmin?: Maybe<Organization>;
  uploadInstances?: Maybe<MutationResponse>;
  verifyAuthentication?: Maybe<Scalars["Boolean"]>;
  verifyEmail?: Maybe<Scalars["Boolean"]>;
  verifyMfa?: Maybe<LoginResponse>;
};

export type MutationAddEmailArgs = {
  newEmail: Scalars["String"];
};

export type MutationAuthenticateArgs = {
  params: AuthenticateParamsInput;
  serviceName: Scalars["String"];
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars["String"];
  oldPassword: Scalars["String"];
};

export type MutationCreateApplicationArgs = {
  params?: InputMaybe<ApplicationInput>;
};

export type MutationCreateEnvironmentArgs = {
  params?: InputMaybe<EnvironmentInput>;
};

export type MutationCreateInstanceKeyArgs = {
  params?: InputMaybe<InstanceKeyInput>;
};

export type MutationCreateNewUserArgs = {
  params: UserProfileInput;
};

export type MutationCreateOneInstanceArgs = {
  params?: InputMaybe<InstanceInput>;
};

export type MutationCreateOneScriptTemplateArgs = {
  params?: InputMaybe<ScriptTemplateInput>;
};

export type MutationCreateOrganizationArgs = {
  params: OrganizationInput;
};

export type MutationCreatePermissionArgs = {
  params: PermissionInput;
};

export type MutationCreateSuperAdminUserArgs = {
  params: UserProfileInput;
};

export type MutationCreateUserArgs = {
  user: CreateUserInput;
};

export type MutationDeactivateOrganizationArgs = {
  organizationId: Scalars["ID"];
};

export type MutationDeleteInstancekeyArgs = {
  instanceKeyId: Scalars["ID"];
};

export type MutationDeleteUserArgs = {
  userId: Scalars["ID"];
};

export type MutationImpersonateArgs = {
  accessToken: Scalars["String"];
  impersonated: ImpersonationUserIdentityInput;
};

export type MutationImpersonateOrganizationArgs = {
  organizationId: Scalars["ID"];
};

export type MutationLoginArgs = {
  params: AuthenticateParamsInput;
};

export type MutationRefreshInstanceDataArgs = {
  instanceId?: InputMaybe<Scalars["ID"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
};

export type MutationRefreshTokensArgs = {
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
};

export type MutationRequestMagicLinkEmailArgs = {
  email: Scalars["String"];
};

export type MutationResendInviteEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationResendMfaArgs = {
  email: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"];
  token: Scalars["String"];
};

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars["String"];
};

export type MutationSendVerificationEmailArgs = {
  email: Scalars["String"];
};

export type MutationTwoFactorSetArgs = {
  code: Scalars["String"];
  secret: TwoFactorSecretKeyInput;
};

export type MutationTwoFactorUnsetArgs = {
  code: Scalars["String"];
};

export type MutationUpdateOneApplicationArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<ApplicationInput>;
};

export type MutationUpdateOneEnvironmentArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<EnvironmentInput>;
};

export type MutationUpdateOneInstanceArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<InstanceEditInput>;
};

export type MutationUpdateOneInstanceKeyArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<InstanceKeyEditInput>;
};

export type MutationUpdateOneOrganizationArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<OrganizationInput>;
};

export type MutationUpdateOneScriptTemplateArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<ScriptTemplateInput>;
};

export type MutationUpdateOneUserArgs = {
  id: Scalars["ID"];
  params?: InputMaybe<UserProfileInput>;
};

export type MutationUpdateOneUserActiveOrgArgs = {
  activeOrganizationId: Scalars["ID"];
};

export type MutationUpdateOrganizationSuperAdminArgs = {
  organizationId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationUploadInstancesArgs = {
  instances?: InputMaybe<Array<InputMaybe<UploadInstanceInput>>>;
  organizationId: Scalars["ID"];
};

export type MutationVerifyAuthenticationArgs = {
  params: AuthenticateParamsInput;
  serviceName: Scalars["String"];
};

export type MutationVerifyEmailArgs = {
  token: Scalars["String"];
};

export type MutationVerifyMfaArgs = {
  email: Scalars["String"];
  pin: Scalars["String"];
};

export type MutationResponse = {
  __typename?: "MutationResponse";
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  success?: Maybe<Scalars["Boolean"]>;
};

export type OragnizationReportsSettingInput = {
  enabled?: InputMaybe<Scalars["Boolean"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type OragnizationReportsSettings = {
  __typename?: "OragnizationReportsSettings";
  capacity?: Maybe<OrganizationReportSetting>;
  sla?: Maybe<OrganizationReportSetting>;
};

export type OragnizationReportsSettingsInput = {
  capacity?: InputMaybe<OragnizationReportsSettingInput>;
  sla?: InputMaybe<OragnizationReportsSettingInput>;
};

export type Organization = {
  __typename?: "Organization";
  apmApiKeyExists?: Maybe<Scalars["String"]>;
  apmApiUrl?: Maybe<Scalars["String"]>;
  apmService?: Maybe<ApmServiceEnum>;
  /** The name of the bucket where backup history is stored */
  backupHistoryBucketName?: Maybe<Scalars["String"]>;
  /** Which cloud vendor is the backup history stored on? */
  backupHistoryLocation?: Maybe<CloudVendorTypeEnum>;
  billingAtten?: Maybe<Array<Maybe<Scalars["String"]>>>;
  billingId?: Maybe<Scalars["String"]>;
  /** the cloud service where the bucket is located */
  cmdbBucketLocation?: Maybe<CloudVendorTypeEnum>;
  /** the name of the bucket where cdmb data is located */
  cmdbBucketName?: Maybe<Scalars["String"]>;
  configurationType?: Maybe<OrganizationConfigurationTypeEnum>;
  customer_tla?: Maybe<Scalars["String"]>;
  deactivated?: Maybe<Scalars["Boolean"]>;
  documentsBucketName?: Maybe<Scalars["String"]>;
  documentsLocation?: Maybe<CloudVendorTypeEnum>;
  id: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  netTerms?: Maybe<Scalars["String"]>;
  settings?: Maybe<OrganizationSettings>;
  superUser?: Maybe<UserProfile>;
  ticketingApiKeyExists?: Maybe<Scalars["String"]>;
  ticketingService?: Maybe<TicketingServiceEnum>;
  users?: Maybe<Array<Maybe<UserProfile>>>;
};

export enum OrganizationConfigurationTypeEnum {
  Internal = "internal",
  Samanage = "samanage",
}

export type OrganizationFeatureSettings = {
  __typename?: "OrganizationFeatureSettings";
  billingEnabled?: Maybe<Scalars["Boolean"]>;
  dataProtectionEnabled?: Maybe<Scalars["Boolean"]>;
  documentsEnabled?: Maybe<Scalars["Boolean"]>;
  reportsEnabled?: Maybe<Scalars["Boolean"]>;
  ticketsEnabled?: Maybe<Scalars["Boolean"]>;
  vpnEnabled?: Maybe<Scalars["Boolean"]>;
};

export type OrganizationFeatureSettingsInput = {
  backupsEnabled?: InputMaybe<Scalars["Boolean"]>;
  billingEnabled?: InputMaybe<Scalars["Boolean"]>;
  dataProtectionEnabled?: InputMaybe<Scalars["Boolean"]>;
  documentsEnabled?: InputMaybe<Scalars["Boolean"]>;
  reportsEnabled?: InputMaybe<Scalars["Boolean"]>;
  ticketsEnabled?: InputMaybe<Scalars["Boolean"]>;
  vpnEnabled?: InputMaybe<Scalars["Boolean"]>;
};

export type OrganizationInput = {
  apmApiKey?: InputMaybe<Scalars["String"]>;
  apmApiUrl?: InputMaybe<Scalars["String"]>;
  apmService?: InputMaybe<ApmServiceEnum>;
  /** The name of the bucket where backup history is stored */
  backupHistoryBucketName?: InputMaybe<Scalars["String"]>;
  /** Which cloud vendor is the backup history stored on? */
  backupHistoryLocation?: InputMaybe<CloudVendorTypeEnum>;
  billingAddress?: InputMaybe<AddressLocationInput>;
  billingAtten?: InputMaybe<Scalars["String"]>;
  billingId?: InputMaybe<Scalars["String"]>;
  /** the cloud service where the bucket is located */
  cmdbBucketLocation?: InputMaybe<CloudVendorTypeEnum>;
  /** the name of the bucket where cdmb data is located */
  cmdbBucketName?: InputMaybe<Scalars["String"]>;
  configurationType?: InputMaybe<OrganizationConfigurationTypeEnum>;
  customer_tla?: InputMaybe<Scalars["String"]>;
  documentsBucketName?: InputMaybe<Scalars["String"]>;
  documentsLocation?: InputMaybe<CloudVendorTypeEnum>;
  name?: InputMaybe<Scalars["String"]>;
  netTerms?: InputMaybe<Scalars["String"]>;
  settings?: InputMaybe<OrganizationSettingsInput>;
  ticketingService?: InputMaybe<TicketingServiceEnum>;
  ticketingServiceApiKey?: InputMaybe<Scalars["String"]>;
};

export type OrganizationReportSetting = {
  __typename?: "OrganizationReportSetting";
  enabled?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
};

export type OrganizationSettings = {
  __typename?: "OrganizationSettings";
  features?: Maybe<OrganizationFeatureSettings>;
  reports?: Maybe<OragnizationReportsSettings>;
};

export type OrganizationSettingsInput = {
  features?: InputMaybe<OrganizationFeatureSettingsInput>;
  reports?: InputMaybe<OragnizationReportsSettingsInput>;
};

export type OrganizationSlaBackupNamespace = {
  __typename?: "OrganizationSlaBackupNamespace";
  /** client url for the bucket, e.g. https://itunonprodbackups.blob.core.usgovcloudapi.net/ */
  clientUrl?: Maybe<Scalars["String"]>;
  /** the name of the bucket holding the TXT file with config */
  configBucketName?: Maybe<Scalars["String"]>;
  /** 'RG-ITU-NonProd'... this helps us lookup the instance keys/api keys we'll need to hit the bucket */
  resourceGroupName?: Maybe<Scalars["String"]>;
  /**  e.g.  'itunonprodbackups' and 'ituprodbackups'  */
  storageAccountName?: Maybe<Scalars["String"]>;
};

export type OrganizationSlaBackupNamespaceInput = {
  /** client url for the bucket, e.g. https://itunonprodbackups.blob.core.usgovcloudapi.net/ */
  clientUrl?: InputMaybe<Scalars["String"]>;
  /** the name of the bucket holding the TXT file with config */
  configBucketName?: InputMaybe<Scalars["String"]>;
  /** 'RG-ITU-NonProd'... this helps us lookup the instance keys/api keys we'll need to hit the bucket */
  resourceGroupName?: InputMaybe<Scalars["String"]>;
  /**  e.g.  'itunonprodbackups' and 'ituprodbackups'  */
  storageAccountName?: InputMaybe<Scalars["String"]>;
};

export type OrganizationSlaBackupSettings = {
  __typename?: "OrganizationSlaBackupSettings";
  enabled?: Maybe<Scalars["Boolean"]>;
  namespaces?: Maybe<Array<Maybe<OrganizationSlaBackupNamespace>>>;
  ns2?: Maybe<Scalars["Boolean"]>;
};

export type OrganizationSlaBackupSettingsInput = {
  enabled?: InputMaybe<Scalars["Boolean"]>;
  namespaces?: InputMaybe<Array<InputMaybe<OrganizationSlaBackupNamespaceInput>>>;
  ns2?: InputMaybe<Scalars["Boolean"]>;
};

export type OrganizationSlaFeatureSettings = {
  __typename?: "OrganizationSlaFeatureSettings";
  backups?: Maybe<OrganizationSlaBackupSettings>;
  enabled?: Maybe<Scalars["Boolean"]>;
  nonProdDiskUsage?: Maybe<Scalars["Boolean"]>;
  nonProdUptime?: Maybe<Scalars["Boolean"]>;
  prodDiskUsage?: Maybe<Scalars["Boolean"]>;
  prodUptime?: Maybe<Scalars["Boolean"]>;
  title?: Maybe<Scalars["String"]>;
};

export type OrganizationSlaFeatureSettingsInput = {
  backups?: InputMaybe<OrganizationSlaBackupSettingsInput>;
  enabled?: InputMaybe<Scalars["Boolean"]>;
  nonProdDiskUsage?: InputMaybe<Scalars["Boolean"]>;
  nonProdUptime?: InputMaybe<Scalars["Boolean"]>;
  prodDiskUsage?: InputMaybe<Scalars["Boolean"]>;
  prodUptime?: InputMaybe<Scalars["Boolean"]>;
};

export type Permission = {
  __typename?: "Permission";
  accesses?: Maybe<Array<Maybe<Access>>>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
};

export type PermissionInput = {
  accesses: Array<AccessNameEnum>;
  admin_only?: InputMaybe<Scalars["Boolean"]>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type Policy = {
  __typename?: "Policy";
  commands?: Maybe<Array<Maybe<Scalars["String"]>>>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  logFile?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  system?: Maybe<PolicySystemEnum>;
  vendor?: Maybe<CloudVendorTypeEnum>;
};

export enum PolicySystemEnum {
  Bd2 = "BD2",
  Bp2 = "BP2",
  Bq2 = "BQ2",
  Bs3 = "BS3",
  Hd2 = "HD2",
  Hp2 = "HP2",
  Hq2 = "HQ2",
  Hs3 = "HS3",
  Saprouter = "SAPROUTER",
  Solman = "Solman",
  Xfr = "XFR",
}

export type Query = {
  __typename?: "Query";
  currentUser?: Maybe<UserProfile>;
  getAccessesBySection?: Maybe<Array<Maybe<AccessSection>>>;
  getAllDueInvoices?: Maybe<InvoiceResult>;
  getAllSkus?: Maybe<SkuResult>;
  getBasisMetrics?: Maybe<Array<MetricResult>>;
  getBusinessDocumentsByOrganization?: Maybe<Array<BusinessDocumentCategory>>;
  getCostAnalysis?: Maybe<Array<Maybe<CostAnalysisResult>>>;
  getDiskUsedPercent?: Maybe<Array<Maybe<MetricResult>>>;
  getEnvironments?: Maybe<Array<Environment>>;
  getHanaDbMetrics?: Maybe<Array<MetricResult>>;
  getManyApplications?: Maybe<Array<Maybe<Application>>>;
  getManyEnvironments?: Maybe<Array<Maybe<Environment>>>;
  getManyIncidents?: Maybe<Array<Maybe<IncidentSummary>>>;
  getManyInstanceBackups?: Maybe<Array<Maybe<TierBackup>>>;
  getManyInstanceKeys?: Maybe<Array<Maybe<InstanceKey>>>;
  getManyInstanceTypes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getManyInstances?: Maybe<Array<Instance>>;
  getManyInvoices?: Maybe<InvoiceResult>;
  getManyOrganizations?: Maybe<Array<Maybe<Organization>>>;
  getManyOverDueInvoices?: Maybe<InvoiceResult>;
  getManyPermissions?: Maybe<Array<Maybe<Permission>>>;
  getManyPolicies?: Maybe<Array<Maybe<Policy>>>;
  getManyScriptBlocks?: Maybe<Array<Maybe<ScriptBlock>>>;
  getManyScriptBroadcasts?: Maybe<Array<Maybe<ScriptBroadcast>>>;
  getManyScriptTemplates?: Maybe<Array<Maybe<ScriptTemplate>>>;
  getManySkus?: Maybe<SkuResult>;
  getManySupportTickets?: Maybe<SupportTicketResult>;
  getManyUsers?: Maybe<Array<UserProfile>>;
  getManyVendors?: Maybe<Array<Maybe<CloudVendorTypeEnum>>>;
  getOneApplication?: Maybe<Application>;
  getOneEnvironment?: Maybe<Environment>;
  getOneInstance?: Maybe<Instance>;
  getOneOrganization?: Maybe<Organization>;
  getOneSupportTicket?: Maybe<SupportTicket>;
  getOneUser?: Maybe<UserProfile>;
  getRegionsByVendor?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getReport_Capacity?: Maybe<Array<MetricResult>>;
  getSLA_DiskUtilization?: Maybe<Array<Maybe<SlaDiskUtilResponseItem>>>;
  getSLA_SummaryTable?: Maybe<Array<Maybe<SlaUptimeSummaryTableItem>>>;
  getSLA_Uptime?: Maybe<Array<Maybe<SlaUptimeItem>>>;
  getSkuBreakdown?: Maybe<InvoiceSkuBreakdownResult>;
  getSystemMetrics?: Maybe<Array<MetricResult>>;
  getTicketSummary?: Maybe<Array<Maybe<TicketSummaryResponse>>>;
  getUser?: Maybe<User>;
  getUsersByOrganization?: Maybe<Array<UserProfile>>;
  getVPNs?: Maybe<Array<Maybe<VpnDataItem>>>;
  getVpnMetrics?: Maybe<Array<VpnMetric>>;
  search?: Maybe<SearchResults>;
  twoFactorSecret?: Maybe<TwoFactorSecretKey>;
};

export type QueryGetAllDueInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetAllSkusArgs = {
  page?: InputMaybe<Scalars["Int"]>;
  searchTerm?: InputMaybe<Scalars["String"]>;
};

export type QueryGetBasisMetricsArgs = {
  instanceId: Scalars["String"];
  relativeTime?: InputMaybe<RelativeTimeEnum>;
};

export type QueryGetBusinessDocumentsByOrganizationArgs = {
  organizationId: Scalars["String"];
};

export type QueryGetCostAnalysisArgs = {
  endDate?: InputMaybe<Scalars["String"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
  startDate?: InputMaybe<Scalars["String"]>;
};

export type QueryGetDiskUsedPercentArgs = {
  instanceId: Scalars["String"];
  relativeTime?: InputMaybe<RelativeTimeEnum>;
};

export type QueryGetEnvironmentsArgs = {
  applicationId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetHanaDbMetricsArgs = {
  instanceId: Scalars["String"];
  relativeTime?: InputMaybe<RelativeTimeEnum>;
};

export type QueryGetManyApplicationsArgs = {
  organizationId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetManyEnvironmentsArgs = {
  applicationId?: InputMaybe<Scalars["ID"]>;
  environmentType?: InputMaybe<EnvironmentTypeEnum>;
};

export type QueryGetManyInstanceBackupsArgs = {
  organizationId: Scalars["ID"];
};

export type QueryGetManyInstanceKeysArgs = {
  organizationId: Scalars["ID"];
};

export type QueryGetManyInstancesArgs = {
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  instanceTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  organizationId?: InputMaybe<Scalars["ID"]>;
};

export type QueryGetManyInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetManyOverDueInvoicesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetManyPermissionsArgs = {
  adminOnly?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryGetManyScriptBlocksArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetManyScriptBroadcastsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetManyScriptTemplatesArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetManySkusArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetManySupportTicketsArgs = {
  page: Scalars["Int"];
  perPage: Scalars["Int"];
  type: SupportTicketTypeEnum;
};

export type QueryGetManyUsersArgs = {
  deactivated?: InputMaybe<Scalars["Boolean"]>;
  userTypes?: InputMaybe<Array<UserTypeEnum>>;
};

export type QueryGetOneApplicationArgs = {
  applicationId: Scalars["ID"];
};

export type QueryGetOneEnvironmentArgs = {
  environmentId: Scalars["ID"];
};

export type QueryGetOneInstanceArgs = {
  instanceId: Scalars["String"];
};

export type QueryGetOneOrganizationArgs = {
  id: Scalars["ID"];
};

export type QueryGetOneSupportTicketArgs = {
  id: Scalars["Int"];
  type: SupportTicketTypeEnum;
};

export type QueryGetOneUserArgs = {
  id: Scalars["ID"];
};

export type QueryGetRegionsByVendorArgs = {
  vendor: CloudVendorTypeEnum;
};

export type QueryGetReport_CapacityArgs = {
  relativeTime?: InputMaybe<RelativeTimeEnum>;
};

export type QueryGetSla_DiskUtilizationArgs = {
  endDate?: InputMaybe<Scalars["Float"]>;
  production?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["Float"]>;
};

export type QueryGetSla_SummaryTableArgs = {
  endDate?: InputMaybe<Scalars["Float"]>;
  startDate?: InputMaybe<Scalars["Float"]>;
};

export type QueryGetSla_UptimeArgs = {
  endDate?: InputMaybe<Scalars["Float"]>;
  production?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<Scalars["Float"]>;
  uptimeType?: InputMaybe<UptimeTypeEnum>;
};

export type QueryGetSkuBreakdownArgs = {
  endDate: Scalars["String"];
  organizationId?: InputMaybe<Scalars["ID"]>;
  startDate: Scalars["String"];
};

export type QueryGetSystemMetricsArgs = {
  instanceId: Scalars["String"];
  relativeTime?: InputMaybe<RelativeTimeEnum>;
};

export type QueryGetUsersByOrganizationArgs = {
  deactivated?: InputMaybe<Scalars["Boolean"]>;
  organizationId: Scalars["ID"];
};

export type QueryGetVpnMetricsArgs = {
  organizationId: Scalars["ID"];
  tunnelIpAddress: Scalars["String"];
};

export type QuerySearchArgs = {
  searchText?: InputMaybe<Scalars["String"]>;
};

export enum RelativeTimeEnum {
  Day = "day",
  Hour = "hour",
  Month = "month",
  Week = "week",
}

export type ScriptBlock = {
  __typename?: "ScriptBlock";
  command?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type ScriptBroadcast = {
  __typename?: "ScriptBroadcast";
  description?: Maybe<Scalars["String"]>;
  environment?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  steps?: Maybe<Array<Maybe<ScriptStep>>>;
};

export type ScriptStep = {
  __typename?: "ScriptStep";
  command?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  /** if the command is a failure, this is the ID of the next command */
  failureId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  instance?: Maybe<Scalars["String"]>;
  last_finish?: Maybe<Scalars["String"]>;
  last_start?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  position?: Maybe<ScriptStepPosition>;
  result?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  /** if command is successful, this is the ID of the next command */
  successId?: Maybe<Scalars["String"]>;
};

export type ScriptStepInput = {
  command?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  /** if the command is a failure, this is the ID of the next command */
  failureId?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  /** position is used for positioning the step in the UI (e.g. react-flow) */
  position?: InputMaybe<ScriptStepPositionInput>;
  /** if command is successful, this is the ID of the next command */
  successId?: InputMaybe<Scalars["String"]>;
};

export type ScriptStepPosition = {
  __typename?: "ScriptStepPosition";
  x?: Maybe<Scalars["Int"]>;
  y?: Maybe<Scalars["Int"]>;
};

export type ScriptStepPositionInput = {
  x?: InputMaybe<Scalars["Int"]>;
  y?: InputMaybe<Scalars["Int"]>;
};

export type ScriptTemplate = {
  __typename?: "ScriptTemplate";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  steps?: Maybe<Array<Maybe<ScriptStep>>>;
};

export type ScriptTemplateInput = {
  description?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  steps?: InputMaybe<Array<InputMaybe<ScriptStepInput>>>;
};

export type SearchResults = {
  __typename?: "SearchResults";
  instances?: Maybe<Array<Maybe<Instance>>>;
};

/** A shortened version of the organizaztion the returns significantly less data than a full organization */
export type ShortOrganization = {
  __typename?: "ShortOrganization";
  billingAddress?: Maybe<AddressLocation>;
  billingAtten?: Maybe<Array<Maybe<Scalars["String"]>>>;
  id?: Maybe<Scalars["ID"]>;
  name?: Maybe<Scalars["String"]>;
  netTerms?: Maybe<Scalars["String"]>;
  settings?: Maybe<OrganizationSettings>;
};

export type Sku = {
  __typename?: "Sku";
  Billing_type?: Maybe<Scalars["String"]>;
  Billing_unit?: Maybe<Scalars["String"]>;
  Cost?: Maybe<Scalars["String"]>;
  Margin?: Maybe<Scalars["String"]>;
  Price?: Maybe<Scalars["String"]>;
  Rollup_code?: Maybe<Scalars["String"]>;
  Rollup_desc?: Maybe<Scalars["String"]>;
  SKU_Code?: Maybe<Scalars["String"]>;
  SKU_desc?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
};

export type SkuResult = {
  __typename?: "SkuResult";
  count?: Maybe<Scalars["Int"]>;
  results?: Maybe<Array<Maybe<Sku>>>;
};

export type SlaDiskUtilResponseItem = {
  __typename?: "SlaDiskUtilResponseItem";
  hostDisplayName?: Maybe<Scalars["String"]>;
  sid?: Maybe<Scalars["String"]>;
  totalAllocated?: Maybe<Scalars["Float"]>;
  totalUsed?: Maybe<Scalars["Float"]>;
};

export type SlaUptimeItem = {
  __typename?: "SlaUptimeItem";
  hostDisplayName?: Maybe<Scalars["String"]>;
  sid?: Maybe<Scalars["String"]>;
  uptime?: Maybe<Scalars["Float"]>;
};

export type SlaUptimeSummaryTableItem = {
  __typename?: "SlaUptimeSummaryTableItem";
  comments?: Maybe<Scalars["String"]>;
  /** The uptime of this host/instance if it's runnnning a DB */
  db?: Maybe<Scalars["Float"]>;
  /** The uptime of this host/instance if it's a host */
  host?: Maybe<Scalars["Float"]>;
  hostDisplayName?: Maybe<Scalars["String"]>;
  /** The uptime of this host/instance if it's running SAP */
  sap?: Maybe<Scalars["Float"]>;
  sid?: Maybe<Scalars["String"]>;
};

export type StartEndDate = {
  __typename?: "StartEndDate";
  endDate?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
};

export type SupportTicket = {
  __typename?: "SupportTicket";
  /** Used for configuration items */
  assetTag?: Maybe<Scalars["String"]>;
  assignedTo?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Maybe<SupportTicketAttachment>>>;
  category?: Maybe<Scalars["String"]>;
  /** Used for changes */
  changePlan?: Maybe<Scalars["String"]>;
  comments?: Maybe<Array<Maybe<SupportTicketComment>>>;
  createdAt?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  dueAt?: Maybe<Scalars["String"]>;
  /** Used for categoryItems */
  dueDays?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  number?: Maybe<Scalars["Int"]>;
  organizationId?: Maybe<Scalars["ID"]>;
  priority?: Maybe<SupportTicketPriorityEnum>;
  /** The email or name of the user who created this request */
  requestedBy?: Maybe<Scalars["String"]>;
  resolutionCode?: Maybe<Scalars["String"]>;
  /** Used for incidents */
  resolutionDescription?: Maybe<Scalars["String"]>;
  rollbackPlan?: Maybe<Scalars["String"]>;
  /** Used for problems */
  rootCause?: Maybe<Scalars["String"]>;
  site?: Maybe<Scalars["String"]>;
  state?: Maybe<SupportTicketStateEnum>;
  subcategory?: Maybe<Scalars["String"]>;
  subject?: Maybe<Scalars["String"]>;
  symptoms?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Maybe<SupportTicketTagObj>>>;
  testPlan?: Maybe<Scalars["String"]>;
  type?: Maybe<SupportTicketTypeEnum>;
  updatedAt?: Maybe<Scalars["String"]>;
  workaround?: Maybe<Scalars["String"]>;
};

export type SupportTicketAttachment = {
  __typename?: "SupportTicketAttachment";
  filename?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  url?: Maybe<Scalars["String"]>;
};

export type SupportTicketAvatar = {
  __typename?: "SupportTicketAvatar";
  color?: Maybe<Scalars["String"]>;
  initials?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type SupportTicketComment = {
  __typename?: "SupportTicketComment";
  attachments?: Maybe<Array<Maybe<SupportTicketAttachment>>>;
  body?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  user?: Maybe<SupportTicketUser>;
};

export type SupportTicketPagination = {
  __typename?: "SupportTicketPagination";
  currentPage?: Maybe<Scalars["Int"]>;
  perPage?: Maybe<Scalars["Int"]>;
  totalCount?: Maybe<Scalars["Int"]>;
  totalPages?: Maybe<Scalars["Int"]>;
};

export enum SupportTicketPriorityEnum {
  Low = "LOW",
  Medium = "MEDIUM",
  None = "NONE",
}

export type SupportTicketResult = {
  __typename?: "SupportTicketResult";
  pagination?: Maybe<SupportTicketPagination>;
  result?: Maybe<SupportTicket>;
  results?: Maybe<Array<Maybe<SupportTicket>>>;
};

export enum SupportTicketStateEnum {
  Assigned = "ASSIGNED",
  Closed = "CLOSED",
  ClosedCompleted = "CLOSED_COMPLETED",
  InProgress = "IN_PROGRESS",
}

export type SupportTicketTagObj = {
  __typename?: "SupportTicketTagObj";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export enum SupportTicketTypeEnum {
  CatalogItems = "CATALOG_ITEMS",
  Changes = "CHANGES",
  ConfigurationItems = "CONFIGURATION_ITEMS",
  Incidents = "INCIDENTS",
  Problems = "PROBLEMS",
  Solutions = "SOLUTIONS",
}

export type SupportTicketUser = {
  __typename?: "SupportTicketUser";
  avatar?: Maybe<SupportTicketAvatar>;
  email?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

/**
 * ------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * ------------------------------------------------------
 */
export type TicketSummaryResponse = {
  __typename?: "TicketSummaryResponse";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  total?: Maybe<Scalars["Int"]>;
};

export enum TicketingServiceEnum {
  Samanage = "samanage",
  ServiceNow = "serviceNow",
}

export type TierBackup = {
  __typename?: "TierBackup";
  id?: Maybe<Scalars["String"]>;
  instance?: Maybe<Instance>;
  last15BackupsDB?: Maybe<Array<Maybe<Backup>>>;
  last15BackupsOS?: Maybe<Array<Maybe<Backup>>>;
  tier?: Maybe<Scalars["String"]>;
};

export type Tokens = {
  __typename?: "Tokens";
  accessToken?: Maybe<Scalars["String"]>;
  refreshToken?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKey = {
  __typename?: "TwoFactorSecretKey";
  ascii?: Maybe<Scalars["String"]>;
  base32?: Maybe<Scalars["String"]>;
  google_auth_qr?: Maybe<Scalars["String"]>;
  hex?: Maybe<Scalars["String"]>;
  otpauth_url?: Maybe<Scalars["String"]>;
  qr_code_ascii?: Maybe<Scalars["String"]>;
  qr_code_base32?: Maybe<Scalars["String"]>;
  qr_code_hex?: Maybe<Scalars["String"]>;
};

export type TwoFactorSecretKeyInput = {
  ascii?: InputMaybe<Scalars["String"]>;
  base32?: InputMaybe<Scalars["String"]>;
  google_auth_qr?: InputMaybe<Scalars["String"]>;
  hex?: InputMaybe<Scalars["String"]>;
  otpauth_url?: InputMaybe<Scalars["String"]>;
  qr_code_ascii?: InputMaybe<Scalars["String"]>;
  qr_code_base32?: InputMaybe<Scalars["String"]>;
  qr_code_hex?: InputMaybe<Scalars["String"]>;
};

export type UploadInstanceInput = {
  customDeviceId?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  dynatraceDetectedName?: InputMaybe<Scalars["String"]>;
  externalId?: InputMaybe<Scalars["String"]>;
  instanceType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  pattern?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
  resourceGroup?: InputMaybe<Scalars["String"]>;
  vendor?: InputMaybe<CloudVendorTypeEnum>;
};

export enum UptimeTypeEnum {
  Db = "db",
  Host = "host",
  Sap = "sap",
}

export type User = {
  __typename?: "User";
  emails?: Maybe<Array<EmailRecord>>;
  id: Scalars["ID"];
  username?: Maybe<Scalars["String"]>;
};

export type UserInput = {
  email?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  username?: InputMaybe<Scalars["String"]>;
};

/** The main User object used for authentication etc */
export type UserProfile = {
  __typename?: "UserProfile";
  acceptedInvite?: Maybe<Scalars["Boolean"]>;
  activeOrganization?: Maybe<ShortOrganization>;
  deactivated?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  organizations?: Maybe<Array<Maybe<ShortOrganization>>>;
  permission?: Maybe<Scalars["String"]>;
  recentlySentInvite?: Maybe<Scalars["String"]>;
  userType?: Maybe<UserTypeEnum>;
};

export type UserProfileInput = {
  customPermissions?: InputMaybe<Array<InputMaybe<AccessNameEnum>>>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  organizationId?: InputMaybe<Scalars["ID"]>;
  organizationIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  permission?: InputMaybe<Scalars["ID"]>;
  userType?: InputMaybe<UserTypeEnum>;
};

export enum UserTypeEnum {
  Client = "client",
  Engineer = "engineer",
  SuperAdmin = "superAdmin",
}

export type VpnDataItem = {
  __typename?: "VpnDataItem";
  id?: Maybe<Scalars["String"]>;
  ipAddress?: Maybe<Scalars["String"]>;
  isRunning?: Maybe<Scalars["Boolean"]>;
  lastUpdated?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  organizationId?: Maybe<Scalars["ID"]>;
};

export type VpnMetric = {
  __typename?: "VpnMetric";
  datapoints?: Maybe<Array<Maybe<DataPoint>>>;
  label?: Maybe<Scalars["String"]>;
};

export type InstanceFragmentFragment = {
  __typename?: "Instance";
  id: string;
  externalId?: string | null;
  name?: string | null;
  description?: string | null;
  state?: InstanceStateEnum | null;
  isRunning?: boolean | null;
  isProduction?: boolean | null;
  numberOfMetrics?: number | null;
  architecture?: string | null;
  instanceType?: string | null;
  instanceSize?: string | null;
  pattern?: string | null;
  dynatraceHostId?: string | null;
  cpu?: string | null;
  rootDeviceType?: string | null;
  privateIPAddress?: string | null;
  subnet?: string | null;
  additionalInfo?: any | null;
  instanceSpecs?: any | null;
  updatedAt?: string | null;
  resourceGroup?: string | null;
  osType?: string | null;
  osVersion?: string | null;
  vendor?: CloudVendorTypeEnum | null;
  region?: string | null;
  customDeviceId?: string | null;
};

export type InstanceKeyFragmentFragment = {
  __typename?: "InstanceKey";
  id?: string | null;
  vendor?: CloudVendorTypeEnum | null;
  name?: string | null;
  description?: string | null;
  region?: string | null;
  use?: InstanceKeyUseEnum | null;
  instances?: { __typename?: "Instance"; id: string; name?: string | null } | null;
};

export type InvoiceFragmentFragment = {
  __typename?: "Invoice";
  id?: string | null;
  ClientId?: string | null;
  Invoiceno?: string | null;
  Invoice_Date?: string | null;
  Invoice_due_date?: string | null;
  Invoice_amount?: string | null;
  Invoice_Notes?: string | null;
  Date_Paid?: string | null;
  Amount_Paid?: string | null;
  balance?: string | null;
  organization?: {
    __typename?: "ShortOrganization";
    id?: string | null;
    name?: string | null;
    netTerms?: string | null;
    billingAtten?: Array<string | null> | null;
    billingAddress?: {
      __typename?: "AddressLocation";
      id?: string | null;
      street1?: string | null;
      street2?: string | null;
      postal?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
    } | null;
  } | null;
  billingDetails?: {
    __typename?: "InvoiceBillingDetails";
    name?: string | null;
    address1?: string | null;
    address2?: string | null;
    phone?: string | null;
    email?: string | null;
    ein?: string | null;
    bankAccount?: string | null;
    bankRouting?: string | null;
    bankSwift?: string | null;
  } | null;
  lineItems?: Array<{
    __typename?: "InvoiceLineItem";
    id: string;
    sku?: string | null;
    description?: string | null;
    quantity?: string | null;
    price?: string | null;
    total?: string | null;
  }> | null;
};

export type OrganizationFragmentFragment = {
  __typename?: "Organization";
  id: string;
  name?: string | null;
  billingId?: string | null;
  customer_tla?: string | null;
  backupHistoryLocation?: CloudVendorTypeEnum | null;
  backupHistoryBucketName?: string | null;
  documentsLocation?: CloudVendorTypeEnum | null;
  documentsBucketName?: string | null;
  cmdbBucketName?: string | null;
  cmdbBucketLocation?: CloudVendorTypeEnum | null;
  configurationType?: OrganizationConfigurationTypeEnum | null;
  ticketingService?: TicketingServiceEnum | null;
  apmService?: ApmServiceEnum | null;
  apmApiUrl?: string | null;
  apmApiKeyExists?: string | null;
  ticketingApiKeyExists?: string | null;
  superUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
  settings?: {
    __typename?: "OrganizationSettings";
    reports?: {
      __typename?: "OragnizationReportsSettings";
      capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
      sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
    } | null;
    features?: {
      __typename?: "OrganizationFeatureSettings";
      documentsEnabled?: boolean | null;
      ticketsEnabled?: boolean | null;
      dataProtectionEnabled?: boolean | null;
      vpnEnabled?: boolean | null;
      billingEnabled?: boolean | null;
      reportsEnabled?: boolean | null;
    } | null;
  } | null;
};

export type OrganizationSettingsFragmentFragment = {
  __typename?: "OrganizationSettings";
  reports?: {
    __typename?: "OragnizationReportsSettings";
    capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
    sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
  } | null;
  features?: {
    __typename?: "OrganizationFeatureSettings";
    documentsEnabled?: boolean | null;
    ticketsEnabled?: boolean | null;
    dataProtectionEnabled?: boolean | null;
    vpnEnabled?: boolean | null;
    billingEnabled?: boolean | null;
    reportsEnabled?: boolean | null;
  } | null;
};

export type PermissionFragmentFragment = { __typename?: "Permission"; id?: string | null; name?: string | null };

export type ScriptTemplateFragmentFragment = {
  __typename?: "ScriptTemplate";
  id: string;
  name?: string | null;
  description?: string | null;
  steps?: Array<{
    __typename?: "ScriptStep";
    id: string;
    name: string;
    description?: string | null;
    command?: string | null;
    successId?: string | null;
    failureId?: string | null;
    position?: { __typename?: "ScriptStepPosition"; x?: number | null; y?: number | null } | null;
  } | null> | null;
};

export type ShortOrganizationFragmentFragment = {
  __typename?: "ShortOrganization";
  id?: string | null;
  name?: string | null;
  settings?: {
    __typename?: "OrganizationSettings";
    reports?: {
      __typename?: "OragnizationReportsSettings";
      capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
      sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
    } | null;
    features?: {
      __typename?: "OrganizationFeatureSettings";
      documentsEnabled?: boolean | null;
      ticketsEnabled?: boolean | null;
      dataProtectionEnabled?: boolean | null;
      vpnEnabled?: boolean | null;
      billingEnabled?: boolean | null;
      reportsEnabled?: boolean | null;
    } | null;
  } | null;
};

export type UserFragmentFragment = {
  __typename: "UserProfile";
  id?: string | null;
  userType?: UserTypeEnum | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  activeOrganization?: {
    __typename?: "ShortOrganization";
    id?: string | null;
    name?: string | null;
    settings?: {
      __typename?: "OrganizationSettings";
      reports?: {
        __typename?: "OragnizationReportsSettings";
        capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
      } | null;
      features?: {
        __typename?: "OrganizationFeatureSettings";
        documentsEnabled?: boolean | null;
        ticketsEnabled?: boolean | null;
        dataProtectionEnabled?: boolean | null;
        vpnEnabled?: boolean | null;
        billingEnabled?: boolean | null;
        reportsEnabled?: boolean | null;
      } | null;
    } | null;
  } | null;
  organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
};

export type AuthenticateMutationVariables = Exact<{
  params: AuthenticateParamsInput;
}>;

export type AuthenticateMutation = {
  __typename?: "Mutation";
  authenticate?: {
    __typename?: "LoginResult";
    sessionId?: string | null;
    tokens?: { __typename?: "Tokens"; refreshToken?: string | null; accessToken?: string | null } | null;
  } | null;
};

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars["String"];
  newPassword: Scalars["String"];
}>;

export type ChangePasswordMutation = { __typename?: "Mutation"; changePassword?: boolean | null };

export type CreateApplicationMutationVariables = Exact<{
  params: ApplicationInput;
}>;

export type CreateApplicationMutation = {
  __typename?: "Mutation";
  createApplication?: { __typename?: "Application"; id: string; name?: string | null } | null;
};

export type CreateEnvironmentMutationVariables = Exact<{
  params: EnvironmentInput;
}>;

export type CreateEnvironmentMutation = {
  __typename?: "Mutation";
  createEnvironment?: { __typename?: "Environment"; id?: string | null; name?: string | null } | null;
};

export type CreateInstanceKeyMutationVariables = Exact<{
  params: InstanceKeyInput;
}>;

export type CreateInstanceKeyMutation = {
  __typename?: "Mutation";
  createInstanceKey?: { __typename?: "InstanceKey"; id?: string | null; name?: string | null; vendor?: CloudVendorTypeEnum | null } | null;
};

export type CreateNewUserMutationVariables = Exact<{
  params: UserProfileInput;
}>;

export type CreateNewUserMutation = {
  __typename?: "Mutation";
  createNewUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
};

export type CreateOneInstanceMutationVariables = Exact<{
  params?: InputMaybe<InstanceInput>;
}>;

export type CreateOneInstanceMutation = {
  __typename?: "Mutation";
  createOneInstance?: {
    __typename?: "Instance";
    id: string;
    externalId?: string | null;
    name?: string | null;
    description?: string | null;
    state?: InstanceStateEnum | null;
    isRunning?: boolean | null;
    isProduction?: boolean | null;
    numberOfMetrics?: number | null;
    architecture?: string | null;
    instanceType?: string | null;
    instanceSize?: string | null;
    pattern?: string | null;
    dynatraceHostId?: string | null;
    cpu?: string | null;
    rootDeviceType?: string | null;
    privateIPAddress?: string | null;
    subnet?: string | null;
    additionalInfo?: any | null;
    instanceSpecs?: any | null;
    updatedAt?: string | null;
    resourceGroup?: string | null;
    osType?: string | null;
    osVersion?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    region?: string | null;
    customDeviceId?: string | null;
  } | null;
};

export type CreateOneScriptTemplateMutationVariables = Exact<{
  params: ScriptTemplateInput;
}>;

export type CreateOneScriptTemplateMutation = {
  __typename?: "Mutation";
  createOneScriptTemplate?: {
    __typename?: "ScriptTemplate";
    id: string;
    name?: string | null;
    description?: string | null;
    steps?: Array<{
      __typename?: "ScriptStep";
      id: string;
      name: string;
      description?: string | null;
      command?: string | null;
      successId?: string | null;
      failureId?: string | null;
      position?: { __typename?: "ScriptStepPosition"; x?: number | null; y?: number | null } | null;
    } | null> | null;
  } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  params: OrganizationInput;
}>;

export type CreateOrganizationMutation = {
  __typename?: "Mutation";
  createOrganization?: {
    __typename?: "Organization";
    id: string;
    name?: string | null;
    billingId?: string | null;
    customer_tla?: string | null;
    backupHistoryLocation?: CloudVendorTypeEnum | null;
    backupHistoryBucketName?: string | null;
    documentsLocation?: CloudVendorTypeEnum | null;
    documentsBucketName?: string | null;
    cmdbBucketName?: string | null;
    cmdbBucketLocation?: CloudVendorTypeEnum | null;
    configurationType?: OrganizationConfigurationTypeEnum | null;
    ticketingService?: TicketingServiceEnum | null;
    apmService?: ApmServiceEnum | null;
    apmApiUrl?: string | null;
    apmApiKeyExists?: string | null;
    ticketingApiKeyExists?: string | null;
    superUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
    settings?: {
      __typename?: "OrganizationSettings";
      reports?: {
        __typename?: "OragnizationReportsSettings";
        capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
      } | null;
      features?: {
        __typename?: "OrganizationFeatureSettings";
        documentsEnabled?: boolean | null;
        ticketsEnabled?: boolean | null;
        dataProtectionEnabled?: boolean | null;
        vpnEnabled?: boolean | null;
        billingEnabled?: boolean | null;
        reportsEnabled?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type CreatePermissionMutationVariables = Exact<{
  params: PermissionInput;
}>;

export type CreatePermissionMutation = {
  __typename?: "Mutation";
  createPermission?: { __typename?: "Permission"; id?: string | null; name?: string | null } | null;
};

export type CreateSuperAdminUserMutationVariables = Exact<{
  params: UserProfileInput;
}>;

export type CreateSuperAdminUserMutation = {
  __typename?: "Mutation";
  createSuperAdminUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
};

export type DeactivateOrganizationMutationVariables = Exact<{
  organizationId: Scalars["ID"];
}>;

export type DeactivateOrganizationMutation = {
  __typename?: "Mutation";
  deactivateOrganization?: { __typename?: "MutationResponse"; success?: boolean | null } | null;
};

export type DeleteInstancekeyMutationVariables = Exact<{
  instanceKeyId: Scalars["ID"];
}>;

export type DeleteInstancekeyMutation = {
  __typename?: "Mutation";
  deleteInstancekey?: { __typename?: "MutationResponse"; success?: boolean | null } | null;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation"; deleteUser?: { __typename?: "MutationResponse"; success?: boolean | null } | null };

export type ImpersonateOrganizationMutationVariables = Exact<{
  organizationId: Scalars["ID"];
}>;

export type ImpersonateOrganizationMutation = {
  __typename?: "Mutation";
  impersonateOrganization?: {
    __typename: "UserProfile";
    id?: string | null;
    userType?: UserTypeEnum | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    activeOrganization?: {
      __typename?: "ShortOrganization";
      id?: string | null;
      name?: string | null;
      settings?: {
        __typename?: "OrganizationSettings";
        reports?: {
          __typename?: "OragnizationReportsSettings";
          capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
          sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        } | null;
        features?: {
          __typename?: "OrganizationFeatureSettings";
          documentsEnabled?: boolean | null;
          ticketsEnabled?: boolean | null;
          dataProtectionEnabled?: boolean | null;
          vpnEnabled?: boolean | null;
          billingEnabled?: boolean | null;
          reportsEnabled?: boolean | null;
        } | null;
      } | null;
    } | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  params: AuthenticateParamsInput;
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login?: {
    __typename?: "LoginResponse";
    mfaRequired?: boolean | null;
    sessionId?: string | null;
    tokens?: { __typename?: "Tokens"; refreshToken?: string | null; accessToken?: string | null } | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "Mutation"; logout?: boolean | null };

export type RefreshInstanceDataMutationVariables = Exact<{
  instanceId?: InputMaybe<Scalars["ID"]>;
  organizationId?: InputMaybe<Scalars["ID"]>;
}>;

export type RefreshInstanceDataMutation = {
  __typename?: "Mutation";
  refreshInstanceData?: {
    __typename?: "Instance";
    id: string;
    externalId?: string | null;
    name?: string | null;
    description?: string | null;
    state?: InstanceStateEnum | null;
    isRunning?: boolean | null;
    isProduction?: boolean | null;
    numberOfMetrics?: number | null;
    architecture?: string | null;
    instanceType?: string | null;
    instanceSize?: string | null;
    pattern?: string | null;
    dynatraceHostId?: string | null;
    cpu?: string | null;
    rootDeviceType?: string | null;
    privateIPAddress?: string | null;
    subnet?: string | null;
    additionalInfo?: any | null;
    instanceSpecs?: any | null;
    updatedAt?: string | null;
    resourceGroup?: string | null;
    osType?: string | null;
    osVersion?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    region?: string | null;
    customDeviceId?: string | null;
  } | null;
};

export type RequestMagicLinkEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type RequestMagicLinkEmailMutation = { __typename?: "Mutation"; requestMagicLinkEmail?: boolean | null };

export type ResendInviteEmailMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type ResendInviteEmailMutation = {
  __typename?: "Mutation";
  resendInviteEmail?: { __typename?: "MutationResponse"; success?: boolean | null } | null;
};

export type ResendMfaMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type ResendMfaMutation = { __typename?: "Mutation"; resendMfa?: { __typename?: "MutationResponse"; success?: boolean | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"];
  token: Scalars["String"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: {
    __typename?: "LoginResult";
    sessionId?: string | null;
    tokens?: { __typename?: "Tokens"; refreshToken?: string | null; accessToken?: string | null } | null;
  } | null;
};

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendResetPasswordEmailMutation = { __typename?: "Mutation"; sendResetPasswordEmail?: boolean | null };

export type TestKeyMutationVariables = Exact<{ [key: string]: never }>;

export type TestKeyMutation = { __typename?: "Mutation"; testKey?: { __typename?: "MutationResponse"; success?: boolean | null } | null };

export type UpdateOneApplicationMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: InputMaybe<ApplicationInput>;
}>;

export type UpdateOneApplicationMutation = {
  __typename?: "Mutation";
  updateOneApplication?: { __typename?: "Application"; id: string; name?: string | null } | null;
};

export type UpdateOneEnvironmentMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: InputMaybe<EnvironmentInput>;
}>;

export type UpdateOneEnvironmentMutation = {
  __typename?: "Mutation";
  updateOneEnvironment?: {
    __typename?: "Environment";
    id?: string | null;
    name?: string | null;
    isProduction?: boolean | null;
    isRunning?: boolean | null;
    environmentType?: EnvironmentTypeEnum | null;
    vendor?: CloudVendorTypeEnum | null;
    applicationId?: string | null;
    instances?: Array<{
      __typename?: "Instance";
      id: string;
      name?: string | null;
      description?: string | null;
      state?: InstanceStateEnum | null;
      isRunning?: boolean | null;
      isProduction?: boolean | null;
      numberOfMetrics?: number | null;
    } | null> | null;
  } | null;
};

export type UpdateOneInstanceMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: InputMaybe<InstanceEditInput>;
}>;

export type UpdateOneInstanceMutation = {
  __typename?: "Mutation";
  updateOneInstance?: {
    __typename?: "Instance";
    id: string;
    externalId?: string | null;
    name?: string | null;
    description?: string | null;
    state?: InstanceStateEnum | null;
    isRunning?: boolean | null;
    isProduction?: boolean | null;
    numberOfMetrics?: number | null;
    architecture?: string | null;
    instanceType?: string | null;
    instanceSize?: string | null;
    pattern?: string | null;
    dynatraceHostId?: string | null;
    cpu?: string | null;
    rootDeviceType?: string | null;
    privateIPAddress?: string | null;
    subnet?: string | null;
    additionalInfo?: any | null;
    instanceSpecs?: any | null;
    updatedAt?: string | null;
    resourceGroup?: string | null;
    osType?: string | null;
    osVersion?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    region?: string | null;
    customDeviceId?: string | null;
  } | null;
};

export type UpdateOneInstanceKeyMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: InputMaybe<InstanceKeyEditInput>;
}>;

export type UpdateOneInstanceKeyMutation = {
  __typename?: "Mutation";
  updateOneInstanceKey?: {
    __typename?: "InstanceKey";
    id?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    name?: string | null;
    description?: string | null;
    region?: string | null;
    use?: InstanceKeyUseEnum | null;
    instances?: { __typename?: "Instance"; id: string; name?: string | null } | null;
  } | null;
};

export type UpdateOneOrganizationMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: InputMaybe<OrganizationInput>;
}>;

export type UpdateOneOrganizationMutation = {
  __typename?: "Mutation";
  updateOneOrganization?: {
    __typename?: "Organization";
    id: string;
    name?: string | null;
    billingId?: string | null;
    customer_tla?: string | null;
    backupHistoryLocation?: CloudVendorTypeEnum | null;
    backupHistoryBucketName?: string | null;
    documentsLocation?: CloudVendorTypeEnum | null;
    documentsBucketName?: string | null;
    cmdbBucketName?: string | null;
    cmdbBucketLocation?: CloudVendorTypeEnum | null;
    configurationType?: OrganizationConfigurationTypeEnum | null;
    ticketingService?: TicketingServiceEnum | null;
    apmService?: ApmServiceEnum | null;
    apmApiUrl?: string | null;
    apmApiKeyExists?: string | null;
    ticketingApiKeyExists?: string | null;
    superUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
    settings?: {
      __typename?: "OrganizationSettings";
      reports?: {
        __typename?: "OragnizationReportsSettings";
        capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
      } | null;
      features?: {
        __typename?: "OrganizationFeatureSettings";
        documentsEnabled?: boolean | null;
        ticketsEnabled?: boolean | null;
        dataProtectionEnabled?: boolean | null;
        vpnEnabled?: boolean | null;
        billingEnabled?: boolean | null;
        reportsEnabled?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateOneUserMutationVariables = Exact<{
  id: Scalars["ID"];
  params?: InputMaybe<UserProfileInput>;
}>;

export type UpdateOneUserMutation = {
  __typename?: "Mutation";
  updateOneUser?: {
    __typename?: "UserProfile";
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    permission?: string | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  } | null;
};

export type UpdateOneUserActiveOrgMutationVariables = Exact<{
  activeOrganizationId: Scalars["ID"];
}>;

export type UpdateOneUserActiveOrgMutation = {
  __typename?: "Mutation";
  updateOneUserActiveOrg?: {
    __typename?: "UserProfile";
    id?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    permission?: string | null;
    activeOrganization?: {
      __typename?: "ShortOrganization";
      id?: string | null;
      name?: string | null;
      settings?: {
        __typename?: "OrganizationSettings";
        reports?: {
          __typename?: "OragnizationReportsSettings";
          capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
          sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        } | null;
        features?: {
          __typename?: "OrganizationFeatureSettings";
          documentsEnabled?: boolean | null;
          ticketsEnabled?: boolean | null;
          dataProtectionEnabled?: boolean | null;
          vpnEnabled?: boolean | null;
          billingEnabled?: boolean | null;
          reportsEnabled?: boolean | null;
        } | null;
      } | null;
    } | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  } | null;
};

export type UpdateOrganizationSuperAdminMutationVariables = Exact<{
  userId: Scalars["ID"];
  organizationId: Scalars["ID"];
}>;

export type UpdateOrganizationSuperAdminMutation = {
  __typename?: "Mutation";
  updateOrganizationSuperAdmin?: {
    __typename?: "Organization";
    id: string;
    superUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
  } | null;
};

export type UploadInstancesMutationVariables = Exact<{
  organizationId: Scalars["ID"];
  instances?: InputMaybe<Array<InputMaybe<UploadInstanceInput>> | InputMaybe<UploadInstanceInput>>;
}>;

export type UploadInstancesMutation = {
  __typename?: "Mutation";
  uploadInstances?: { __typename?: "MutationResponse"; success?: boolean | null } | null;
};

export type VerifyMfaMutationVariables = Exact<{
  pin: Scalars["String"];
  email: Scalars["String"];
}>;

export type VerifyMfaMutation = {
  __typename?: "Mutation";
  verifyMfa?: {
    __typename?: "LoginResponse";
    mfaRequired?: boolean | null;
    sessionId?: string | null;
    tokens?: { __typename?: "Tokens"; refreshToken?: string | null; accessToken?: string | null } | null;
  } | null;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: "Query";
  currentUser?: {
    __typename: "UserProfile";
    id?: string | null;
    userType?: UserTypeEnum | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    activeOrganization?: {
      __typename?: "ShortOrganization";
      id?: string | null;
      name?: string | null;
      settings?: {
        __typename?: "OrganizationSettings";
        reports?: {
          __typename?: "OragnizationReportsSettings";
          capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
          sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        } | null;
        features?: {
          __typename?: "OrganizationFeatureSettings";
          documentsEnabled?: boolean | null;
          ticketsEnabled?: boolean | null;
          dataProtectionEnabled?: boolean | null;
          vpnEnabled?: boolean | null;
          billingEnabled?: boolean | null;
          reportsEnabled?: boolean | null;
        } | null;
      } | null;
    } | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  } | null;
};

export type GetAccessesBySectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetAccessesBySectionQuery = {
  __typename?: "Query";
  getAccessesBySection?: Array<{
    __typename?: "AccessSection";
    sectionName?: string | null;
    items?: Array<{ __typename?: "AccessItem"; key?: AccessNameEnum | null; name?: string | null } | null> | null;
  } | null> | null;
};

export type GetAllDueInvoicesQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars["ID"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetAllDueInvoicesQuery = {
  __typename?: "Query";
  getAllDueInvoices?: {
    __typename?: "InvoiceResult";
    count?: number | null;
    results?: Array<{
      __typename?: "Invoice";
      id?: string | null;
      ClientId?: string | null;
      Invoiceno?: string | null;
      Invoice_Date?: string | null;
      Invoice_due_date?: string | null;
      Invoice_amount?: string | null;
      Invoice_Notes?: string | null;
      Date_Paid?: string | null;
      Amount_Paid?: string | null;
      balance?: string | null;
      organization?: {
        __typename?: "ShortOrganization";
        id?: string | null;
        name?: string | null;
        netTerms?: string | null;
        billingAtten?: Array<string | null> | null;
        billingAddress?: {
          __typename?: "AddressLocation";
          id?: string | null;
          street1?: string | null;
          street2?: string | null;
          postal?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
        } | null;
      } | null;
      billingDetails?: {
        __typename?: "InvoiceBillingDetails";
        name?: string | null;
        address1?: string | null;
        address2?: string | null;
        phone?: string | null;
        email?: string | null;
        ein?: string | null;
        bankAccount?: string | null;
        bankRouting?: string | null;
        bankSwift?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "InvoiceLineItem";
        id: string;
        sku?: string | null;
        description?: string | null;
        quantity?: string | null;
        price?: string | null;
        total?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetBasisMetricsQueryVariables = Exact<{
  instanceId: Scalars["String"];
  relativeTime: RelativeTimeEnum;
}>;

export type GetBasisMetricsQuery = {
  __typename?: "Query";
  getBasisMetrics?: Array<{
    __typename?: "MetricResult";
    metric?: string | null;
    metricName?: string | null;
    metricType: MetricUnitTypeEnum;
    metricValues?: Array<{ __typename?: "DataPoint"; timestamp?: string | null; value?: number | null } | null> | null;
  }> | null;
};

export type GetBusinessDocumentsByOrganizationQueryVariables = Exact<{
  organizationId: Scalars["String"];
}>;

export type GetBusinessDocumentsByOrganizationQuery = {
  __typename?: "Query";
  getBusinessDocumentsByOrganization?: Array<{
    __typename?: "BusinessDocumentCategory";
    id?: string | null;
    name?: string | null;
    documents?: Array<{
      __typename?: "BusinessDocument";
      id?: string | null;
      name?: string | null;
      lastModified?: string | null;
      size?: string | null;
      type?: BusinessDocumentTypeEnum | null;
      url?: string | null;
      documents?: Array<{
        __typename?: "BusinessDocument";
        id?: string | null;
        name?: string | null;
        lastModified?: string | null;
        size?: string | null;
        type?: BusinessDocumentTypeEnum | null;
        url?: string | null;
      } | null> | null;
    } | null> | null;
  }> | null;
};

export type GetCostAnalysisQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetCostAnalysisQuery = {
  __typename?: "Query";
  getCostAnalysis?: Array<{
    __typename?: "CostAnalysisResult";
    id?: string | null;
    values?: Array<{ __typename?: "CostAnalysisValue"; key?: string | null; value?: string | null } | null> | null;
  } | null> | null;
};

export type GetDiskUsedPercentQueryVariables = Exact<{
  instanceId: Scalars["String"];
  relativeTime: RelativeTimeEnum;
}>;

export type GetDiskUsedPercentQuery = {
  __typename?: "Query";
  getDiskUsedPercent?: Array<{
    __typename?: "MetricResult";
    metric?: string | null;
    metricName?: string | null;
    metricType: MetricUnitTypeEnum;
    metricValues?: Array<{ __typename?: "DataPoint"; timestamp?: string | null; value?: number | null; unit?: string | null } | null> | null;
  } | null> | null;
};

export type GetEnvironmentsQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetEnvironmentsQuery = {
  __typename?: "Query";
  getEnvironments?: Array<{
    __typename?: "Environment";
    id?: string | null;
    name?: string | null;
    isProduction?: boolean | null;
    isRunning?: boolean | null;
    environmentType?: EnvironmentTypeEnum | null;
    instances?: Array<{
      __typename?: "Instance";
      id: string;
      name?: string | null;
      description?: string | null;
      state?: InstanceStateEnum | null;
      isRunning?: boolean | null;
      isProduction?: boolean | null;
      numberOfMetrics?: number | null;
    } | null> | null;
  }> | null;
};

export type GetHanaDbMetricsQueryVariables = Exact<{
  instanceId: Scalars["String"];
  relativeTime: RelativeTimeEnum;
}>;

export type GetHanaDbMetricsQuery = {
  __typename?: "Query";
  getHanaDbMetrics?: Array<{
    __typename?: "MetricResult";
    metric?: string | null;
    metricName?: string | null;
    metricType: MetricUnitTypeEnum;
    metricValues?: Array<{ __typename?: "DataPoint"; timestamp?: string | null; value?: number | null } | null> | null;
  }> | null;
};

export type GetInstanceBackupsQueryVariables = Exact<{
  instanceId: Scalars["String"];
}>;

export type GetInstanceBackupsQuery = {
  __typename?: "Query";
  getOneInstance?: {
    __typename?: "Instance";
    id: string;
    name?: string | null;
    backups?: Array<{
      __typename?: "Backup";
      id?: string | null;
      status?: BackupStatusEnum | null;
      state?: string | null;
      type?: BackupTypeEnum | null;
      startTime?: string | null;
      durationHours?: string | null;
      durationMinutes?: string | null;
      durationSeconds?: string | null;
      detail?: string | null;
    } | null> | null;
  } | null;
};

export type GetManyApplicationsQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars["ID"]>;
}>;

export type GetManyApplicationsQuery = {
  __typename?: "Query";
  getManyApplications?: Array<{
    __typename?: "Application";
    id: string;
    name?: string | null;
    environments?: Array<{
      __typename?: "Environment";
      id?: string | null;
      name?: string | null;
      isProduction?: boolean | null;
      isRunning?: boolean | null;
      environmentType?: EnvironmentTypeEnum | null;
      instances?: Array<{
        __typename?: "Instance";
        id: string;
        name?: string | null;
        description?: string | null;
        state?: InstanceStateEnum | null;
        isRunning?: boolean | null;
        isProduction?: boolean | null;
        numberOfMetrics?: number | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetManyEnvironmentsQueryVariables = Exact<{
  applicationId?: InputMaybe<Scalars["ID"]>;
  environmentType?: InputMaybe<EnvironmentTypeEnum>;
}>;

export type GetManyEnvironmentsQuery = {
  __typename?: "Query";
  getManyEnvironments?: Array<{
    __typename?: "Environment";
    id?: string | null;
    name?: string | null;
    isProduction?: boolean | null;
    isRunning?: boolean | null;
    environmentType?: EnvironmentTypeEnum | null;
    application?: { __typename?: "Application"; id: string; name?: string | null } | null;
    instances?: Array<{
      __typename?: "Instance";
      id: string;
      name?: string | null;
      description?: string | null;
      externalId?: string | null;
      state?: InstanceStateEnum | null;
      isRunning?: boolean | null;
      isProduction?: boolean | null;
      numberOfMetrics?: number | null;
      updatedAt?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetManyIncidentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyIncidentsQuery = {
  __typename?: "Query";
  getManyIncidents?: Array<{
    __typename?: "IncidentSummary";
    sysId?: string | null;
    number?: string | null;
    priority?: string | null;
    description?: string | null;
    openedAt?: string | null;
    state?: string | null;
  } | null> | null;
};

export type GetManyInstanceBackupsQueryVariables = Exact<{
  organizationId: Scalars["ID"];
}>;

export type GetManyInstanceBackupsQuery = {
  __typename?: "Query";
  getManyInstanceBackups?: Array<{
    __typename?: "TierBackup";
    id?: string | null;
    tier?: string | null;
    instance?: { __typename?: "Instance"; id: string; externalId?: string | null } | null;
    last15BackupsDB?: Array<{
      __typename?: "Backup";
      id?: string | null;
      status?: BackupStatusEnum | null;
      state?: string | null;
      type?: BackupTypeEnum | null;
      startTime?: string | null;
      durationHours?: string | null;
      durationMinutes?: string | null;
      durationSeconds?: string | null;
      detail?: string | null;
    } | null> | null;
    last15BackupsOS?: Array<{
      __typename?: "Backup";
      id?: string | null;
      status?: BackupStatusEnum | null;
      state?: string | null;
      type?: BackupTypeEnum | null;
      startTime?: string | null;
      durationHours?: string | null;
      durationMinutes?: string | null;
      durationSeconds?: string | null;
      detail?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetManyInstanceKeysQueryVariables = Exact<{
  organizationId: Scalars["ID"];
}>;

export type GetManyInstanceKeysQuery = {
  __typename?: "Query";
  getManyInstanceKeys?: Array<{
    __typename?: "InstanceKey";
    id?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    name?: string | null;
    description?: string | null;
    region?: string | null;
    use?: InstanceKeyUseEnum | null;
    instances?: { __typename?: "Instance"; id: string; name?: string | null } | null;
  } | null> | null;
};

export type GetManyInstanceTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyInstanceTypesQuery = { __typename?: "Query"; getManyInstanceTypes?: Array<string | null> | null };

export type GetManyInstancesQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars["ID"]>;
  instanceIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>>;
  instanceTypes?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetManyInstancesQuery = {
  __typename?: "Query";
  getManyInstances?: Array<{
    __typename?: "Instance";
    id: string;
    name?: string | null;
    description?: string | null;
    state?: InstanceStateEnum | null;
    isRunning?: boolean | null;
    externalId?: string | null;
    isProduction?: boolean | null;
    pattern?: string | null;
    resourceGroup?: string | null;
    numberOfMetrics?: number | null;
    instanceType?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    region?: string | null;
    environment?: { __typename?: "Environment"; id?: string | null; name?: string | null; environmentType?: EnvironmentTypeEnum | null } | null;
    application?: { __typename?: "Application"; id: string; name?: string | null } | null;
  }> | null;
};

export type GetManyInvoicesQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars["ID"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetManyInvoicesQuery = {
  __typename?: "Query";
  getManyInvoices?: {
    __typename?: "InvoiceResult";
    count?: number | null;
    results?: Array<{
      __typename?: "Invoice";
      id?: string | null;
      ClientId?: string | null;
      Invoiceno?: string | null;
      Invoice_Date?: string | null;
      Invoice_due_date?: string | null;
      Invoice_amount?: string | null;
      Invoice_Notes?: string | null;
      Date_Paid?: string | null;
      Amount_Paid?: string | null;
      balance?: string | null;
      organization?: {
        __typename?: "ShortOrganization";
        id?: string | null;
        name?: string | null;
        netTerms?: string | null;
        billingAtten?: Array<string | null> | null;
        billingAddress?: {
          __typename?: "AddressLocation";
          id?: string | null;
          street1?: string | null;
          street2?: string | null;
          postal?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
        } | null;
      } | null;
      billingDetails?: {
        __typename?: "InvoiceBillingDetails";
        name?: string | null;
        address1?: string | null;
        address2?: string | null;
        phone?: string | null;
        email?: string | null;
        ein?: string | null;
        bankAccount?: string | null;
        bankRouting?: string | null;
        bankSwift?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "InvoiceLineItem";
        id: string;
        sku?: string | null;
        description?: string | null;
        quantity?: string | null;
        price?: string | null;
        total?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetManyOrganizationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyOrganizationsQuery = {
  __typename?: "Query";
  getManyOrganizations?: Array<{ __typename?: "Organization"; id: string; name?: string | null; billingId?: string | null } | null> | null;
};

export type GetManyOverDueInvoicesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyOverDueInvoicesQuery = {
  __typename?: "Query";
  getManyOverDueInvoices?: {
    __typename?: "InvoiceResult";
    count?: number | null;
    results?: Array<{
      __typename?: "Invoice";
      id?: string | null;
      ClientId?: string | null;
      Invoiceno?: string | null;
      Invoice_Date?: string | null;
      Invoice_due_date?: string | null;
      Invoice_amount?: string | null;
      Invoice_Notes?: string | null;
      Date_Paid?: string | null;
      Amount_Paid?: string | null;
      balance?: string | null;
      organization?: {
        __typename?: "ShortOrganization";
        id?: string | null;
        name?: string | null;
        netTerms?: string | null;
        billingAtten?: Array<string | null> | null;
        billingAddress?: {
          __typename?: "AddressLocation";
          id?: string | null;
          street1?: string | null;
          street2?: string | null;
          postal?: string | null;
          city?: string | null;
          state?: string | null;
          country?: string | null;
        } | null;
      } | null;
      billingDetails?: {
        __typename?: "InvoiceBillingDetails";
        name?: string | null;
        address1?: string | null;
        address2?: string | null;
        phone?: string | null;
        email?: string | null;
        ein?: string | null;
        bankAccount?: string | null;
        bankRouting?: string | null;
        bankSwift?: string | null;
      } | null;
      lineItems?: Array<{
        __typename?: "InvoiceLineItem";
        id: string;
        sku?: string | null;
        description?: string | null;
        quantity?: string | null;
        price?: string | null;
        total?: string | null;
      }> | null;
    } | null> | null;
  } | null;
};

export type GetManyPermissionsQueryVariables = Exact<{
  adminOnly?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetManyPermissionsQuery = {
  __typename?: "Query";
  getManyPermissions?: Array<{
    __typename?: "Permission";
    id?: string | null;
    name?: string | null;
    accesses?: Array<{ __typename?: "Access"; id?: string | null; name?: AccessNameEnum | null } | null> | null;
  } | null> | null;
};

export type GetManyPoliciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyPoliciesQuery = {
  __typename?: "Query";
  getManyPolicies?: Array<{
    __typename?: "Policy";
    id?: string | null;
    name?: string | null;
    system?: PolicySystemEnum | null;
    vendor?: CloudVendorTypeEnum | null;
  } | null> | null;
};

export type GetManyScriptBlocksQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetManyScriptBlocksQuery = {
  __typename?: "Query";
  getManyScriptBlocks?: Array<{
    __typename?: "ScriptBlock";
    id: string;
    name?: string | null;
    command?: string | null;
    description?: string | null;
  } | null> | null;
};

export type GetManyScriptBroadcastsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetManyScriptBroadcastsQuery = {
  __typename?: "Query";
  getManyScriptBroadcasts?: Array<{
    __typename?: "ScriptBroadcast";
    id: string;
    name?: string | null;
    description?: string | null;
    steps?: Array<{
      __typename?: "ScriptStep";
      id: string;
      name: string;
      command?: string | null;
      description?: string | null;
      last_start?: string | null;
      last_finish?: string | null;
      status?: string | null;
      result?: string | null;
      instance?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetManyScriptTemplatesQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetManyScriptTemplatesQuery = {
  __typename?: "Query";
  getManyScriptTemplates?: Array<{
    __typename?: "ScriptTemplate";
    id: string;
    name?: string | null;
    description?: string | null;
    steps?: Array<{
      __typename?: "ScriptStep";
      id: string;
      name: string;
      description?: string | null;
      command?: string | null;
      successId?: string | null;
      failureId?: string | null;
      position?: { __typename?: "ScriptStepPosition"; x?: number | null; y?: number | null } | null;
    } | null> | null;
  } | null> | null;
};

export type GetManySkusQueryVariables = Exact<{
  skip?: InputMaybe<Scalars["Int"]>;
  limit?: InputMaybe<Scalars["Int"]>;
}>;

export type GetManySkusQuery = {
  __typename?: "Query";
  getManySkus?: {
    __typename?: "SkuResult";
    count?: number | null;
    results?: Array<{
      __typename?: "Sku";
      id?: string | null;
      SKU_Code?: string | null;
      SKU_desc?: string | null;
      Rollup_code?: string | null;
      Rollup_desc?: string | null;
      Price?: string | null;
      Cost?: string | null;
      Margin?: string | null;
      Billing_unit?: string | null;
      Billing_type?: string | null;
    } | null> | null;
  } | null;
};

export type GetManySupportTicketsQueryVariables = Exact<{
  type: SupportTicketTypeEnum;
  page: Scalars["Int"];
  perPage: Scalars["Int"];
}>;

export type GetManySupportTicketsQuery = {
  __typename?: "Query";
  getManySupportTickets?: {
    __typename?: "SupportTicketResult";
    results?: Array<{
      __typename?: "SupportTicket";
      id?: number | null;
      type?: SupportTicketTypeEnum | null;
      subject?: string | null;
      state?: SupportTicketStateEnum | null;
      priority?: SupportTicketPriorityEnum | null;
      createdAt?: string | null;
      requestedBy?: string | null;
    } | null> | null;
    pagination?: {
      __typename?: "SupportTicketPagination";
      perPage?: number | null;
      currentPage?: number | null;
      totalCount?: number | null;
      totalPages?: number | null;
    } | null;
  } | null;
};

export type GetManyUsersQueryVariables = Exact<{
  userTypes?: InputMaybe<Array<UserTypeEnum> | UserTypeEnum>;
  deactivated?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetManyUsersQuery = {
  __typename?: "Query";
  getManyUsers?: Array<{
    __typename?: "UserProfile";
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptedInvite?: boolean | null;
    recentlySentInvite?: string | null;
    deactivated?: boolean | null;
    userType?: UserTypeEnum | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  }> | null;
};

export type GetManyVendorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetManyVendorsQuery = { __typename?: "Query"; getManyVendors?: Array<CloudVendorTypeEnum | null> | null };

export type GetOneApplicationQueryVariables = Exact<{
  applicationId: Scalars["ID"];
}>;

export type GetOneApplicationQuery = {
  __typename?: "Query";
  getOneApplication?: { __typename?: "Application"; id: string; name?: string | null } | null;
};

export type GetOneEnvironmentQueryVariables = Exact<{
  environmentId: Scalars["ID"];
}>;

export type GetOneEnvironmentQuery = {
  __typename?: "Query";
  getOneEnvironment?: {
    __typename?: "Environment";
    id?: string | null;
    name?: string | null;
    isProduction?: boolean | null;
    isRunning?: boolean | null;
    environmentType?: EnvironmentTypeEnum | null;
    vendor?: CloudVendorTypeEnum | null;
    applicationId?: string | null;
    instances?: Array<{
      __typename?: "Instance";
      id: string;
      externalId?: string | null;
      name?: string | null;
      description?: string | null;
      state?: InstanceStateEnum | null;
      isRunning?: boolean | null;
      isProduction?: boolean | null;
      numberOfMetrics?: number | null;
      updatedAt?: string | null;
    } | null> | null;
  } | null;
};

export type GetOneInstanceQueryVariables = Exact<{
  instanceId: Scalars["String"];
}>;

export type GetOneInstanceQuery = {
  __typename?: "Query";
  getOneInstance?: {
    __typename?: "Instance";
    id: string;
    externalId?: string | null;
    name?: string | null;
    description?: string | null;
    state?: InstanceStateEnum | null;
    isRunning?: boolean | null;
    isProduction?: boolean | null;
    numberOfMetrics?: number | null;
    architecture?: string | null;
    instanceType?: string | null;
    dynatraceHostId?: string | null;
    instanceSize?: string | null;
    vendorInstanceType?: string | null;
    cpu?: string | null;
    region?: string | null;
    resourceGroup?: string | null;
    pattern?: string | null;
    rootDeviceType?: string | null;
    privateIPAddress?: string | null;
    subnet?: string | null;
    additionalInfo?: any | null;
    instanceSpecs?: any | null;
    lastInstanceSpecs?: any | null;
    customDeviceId?: string | null;
    updatedAt?: string | null;
    vendor?: CloudVendorTypeEnum | null;
    osType?: string | null;
    osVersion?: string | null;
    networkIn?: {
      __typename?: "InstanceNetworkItem";
      mean?: string | null;
      data?: Array<{ __typename?: "InstanceNetworkItemData"; timestamp?: string | null; value?: string | null; unit?: string | null } | null> | null;
    } | null;
    networkOut?: {
      __typename?: "InstanceNetworkItem";
      mean?: string | null;
      data?: Array<{ __typename?: "InstanceNetworkItemData"; timestamp?: string | null; value?: string | null; unit?: string | null } | null> | null;
    } | null;
    changeLogs?: Array<{
      __typename?: "InstanceChangeLog";
      id?: string | null;
      instanceId?: string | null;
      dateOfChange?: string | null;
      key?: string | null;
      oldValue?: string | null;
      typeOfChange?: InstanceChangeLogTypeEnum | null;
      changedById?: string | null;
      changedByString?: string | null;
    } | null> | null;
    application?: { __typename?: "Application"; id: string; name?: string | null } | null;
  } | null;
};

export type GetOneInstance_ShortQueryVariables = Exact<{
  instanceId: Scalars["String"];
}>;

export type GetOneInstance_ShortQuery = {
  __typename?: "Query";
  getOneInstance?: {
    __typename?: "Instance";
    id: string;
    externalId?: string | null;
    name?: string | null;
    description?: string | null;
    architecture?: string | null;
    instanceType?: string | null;
    instanceSize?: string | null;
    vendorInstanceType?: string | null;
    cpu?: string | null;
    region?: string | null;
    resourceGroup?: string | null;
    pattern?: string | null;
    rootDeviceType?: string | null;
    privateIPAddress?: string | null;
    subnet?: string | null;
    additionalInfo?: any | null;
    instanceSpecs?: any | null;
    lastInstanceSpecs?: any | null;
    customDeviceId?: string | null;
    dynatraceHostId?: string | null;
    updatedAt?: string | null;
    vendor?: CloudVendorTypeEnum | null;
  } | null;
};

export type GetOneOrganizationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneOrganizationQuery = {
  __typename?: "Query";
  getOneOrganization?: {
    __typename?: "Organization";
    id: string;
    name?: string | null;
    billingId?: string | null;
    customer_tla?: string | null;
    backupHistoryLocation?: CloudVendorTypeEnum | null;
    backupHistoryBucketName?: string | null;
    documentsLocation?: CloudVendorTypeEnum | null;
    documentsBucketName?: string | null;
    cmdbBucketName?: string | null;
    cmdbBucketLocation?: CloudVendorTypeEnum | null;
    configurationType?: OrganizationConfigurationTypeEnum | null;
    ticketingService?: TicketingServiceEnum | null;
    apmService?: ApmServiceEnum | null;
    apmApiUrl?: string | null;
    apmApiKeyExists?: string | null;
    ticketingApiKeyExists?: string | null;
    superUser?: { __typename?: "UserProfile"; id?: string | null; email?: string | null } | null;
    settings?: {
      __typename?: "OrganizationSettings";
      reports?: {
        __typename?: "OragnizationReportsSettings";
        capacity?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
        sla?: { __typename?: "OrganizationReportSetting"; title?: string | null; enabled?: boolean | null } | null;
      } | null;
      features?: {
        __typename?: "OrganizationFeatureSettings";
        documentsEnabled?: boolean | null;
        ticketsEnabled?: boolean | null;
        dataProtectionEnabled?: boolean | null;
        vpnEnabled?: boolean | null;
        billingEnabled?: boolean | null;
        reportsEnabled?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type GetOneSupportTicketQueryVariables = Exact<{
  id: Scalars["Int"];
  type: SupportTicketTypeEnum;
}>;

export type GetOneSupportTicketQuery = {
  __typename?: "Query";
  getOneSupportTicket?: {
    __typename?: "SupportTicket";
    id?: number | null;
    number?: number | null;
    type?: SupportTicketTypeEnum | null;
    subject?: string | null;
    description?: string | null;
    state?: SupportTicketStateEnum | null;
    priority?: SupportTicketPriorityEnum | null;
    createdAt?: string | null;
    requestedBy?: string | null;
    category?: string | null;
    subcategory?: string | null;
    assignedTo?: string | null;
    rootCause?: string | null;
    symptoms?: string | null;
    workaround?: string | null;
    resolutionDescription?: string | null;
    resolutionCode?: string | null;
    site?: string | null;
    dueDays?: string | null;
    updatedAt?: string | null;
    dueAt?: string | null;
    assetTag?: string | null;
    rollbackPlan?: string | null;
    testPlan?: string | null;
    changePlan?: string | null;
    tags?: Array<{ __typename?: "SupportTicketTagObj"; id?: string | null; name?: string | null } | null> | null;
    attachments?: Array<{ __typename?: "SupportTicketAttachment"; id?: number | null; filename?: string | null; url?: string | null } | null> | null;
    comments?: Array<{
      __typename?: "SupportTicketComment";
      id?: number | null;
      body?: string | null;
      createdAt?: string | null;
      updatedAt?: string | null;
      user?: {
        __typename?: "SupportTicketUser";
        id?: number | null;
        email?: string | null;
        name?: string | null;
        avatar?: { __typename?: "SupportTicketAvatar"; type?: string | null; color?: string | null; initials?: string | null } | null;
      } | null;
      attachments?: Array<{
        __typename?: "SupportTicketAttachment";
        id?: number | null;
        filename?: string | null;
        url?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetOneUserQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetOneUserQuery = {
  __typename?: "Query";
  getOneUser?: {
    __typename?: "UserProfile";
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    userType?: UserTypeEnum | null;
    permission?: string | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  } | null;
};

export type GetRegionsByVendorQueryVariables = Exact<{
  vendor: CloudVendorTypeEnum;
}>;

export type GetRegionsByVendorQuery = { __typename?: "Query"; getRegionsByVendor?: Array<string | null> | null };

export type GetReport_CapacityQueryVariables = Exact<{
  relativeTime?: InputMaybe<RelativeTimeEnum>;
}>;

export type GetReport_CapacityQuery = {
  __typename?: "Query";
  getReport_Capacity?: Array<{
    __typename?: "MetricResult";
    metric?: string | null;
    metricName?: string | null;
    metricType: MetricUnitTypeEnum;
    metricDescription?: string | null;
    metricValues?: Array<{ __typename?: "DataPoint"; timestamp?: string | null; value?: number | null } | null> | null;
  }> | null;
};

export type GetSla_DiskUtilizationQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Float"]>;
  endDate?: InputMaybe<Scalars["Float"]>;
  production?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetSla_DiskUtilizationQuery = {
  __typename?: "Query";
  getSLA_DiskUtilization?: Array<{
    __typename?: "SlaDiskUtilResponseItem";
    hostDisplayName?: string | null;
    totalAllocated?: number | null;
    totalUsed?: number | null;
  } | null> | null;
};

export type GetSla_SummaryTableQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Float"]>;
  endDate?: InputMaybe<Scalars["Float"]>;
}>;

export type GetSla_SummaryTableQuery = {
  __typename?: "Query";
  getSLA_SummaryTable?: Array<{
    __typename?: "SlaUptimeSummaryTableItem";
    sid?: string | null;
    hostDisplayName?: string | null;
    sap?: number | null;
    db?: number | null;
    host?: number | null;
    comments?: string | null;
  } | null> | null;
};

export type GetSla_UptimeQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars["Float"]>;
  endDate?: InputMaybe<Scalars["Float"]>;
  production?: InputMaybe<Scalars["Boolean"]>;
  uptimeType?: InputMaybe<UptimeTypeEnum>;
}>;

export type GetSla_UptimeQuery = {
  __typename?: "Query";
  getSLA_Uptime?: Array<{ __typename?: "SlaUptimeItem"; hostDisplayName?: string | null; uptime?: number | null; sid?: string | null } | null> | null;
};

export type GetSkuBreakdownQueryVariables = Exact<{
  organizationId?: InputMaybe<Scalars["ID"]>;
  startDate: Scalars["String"];
  endDate: Scalars["String"];
}>;

export type GetSkuBreakdownQuery = {
  __typename?: "Query";
  getSkuBreakdown?: {
    __typename?: "InvoiceSkuBreakdownResult";
    results?: Array<{ __typename?: "InvoiceSkuBreakdown"; SKU_code?: string | null; Sold_unit_price?: string | null } | null> | null;
  } | null;
};

export type GetSystemMetricsQueryVariables = Exact<{
  instanceId: Scalars["String"];
  relativeTime?: InputMaybe<RelativeTimeEnum>;
}>;

export type GetSystemMetricsQuery = {
  __typename?: "Query";
  getSystemMetrics?: Array<{
    __typename?: "MetricResult";
    metric?: string | null;
    metricName?: string | null;
    metricType: MetricUnitTypeEnum;
    metricDescription?: string | null;
    metricValues?: Array<{ __typename?: "DataPoint"; timestamp?: string | null; value?: number | null } | null> | null;
  }> | null;
};

export type GetTicketSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type GetTicketSummaryQuery = {
  __typename?: "Query";
  getTicketSummary?: Array<{ __typename?: "TicketSummaryResponse"; id?: string | null; name?: string | null; total?: number | null } | null> | null;
};

export type GetUsersByOrganizationQueryVariables = Exact<{
  organizationId: Scalars["ID"];
  deactivated?: InputMaybe<Scalars["Boolean"]>;
}>;

export type GetUsersByOrganizationQuery = {
  __typename?: "Query";
  getUsersByOrganization?: Array<{
    __typename?: "UserProfile";
    id?: string | null;
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    acceptedInvite?: boolean | null;
    recentlySentInvite?: string | null;
    deactivated?: boolean | null;
    userType?: UserTypeEnum | null;
    organizations?: Array<{ __typename?: "ShortOrganization"; id?: string | null; name?: string | null } | null> | null;
  }> | null;
};

export type GetVpNsQueryVariables = Exact<{ [key: string]: never }>;

export type GetVpNsQuery = {
  __typename?: "Query";
  getVPNs?: Array<{
    __typename?: "VpnDataItem";
    id?: string | null;
    name?: string | null;
    isRunning?: boolean | null;
    ipAddress?: string | null;
    lastUpdated?: string | null;
    organizationId?: string | null;
  } | null> | null;
};

export type GetVpnMetricsQueryVariables = Exact<{
  organizationId: Scalars["ID"];
  tunnelIpAddress: Scalars["String"];
}>;

export type GetVpnMetricsQuery = {
  __typename?: "Query";
  getVpnMetrics?: Array<{
    __typename?: "VpnMetric";
    label?: string | null;
    datapoints?: Array<{ __typename?: "DataPoint"; timestamp?: string | null; value?: number | null; unit?: string | null } | null> | null;
  }> | null;
};

export type SearchQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars["String"]>;
}>;

export type SearchQuery = {
  __typename?: "Query";
  search?: {
    __typename?: "SearchResults";
    instances?: Array<{ __typename?: "Instance"; id: string; name?: string | null; externalId?: string | null } | null> | null;
  } | null;
};

export type RefreshTokensMutationVariables = Exact<{
  accessToken: Scalars["String"];
  refreshToken: Scalars["String"];
}>;

export type RefreshTokensMutation = {
  __typename?: "Mutation";
  refreshTokens?: {
    __typename?: "LoginResult";
    sessionId?: string | null;
    tokens?: { __typename?: "Tokens"; refreshToken?: string | null; accessToken?: string | null } | null;
  } | null;
};

export const InstanceFragmentFragmentDoc = gql`
  fragment instanceFragment on Instance {
    id
    externalId
    name
    description
    state
    isRunning
    isProduction
    numberOfMetrics
    architecture
    instanceType
    instanceSize
    pattern
    dynatraceHostId
    cpu
    rootDeviceType
    privateIPAddress
    subnet
    additionalInfo
    instanceSpecs
    updatedAt
    resourceGroup
    osType
    osVersion
    vendor
    region
    customDeviceId
  }
`;
export const InstanceKeyFragmentFragmentDoc = gql`
  fragment instanceKeyFragment on InstanceKey {
    id
    vendor
    name
    description
    region
    use
    instances {
      id
      name
    }
  }
`;
export const InvoiceFragmentFragmentDoc = gql`
  fragment invoiceFragment on Invoice {
    id
    ClientId
    Invoiceno
    Invoice_Date
    Invoice_due_date
    Invoice_amount
    Invoice_Notes
    Date_Paid
    Amount_Paid
    balance
    organization {
      id
      name
      netTerms
      billingAtten
      billingAddress {
        id
        street1
        street2
        postal
        city
        state
        country
      }
    }
    billingDetails {
      name
      address1
      address2
      phone
      email
      ein
      bankAccount
      bankRouting
      bankSwift
    }
    lineItems {
      id
      sku
      description
      quantity
      price
      total
    }
  }
`;
export const OrganizationSettingsFragmentFragmentDoc = gql`
  fragment organizationSettingsFragment on OrganizationSettings {
    reports {
      capacity {
        title
        enabled
      }
      sla {
        title
        enabled
      }
    }
    features {
      documentsEnabled
      ticketsEnabled
      dataProtectionEnabled
      vpnEnabled
      billingEnabled
      reportsEnabled
    }
  }
`;
export const OrganizationFragmentFragmentDoc = gql`
  fragment organizationFragment on Organization {
    id
    name
    billingId
    customer_tla
    backupHistoryLocation
    backupHistoryBucketName
    documentsLocation
    documentsBucketName
    superUser {
      id
      email
    }
    cmdbBucketName
    cmdbBucketLocation
    configurationType
    ticketingService
    apmService
    apmApiUrl
    apmApiKeyExists
    ticketingApiKeyExists
    settings {
      ...organizationSettingsFragment
    }
  }
  ${OrganizationSettingsFragmentFragmentDoc}
`;
export const PermissionFragmentFragmentDoc = gql`
  fragment permissionFragment on Permission {
    id
    name
  }
`;
export const ScriptTemplateFragmentFragmentDoc = gql`
  fragment scriptTemplateFragment on ScriptTemplate {
    id
    name
    description
    steps {
      id
      name
      description
      command
      successId
      failureId
      position {
        x
        y
      }
    }
  }
`;
export const ShortOrganizationFragmentFragmentDoc = gql`
  fragment shortOrganizationFragment on ShortOrganization {
    id
    name
    settings {
      ...organizationSettingsFragment
    }
  }
  ${OrganizationSettingsFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql`
  fragment userFragment on UserProfile {
    id
    __typename
    userType
    email
    firstName
    lastName
    activeOrganization {
      ...shortOrganizationFragment
    }
    organizations {
      id
      name
    }
  }
  ${ShortOrganizationFragmentFragmentDoc}
`;
export const AuthenticateDocument = gql`
  mutation authenticate($params: AuthenticateParamsInput!) {
    authenticate(serviceName: "password", params: $params) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
}
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const ChangePasswordDocument = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateApplicationDocument = gql`
  mutation createApplication($params: ApplicationInput!) {
    createApplication(params: $params) {
      id
      name
    }
  }
`;
export type CreateApplicationMutationFn = Apollo.MutationFunction<CreateApplicationMutation, CreateApplicationMutationVariables>;

/**
 * __useCreateApplicationMutation__
 *
 * To run a mutation, you first call `useCreateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApplicationMutation, { data, loading, error }] = useCreateApplicationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateApplicationMutation, CreateApplicationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateApplicationMutation, CreateApplicationMutationVariables>(CreateApplicationDocument, options);
}
export type CreateApplicationMutationHookResult = ReturnType<typeof useCreateApplicationMutation>;
export type CreateApplicationMutationResult = Apollo.MutationResult<CreateApplicationMutation>;
export type CreateApplicationMutationOptions = Apollo.BaseMutationOptions<CreateApplicationMutation, CreateApplicationMutationVariables>;
export const CreateEnvironmentDocument = gql`
  mutation createEnvironment($params: EnvironmentInput!) {
    createEnvironment(params: $params) {
      id
      name
    }
  }
`;
export type CreateEnvironmentMutationFn = Apollo.MutationFunction<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;

/**
 * __useCreateEnvironmentMutation__
 *
 * To run a mutation, you first call `useCreateEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnvironmentMutation, { data, loading, error }] = useCreateEnvironmentMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>(CreateEnvironmentDocument, options);
}
export type CreateEnvironmentMutationHookResult = ReturnType<typeof useCreateEnvironmentMutation>;
export type CreateEnvironmentMutationResult = Apollo.MutationResult<CreateEnvironmentMutation>;
export type CreateEnvironmentMutationOptions = Apollo.BaseMutationOptions<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;
export const CreateInstanceKeyDocument = gql`
  mutation createInstanceKey($params: InstanceKeyInput!) {
    createInstanceKey(params: $params) {
      id
      name
      vendor
    }
  }
`;
export type CreateInstanceKeyMutationFn = Apollo.MutationFunction<CreateInstanceKeyMutation, CreateInstanceKeyMutationVariables>;

/**
 * __useCreateInstanceKeyMutation__
 *
 * To run a mutation, you first call `useCreateInstanceKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstanceKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstanceKeyMutation, { data, loading, error }] = useCreateInstanceKeyMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateInstanceKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInstanceKeyMutation, CreateInstanceKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInstanceKeyMutation, CreateInstanceKeyMutationVariables>(CreateInstanceKeyDocument, options);
}
export type CreateInstanceKeyMutationHookResult = ReturnType<typeof useCreateInstanceKeyMutation>;
export type CreateInstanceKeyMutationResult = Apollo.MutationResult<CreateInstanceKeyMutation>;
export type CreateInstanceKeyMutationOptions = Apollo.BaseMutationOptions<CreateInstanceKeyMutation, CreateInstanceKeyMutationVariables>;
export const CreateNewUserDocument = gql`
  mutation createNewUser($params: UserProfileInput!) {
    createNewUser(params: $params) {
      id
      email
    }
  }
`;
export type CreateNewUserMutationFn = Apollo.MutationFunction<CreateNewUserMutation, CreateNewUserMutationVariables>;

/**
 * __useCreateNewUserMutation__
 *
 * To run a mutation, you first call `useCreateNewUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewUserMutation, { data, loading, error }] = useCreateNewUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateNewUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewUserMutation, CreateNewUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNewUserMutation, CreateNewUserMutationVariables>(CreateNewUserDocument, options);
}
export type CreateNewUserMutationHookResult = ReturnType<typeof useCreateNewUserMutation>;
export type CreateNewUserMutationResult = Apollo.MutationResult<CreateNewUserMutation>;
export type CreateNewUserMutationOptions = Apollo.BaseMutationOptions<CreateNewUserMutation, CreateNewUserMutationVariables>;
export const CreateOneInstanceDocument = gql`
  mutation createOneInstance($params: InstanceInput) {
    createOneInstance(params: $params) {
      ...instanceFragment
    }
  }
  ${InstanceFragmentFragmentDoc}
`;
export type CreateOneInstanceMutationFn = Apollo.MutationFunction<CreateOneInstanceMutation, CreateOneInstanceMutationVariables>;

/**
 * __useCreateOneInstanceMutation__
 *
 * To run a mutation, you first call `useCreateOneInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneInstanceMutation, { data, loading, error }] = useCreateOneInstanceMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOneInstanceMutation, CreateOneInstanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOneInstanceMutation, CreateOneInstanceMutationVariables>(CreateOneInstanceDocument, options);
}
export type CreateOneInstanceMutationHookResult = ReturnType<typeof useCreateOneInstanceMutation>;
export type CreateOneInstanceMutationResult = Apollo.MutationResult<CreateOneInstanceMutation>;
export type CreateOneInstanceMutationOptions = Apollo.BaseMutationOptions<CreateOneInstanceMutation, CreateOneInstanceMutationVariables>;
export const CreateOneScriptTemplateDocument = gql`
  mutation createOneScriptTemplate($params: ScriptTemplateInput!) {
    createOneScriptTemplate(params: $params) {
      ...scriptTemplateFragment
    }
  }
  ${ScriptTemplateFragmentFragmentDoc}
`;
export type CreateOneScriptTemplateMutationFn = Apollo.MutationFunction<CreateOneScriptTemplateMutation, CreateOneScriptTemplateMutationVariables>;

/**
 * __useCreateOneScriptTemplateMutation__
 *
 * To run a mutation, you first call `useCreateOneScriptTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneScriptTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneScriptTemplateMutation, { data, loading, error }] = useCreateOneScriptTemplateMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOneScriptTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOneScriptTemplateMutation, CreateOneScriptTemplateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOneScriptTemplateMutation, CreateOneScriptTemplateMutationVariables>(CreateOneScriptTemplateDocument, options);
}
export type CreateOneScriptTemplateMutationHookResult = ReturnType<typeof useCreateOneScriptTemplateMutation>;
export type CreateOneScriptTemplateMutationResult = Apollo.MutationResult<CreateOneScriptTemplateMutation>;
export type CreateOneScriptTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateOneScriptTemplateMutation,
  CreateOneScriptTemplateMutationVariables
>;
export const CreateOrganizationDocument = gql`
  mutation createOrganization($params: OrganizationInput!) {
    createOrganization(params: $params) {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const CreatePermissionDocument = gql`
  mutation createPermission($params: PermissionInput!) {
    createPermission(params: $params) {
      ...permissionFragment
    }
  }
  ${PermissionFragmentFragmentDoc}
`;
export type CreatePermissionMutationFn = Apollo.MutationFunction<CreatePermissionMutation, CreatePermissionMutationVariables>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePermissionMutation, CreatePermissionMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(CreatePermissionDocument, options);
}
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>;
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<CreatePermissionMutation, CreatePermissionMutationVariables>;
export const CreateSuperAdminUserDocument = gql`
  mutation createSuperAdminUser($params: UserProfileInput!) {
    createSuperAdminUser(params: $params) {
      id
      email
    }
  }
`;
export type CreateSuperAdminUserMutationFn = Apollo.MutationFunction<CreateSuperAdminUserMutation, CreateSuperAdminUserMutationVariables>;

/**
 * __useCreateSuperAdminUserMutation__
 *
 * To run a mutation, you first call `useCreateSuperAdminUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuperAdminUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuperAdminUserMutation, { data, loading, error }] = useCreateSuperAdminUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateSuperAdminUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSuperAdminUserMutation, CreateSuperAdminUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSuperAdminUserMutation, CreateSuperAdminUserMutationVariables>(CreateSuperAdminUserDocument, options);
}
export type CreateSuperAdminUserMutationHookResult = ReturnType<typeof useCreateSuperAdminUserMutation>;
export type CreateSuperAdminUserMutationResult = Apollo.MutationResult<CreateSuperAdminUserMutation>;
export type CreateSuperAdminUserMutationOptions = Apollo.BaseMutationOptions<CreateSuperAdminUserMutation, CreateSuperAdminUserMutationVariables>;
export const DeactivateOrganizationDocument = gql`
  mutation deactivateOrganization($organizationId: ID!) {
    deactivateOrganization(organizationId: $organizationId) {
      success
    }
  }
`;
export type DeactivateOrganizationMutationFn = Apollo.MutationFunction<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>;

/**
 * __useDeactivateOrganizationMutation__
 *
 * To run a mutation, you first call `useDeactivateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateOrganizationMutation, { data, loading, error }] = useDeactivateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDeactivateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeactivateOrganizationMutation, DeactivateOrganizationMutationVariables>(DeactivateOrganizationDocument, options);
}
export type DeactivateOrganizationMutationHookResult = ReturnType<typeof useDeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationResult = Apollo.MutationResult<DeactivateOrganizationMutation>;
export type DeactivateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  DeactivateOrganizationMutation,
  DeactivateOrganizationMutationVariables
>;
export const DeleteInstancekeyDocument = gql`
  mutation deleteInstancekey($instanceKeyId: ID!) {
    deleteInstancekey(instanceKeyId: $instanceKeyId) {
      success
    }
  }
`;
export type DeleteInstancekeyMutationFn = Apollo.MutationFunction<DeleteInstancekeyMutation, DeleteInstancekeyMutationVariables>;

/**
 * __useDeleteInstancekeyMutation__
 *
 * To run a mutation, you first call `useDeleteInstancekeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInstancekeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInstancekeyMutation, { data, loading, error }] = useDeleteInstancekeyMutation({
 *   variables: {
 *      instanceKeyId: // value for 'instanceKeyId'
 *   },
 * });
 */
export function useDeleteInstancekeyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInstancekeyMutation, DeleteInstancekeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInstancekeyMutation, DeleteInstancekeyMutationVariables>(DeleteInstancekeyDocument, options);
}
export type DeleteInstancekeyMutationHookResult = ReturnType<typeof useDeleteInstancekeyMutation>;
export type DeleteInstancekeyMutationResult = Apollo.MutationResult<DeleteInstancekeyMutation>;
export type DeleteInstancekeyMutationOptions = Apollo.BaseMutationOptions<DeleteInstancekeyMutation, DeleteInstancekeyMutationVariables>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      success
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const ImpersonateOrganizationDocument = gql`
  mutation impersonateOrganization($organizationId: ID!) {
    impersonateOrganization(organizationId: $organizationId) {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type ImpersonateOrganizationMutationFn = Apollo.MutationFunction<ImpersonateOrganizationMutation, ImpersonateOrganizationMutationVariables>;

/**
 * __useImpersonateOrganizationMutation__
 *
 * To run a mutation, you first call `useImpersonateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateOrganizationMutation, { data, loading, error }] = useImpersonateOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useImpersonateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<ImpersonateOrganizationMutation, ImpersonateOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImpersonateOrganizationMutation, ImpersonateOrganizationMutationVariables>(ImpersonateOrganizationDocument, options);
}
export type ImpersonateOrganizationMutationHookResult = ReturnType<typeof useImpersonateOrganizationMutation>;
export type ImpersonateOrganizationMutationResult = Apollo.MutationResult<ImpersonateOrganizationMutation>;
export type ImpersonateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  ImpersonateOrganizationMutation,
  ImpersonateOrganizationMutationVariables
>;
export const LoginDocument = gql`
  mutation login($params: AuthenticateParamsInput!) {
    login(params: $params) {
      mfaRequired
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RefreshInstanceDataDocument = gql`
  mutation refreshInstanceData($instanceId: ID, $organizationId: ID) {
    refreshInstanceData(instanceId: $instanceId, organizationId: $organizationId) {
      ...instanceFragment
    }
  }
  ${InstanceFragmentFragmentDoc}
`;
export type RefreshInstanceDataMutationFn = Apollo.MutationFunction<RefreshInstanceDataMutation, RefreshInstanceDataMutationVariables>;

/**
 * __useRefreshInstanceDataMutation__
 *
 * To run a mutation, you first call `useRefreshInstanceDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshInstanceDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshInstanceDataMutation, { data, loading, error }] = useRefreshInstanceDataMutation({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRefreshInstanceDataMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshInstanceDataMutation, RefreshInstanceDataMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshInstanceDataMutation, RefreshInstanceDataMutationVariables>(RefreshInstanceDataDocument, options);
}
export type RefreshInstanceDataMutationHookResult = ReturnType<typeof useRefreshInstanceDataMutation>;
export type RefreshInstanceDataMutationResult = Apollo.MutationResult<RefreshInstanceDataMutation>;
export type RefreshInstanceDataMutationOptions = Apollo.BaseMutationOptions<RefreshInstanceDataMutation, RefreshInstanceDataMutationVariables>;
export const RequestMagicLinkEmailDocument = gql`
  mutation requestMagicLinkEmail($email: String!) {
    requestMagicLinkEmail(email: $email)
  }
`;
export type RequestMagicLinkEmailMutationFn = Apollo.MutationFunction<RequestMagicLinkEmailMutation, RequestMagicLinkEmailMutationVariables>;

/**
 * __useRequestMagicLinkEmailMutation__
 *
 * To run a mutation, you first call `useRequestMagicLinkEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMagicLinkEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMagicLinkEmailMutation, { data, loading, error }] = useRequestMagicLinkEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestMagicLinkEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<RequestMagicLinkEmailMutation, RequestMagicLinkEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMagicLinkEmailMutation, RequestMagicLinkEmailMutationVariables>(RequestMagicLinkEmailDocument, options);
}
export type RequestMagicLinkEmailMutationHookResult = ReturnType<typeof useRequestMagicLinkEmailMutation>;
export type RequestMagicLinkEmailMutationResult = Apollo.MutationResult<RequestMagicLinkEmailMutation>;
export type RequestMagicLinkEmailMutationOptions = Apollo.BaseMutationOptions<RequestMagicLinkEmailMutation, RequestMagicLinkEmailMutationVariables>;
export const ResendInviteEmailDocument = gql`
  mutation resendInviteEmail($userId: ID!) {
    resendInviteEmail(userId: $userId) {
      success
    }
  }
`;
export type ResendInviteEmailMutationFn = Apollo.MutationFunction<ResendInviteEmailMutation, ResendInviteEmailMutationVariables>;

/**
 * __useResendInviteEmailMutation__
 *
 * To run a mutation, you first call `useResendInviteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteEmailMutation, { data, loading, error }] = useResendInviteEmailMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInviteEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<ResendInviteEmailMutation, ResendInviteEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInviteEmailMutation, ResendInviteEmailMutationVariables>(ResendInviteEmailDocument, options);
}
export type ResendInviteEmailMutationHookResult = ReturnType<typeof useResendInviteEmailMutation>;
export type ResendInviteEmailMutationResult = Apollo.MutationResult<ResendInviteEmailMutation>;
export type ResendInviteEmailMutationOptions = Apollo.BaseMutationOptions<ResendInviteEmailMutation, ResendInviteEmailMutationVariables>;
export const ResendMfaDocument = gql`
  mutation resendMfa($email: String!) {
    resendMfa(email: $email) {
      success
    }
  }
`;
export type ResendMfaMutationFn = Apollo.MutationFunction<ResendMfaMutation, ResendMfaMutationVariables>;

/**
 * __useResendMfaMutation__
 *
 * To run a mutation, you first call `useResendMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendMfaMutation, { data, loading, error }] = useResendMfaMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendMfaMutation(baseOptions?: Apollo.MutationHookOptions<ResendMfaMutation, ResendMfaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendMfaMutation, ResendMfaMutationVariables>(ResendMfaDocument, options);
}
export type ResendMfaMutationHookResult = ReturnType<typeof useResendMfaMutation>;
export type ResendMfaMutationResult = Apollo.MutationResult<ResendMfaMutation>;
export type ResendMfaMutationOptions = Apollo.BaseMutationOptions<ResendMfaMutation, ResendMfaMutationVariables>;
export const ResetPasswordDocument = gql`
  mutation resetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
  mutation sendResetPasswordEmail($email: String!) {
    sendResetPasswordEmail(email: $email)
  }
`;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
}
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>;
export const TestKeyDocument = gql`
  mutation testKey {
    testKey {
      success
    }
  }
`;
export type TestKeyMutationFn = Apollo.MutationFunction<TestKeyMutation, TestKeyMutationVariables>;

/**
 * __useTestKeyMutation__
 *
 * To run a mutation, you first call `useTestKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testKeyMutation, { data, loading, error }] = useTestKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useTestKeyMutation(baseOptions?: Apollo.MutationHookOptions<TestKeyMutation, TestKeyMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TestKeyMutation, TestKeyMutationVariables>(TestKeyDocument, options);
}
export type TestKeyMutationHookResult = ReturnType<typeof useTestKeyMutation>;
export type TestKeyMutationResult = Apollo.MutationResult<TestKeyMutation>;
export type TestKeyMutationOptions = Apollo.BaseMutationOptions<TestKeyMutation, TestKeyMutationVariables>;
export const UpdateOneApplicationDocument = gql`
  mutation updateOneApplication($id: ID!, $params: ApplicationInput) {
    updateOneApplication(id: $id, params: $params) {
      id
      name
    }
  }
`;
export type UpdateOneApplicationMutationFn = Apollo.MutationFunction<UpdateOneApplicationMutation, UpdateOneApplicationMutationVariables>;

/**
 * __useUpdateOneApplicationMutation__
 *
 * To run a mutation, you first call `useUpdateOneApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneApplicationMutation, { data, loading, error }] = useUpdateOneApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneApplicationMutation, UpdateOneApplicationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneApplicationMutation, UpdateOneApplicationMutationVariables>(UpdateOneApplicationDocument, options);
}
export type UpdateOneApplicationMutationHookResult = ReturnType<typeof useUpdateOneApplicationMutation>;
export type UpdateOneApplicationMutationResult = Apollo.MutationResult<UpdateOneApplicationMutation>;
export type UpdateOneApplicationMutationOptions = Apollo.BaseMutationOptions<UpdateOneApplicationMutation, UpdateOneApplicationMutationVariables>;
export const UpdateOneEnvironmentDocument = gql`
  mutation updateOneEnvironment($id: ID!, $params: EnvironmentInput) {
    updateOneEnvironment(id: $id, params: $params) {
      id
      name
      isProduction
      isRunning
      environmentType
      vendor
      applicationId
      instances {
        id
        name
        description
        state
        isRunning
        isProduction
        numberOfMetrics
      }
    }
  }
`;
export type UpdateOneEnvironmentMutationFn = Apollo.MutationFunction<UpdateOneEnvironmentMutation, UpdateOneEnvironmentMutationVariables>;

/**
 * __useUpdateOneEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpdateOneEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneEnvironmentMutation, { data, loading, error }] = useUpdateOneEnvironmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneEnvironmentMutation, UpdateOneEnvironmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneEnvironmentMutation, UpdateOneEnvironmentMutationVariables>(UpdateOneEnvironmentDocument, options);
}
export type UpdateOneEnvironmentMutationHookResult = ReturnType<typeof useUpdateOneEnvironmentMutation>;
export type UpdateOneEnvironmentMutationResult = Apollo.MutationResult<UpdateOneEnvironmentMutation>;
export type UpdateOneEnvironmentMutationOptions = Apollo.BaseMutationOptions<UpdateOneEnvironmentMutation, UpdateOneEnvironmentMutationVariables>;
export const UpdateOneInstanceDocument = gql`
  mutation updateOneInstance($id: ID!, $params: InstanceEditInput) {
    updateOneInstance(id: $id, params: $params) {
      ...instanceFragment
    }
  }
  ${InstanceFragmentFragmentDoc}
`;
export type UpdateOneInstanceMutationFn = Apollo.MutationFunction<UpdateOneInstanceMutation, UpdateOneInstanceMutationVariables>;

/**
 * __useUpdateOneInstanceMutation__
 *
 * To run a mutation, you first call `useUpdateOneInstanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInstanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneInstanceMutation, { data, loading, error }] = useUpdateOneInstanceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneInstanceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneInstanceMutation, UpdateOneInstanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneInstanceMutation, UpdateOneInstanceMutationVariables>(UpdateOneInstanceDocument, options);
}
export type UpdateOneInstanceMutationHookResult = ReturnType<typeof useUpdateOneInstanceMutation>;
export type UpdateOneInstanceMutationResult = Apollo.MutationResult<UpdateOneInstanceMutation>;
export type UpdateOneInstanceMutationOptions = Apollo.BaseMutationOptions<UpdateOneInstanceMutation, UpdateOneInstanceMutationVariables>;
export const UpdateOneInstanceKeyDocument = gql`
  mutation updateOneInstanceKey($id: ID!, $params: InstanceKeyEditInput) {
    updateOneInstanceKey(id: $id, params: $params) {
      ...instanceKeyFragment
    }
  }
  ${InstanceKeyFragmentFragmentDoc}
`;
export type UpdateOneInstanceKeyMutationFn = Apollo.MutationFunction<UpdateOneInstanceKeyMutation, UpdateOneInstanceKeyMutationVariables>;

/**
 * __useUpdateOneInstanceKeyMutation__
 *
 * To run a mutation, you first call `useUpdateOneInstanceKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneInstanceKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneInstanceKeyMutation, { data, loading, error }] = useUpdateOneInstanceKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneInstanceKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneInstanceKeyMutation, UpdateOneInstanceKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneInstanceKeyMutation, UpdateOneInstanceKeyMutationVariables>(UpdateOneInstanceKeyDocument, options);
}
export type UpdateOneInstanceKeyMutationHookResult = ReturnType<typeof useUpdateOneInstanceKeyMutation>;
export type UpdateOneInstanceKeyMutationResult = Apollo.MutationResult<UpdateOneInstanceKeyMutation>;
export type UpdateOneInstanceKeyMutationOptions = Apollo.BaseMutationOptions<UpdateOneInstanceKeyMutation, UpdateOneInstanceKeyMutationVariables>;
export const UpdateOneOrganizationDocument = gql`
  mutation updateOneOrganization($id: ID!, $params: OrganizationInput) {
    updateOneOrganization(id: $id, params: $params) {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;
export type UpdateOneOrganizationMutationFn = Apollo.MutationFunction<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>;

/**
 * __useUpdateOneOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOneOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneOrganizationMutation, { data, loading, error }] = useUpdateOneOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>(UpdateOneOrganizationDocument, options);
}
export type UpdateOneOrganizationMutationHookResult = ReturnType<typeof useUpdateOneOrganizationMutation>;
export type UpdateOneOrganizationMutationResult = Apollo.MutationResult<UpdateOneOrganizationMutation>;
export type UpdateOneOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOneOrganizationMutation, UpdateOneOrganizationMutationVariables>;
export const UpdateOneUserDocument = gql`
  mutation updateOneUser($id: ID!, $params: UserProfileInput) {
    updateOneUser(id: $id, params: $params) {
      id
      firstName
      lastName
      email
      permission
      organizations {
        id
        name
      }
    }
  }
`;
export type UpdateOneUserMutationFn = Apollo.MutationFunction<UpdateOneUserMutation, UpdateOneUserMutationVariables>;

/**
 * __useUpdateOneUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserMutation, { data, loading, error }] = useUpdateOneUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUpdateOneUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneUserMutation, UpdateOneUserMutationVariables>(UpdateOneUserDocument, options);
}
export type UpdateOneUserMutationHookResult = ReturnType<typeof useUpdateOneUserMutation>;
export type UpdateOneUserMutationResult = Apollo.MutationResult<UpdateOneUserMutation>;
export type UpdateOneUserMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserMutation, UpdateOneUserMutationVariables>;
export const UpdateOneUserActiveOrgDocument = gql`
  mutation updateOneUserActiveOrg($activeOrganizationId: ID!) {
    updateOneUserActiveOrg(activeOrganizationId: $activeOrganizationId) {
      id
      firstName
      lastName
      email
      permission
      activeOrganization {
        ...shortOrganizationFragment
      }
      organizations {
        id
        name
      }
    }
  }
  ${ShortOrganizationFragmentFragmentDoc}
`;
export type UpdateOneUserActiveOrgMutationFn = Apollo.MutationFunction<UpdateOneUserActiveOrgMutation, UpdateOneUserActiveOrgMutationVariables>;

/**
 * __useUpdateOneUserActiveOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserActiveOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserActiveOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserActiveOrgMutation, { data, loading, error }] = useUpdateOneUserActiveOrgMutation({
 *   variables: {
 *      activeOrganizationId: // value for 'activeOrganizationId'
 *   },
 * });
 */
export function useUpdateOneUserActiveOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOneUserActiveOrgMutation, UpdateOneUserActiveOrgMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOneUserActiveOrgMutation, UpdateOneUserActiveOrgMutationVariables>(UpdateOneUserActiveOrgDocument, options);
}
export type UpdateOneUserActiveOrgMutationHookResult = ReturnType<typeof useUpdateOneUserActiveOrgMutation>;
export type UpdateOneUserActiveOrgMutationResult = Apollo.MutationResult<UpdateOneUserActiveOrgMutation>;
export type UpdateOneUserActiveOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneUserActiveOrgMutation,
  UpdateOneUserActiveOrgMutationVariables
>;
export const UpdateOrganizationSuperAdminDocument = gql`
  mutation updateOrganizationSuperAdmin($userId: ID!, $organizationId: ID!) {
    updateOrganizationSuperAdmin(userId: $userId, organizationId: $organizationId) {
      id
      superUser {
        id
        email
      }
    }
  }
`;
export type UpdateOrganizationSuperAdminMutationFn = Apollo.MutationFunction<
  UpdateOrganizationSuperAdminMutation,
  UpdateOrganizationSuperAdminMutationVariables
>;

/**
 * __useUpdateOrganizationSuperAdminMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSuperAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSuperAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSuperAdminMutation, { data, loading, error }] = useUpdateOrganizationSuperAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useUpdateOrganizationSuperAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationSuperAdminMutation, UpdateOrganizationSuperAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationSuperAdminMutation, UpdateOrganizationSuperAdminMutationVariables>(
    UpdateOrganizationSuperAdminDocument,
    options
  );
}
export type UpdateOrganizationSuperAdminMutationHookResult = ReturnType<typeof useUpdateOrganizationSuperAdminMutation>;
export type UpdateOrganizationSuperAdminMutationResult = Apollo.MutationResult<UpdateOrganizationSuperAdminMutation>;
export type UpdateOrganizationSuperAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationSuperAdminMutation,
  UpdateOrganizationSuperAdminMutationVariables
>;
export const UploadInstancesDocument = gql`
  mutation uploadInstances($organizationId: ID!, $instances: [UploadInstanceInput]) {
    uploadInstances(organizationId: $organizationId, instances: $instances) {
      success
    }
  }
`;
export type UploadInstancesMutationFn = Apollo.MutationFunction<UploadInstancesMutation, UploadInstancesMutationVariables>;

/**
 * __useUploadInstancesMutation__
 *
 * To run a mutation, you first call `useUploadInstancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInstancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInstancesMutation, { data, loading, error }] = useUploadInstancesMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      instances: // value for 'instances'
 *   },
 * });
 */
export function useUploadInstancesMutation(baseOptions?: Apollo.MutationHookOptions<UploadInstancesMutation, UploadInstancesMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadInstancesMutation, UploadInstancesMutationVariables>(UploadInstancesDocument, options);
}
export type UploadInstancesMutationHookResult = ReturnType<typeof useUploadInstancesMutation>;
export type UploadInstancesMutationResult = Apollo.MutationResult<UploadInstancesMutation>;
export type UploadInstancesMutationOptions = Apollo.BaseMutationOptions<UploadInstancesMutation, UploadInstancesMutationVariables>;
export const VerifyMfaDocument = gql`
  mutation verifyMfa($pin: String!, $email: String!) {
    verifyMfa(pin: $pin, email: $email) {
      mfaRequired
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type VerifyMfaMutationFn = Apollo.MutationFunction<VerifyMfaMutation, VerifyMfaMutationVariables>;

/**
 * __useVerifyMfaMutation__
 *
 * To run a mutation, you first call `useVerifyMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMfaMutation, { data, loading, error }] = useVerifyMfaMutation({
 *   variables: {
 *      pin: // value for 'pin'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useVerifyMfaMutation(baseOptions?: Apollo.MutationHookOptions<VerifyMfaMutation, VerifyMfaMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyMfaMutation, VerifyMfaMutationVariables>(VerifyMfaDocument, options);
}
export type VerifyMfaMutationHookResult = ReturnType<typeof useVerifyMfaMutation>;
export type VerifyMfaMutationResult = Apollo.MutationResult<VerifyMfaMutation>;
export type VerifyMfaMutationOptions = Apollo.BaseMutationOptions<VerifyMfaMutation, VerifyMfaMutationVariables>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...userFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const GetAccessesBySectionDocument = gql`
  query getAccessesBySection {
    getAccessesBySection {
      sectionName
      items {
        key
        name
      }
    }
  }
`;

/**
 * __useGetAccessesBySectionQuery__
 *
 * To run a query within a React component, call `useGetAccessesBySectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessesBySectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessesBySectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccessesBySectionQuery(baseOptions?: Apollo.QueryHookOptions<GetAccessesBySectionQuery, GetAccessesBySectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccessesBySectionQuery, GetAccessesBySectionQueryVariables>(GetAccessesBySectionDocument, options);
}
export function useGetAccessesBySectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccessesBySectionQuery, GetAccessesBySectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccessesBySectionQuery, GetAccessesBySectionQueryVariables>(GetAccessesBySectionDocument, options);
}
export type GetAccessesBySectionQueryHookResult = ReturnType<typeof useGetAccessesBySectionQuery>;
export type GetAccessesBySectionLazyQueryHookResult = ReturnType<typeof useGetAccessesBySectionLazyQuery>;
export type GetAccessesBySectionQueryResult = Apollo.QueryResult<GetAccessesBySectionQuery, GetAccessesBySectionQueryVariables>;
export const GetAllDueInvoicesDocument = gql`
  query getAllDueInvoices($organizationId: ID, $skip: Int, $limit: Int) {
    getAllDueInvoices(organizationId: $organizationId, skip: $skip, limit: $limit) {
      results {
        ...invoiceFragment
      }
      count
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;

/**
 * __useGetAllDueInvoicesQuery__
 *
 * To run a query within a React component, call `useGetAllDueInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDueInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDueInvoicesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllDueInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDueInvoicesQuery, GetAllDueInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllDueInvoicesQuery, GetAllDueInvoicesQueryVariables>(GetAllDueInvoicesDocument, options);
}
export function useGetAllDueInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDueInvoicesQuery, GetAllDueInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllDueInvoicesQuery, GetAllDueInvoicesQueryVariables>(GetAllDueInvoicesDocument, options);
}
export type GetAllDueInvoicesQueryHookResult = ReturnType<typeof useGetAllDueInvoicesQuery>;
export type GetAllDueInvoicesLazyQueryHookResult = ReturnType<typeof useGetAllDueInvoicesLazyQuery>;
export type GetAllDueInvoicesQueryResult = Apollo.QueryResult<GetAllDueInvoicesQuery, GetAllDueInvoicesQueryVariables>;
export const GetBasisMetricsDocument = gql`
  query getBasisMetrics($instanceId: String!, $relativeTime: RelativeTimeEnum!) {
    getBasisMetrics(instanceId: $instanceId, relativeTime: $relativeTime) {
      metricValues {
        timestamp
        value
      }
      metric
      metricName
      metricType
    }
  }
`;

/**
 * __useGetBasisMetricsQuery__
 *
 * To run a query within a React component, call `useGetBasisMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasisMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasisMetricsQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      relativeTime: // value for 'relativeTime'
 *   },
 * });
 */
export function useGetBasisMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetBasisMetricsQuery, GetBasisMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBasisMetricsQuery, GetBasisMetricsQueryVariables>(GetBasisMetricsDocument, options);
}
export function useGetBasisMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBasisMetricsQuery, GetBasisMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBasisMetricsQuery, GetBasisMetricsQueryVariables>(GetBasisMetricsDocument, options);
}
export type GetBasisMetricsQueryHookResult = ReturnType<typeof useGetBasisMetricsQuery>;
export type GetBasisMetricsLazyQueryHookResult = ReturnType<typeof useGetBasisMetricsLazyQuery>;
export type GetBasisMetricsQueryResult = Apollo.QueryResult<GetBasisMetricsQuery, GetBasisMetricsQueryVariables>;
export const GetBusinessDocumentsByOrganizationDocument = gql`
  query getBusinessDocumentsByOrganization($organizationId: String!) {
    getBusinessDocumentsByOrganization(organizationId: $organizationId) {
      id
      name
      documents {
        id
        name
        lastModified
        size
        type
        url
        documents {
          id
          name
          lastModified
          size
          type
          url
        }
      }
    }
  }
`;

/**
 * __useGetBusinessDocumentsByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetBusinessDocumentsByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessDocumentsByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessDocumentsByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetBusinessDocumentsByOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetBusinessDocumentsByOrganizationQuery, GetBusinessDocumentsByOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessDocumentsByOrganizationQuery, GetBusinessDocumentsByOrganizationQueryVariables>(
    GetBusinessDocumentsByOrganizationDocument,
    options
  );
}
export function useGetBusinessDocumentsByOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessDocumentsByOrganizationQuery, GetBusinessDocumentsByOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBusinessDocumentsByOrganizationQuery, GetBusinessDocumentsByOrganizationQueryVariables>(
    GetBusinessDocumentsByOrganizationDocument,
    options
  );
}
export type GetBusinessDocumentsByOrganizationQueryHookResult = ReturnType<typeof useGetBusinessDocumentsByOrganizationQuery>;
export type GetBusinessDocumentsByOrganizationLazyQueryHookResult = ReturnType<typeof useGetBusinessDocumentsByOrganizationLazyQuery>;
export type GetBusinessDocumentsByOrganizationQueryResult = Apollo.QueryResult<
  GetBusinessDocumentsByOrganizationQuery,
  GetBusinessDocumentsByOrganizationQueryVariables
>;
export const GetCostAnalysisDocument = gql`
  query getCostAnalysis($organizationId: ID) {
    getCostAnalysis(organizationId: $organizationId) {
      id
      values {
        key
        value
      }
    }
  }
`;

/**
 * __useGetCostAnalysisQuery__
 *
 * To run a query within a React component, call `useGetCostAnalysisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostAnalysisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostAnalysisQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetCostAnalysisQuery(baseOptions?: Apollo.QueryHookOptions<GetCostAnalysisQuery, GetCostAnalysisQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCostAnalysisQuery, GetCostAnalysisQueryVariables>(GetCostAnalysisDocument, options);
}
export function useGetCostAnalysisLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCostAnalysisQuery, GetCostAnalysisQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCostAnalysisQuery, GetCostAnalysisQueryVariables>(GetCostAnalysisDocument, options);
}
export type GetCostAnalysisQueryHookResult = ReturnType<typeof useGetCostAnalysisQuery>;
export type GetCostAnalysisLazyQueryHookResult = ReturnType<typeof useGetCostAnalysisLazyQuery>;
export type GetCostAnalysisQueryResult = Apollo.QueryResult<GetCostAnalysisQuery, GetCostAnalysisQueryVariables>;
export const GetDiskUsedPercentDocument = gql`
  query getDiskUsedPercent($instanceId: String!, $relativeTime: RelativeTimeEnum!) {
    getDiskUsedPercent(instanceId: $instanceId, relativeTime: $relativeTime) {
      metricValues {
        timestamp
        value
        unit
      }
      metric
      metricName
      metricType
    }
  }
`;

/**
 * __useGetDiskUsedPercentQuery__
 *
 * To run a query within a React component, call `useGetDiskUsedPercentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiskUsedPercentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiskUsedPercentQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      relativeTime: // value for 'relativeTime'
 *   },
 * });
 */
export function useGetDiskUsedPercentQuery(baseOptions: Apollo.QueryHookOptions<GetDiskUsedPercentQuery, GetDiskUsedPercentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDiskUsedPercentQuery, GetDiskUsedPercentQueryVariables>(GetDiskUsedPercentDocument, options);
}
export function useGetDiskUsedPercentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiskUsedPercentQuery, GetDiskUsedPercentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDiskUsedPercentQuery, GetDiskUsedPercentQueryVariables>(GetDiskUsedPercentDocument, options);
}
export type GetDiskUsedPercentQueryHookResult = ReturnType<typeof useGetDiskUsedPercentQuery>;
export type GetDiskUsedPercentLazyQueryHookResult = ReturnType<typeof useGetDiskUsedPercentLazyQuery>;
export type GetDiskUsedPercentQueryResult = Apollo.QueryResult<GetDiskUsedPercentQuery, GetDiskUsedPercentQueryVariables>;
export const GetEnvironmentsDocument = gql`
  query getEnvironments($applicationId: ID) {
    getEnvironments(applicationId: $applicationId) {
      id
      name
      isProduction
      isRunning
      environmentType
      instances {
        id
        name
        description
        state
        isRunning
        isProduction
        numberOfMetrics
      }
    }
  }
`;

/**
 * __useGetEnvironmentsQuery__
 *
 * To run a query within a React component, call `useGetEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnvironmentsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetEnvironmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetEnvironmentsQuery, GetEnvironmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEnvironmentsQuery, GetEnvironmentsQueryVariables>(GetEnvironmentsDocument, options);
}
export function useGetEnvironmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEnvironmentsQuery, GetEnvironmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEnvironmentsQuery, GetEnvironmentsQueryVariables>(GetEnvironmentsDocument, options);
}
export type GetEnvironmentsQueryHookResult = ReturnType<typeof useGetEnvironmentsQuery>;
export type GetEnvironmentsLazyQueryHookResult = ReturnType<typeof useGetEnvironmentsLazyQuery>;
export type GetEnvironmentsQueryResult = Apollo.QueryResult<GetEnvironmentsQuery, GetEnvironmentsQueryVariables>;
export const GetHanaDbMetricsDocument = gql`
  query getHanaDbMetrics($instanceId: String!, $relativeTime: RelativeTimeEnum!) {
    getHanaDbMetrics(instanceId: $instanceId, relativeTime: $relativeTime) {
      metricValues {
        timestamp
        value
      }
      metric
      metricName
      metricType
    }
  }
`;

/**
 * __useGetHanaDbMetricsQuery__
 *
 * To run a query within a React component, call `useGetHanaDbMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHanaDbMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHanaDbMetricsQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      relativeTime: // value for 'relativeTime'
 *   },
 * });
 */
export function useGetHanaDbMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetHanaDbMetricsQuery, GetHanaDbMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHanaDbMetricsQuery, GetHanaDbMetricsQueryVariables>(GetHanaDbMetricsDocument, options);
}
export function useGetHanaDbMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHanaDbMetricsQuery, GetHanaDbMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHanaDbMetricsQuery, GetHanaDbMetricsQueryVariables>(GetHanaDbMetricsDocument, options);
}
export type GetHanaDbMetricsQueryHookResult = ReturnType<typeof useGetHanaDbMetricsQuery>;
export type GetHanaDbMetricsLazyQueryHookResult = ReturnType<typeof useGetHanaDbMetricsLazyQuery>;
export type GetHanaDbMetricsQueryResult = Apollo.QueryResult<GetHanaDbMetricsQuery, GetHanaDbMetricsQueryVariables>;
export const GetInstanceBackupsDocument = gql`
  query getInstanceBackups($instanceId: String!) {
    getOneInstance(instanceId: $instanceId) {
      id
      name
      backups {
        id
        status
        state
        type
        startTime
        durationHours
        durationMinutes
        durationSeconds
        detail
      }
    }
  }
`;

/**
 * __useGetInstanceBackupsQuery__
 *
 * To run a query within a React component, call `useGetInstanceBackupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstanceBackupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstanceBackupsQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useGetInstanceBackupsQuery(baseOptions: Apollo.QueryHookOptions<GetInstanceBackupsQuery, GetInstanceBackupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInstanceBackupsQuery, GetInstanceBackupsQueryVariables>(GetInstanceBackupsDocument, options);
}
export function useGetInstanceBackupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstanceBackupsQuery, GetInstanceBackupsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInstanceBackupsQuery, GetInstanceBackupsQueryVariables>(GetInstanceBackupsDocument, options);
}
export type GetInstanceBackupsQueryHookResult = ReturnType<typeof useGetInstanceBackupsQuery>;
export type GetInstanceBackupsLazyQueryHookResult = ReturnType<typeof useGetInstanceBackupsLazyQuery>;
export type GetInstanceBackupsQueryResult = Apollo.QueryResult<GetInstanceBackupsQuery, GetInstanceBackupsQueryVariables>;
export const GetManyApplicationsDocument = gql`
  query getManyApplications($organizationId: ID) {
    getManyApplications(organizationId: $organizationId) {
      id
      name
      environments {
        id
        name
        isProduction
        isRunning
        environmentType
        instances {
          id
          name
          description
          state
          isRunning
          isProduction
          numberOfMetrics
        }
      }
    }
  }
`;

/**
 * __useGetManyApplicationsQuery__
 *
 * To run a query within a React component, call `useGetManyApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyApplicationsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetManyApplicationsQuery(baseOptions?: Apollo.QueryHookOptions<GetManyApplicationsQuery, GetManyApplicationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyApplicationsQuery, GetManyApplicationsQueryVariables>(GetManyApplicationsDocument, options);
}
export function useGetManyApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyApplicationsQuery, GetManyApplicationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyApplicationsQuery, GetManyApplicationsQueryVariables>(GetManyApplicationsDocument, options);
}
export type GetManyApplicationsQueryHookResult = ReturnType<typeof useGetManyApplicationsQuery>;
export type GetManyApplicationsLazyQueryHookResult = ReturnType<typeof useGetManyApplicationsLazyQuery>;
export type GetManyApplicationsQueryResult = Apollo.QueryResult<GetManyApplicationsQuery, GetManyApplicationsQueryVariables>;
export const GetManyEnvironmentsDocument = gql`
  query getManyEnvironments($applicationId: ID, $environmentType: EnvironmentTypeEnum) {
    getManyEnvironments(applicationId: $applicationId, environmentType: $environmentType) {
      id
      name
      isProduction
      isRunning
      environmentType
      application {
        id
        name
      }
      instances {
        id
        name
        description
        externalId
        state
        isRunning
        isProduction
        numberOfMetrics
        updatedAt
      }
    }
  }
`;

/**
 * __useGetManyEnvironmentsQuery__
 *
 * To run a query within a React component, call `useGetManyEnvironmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyEnvironmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyEnvironmentsQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *      environmentType: // value for 'environmentType'
 *   },
 * });
 */
export function useGetManyEnvironmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetManyEnvironmentsQuery, GetManyEnvironmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyEnvironmentsQuery, GetManyEnvironmentsQueryVariables>(GetManyEnvironmentsDocument, options);
}
export function useGetManyEnvironmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyEnvironmentsQuery, GetManyEnvironmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyEnvironmentsQuery, GetManyEnvironmentsQueryVariables>(GetManyEnvironmentsDocument, options);
}
export type GetManyEnvironmentsQueryHookResult = ReturnType<typeof useGetManyEnvironmentsQuery>;
export type GetManyEnvironmentsLazyQueryHookResult = ReturnType<typeof useGetManyEnvironmentsLazyQuery>;
export type GetManyEnvironmentsQueryResult = Apollo.QueryResult<GetManyEnvironmentsQuery, GetManyEnvironmentsQueryVariables>;
export const GetManyIncidentsDocument = gql`
  query getManyIncidents {
    getManyIncidents {
      sysId
      number
      priority
      description
      openedAt
      state
    }
  }
`;

/**
 * __useGetManyIncidentsQuery__
 *
 * To run a query within a React component, call `useGetManyIncidentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyIncidentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyIncidentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyIncidentsQuery(baseOptions?: Apollo.QueryHookOptions<GetManyIncidentsQuery, GetManyIncidentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyIncidentsQuery, GetManyIncidentsQueryVariables>(GetManyIncidentsDocument, options);
}
export function useGetManyIncidentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyIncidentsQuery, GetManyIncidentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyIncidentsQuery, GetManyIncidentsQueryVariables>(GetManyIncidentsDocument, options);
}
export type GetManyIncidentsQueryHookResult = ReturnType<typeof useGetManyIncidentsQuery>;
export type GetManyIncidentsLazyQueryHookResult = ReturnType<typeof useGetManyIncidentsLazyQuery>;
export type GetManyIncidentsQueryResult = Apollo.QueryResult<GetManyIncidentsQuery, GetManyIncidentsQueryVariables>;
export const GetManyInstanceBackupsDocument = gql`
  query getManyInstanceBackups($organizationId: ID!) {
    getManyInstanceBackups(organizationId: $organizationId) {
      id
      tier
      instance {
        id
        externalId
      }
      last15BackupsDB {
        id
        status
        state
        type
        startTime
        durationHours
        durationMinutes
        durationSeconds
        detail
      }
      last15BackupsOS {
        id
        status
        state
        type
        startTime
        durationHours
        durationMinutes
        durationSeconds
        detail
      }
    }
  }
`;

/**
 * __useGetManyInstanceBackupsQuery__
 *
 * To run a query within a React component, call `useGetManyInstanceBackupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInstanceBackupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyInstanceBackupsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetManyInstanceBackupsQuery(
  baseOptions: Apollo.QueryHookOptions<GetManyInstanceBackupsQuery, GetManyInstanceBackupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyInstanceBackupsQuery, GetManyInstanceBackupsQueryVariables>(GetManyInstanceBackupsDocument, options);
}
export function useGetManyInstanceBackupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyInstanceBackupsQuery, GetManyInstanceBackupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyInstanceBackupsQuery, GetManyInstanceBackupsQueryVariables>(GetManyInstanceBackupsDocument, options);
}
export type GetManyInstanceBackupsQueryHookResult = ReturnType<typeof useGetManyInstanceBackupsQuery>;
export type GetManyInstanceBackupsLazyQueryHookResult = ReturnType<typeof useGetManyInstanceBackupsLazyQuery>;
export type GetManyInstanceBackupsQueryResult = Apollo.QueryResult<GetManyInstanceBackupsQuery, GetManyInstanceBackupsQueryVariables>;
export const GetManyInstanceKeysDocument = gql`
  query getManyInstanceKeys($organizationId: ID!) {
    getManyInstanceKeys(organizationId: $organizationId) {
      ...instanceKeyFragment
    }
  }
  ${InstanceKeyFragmentFragmentDoc}
`;

/**
 * __useGetManyInstanceKeysQuery__
 *
 * To run a query within a React component, call `useGetManyInstanceKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInstanceKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyInstanceKeysQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetManyInstanceKeysQuery(baseOptions: Apollo.QueryHookOptions<GetManyInstanceKeysQuery, GetManyInstanceKeysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyInstanceKeysQuery, GetManyInstanceKeysQueryVariables>(GetManyInstanceKeysDocument, options);
}
export function useGetManyInstanceKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyInstanceKeysQuery, GetManyInstanceKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyInstanceKeysQuery, GetManyInstanceKeysQueryVariables>(GetManyInstanceKeysDocument, options);
}
export type GetManyInstanceKeysQueryHookResult = ReturnType<typeof useGetManyInstanceKeysQuery>;
export type GetManyInstanceKeysLazyQueryHookResult = ReturnType<typeof useGetManyInstanceKeysLazyQuery>;
export type GetManyInstanceKeysQueryResult = Apollo.QueryResult<GetManyInstanceKeysQuery, GetManyInstanceKeysQueryVariables>;
export const GetManyInstanceTypesDocument = gql`
  query getManyInstanceTypes {
    getManyInstanceTypes
  }
`;

/**
 * __useGetManyInstanceTypesQuery__
 *
 * To run a query within a React component, call `useGetManyInstanceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInstanceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyInstanceTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyInstanceTypesQuery(baseOptions?: Apollo.QueryHookOptions<GetManyInstanceTypesQuery, GetManyInstanceTypesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyInstanceTypesQuery, GetManyInstanceTypesQueryVariables>(GetManyInstanceTypesDocument, options);
}
export function useGetManyInstanceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyInstanceTypesQuery, GetManyInstanceTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyInstanceTypesQuery, GetManyInstanceTypesQueryVariables>(GetManyInstanceTypesDocument, options);
}
export type GetManyInstanceTypesQueryHookResult = ReturnType<typeof useGetManyInstanceTypesQuery>;
export type GetManyInstanceTypesLazyQueryHookResult = ReturnType<typeof useGetManyInstanceTypesLazyQuery>;
export type GetManyInstanceTypesQueryResult = Apollo.QueryResult<GetManyInstanceTypesQuery, GetManyInstanceTypesQueryVariables>;
export const GetManyInstancesDocument = gql`
  query getManyInstances($organizationId: ID, $instanceIds: [ID], $instanceTypes: [String]) {
    getManyInstances(organizationId: $organizationId, instanceIds: $instanceIds, instanceTypes: $instanceTypes) {
      id
      name
      description
      state
      isRunning
      externalId
      isProduction
      pattern
      resourceGroup
      numberOfMetrics
      instanceType
      vendor
      region
      environment {
        id
        name
        environmentType
      }
      application {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManyInstancesQuery__
 *
 * To run a query within a React component, call `useGetManyInstancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInstancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyInstancesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      instanceIds: // value for 'instanceIds'
 *      instanceTypes: // value for 'instanceTypes'
 *   },
 * });
 */
export function useGetManyInstancesQuery(baseOptions?: Apollo.QueryHookOptions<GetManyInstancesQuery, GetManyInstancesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyInstancesQuery, GetManyInstancesQueryVariables>(GetManyInstancesDocument, options);
}
export function useGetManyInstancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyInstancesQuery, GetManyInstancesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyInstancesQuery, GetManyInstancesQueryVariables>(GetManyInstancesDocument, options);
}
export type GetManyInstancesQueryHookResult = ReturnType<typeof useGetManyInstancesQuery>;
export type GetManyInstancesLazyQueryHookResult = ReturnType<typeof useGetManyInstancesLazyQuery>;
export type GetManyInstancesQueryResult = Apollo.QueryResult<GetManyInstancesQuery, GetManyInstancesQueryVariables>;
export const GetManyInvoicesDocument = gql`
  query getManyInvoices($organizationId: ID, $skip: Int, $limit: Int) {
    getManyInvoices(organizationId: $organizationId, skip: $skip, limit: $limit) {
      results {
        ...invoiceFragment
      }
      count
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;

/**
 * __useGetManyInvoicesQuery__
 *
 * To run a query within a React component, call `useGetManyInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyInvoicesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetManyInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetManyInvoicesQuery, GetManyInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyInvoicesQuery, GetManyInvoicesQueryVariables>(GetManyInvoicesDocument, options);
}
export function useGetManyInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyInvoicesQuery, GetManyInvoicesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyInvoicesQuery, GetManyInvoicesQueryVariables>(GetManyInvoicesDocument, options);
}
export type GetManyInvoicesQueryHookResult = ReturnType<typeof useGetManyInvoicesQuery>;
export type GetManyInvoicesLazyQueryHookResult = ReturnType<typeof useGetManyInvoicesLazyQuery>;
export type GetManyInvoicesQueryResult = Apollo.QueryResult<GetManyInvoicesQuery, GetManyInvoicesQueryVariables>;
export const GetManyOrganizationsDocument = gql`
  query getManyOrganizations {
    getManyOrganizations {
      id
      name
      billingId
    }
  }
`;

/**
 * __useGetManyOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetManyOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetManyOrganizationsQuery, GetManyOrganizationsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyOrganizationsQuery, GetManyOrganizationsQueryVariables>(GetManyOrganizationsDocument, options);
}
export function useGetManyOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyOrganizationsQuery, GetManyOrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyOrganizationsQuery, GetManyOrganizationsQueryVariables>(GetManyOrganizationsDocument, options);
}
export type GetManyOrganizationsQueryHookResult = ReturnType<typeof useGetManyOrganizationsQuery>;
export type GetManyOrganizationsLazyQueryHookResult = ReturnType<typeof useGetManyOrganizationsLazyQuery>;
export type GetManyOrganizationsQueryResult = Apollo.QueryResult<GetManyOrganizationsQuery, GetManyOrganizationsQueryVariables>;
export const GetManyOverDueInvoicesDocument = gql`
  query getManyOverDueInvoices {
    getManyOverDueInvoices {
      results {
        ...invoiceFragment
      }
      count
    }
  }
  ${InvoiceFragmentFragmentDoc}
`;

/**
 * __useGetManyOverDueInvoicesQuery__
 *
 * To run a query within a React component, call `useGetManyOverDueInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyOverDueInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyOverDueInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyOverDueInvoicesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManyOverDueInvoicesQuery, GetManyOverDueInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyOverDueInvoicesQuery, GetManyOverDueInvoicesQueryVariables>(GetManyOverDueInvoicesDocument, options);
}
export function useGetManyOverDueInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyOverDueInvoicesQuery, GetManyOverDueInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyOverDueInvoicesQuery, GetManyOverDueInvoicesQueryVariables>(GetManyOverDueInvoicesDocument, options);
}
export type GetManyOverDueInvoicesQueryHookResult = ReturnType<typeof useGetManyOverDueInvoicesQuery>;
export type GetManyOverDueInvoicesLazyQueryHookResult = ReturnType<typeof useGetManyOverDueInvoicesLazyQuery>;
export type GetManyOverDueInvoicesQueryResult = Apollo.QueryResult<GetManyOverDueInvoicesQuery, GetManyOverDueInvoicesQueryVariables>;
export const GetManyPermissionsDocument = gql`
  query getManyPermissions($adminOnly: Boolean) {
    getManyPermissions(adminOnly: $adminOnly) {
      id
      name
      accesses {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManyPermissionsQuery__
 *
 * To run a query within a React component, call `useGetManyPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyPermissionsQuery({
 *   variables: {
 *      adminOnly: // value for 'adminOnly'
 *   },
 * });
 */
export function useGetManyPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetManyPermissionsQuery, GetManyPermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyPermissionsQuery, GetManyPermissionsQueryVariables>(GetManyPermissionsDocument, options);
}
export function useGetManyPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyPermissionsQuery, GetManyPermissionsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyPermissionsQuery, GetManyPermissionsQueryVariables>(GetManyPermissionsDocument, options);
}
export type GetManyPermissionsQueryHookResult = ReturnType<typeof useGetManyPermissionsQuery>;
export type GetManyPermissionsLazyQueryHookResult = ReturnType<typeof useGetManyPermissionsLazyQuery>;
export type GetManyPermissionsQueryResult = Apollo.QueryResult<GetManyPermissionsQuery, GetManyPermissionsQueryVariables>;
export const GetManyPoliciesDocument = gql`
  query getManyPolicies {
    getManyPolicies {
      id
      name
      system
      vendor
    }
  }
`;

/**
 * __useGetManyPoliciesQuery__
 *
 * To run a query within a React component, call `useGetManyPoliciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyPoliciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyPoliciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyPoliciesQuery(baseOptions?: Apollo.QueryHookOptions<GetManyPoliciesQuery, GetManyPoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyPoliciesQuery, GetManyPoliciesQueryVariables>(GetManyPoliciesDocument, options);
}
export function useGetManyPoliciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyPoliciesQuery, GetManyPoliciesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyPoliciesQuery, GetManyPoliciesQueryVariables>(GetManyPoliciesDocument, options);
}
export type GetManyPoliciesQueryHookResult = ReturnType<typeof useGetManyPoliciesQuery>;
export type GetManyPoliciesLazyQueryHookResult = ReturnType<typeof useGetManyPoliciesLazyQuery>;
export type GetManyPoliciesQueryResult = Apollo.QueryResult<GetManyPoliciesQuery, GetManyPoliciesQueryVariables>;
export const GetManyScriptBlocksDocument = gql`
  query getManyScriptBlocks($skip: Int, $limit: Int) {
    getManyScriptBlocks(skip: $skip, limit: $limit) {
      id
      name
      command
      description
    }
  }
`;

/**
 * __useGetManyScriptBlocksQuery__
 *
 * To run a query within a React component, call `useGetManyScriptBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyScriptBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyScriptBlocksQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetManyScriptBlocksQuery(baseOptions?: Apollo.QueryHookOptions<GetManyScriptBlocksQuery, GetManyScriptBlocksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyScriptBlocksQuery, GetManyScriptBlocksQueryVariables>(GetManyScriptBlocksDocument, options);
}
export function useGetManyScriptBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyScriptBlocksQuery, GetManyScriptBlocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyScriptBlocksQuery, GetManyScriptBlocksQueryVariables>(GetManyScriptBlocksDocument, options);
}
export type GetManyScriptBlocksQueryHookResult = ReturnType<typeof useGetManyScriptBlocksQuery>;
export type GetManyScriptBlocksLazyQueryHookResult = ReturnType<typeof useGetManyScriptBlocksLazyQuery>;
export type GetManyScriptBlocksQueryResult = Apollo.QueryResult<GetManyScriptBlocksQuery, GetManyScriptBlocksQueryVariables>;
export const GetManyScriptBroadcastsDocument = gql`
  query getManyScriptBroadcasts($skip: Int, $limit: Int) {
    getManyScriptBroadcasts(skip: $skip, limit: $limit) {
      id
      name
      description
      steps {
        id
        name
        command
        description
        last_start
        last_finish
        status
        result
        instance
      }
    }
  }
`;

/**
 * __useGetManyScriptBroadcastsQuery__
 *
 * To run a query within a React component, call `useGetManyScriptBroadcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyScriptBroadcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyScriptBroadcastsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetManyScriptBroadcastsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManyScriptBroadcastsQuery, GetManyScriptBroadcastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyScriptBroadcastsQuery, GetManyScriptBroadcastsQueryVariables>(GetManyScriptBroadcastsDocument, options);
}
export function useGetManyScriptBroadcastsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyScriptBroadcastsQuery, GetManyScriptBroadcastsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyScriptBroadcastsQuery, GetManyScriptBroadcastsQueryVariables>(GetManyScriptBroadcastsDocument, options);
}
export type GetManyScriptBroadcastsQueryHookResult = ReturnType<typeof useGetManyScriptBroadcastsQuery>;
export type GetManyScriptBroadcastsLazyQueryHookResult = ReturnType<typeof useGetManyScriptBroadcastsLazyQuery>;
export type GetManyScriptBroadcastsQueryResult = Apollo.QueryResult<GetManyScriptBroadcastsQuery, GetManyScriptBroadcastsQueryVariables>;
export const GetManyScriptTemplatesDocument = gql`
  query getManyScriptTemplates($skip: Int, $limit: Int) {
    getManyScriptTemplates(skip: $skip, limit: $limit) {
      ...scriptTemplateFragment
    }
  }
  ${ScriptTemplateFragmentFragmentDoc}
`;

/**
 * __useGetManyScriptTemplatesQuery__
 *
 * To run a query within a React component, call `useGetManyScriptTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyScriptTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyScriptTemplatesQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetManyScriptTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetManyScriptTemplatesQuery, GetManyScriptTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyScriptTemplatesQuery, GetManyScriptTemplatesQueryVariables>(GetManyScriptTemplatesDocument, options);
}
export function useGetManyScriptTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManyScriptTemplatesQuery, GetManyScriptTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyScriptTemplatesQuery, GetManyScriptTemplatesQueryVariables>(GetManyScriptTemplatesDocument, options);
}
export type GetManyScriptTemplatesQueryHookResult = ReturnType<typeof useGetManyScriptTemplatesQuery>;
export type GetManyScriptTemplatesLazyQueryHookResult = ReturnType<typeof useGetManyScriptTemplatesLazyQuery>;
export type GetManyScriptTemplatesQueryResult = Apollo.QueryResult<GetManyScriptTemplatesQuery, GetManyScriptTemplatesQueryVariables>;
export const GetManySkusDocument = gql`
  query getManySkus($skip: Int, $limit: Int) {
    getManySkus(skip: $skip, limit: $limit) {
      results {
        id
        SKU_Code
        SKU_desc
        Rollup_code
        Rollup_desc
        Price
        Cost
        Margin
        Billing_unit
        Billing_type
      }
      count
    }
  }
`;

/**
 * __useGetManySkusQuery__
 *
 * To run a query within a React component, call `useGetManySkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySkusQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetManySkusQuery(baseOptions?: Apollo.QueryHookOptions<GetManySkusQuery, GetManySkusQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManySkusQuery, GetManySkusQueryVariables>(GetManySkusDocument, options);
}
export function useGetManySkusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManySkusQuery, GetManySkusQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManySkusQuery, GetManySkusQueryVariables>(GetManySkusDocument, options);
}
export type GetManySkusQueryHookResult = ReturnType<typeof useGetManySkusQuery>;
export type GetManySkusLazyQueryHookResult = ReturnType<typeof useGetManySkusLazyQuery>;
export type GetManySkusQueryResult = Apollo.QueryResult<GetManySkusQuery, GetManySkusQueryVariables>;
export const GetManySupportTicketsDocument = gql`
  query getManySupportTickets($type: SupportTicketTypeEnum!, $page: Int!, $perPage: Int!) {
    getManySupportTickets(type: $type, page: $page, perPage: $perPage) {
      results {
        id
        type
        subject
        state
        priority
        createdAt
        requestedBy
      }
      pagination {
        perPage
        currentPage
        totalCount
        totalPages
      }
    }
  }
`;

/**
 * __useGetManySupportTicketsQuery__
 *
 * To run a query within a React component, call `useGetManySupportTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManySupportTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManySupportTicketsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetManySupportTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetManySupportTicketsQuery, GetManySupportTicketsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManySupportTicketsQuery, GetManySupportTicketsQueryVariables>(GetManySupportTicketsDocument, options);
}
export function useGetManySupportTicketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetManySupportTicketsQuery, GetManySupportTicketsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManySupportTicketsQuery, GetManySupportTicketsQueryVariables>(GetManySupportTicketsDocument, options);
}
export type GetManySupportTicketsQueryHookResult = ReturnType<typeof useGetManySupportTicketsQuery>;
export type GetManySupportTicketsLazyQueryHookResult = ReturnType<typeof useGetManySupportTicketsLazyQuery>;
export type GetManySupportTicketsQueryResult = Apollo.QueryResult<GetManySupportTicketsQuery, GetManySupportTicketsQueryVariables>;
export const GetManyUsersDocument = gql`
  query getManyUsers($userTypes: [UserTypeEnum!], $deactivated: Boolean) {
    getManyUsers(userTypes: $userTypes, deactivated: $deactivated) {
      id
      email
      firstName
      lastName
      acceptedInvite
      recentlySentInvite
      deactivated
      userType
      organizations {
        id
        name
      }
    }
  }
`;

/**
 * __useGetManyUsersQuery__
 *
 * To run a query within a React component, call `useGetManyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyUsersQuery({
 *   variables: {
 *      userTypes: // value for 'userTypes'
 *      deactivated: // value for 'deactivated'
 *   },
 * });
 */
export function useGetManyUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetManyUsersQuery, GetManyUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyUsersQuery, GetManyUsersQueryVariables>(GetManyUsersDocument, options);
}
export function useGetManyUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyUsersQuery, GetManyUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyUsersQuery, GetManyUsersQueryVariables>(GetManyUsersDocument, options);
}
export type GetManyUsersQueryHookResult = ReturnType<typeof useGetManyUsersQuery>;
export type GetManyUsersLazyQueryHookResult = ReturnType<typeof useGetManyUsersLazyQuery>;
export type GetManyUsersQueryResult = Apollo.QueryResult<GetManyUsersQuery, GetManyUsersQueryVariables>;
export const GetManyVendorsDocument = gql`
  query getManyVendors {
    getManyVendors
  }
`;

/**
 * __useGetManyVendorsQuery__
 *
 * To run a query within a React component, call `useGetManyVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManyVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManyVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetManyVendorsQuery(baseOptions?: Apollo.QueryHookOptions<GetManyVendorsQuery, GetManyVendorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManyVendorsQuery, GetManyVendorsQueryVariables>(GetManyVendorsDocument, options);
}
export function useGetManyVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetManyVendorsQuery, GetManyVendorsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetManyVendorsQuery, GetManyVendorsQueryVariables>(GetManyVendorsDocument, options);
}
export type GetManyVendorsQueryHookResult = ReturnType<typeof useGetManyVendorsQuery>;
export type GetManyVendorsLazyQueryHookResult = ReturnType<typeof useGetManyVendorsLazyQuery>;
export type GetManyVendorsQueryResult = Apollo.QueryResult<GetManyVendorsQuery, GetManyVendorsQueryVariables>;
export const GetOneApplicationDocument = gql`
  query getOneApplication($applicationId: ID!) {
    getOneApplication(applicationId: $applicationId) {
      id
      name
    }
  }
`;

/**
 * __useGetOneApplicationQuery__
 *
 * To run a query within a React component, call `useGetOneApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneApplicationQuery({
 *   variables: {
 *      applicationId: // value for 'applicationId'
 *   },
 * });
 */
export function useGetOneApplicationQuery(baseOptions: Apollo.QueryHookOptions<GetOneApplicationQuery, GetOneApplicationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneApplicationQuery, GetOneApplicationQueryVariables>(GetOneApplicationDocument, options);
}
export function useGetOneApplicationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneApplicationQuery, GetOneApplicationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneApplicationQuery, GetOneApplicationQueryVariables>(GetOneApplicationDocument, options);
}
export type GetOneApplicationQueryHookResult = ReturnType<typeof useGetOneApplicationQuery>;
export type GetOneApplicationLazyQueryHookResult = ReturnType<typeof useGetOneApplicationLazyQuery>;
export type GetOneApplicationQueryResult = Apollo.QueryResult<GetOneApplicationQuery, GetOneApplicationQueryVariables>;
export const GetOneEnvironmentDocument = gql`
  query getOneEnvironment($environmentId: ID!) {
    getOneEnvironment(environmentId: $environmentId) {
      id
      name
      isProduction
      isRunning
      environmentType
      vendor
      applicationId
      instances {
        id
        externalId
        name
        description
        state
        isRunning
        isProduction
        numberOfMetrics
        updatedAt
      }
    }
  }
`;

/**
 * __useGetOneEnvironmentQuery__
 *
 * To run a query within a React component, call `useGetOneEnvironmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneEnvironmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneEnvironmentQuery({
 *   variables: {
 *      environmentId: // value for 'environmentId'
 *   },
 * });
 */
export function useGetOneEnvironmentQuery(baseOptions: Apollo.QueryHookOptions<GetOneEnvironmentQuery, GetOneEnvironmentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneEnvironmentQuery, GetOneEnvironmentQueryVariables>(GetOneEnvironmentDocument, options);
}
export function useGetOneEnvironmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneEnvironmentQuery, GetOneEnvironmentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneEnvironmentQuery, GetOneEnvironmentQueryVariables>(GetOneEnvironmentDocument, options);
}
export type GetOneEnvironmentQueryHookResult = ReturnType<typeof useGetOneEnvironmentQuery>;
export type GetOneEnvironmentLazyQueryHookResult = ReturnType<typeof useGetOneEnvironmentLazyQuery>;
export type GetOneEnvironmentQueryResult = Apollo.QueryResult<GetOneEnvironmentQuery, GetOneEnvironmentQueryVariables>;
export const GetOneInstanceDocument = gql`
  query getOneInstance($instanceId: String!) {
    getOneInstance(instanceId: $instanceId) {
      id
      externalId
      name
      description
      state
      isRunning
      isProduction
      numberOfMetrics
      architecture
      instanceType
      dynatraceHostId
      instanceSize
      vendorInstanceType
      cpu
      region
      resourceGroup
      networkIn {
        mean
        data {
          timestamp
          value
          unit
        }
      }
      networkOut {
        mean
        data {
          timestamp
          value
          unit
        }
      }
      pattern
      rootDeviceType
      privateIPAddress
      subnet
      additionalInfo
      instanceSpecs
      lastInstanceSpecs
      customDeviceId
      updatedAt
      vendor
      osType
      osVersion
      changeLogs {
        id
        instanceId
        dateOfChange
        key
        oldValue
        typeOfChange
        changedById
        changedByString
      }
      application {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOneInstanceQuery__
 *
 * To run a query within a React component, call `useGetOneInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneInstanceQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useGetOneInstanceQuery(baseOptions: Apollo.QueryHookOptions<GetOneInstanceQuery, GetOneInstanceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneInstanceQuery, GetOneInstanceQueryVariables>(GetOneInstanceDocument, options);
}
export function useGetOneInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneInstanceQuery, GetOneInstanceQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneInstanceQuery, GetOneInstanceQueryVariables>(GetOneInstanceDocument, options);
}
export type GetOneInstanceQueryHookResult = ReturnType<typeof useGetOneInstanceQuery>;
export type GetOneInstanceLazyQueryHookResult = ReturnType<typeof useGetOneInstanceLazyQuery>;
export type GetOneInstanceQueryResult = Apollo.QueryResult<GetOneInstanceQuery, GetOneInstanceQueryVariables>;
export const GetOneInstance_ShortDocument = gql`
  query getOneInstance_short($instanceId: String!) {
    getOneInstance(instanceId: $instanceId) {
      id
      externalId
      name
      description
      architecture
      instanceType
      instanceSize
      vendorInstanceType
      cpu
      region
      resourceGroup
      pattern
      rootDeviceType
      privateIPAddress
      subnet
      additionalInfo
      instanceSpecs
      lastInstanceSpecs
      customDeviceId
      dynatraceHostId
      updatedAt
      vendor
    }
  }
`;

/**
 * __useGetOneInstance_ShortQuery__
 *
 * To run a query within a React component, call `useGetOneInstance_ShortQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneInstance_ShortQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneInstance_ShortQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *   },
 * });
 */
export function useGetOneInstance_ShortQuery(baseOptions: Apollo.QueryHookOptions<GetOneInstance_ShortQuery, GetOneInstance_ShortQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneInstance_ShortQuery, GetOneInstance_ShortQueryVariables>(GetOneInstance_ShortDocument, options);
}
export function useGetOneInstance_ShortLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOneInstance_ShortQuery, GetOneInstance_ShortQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneInstance_ShortQuery, GetOneInstance_ShortQueryVariables>(GetOneInstance_ShortDocument, options);
}
export type GetOneInstance_ShortQueryHookResult = ReturnType<typeof useGetOneInstance_ShortQuery>;
export type GetOneInstance_ShortLazyQueryHookResult = ReturnType<typeof useGetOneInstance_ShortLazyQuery>;
export type GetOneInstance_ShortQueryResult = Apollo.QueryResult<GetOneInstance_ShortQuery, GetOneInstance_ShortQueryVariables>;
export const GetOneOrganizationDocument = gql`
  query getOneOrganization($id: ID!) {
    getOneOrganization(id: $id) {
      ...organizationFragment
    }
  }
  ${OrganizationFragmentFragmentDoc}
`;

/**
 * __useGetOneOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOneOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOneOrganizationQuery, GetOneOrganizationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneOrganizationQuery, GetOneOrganizationQueryVariables>(GetOneOrganizationDocument, options);
}
export function useGetOneOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneOrganizationQuery, GetOneOrganizationQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneOrganizationQuery, GetOneOrganizationQueryVariables>(GetOneOrganizationDocument, options);
}
export type GetOneOrganizationQueryHookResult = ReturnType<typeof useGetOneOrganizationQuery>;
export type GetOneOrganizationLazyQueryHookResult = ReturnType<typeof useGetOneOrganizationLazyQuery>;
export type GetOneOrganizationQueryResult = Apollo.QueryResult<GetOneOrganizationQuery, GetOneOrganizationQueryVariables>;
export const GetOneSupportTicketDocument = gql`
  query getOneSupportTicket($id: Int!, $type: SupportTicketTypeEnum!) {
    getOneSupportTicket(id: $id, type: $type) {
      id
      number
      type
      subject
      description
      state
      priority
      createdAt
      requestedBy
      category
      subcategory
      assignedTo
      rootCause
      symptoms
      workaround
      resolutionDescription
      resolutionCode
      site
      dueDays
      tags {
        id
        name
      }
      createdAt
      updatedAt
      dueAt
      assetTag
      rollbackPlan
      testPlan
      changePlan
      attachments {
        id
        filename
        url
      }
      comments {
        id
        body
        user {
          id
          email
          name
          avatar {
            type
            color
            initials
          }
        }
        attachments {
          id
          filename
          url
        }
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useGetOneSupportTicketQuery__
 *
 * To run a query within a React component, call `useGetOneSupportTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneSupportTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneSupportTicketQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetOneSupportTicketQuery(baseOptions: Apollo.QueryHookOptions<GetOneSupportTicketQuery, GetOneSupportTicketQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneSupportTicketQuery, GetOneSupportTicketQueryVariables>(GetOneSupportTicketDocument, options);
}
export function useGetOneSupportTicketLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOneSupportTicketQuery, GetOneSupportTicketQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneSupportTicketQuery, GetOneSupportTicketQueryVariables>(GetOneSupportTicketDocument, options);
}
export type GetOneSupportTicketQueryHookResult = ReturnType<typeof useGetOneSupportTicketQuery>;
export type GetOneSupportTicketLazyQueryHookResult = ReturnType<typeof useGetOneSupportTicketLazyQuery>;
export type GetOneSupportTicketQueryResult = Apollo.QueryResult<GetOneSupportTicketQuery, GetOneSupportTicketQueryVariables>;
export const GetOneUserDocument = gql`
  query getOneUser($id: ID!) {
    getOneUser(id: $id) {
      id
      email
      firstName
      lastName
      userType
      permission
      organizations {
        id
        name
      }
    }
  }
`;

/**
 * __useGetOneUserQuery__
 *
 * To run a query within a React component, call `useGetOneUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneUserQuery(baseOptions: Apollo.QueryHookOptions<GetOneUserQuery, GetOneUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOneUserQuery, GetOneUserQueryVariables>(GetOneUserDocument, options);
}
export function useGetOneUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneUserQuery, GetOneUserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOneUserQuery, GetOneUserQueryVariables>(GetOneUserDocument, options);
}
export type GetOneUserQueryHookResult = ReturnType<typeof useGetOneUserQuery>;
export type GetOneUserLazyQueryHookResult = ReturnType<typeof useGetOneUserLazyQuery>;
export type GetOneUserQueryResult = Apollo.QueryResult<GetOneUserQuery, GetOneUserQueryVariables>;
export const GetRegionsByVendorDocument = gql`
  query getRegionsByVendor($vendor: CloudVendorTypeEnum!) {
    getRegionsByVendor(vendor: $vendor)
  }
`;

/**
 * __useGetRegionsByVendorQuery__
 *
 * To run a query within a React component, call `useGetRegionsByVendorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsByVendorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsByVendorQuery({
 *   variables: {
 *      vendor: // value for 'vendor'
 *   },
 * });
 */
export function useGetRegionsByVendorQuery(baseOptions: Apollo.QueryHookOptions<GetRegionsByVendorQuery, GetRegionsByVendorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionsByVendorQuery, GetRegionsByVendorQueryVariables>(GetRegionsByVendorDocument, options);
}
export function useGetRegionsByVendorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionsByVendorQuery, GetRegionsByVendorQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionsByVendorQuery, GetRegionsByVendorQueryVariables>(GetRegionsByVendorDocument, options);
}
export type GetRegionsByVendorQueryHookResult = ReturnType<typeof useGetRegionsByVendorQuery>;
export type GetRegionsByVendorLazyQueryHookResult = ReturnType<typeof useGetRegionsByVendorLazyQuery>;
export type GetRegionsByVendorQueryResult = Apollo.QueryResult<GetRegionsByVendorQuery, GetRegionsByVendorQueryVariables>;
export const GetReport_CapacityDocument = gql`
  query getReport_Capacity($relativeTime: RelativeTimeEnum) {
    getReport_Capacity(relativeTime: $relativeTime) {
      metricValues {
        timestamp
        value
      }
      metric
      metricName
      metricType
      metricDescription
    }
  }
`;

/**
 * __useGetReport_CapacityQuery__
 *
 * To run a query within a React component, call `useGetReport_CapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReport_CapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReport_CapacityQuery({
 *   variables: {
 *      relativeTime: // value for 'relativeTime'
 *   },
 * });
 */
export function useGetReport_CapacityQuery(baseOptions?: Apollo.QueryHookOptions<GetReport_CapacityQuery, GetReport_CapacityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReport_CapacityQuery, GetReport_CapacityQueryVariables>(GetReport_CapacityDocument, options);
}
export function useGetReport_CapacityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReport_CapacityQuery, GetReport_CapacityQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReport_CapacityQuery, GetReport_CapacityQueryVariables>(GetReport_CapacityDocument, options);
}
export type GetReport_CapacityQueryHookResult = ReturnType<typeof useGetReport_CapacityQuery>;
export type GetReport_CapacityLazyQueryHookResult = ReturnType<typeof useGetReport_CapacityLazyQuery>;
export type GetReport_CapacityQueryResult = Apollo.QueryResult<GetReport_CapacityQuery, GetReport_CapacityQueryVariables>;
export const GetSla_DiskUtilizationDocument = gql`
  query getSLA_DiskUtilization($startDate: Float, $endDate: Float, $production: Boolean) {
    getSLA_DiskUtilization(startDate: $startDate, endDate: $endDate, production: $production) {
      hostDisplayName
      totalAllocated
      totalUsed
    }
  }
`;

/**
 * __useGetSla_DiskUtilizationQuery__
 *
 * To run a query within a React component, call `useGetSla_DiskUtilizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSla_DiskUtilizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSla_DiskUtilizationQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      production: // value for 'production'
 *   },
 * });
 */
export function useGetSla_DiskUtilizationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSla_DiskUtilizationQuery, GetSla_DiskUtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSla_DiskUtilizationQuery, GetSla_DiskUtilizationQueryVariables>(GetSla_DiskUtilizationDocument, options);
}
export function useGetSla_DiskUtilizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSla_DiskUtilizationQuery, GetSla_DiskUtilizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSla_DiskUtilizationQuery, GetSla_DiskUtilizationQueryVariables>(GetSla_DiskUtilizationDocument, options);
}
export type GetSla_DiskUtilizationQueryHookResult = ReturnType<typeof useGetSla_DiskUtilizationQuery>;
export type GetSla_DiskUtilizationLazyQueryHookResult = ReturnType<typeof useGetSla_DiskUtilizationLazyQuery>;
export type GetSla_DiskUtilizationQueryResult = Apollo.QueryResult<GetSla_DiskUtilizationQuery, GetSla_DiskUtilizationQueryVariables>;
export const GetSla_SummaryTableDocument = gql`
  query getSLA_SummaryTable($startDate: Float, $endDate: Float) {
    getSLA_SummaryTable(startDate: $startDate, endDate: $endDate) {
      sid
      hostDisplayName
      sap
      db
      host
      comments
    }
  }
`;

/**
 * __useGetSla_SummaryTableQuery__
 *
 * To run a query within a React component, call `useGetSla_SummaryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSla_SummaryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSla_SummaryTableQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetSla_SummaryTableQuery(baseOptions?: Apollo.QueryHookOptions<GetSla_SummaryTableQuery, GetSla_SummaryTableQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSla_SummaryTableQuery, GetSla_SummaryTableQueryVariables>(GetSla_SummaryTableDocument, options);
}
export function useGetSla_SummaryTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSla_SummaryTableQuery, GetSla_SummaryTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSla_SummaryTableQuery, GetSla_SummaryTableQueryVariables>(GetSla_SummaryTableDocument, options);
}
export type GetSla_SummaryTableQueryHookResult = ReturnType<typeof useGetSla_SummaryTableQuery>;
export type GetSla_SummaryTableLazyQueryHookResult = ReturnType<typeof useGetSla_SummaryTableLazyQuery>;
export type GetSla_SummaryTableQueryResult = Apollo.QueryResult<GetSla_SummaryTableQuery, GetSla_SummaryTableQueryVariables>;
export const GetSla_UptimeDocument = gql`
  query getSLA_Uptime($startDate: Float, $endDate: Float, $production: Boolean, $uptimeType: UptimeTypeEnum) {
    getSLA_Uptime(startDate: $startDate, endDate: $endDate, production: $production, uptimeType: $uptimeType) {
      hostDisplayName
      uptime
      sid
    }
  }
`;

/**
 * __useGetSla_UptimeQuery__
 *
 * To run a query within a React component, call `useGetSla_UptimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSla_UptimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSla_UptimeQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      production: // value for 'production'
 *      uptimeType: // value for 'uptimeType'
 *   },
 * });
 */
export function useGetSla_UptimeQuery(baseOptions?: Apollo.QueryHookOptions<GetSla_UptimeQuery, GetSla_UptimeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSla_UptimeQuery, GetSla_UptimeQueryVariables>(GetSla_UptimeDocument, options);
}
export function useGetSla_UptimeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSla_UptimeQuery, GetSla_UptimeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSla_UptimeQuery, GetSla_UptimeQueryVariables>(GetSla_UptimeDocument, options);
}
export type GetSla_UptimeQueryHookResult = ReturnType<typeof useGetSla_UptimeQuery>;
export type GetSla_UptimeLazyQueryHookResult = ReturnType<typeof useGetSla_UptimeLazyQuery>;
export type GetSla_UptimeQueryResult = Apollo.QueryResult<GetSla_UptimeQuery, GetSla_UptimeQueryVariables>;
export const GetSkuBreakdownDocument = gql`
  query getSkuBreakdown($organizationId: ID, $startDate: String!, $endDate: String!) {
    getSkuBreakdown(organizationId: $organizationId, startDate: $startDate, endDate: $endDate) {
      results {
        SKU_code
        Sold_unit_price
      }
    }
  }
`;

/**
 * __useGetSkuBreakdownQuery__
 *
 * To run a query within a React component, call `useGetSkuBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSkuBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSkuBreakdownQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetSkuBreakdownQuery(baseOptions: Apollo.QueryHookOptions<GetSkuBreakdownQuery, GetSkuBreakdownQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSkuBreakdownQuery, GetSkuBreakdownQueryVariables>(GetSkuBreakdownDocument, options);
}
export function useGetSkuBreakdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSkuBreakdownQuery, GetSkuBreakdownQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSkuBreakdownQuery, GetSkuBreakdownQueryVariables>(GetSkuBreakdownDocument, options);
}
export type GetSkuBreakdownQueryHookResult = ReturnType<typeof useGetSkuBreakdownQuery>;
export type GetSkuBreakdownLazyQueryHookResult = ReturnType<typeof useGetSkuBreakdownLazyQuery>;
export type GetSkuBreakdownQueryResult = Apollo.QueryResult<GetSkuBreakdownQuery, GetSkuBreakdownQueryVariables>;
export const GetSystemMetricsDocument = gql`
  query getSystemMetrics($instanceId: String!, $relativeTime: RelativeTimeEnum) {
    getSystemMetrics(instanceId: $instanceId, relativeTime: $relativeTime) {
      metricValues {
        timestamp
        value
      }
      metric
      metricName
      metricType
      metricDescription
    }
  }
`;

/**
 * __useGetSystemMetricsQuery__
 *
 * To run a query within a React component, call `useGetSystemMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemMetricsQuery({
 *   variables: {
 *      instanceId: // value for 'instanceId'
 *      relativeTime: // value for 'relativeTime'
 *   },
 * });
 */
export function useGetSystemMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetSystemMetricsQuery, GetSystemMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSystemMetricsQuery, GetSystemMetricsQueryVariables>(GetSystemMetricsDocument, options);
}
export function useGetSystemMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemMetricsQuery, GetSystemMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSystemMetricsQuery, GetSystemMetricsQueryVariables>(GetSystemMetricsDocument, options);
}
export type GetSystemMetricsQueryHookResult = ReturnType<typeof useGetSystemMetricsQuery>;
export type GetSystemMetricsLazyQueryHookResult = ReturnType<typeof useGetSystemMetricsLazyQuery>;
export type GetSystemMetricsQueryResult = Apollo.QueryResult<GetSystemMetricsQuery, GetSystemMetricsQueryVariables>;
export const GetTicketSummaryDocument = gql`
  query getTicketSummary {
    getTicketSummary {
      id
      name
      total
    }
  }
`;

/**
 * __useGetTicketSummaryQuery__
 *
 * To run a query within a React component, call `useGetTicketSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>(GetTicketSummaryDocument, options);
}
export function useGetTicketSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>(GetTicketSummaryDocument, options);
}
export type GetTicketSummaryQueryHookResult = ReturnType<typeof useGetTicketSummaryQuery>;
export type GetTicketSummaryLazyQueryHookResult = ReturnType<typeof useGetTicketSummaryLazyQuery>;
export type GetTicketSummaryQueryResult = Apollo.QueryResult<GetTicketSummaryQuery, GetTicketSummaryQueryVariables>;
export const GetUsersByOrganizationDocument = gql`
  query getUsersByOrganization($organizationId: ID!, $deactivated: Boolean) {
    getUsersByOrganization(organizationId: $organizationId, deactivated: $deactivated) {
      id
      email
      firstName
      lastName
      acceptedInvite
      recentlySentInvite
      deactivated
      userType
      organizations {
        id
        name
      }
    }
  }
`;

/**
 * __useGetUsersByOrganizationQuery__
 *
 * To run a query within a React component, call `useGetUsersByOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deactivated: // value for 'deactivated'
 *   },
 * });
 */
export function useGetUsersByOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
}
export function useGetUsersByOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>(GetUsersByOrganizationDocument, options);
}
export type GetUsersByOrganizationQueryHookResult = ReturnType<typeof useGetUsersByOrganizationQuery>;
export type GetUsersByOrganizationLazyQueryHookResult = ReturnType<typeof useGetUsersByOrganizationLazyQuery>;
export type GetUsersByOrganizationQueryResult = Apollo.QueryResult<GetUsersByOrganizationQuery, GetUsersByOrganizationQueryVariables>;
export const GetVpNsDocument = gql`
  query getVPNs {
    getVPNs {
      id
      name
      isRunning
      ipAddress
      lastUpdated
      organizationId
    }
  }
`;

/**
 * __useGetVpNsQuery__
 *
 * To run a query within a React component, call `useGetVpNsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVpNsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVpNsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetVpNsQuery(baseOptions?: Apollo.QueryHookOptions<GetVpNsQuery, GetVpNsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVpNsQuery, GetVpNsQueryVariables>(GetVpNsDocument, options);
}
export function useGetVpNsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVpNsQuery, GetVpNsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVpNsQuery, GetVpNsQueryVariables>(GetVpNsDocument, options);
}
export type GetVpNsQueryHookResult = ReturnType<typeof useGetVpNsQuery>;
export type GetVpNsLazyQueryHookResult = ReturnType<typeof useGetVpNsLazyQuery>;
export type GetVpNsQueryResult = Apollo.QueryResult<GetVpNsQuery, GetVpNsQueryVariables>;
export const GetVpnMetricsDocument = gql`
  query getVpnMetrics($organizationId: ID!, $tunnelIpAddress: String!) {
    getVpnMetrics(organizationId: $organizationId, tunnelIpAddress: $tunnelIpAddress) {
      label
      datapoints {
        timestamp
        value
        unit
      }
    }
  }
`;

/**
 * __useGetVpnMetricsQuery__
 *
 * To run a query within a React component, call `useGetVpnMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVpnMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVpnMetricsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      tunnelIpAddress: // value for 'tunnelIpAddress'
 *   },
 * });
 */
export function useGetVpnMetricsQuery(baseOptions: Apollo.QueryHookOptions<GetVpnMetricsQuery, GetVpnMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVpnMetricsQuery, GetVpnMetricsQueryVariables>(GetVpnMetricsDocument, options);
}
export function useGetVpnMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVpnMetricsQuery, GetVpnMetricsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVpnMetricsQuery, GetVpnMetricsQueryVariables>(GetVpnMetricsDocument, options);
}
export type GetVpnMetricsQueryHookResult = ReturnType<typeof useGetVpnMetricsQuery>;
export type GetVpnMetricsLazyQueryHookResult = ReturnType<typeof useGetVpnMetricsLazyQuery>;
export type GetVpnMetricsQueryResult = Apollo.QueryResult<GetVpnMetricsQuery, GetVpnMetricsQueryVariables>;
export const SearchDocument = gql`
  query search($searchText: String) {
    search(searchText: $searchText) {
      instances {
        id
        name
        externalId
      }
    }
  }
`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useSearchQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const RefreshTokensDocument = gql`
  mutation refreshTokens($accessToken: String!, $refreshToken: String!) {
    refreshTokens(accessToken: $accessToken, refreshToken: $refreshToken) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
export type RefreshTokensMutationFn = Apollo.MutationFunction<RefreshTokensMutation, RefreshTokensMutationVariables>;

/**
 * __useRefreshTokensMutation__
 *
 * To run a mutation, you first call `useRefreshTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokensMutation, { data, loading, error }] = useRefreshTokensMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshTokensMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokensMutation, RefreshTokensMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshTokensMutation, RefreshTokensMutationVariables>(RefreshTokensDocument, options);
}
export type RefreshTokensMutationHookResult = ReturnType<typeof useRefreshTokensMutation>;
export type RefreshTokensMutationResult = Apollo.MutationResult<RefreshTokensMutation>;
export type RefreshTokensMutationOptions = Apollo.BaseMutationOptions<RefreshTokensMutation, RefreshTokensMutationVariables>;
