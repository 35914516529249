import Loading from "components/common/Loading";
import {
  Application,
  GetManyApplicationsDocument,
  useCreateApplicationMutation,
  useCreateEnvironmentMutation,
  useGetManyApplicationsQuery,
  useGetManyInstancesQuery,
} from "generated/graphql";
import Text from "components/text/Text";
import Tag from "components/common/Tag";
import ApplicationForm from "components/forms/ApplicationForm";
import EnvironmentForm from "components/forms/EnvironmentForm";
import Button from "components/common/Button";
import message from "components/common/message";
import { useState } from "react";
import Tooltip from "components/common/Tooltip";
import DarkTable from "components/common/DarkTable";
import Drawer from "components/common/Drawer";
import EditApplication from "components/common/EditApplication";
import EditEnvironment from "components/common/EditEnvironment";
import styled from "styled-components";
import environmentTypeHashmap from "lib/helpers/environmentTypeHashmap";
import { EditOutlined } from "@ant-design/icons";
import theme from "lib/theme";

const LinkBtn = styled.button`
  border: 0px;
  padding: 0px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.text2};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.text3};
  }
`;

const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Applications({ organizationId }) {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [editApplication, setEditApplication] = useState<boolean | string>(
    false
  );
  const [editEnvironment, setEditEnvironment] = useState<boolean | string>(
    false
  );
  const [addNewEnv, setaddNewEnv] = useState<false | Application>(false); // is either false or holds an application ID for which we want to add an environment to
  /**
   * QUERIES
   */
  const { data, loading, error } = useGetManyApplicationsQuery({
    variables: {
      organizationId,
    },
  });
  const { data: instancesData } = useGetManyInstancesQuery({
    variables: {
      organizationId,
    },
  });
  /**
   * MUTATIONS
   */
  const [createApplicationMutation, { loading: creating }] =
    useCreateApplicationMutation();
  const [createEnvironmentMutation, { loading: creatingEnv }] =
    useCreateEnvironmentMutation();

  if (loading) return <Loading />;
  if (error) return <Text>{error?.message}</Text>;

  const getDrawerTitle = () => {
    if (addNew) {
      return "Create a New Application";
    }
    if (addNewEnv) {
      return `Create environment for ${addNewEnv?.name}`;
    }
    if (editApplication) {
      return "Edit Application";
    }
    if (editEnvironment) {
      return "Edit Environemnt";
    }
  };

  const onCloseDrawer = () => {
    setAddNew(false);
    setaddNewEnv(false);
    setEditApplication(false);
    setEditEnvironment(false);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setAddNew(true)} style={{ marginBottom: 16 }}>
          + New Application
        </Button>
      </div>
      <DarkTable
        dataSource={data?.getManyApplications}
        columns={[
          {
            key: "id",
            title: "Name",
            render: (record: Application) => {
              return <Text>{record?.name}</Text>;
            },
          },
          {
            title: "Environments",
            render: (record: Application) => {
              return (
                <>
                  {record?.environments?.map((environment) => (
                    <Tooltip
                      key={environment?.id}
                      title={environment?.instances?.map((instance) => (
                        <Tag
                          key={instance?.name}
                          onClick={() => console.log(instance)}
                          style={{ cursor: "pointer" }}
                        >
                          {instance?.name}
                        </Tag>
                      ))}
                    >
                      <Tag
                        onClick={() => {
                          if (!environment?.id) return;
                          setEditEnvironment(environment?.id);
                        }}
                      >
                        {environment?.name} -
                        {
                          environmentTypeHashmap[
                            environment?.environmentType as string
                          ]
                        }
                      </Tag>
                    </Tooltip>
                  ))}
                  <LinkBtn color="cyan" onClick={() => setaddNewEnv(record)}>
                    + New Env
                  </LinkBtn>
                </>
              );
            },
          },
          {
            title: "",
            render: (item: Application) => (
              <Center>
                <EditOutlined
                  style={{ color: theme.colors.primary4 }}
                  onClick={() => setEditApplication(item.id)}
                />
              </Center>
            ),
          },
        ]}
      />
      <Drawer
        title={getDrawerTitle()}
        placement="right"
        width={500}
        onClose={onCloseDrawer}
        visible={
          addNew || addNewEnv || editApplication || editEnvironment
            ? true
            : false
        }
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        {addNew && (
          <ApplicationForm
            loading={creating}
            onSubmit={async (values) => {
              try {
                await createApplicationMutation({
                  variables: {
                    params: {
                      name: values.name,
                      organizationId: organizationId,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GetManyApplicationsDocument,
                      variables: {
                        organizationId,
                      },
                    },
                  ],
                });
                setAddNew(false);
                message.success("Application added!");
              } catch (err) {
                console.log(err);
              }
            }}
            onCancel={() => setAddNew(false)}
          />
        )}
        {editApplication && (
          <EditApplication
            applicationId={editApplication}
            onCancel={onCloseDrawer}
          />
        )}
        {editEnvironment && (
          <EditEnvironment
            environmentId={editEnvironment}
            onCancel={onCloseDrawer}
            instances={instancesData?.getManyInstances || []}
          />
        )}
        {addNewEnv && (
          <EnvironmentForm
            loading={creatingEnv}
            onSubmit={async (values) => {
              try {
                await createEnvironmentMutation({
                  variables: {
                    params: {
                      name: values.name,
                      environmentType: values.environmentType,
                      instanceIds: values.instanceIds,
                      applicationId: addNewEnv.id,
                      tiers: [values.tier],
                      vendor: values.vendor,
                      organizationId,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GetManyApplicationsDocument,
                      variables: {
                        organizationId,
                      },
                    },
                  ],
                });
                setaddNewEnv(false);
                message.success("Application added!");
              } catch (err) {
                console.log(err);
              }
            }}
            onCancel={() => setaddNewEnv(false)}
            instances={instancesData?.getManyInstances || []}
          />
        )}
      </Drawer>
    </div>
  );
}
