import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DarkTable from "components/common/DarkTable";
import { Invoice, useGetManyInvoicesQuery } from "generated/graphql";
import { useState } from "react";
import { toNumber } from "lodash";
import moment from "moment";
import InvoiceForm from "components/forms/InvoiceForm";
import Drawer from "components/common/Drawer";
import Button from "components/common/Button";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import theme from "lib/theme";
import Row from "components/common/Row";
import Col from "components/common/Col";
import InvoicePDF from "components/common/InvoicePDF";
import useDownloadPdf from "lib/hooks/useDownloadPdf";
import formatCurrency from "lib/helpers/formatCurrency";

const DEFAULT_LIMIT = 7;

const InvoiceDetail = ({ invoice }: { invoice: Invoice }) => {
  const { downloadPdf, downloading } = useDownloadPdf();

  const onDownload = async () => {
    try {
      await downloadPdf(<InvoicePDF invoice={invoice} downloading />, `Invoice.pdf`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Row gutter={24} style={{ padding: 16, paddingTop: 8 }}>
        <Col xs={12}>
          <Caption>Invoice #:</Caption>
          <Text>000{invoice.Invoiceno}</Text>
          <Caption style={{ marginTop: 16 }}>Amount:</Caption>
          <Text> {formatCurrency(invoice.Invoice_amount || "")}</Text>
          <Caption style={{ marginTop: 16 }}>Paid:</Caption>
          <Text> {formatCurrency(invoice.Amount_Paid || "")}</Text>
          <Caption style={{ marginTop: 16 }}>Notes:</Caption>
          <Text>{invoice.Invoice_Notes}</Text>
        </Col>
        <Col xs={12}>
          <Button loading={downloading} disabled={downloading} onClick={onDownload} grey style={{ marginLeft: 24 }}>
            Download Invoice
          </Button>
        </Col>
        <Col xs={20}>
          <Caption style={{ marginTop: 24 }}>Line Items</Caption>
          {invoice?.lineItems?.map((item) => {
            return (
              <Row key={item?.id} style={{ display: "flex", marginTop: 12 }}>
                <Col xs={8}>
                  <Text>
                    {item.sku} ({item.description})
                  </Text>
                </Col>
                <Col xs={4}>
                  <Text style={{ textAlign: "right" }}>{toNumber(item?.quantity || 0)?.toFixed(1)}</Text>
                </Col>
                <Col xs={6}>
                  <Text style={{ textAlign: "right" }}>{formatCurrency(item.price || "")}</Text>
                </Col>
                <Col xs={6}>
                  <Text style={{ textAlign: "right" }}>{formatCurrency(item.total || "")}</Text>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col xs={4} />
      </Row>
    </>
  );
};

export default function AdminInvoices() {
  const [addInvoice, setAddInvoice] = useState(false);
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: DEFAULT_LIMIT,
  });

  const { data, loading } = useGetManyInvoicesQuery({
    variables: {
      skip: filters.skip,
      limit: filters.limit,
    },
  });

  const columns = [
    {
      title: "Invoice #",
      key: "id",
      render: (record: Invoice) => <Text>{record.Invoiceno}</Text>,
    },
    {
      title: "Client",

      render: (record: Invoice) => <Text>{record?.organization?.name}</Text>,
    },
    // {
    //   key: "id",
    //   title: "Notes",
    //   width: 200,
    //   render: (record: Invoice) => <Caption>{record.Invoice_Notes}</Caption>,
    // },

    {
      title: <div style={{ textAlign: "right" }}>Amount</div>,
      width: 120,
      render: (record: Invoice) => <Caption style={{ textAlign: "right" }}>{formatCurrency(record.Invoice_amount || "")}</Caption>,
    },
    {
      title: <div style={{ textAlign: "right" }}>Paid</div>,
      width: 120,
      render: (record: Invoice) => (
        <Caption
          style={{
            textAlign: "right",
            color: record.Amount_Paid === "0.00" ? theme.colors.red6 : undefined,
          }}
        >
          {formatCurrency(record.Amount_Paid || "")}
        </Caption>
      ),
    },
    {
      title: "Date",
      render: (record: Invoice) => <Caption>{moment(record.Invoice_Date)?.format("M/D/YY")}</Caption>,
    },
    {
      title: "Paid On",
      render: (record: Invoice) =>
        record.Date_Paid && record.Date_Paid !== "0000-00-00 00:00:00" ? (
          <Caption>{moment(parseInt(record.Date_Paid))?.format("M/D/YY")}</Caption>
        ) : null,
    },
  ];

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 16,
        }}
      >
        <PageTitle>Invoices</PageTitle>
        {/* <Button onClick={() => setAddInvoice(true)}>New Invoice</Button> */}
      </div>
      <div style={{ marginTop: 16 }}>
        <DarkTable
          loading={loading}
          dataSource={data?.getManyInvoices?.results}
          columns={columns}
          onChange={(pagination: any, filters: any, sorter: any) => {
            setFilters({
              ...filters,
              current: pagination?.current,
              limit: pagination?.pageSize,
              skip: pagination?.pageSize * (pagination.current - 1),
            });
          }}
          pagination={{
            pageSize: filters.limit,
            current: filters.current,
            total: data?.getManyInvoices?.count || data?.getManyInvoices?.results?.length,
          }}
          expandable={{
            expandedRowRender: (record: Invoice) => <InvoiceDetail invoice={record} />,

            rowExpandable: () => true,
          }}
        />
      </div>
      <Drawer title="Create New Invoice" visible={addInvoice ? true : false} onClose={() => setAddInvoice(false)}>
        <InvoiceForm onCancel={() => setAddInvoice(false)} onSubmit={() => console.log("test")} />
      </Drawer>
    </PageContainer>
  );
}
