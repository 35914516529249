import styled from "styled-components";
import PageTitle from "components/text/PageTitle";
import PageSubtitle from "components/text/PageSubtitle";
import logoPNG from "lib/media/apiphani-logo.png";
import theme from "lib/theme";

const Container = styled.div`
  min-height: 200px;
  width: 550px;
  margin: auto;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

interface ErrorStateProps {
  title?: string;
  subtitle?: string;
  image?: any;
}

export default function ErrorState({ title, subtitle, image }: ErrorStateProps) {
  return (
    <Container>
      <div>
        {image ? image : <img src={logoPNG} alt="apiphani-logo" height="40" style={{ marginBottom: 40 }} />}
        <PageTitle style={{ marginBottom: 8 }}>{title || "Error..."}</PageTitle>
        {subtitle && <PageSubtitle style={{ color: theme.colors.text2 }}>{subtitle || "Error..."}</PageSubtitle>}
      </div>
    </Container>
  );
}
