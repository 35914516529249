// TOP LEVEL IMPORTS
import { useState } from "react";
import styled from "styled-components";
import message from "components/common/message";
import { useHistory } from "react-router";
// COMPONENTS
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
import Caption from "components/text/Caption";
import FormItem from "components/common/FormItem";
// APOLLO
import ApolloClient from "ApolloClient";
// LIB
import setLocalStorage from "lib/helpers/setLocalStorage";
import constants from "lib/config";
import { useResendMfaMutation, useVerifyMfaMutation } from "generated/graphql";
import useUrlChange from "lib/hooks/useUrlChange";
import logo from "lib/media/luumen-logo.png";

const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

const PageHeader = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  display: block;
  color: #fff;
  font-weight: 300;
  margin-bottom: 16px;
  @media only screen and (max-width: 812px) {
    font-size: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
  height: 100vh;
`;

const Content = styled.div`
  width: 400px;
  margin: auto;
  display: flex;
  align-items: center;
  /* background: #fff; */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  min-height: 500px;
  border-radius: 7px;
  padding: 16px;
  max-width: 100%;
`;

const Logo = styled.img`
  height: 50px;
  margin: auto;
  margin-bottom: 24px;
`;

// STYLED-COMPONENTS
// ========================================

const ErrorBlock = styled.div`
  border: 1px solid ${(p) => p.theme.colors.red5};
  background: ${(p) => p.theme.colors.red10};
  padding: 8px;
  color: ${(p) => p.theme.colors.red2};
  border-radius: 5px;
  margin-top: 8px;
`;

export default function AuthMfa() {
  const [pin, setPin] = useState("");
  const history = useHistory();
  const [errors, setErrors] = useState<null | string[]>(null);
  const [verifyMfaMutation] = useVerifyMfaMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [resendMfaMutation, { loading: resending }] = useResendMfaMutation();
  const {
    query: { email },
  } = useUrlChange();

  const onSubmit = async (e) => {
    try {
      setLoading(true);

      if (loading) return;

      e.preventDefault();
      // // reset errors
      setErrors([]);

      // check that they give a password
      if (!pin || pin.length === 0) {
        return setErrors(["Please provide a pin"]);
      }

      if (!email) return;

      const { data } = await verifyMfaMutation({
        variables: {
          email: email as string,
          pin,
        },
      });

      if (
        !data?.verifyMfa?.tokens?.accessToken ||
        !data?.verifyMfa?.tokens?.refreshToken
      )
        return;

      setLocalStorage(
        constants.authTokenName,
        data?.verifyMfa?.tokens?.accessToken
      );
      setLocalStorage(
        constants?.refreshTokenName,
        data?.verifyMfa?.tokens?.refreshToken
      );
      message.success(`We're logging you in...`);
      await ApolloClient.resetStore();
      setLoading(false);
      history.push("/admin");
    } catch (err: any) {
      setLoading(false);
      let errMessage = err.message.replace("GraphQL", "");
      if (err && err.message.includes("Incorrect password [403]")) {
        errMessage = "You have entered an invalid username or password";
      }
      setErrors([errMessage]);
      return;
    }
  };

  return (
    <Container>
      <Content>
        <div
          style={{
            marginTop: 32,
            marginLeft: 16,
          }}
        >
          <TitleContainer>
            <Logo src={logo} />
            <PageHeader>Verify Your Identity</PageHeader>
            <Caption style={{ width: "90%", margin: "auto" }}>
              A verification code has been sent to your email. Enter the code to
              continue and be redirected.
            </Caption>
          </TitleContainer>
          <form onSubmit={onSubmit}>
            <FormItem label="Code">
              <TextInput
                onChange={(e) => setPin(e.target.value.toLowerCase()?.trim())}
                value={pin}
              />
            </FormItem>
            {errors?.map((item) => (
              <ErrorBlock key={item} data-testid="error-block">
                {item.replace("error: ", "")}
              </ErrorBlock>
            ))}
            <FormItem>
              <Button
                disabled={loading}
                loading={loading}
                type="submit"
                data-testid="login-submit-btn"
                fullWidth
              >
                Submit
              </Button>
            </FormItem>
          </form>
          <div style={{ marginTop: 16, textAlign: "center" }}>
            <Caption style={{ marginTop: 24 }}>
              Need a new code?{" "}
              <Button
                grey
                style={{ marginLeft: 8 }}
                onClick={async () => {
                  try {
                    if (resending) return;
                    await resendMfaMutation({
                      variables: {
                        email: email as string,
                      },
                    });
                    message.success(`We've sent you a new code`);
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                Send new code
              </Button>
            </Caption>
          </div>
        </div>
      </Content>
    </Container>
  );
}
