import styled from "styled-components";
import {
  GetManyInstanceKeysDocument,
  InstanceKey,
  InstanceKeyInput,
  useCreateInstanceKeyMutation,
  useDeleteInstancekeyMutation,
  useGetManyInstanceKeysQuery,
  useGetManyInstancesQuery,
} from "generated/graphql";
import Button from "components/common/Button";
import Popconfirm from "components/common/Popconfirm";
import InstanceKeyForm from "components/forms/InstanceKeyForm";
import { useState } from "react";
import message from "components/common/message";
import DarkTable from "components/common/DarkTable";
import Drawer from "components/common/Drawer";

const RemoveBtn = styled.button`
  border: 0px;
  background: transparent;
  padding: 0px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.red5};
`;

export default function Credentials({ organizationId }) {
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [addNewKey, setAddNewKey] = useState<boolean>(false);
  const [deleteInstancekeyMutation, { loading: deleting }] = useDeleteInstancekeyMutation();
  const [createInstanceKeyMutation, { loading: creating }] = useCreateInstanceKeyMutation();
  const { data, loading, error } = useGetManyInstanceKeysQuery({
    variables: {
      organizationId,
    },
  });

  const { data: instancesData } = useGetManyInstancesQuery({
    variables: {
      organizationId,
    },
  });

  const onCloseDrawer = () => {
    setShowEditForm(false);
    setAddNewKey(false);
  };

  const getDrawerTitle = () => {
    if (addNewKey) {
      return "Add new credential";
    }
    if (showEditForm) {
      return "Edit credential";
    }
    return null;
  };

  if (loading) return null;

  if (error) return null;

  const instancekeys = data?.getManyInstanceKeys || [];

  const columns = [
    {
      key: "id",
      title: "Name",
      render: (record: InstanceKey) => record.name,
    },
    {
      title: "Description",
      render: (record: InstanceKey) => record.description,
    },

    {
      title: "Vendor",
      render: (record: InstanceKey) => record.vendor,
    },
    {
      title: "Region",
      render: (record: InstanceKey) => record.region,
    },
    {
      title: "Use",
      render: (record: InstanceKey) => record.use || "general",
    },
    // {
    //   title: "Edit",
    //   render: (record: InstanceKey) => (
    //     <RemoveBtn disabled={deleting} onClick={() => setShowEditForm(true)}>
    //       Edit
    //     </RemoveBtn>
    //   ),
    // },
    {
      title: "Remove",
      render: (record: InstanceKey) => (
        <Popconfirm title="Are you sure you want to remove these instance keys?" okText="yes, I'm sure" onConfirm={() => handleDelete(record.id)}>
          <RemoveBtn disabled={deleting}>Remove</RemoveBtn>
        </Popconfirm>
      ),
    },
  ];

  const handleDelete = async (instanceKeyId) => {
    try {
      await deleteInstancekeyMutation({
        variables: {
          instanceKeyId,
        },
        refetchQueries: [
          {
            query: GetManyInstanceKeysDocument,
            variables: {
              organizationId,
            },
          },
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setAddNewKey(true)} style={{ margin: 8, marginLeft: 0 }}>
          + New Key
        </Button>
      </div>
      <DarkTable rowKey="id" dataSource={instancekeys as InstanceKey[]} columns={columns} />
      <Drawer
        placement="right"
        width={500}
        onClose={onCloseDrawer}
        visible={addNewKey || showEditForm ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
        title={getDrawerTitle()}
      >
        {addNewKey && (
          <InstanceKeyForm
            loading={creating}
            onCancel={onCloseDrawer}
            instances={instancesData?.getManyInstances || []}
            onSubmit={async (values: InstanceKeyInput) => {
              try {
                await createInstanceKeyMutation({
                  variables: {
                    params: {
                      name: values.name,
                      description: values?.description,
                      vendor: values.vendor,
                      private_key: values.private_key,
                      public_key: values.public_key,
                      region: values.region,
                      resourceGroup: values.resourceGroup,
                      organizationId,
                      applicationId: values?.applicationId,
                      subscriptionId: values?.subscriptionId,
                      use: values?.use,
                      instanceIds: values?.instanceIds,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GetManyInstanceKeysDocument,
                      variables: {
                        organizationId,
                      },
                    },
                  ],
                });
                setAddNewKey(false);
                message.success("Your keys were saved");
              } catch (err: any) {
                console.log(err);
                if (err.message.includes("E11000 duplicate key error")) {
                  message.error(`This organization already has keys for ${values.vendor}`);
                }
              }
            }}
          />
        )}
        {/* {showEditForm && (
          <InstanceKeyForm
            loading={creating}
            onCancel={onCloseDrawer}
            mode={InstanceKeyFormModeEnum.editing}
            onSubmit={async (values: InstanceKeyInput) => {
              try {
                await createInstanceKeyMutation({
                  variables: {
                    params: {
                      name: values.name,
                      vendor: values.vendor,
                      private_key: values.private_key,
                      public_key: values.public_key,
                      region: values.region,
                      organizationId,
                      applicationId: values?.applicationId,
                      subscriptionId: values?.subscriptionId,
                      use: values?.use,
                    },
                  },
                });
                setAddNewKey(false);
                message.success("Your keys were saved");
              } catch (err: any) {
                console.log(err);
                if (err.message.includes("E11000 duplicate key error")) {
                  message.error(
                    `This organization already has keys for ${values.vendor}`
                  );
                }
              }
            }}
          />
        )} */}
      </Drawer>
    </div>
  );
}
