import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import AccessesSelector from "components/inputs/AccessesSelector";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface PermissionFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  defaultValues?: {
    name?: string | null;
    description?: string | null;
    accesses?: [];
  };
}

export default function PermissionForm({
  onSubmit,
  onCancel,
  loading,
  defaultValues,
}: PermissionFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues,
  });

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    // @TODO: make sure that either customPermissions are set or a permission is selected. Throw an error if neither are set
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Name" required error={errors?.name?.message}>
        <TextInput
          type="name"
          {...register("name", {
            required: "Name is required.",
          })}
        />
      </FormItem>
      <FormItem label="Description" error={errors?.description?.message}>
        <TextInput type="description" {...register("description")} />
      </FormItem>
      <div>
        <FormItem label="accesses">
          <Controller
            name="accesses"
            control={control}
            render={({ field }) => (
              <AccessesSelector
                {...field}
                onChange={(values) => field.onChange(values || [])}
              />
            )}
          />
        </FormItem>
      </div>
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
