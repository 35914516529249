import { getBezierPath, getEdgeCenter, getMarkerEnd } from "react-flow-renderer";
import styled from "styled-components";
import { useStateMachine } from "little-state-machine";
import { updateElements } from "../../index";

const foreignObjectSize = 40;

// const onEdgeClick = (evt, id) => {
//   evt.stopPropagation();
//   alert(`remove ${id}`);
// };

const Button = styled.button`
  background: #eee;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  line-height: 1;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0 0 6px 2px rgb(0 0 0 / 8%);
    transform: scale(1.1);
  }
`;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
  onEdgeClick,
}) {
  const { actions, state } = useStateMachine({ updateElements });
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  return (
    <>
      <path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={edgeCenterX - foreignObjectSize / 2}
        y={edgeCenterY - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <body>
          <Button onClick={(event) => actions.updateElements(state?.elements?.filter((item) => item.id !== id))}>×</Button>
        </body>
      </foreignObject>
    </>
  );
}
