import Row from "components/common/Row";
import Col from "components/common/Col";
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
import PageTitle from "components/text/PageTitle";
import FlowEditor from "./FlowEditor";
import { useState, useEffect } from "react";
import Drawer from "components/common/Drawer";
import { useStateMachine, GlobalState } from "little-state-machine";
import ScriptDrawer from "./ScriptDrawer";
import { StateMachineProvider, createStore } from "little-state-machine";
import { FlowTemplate } from "lib/helpers/mapScriptTemplateToFlow";

createStore(
  {
    elements: [],
    name: "",
    description: "",
  },
  {}
);

export function updateElements(state: GlobalState, payload) {
  console.log({ payload });
  return {
    ...state,
    elements: Array.isArray(payload) ? [...payload] : [...state.elements, payload],
  };
}

export function onRemoveElement(state: GlobalState, payload = []) {
  return {
    ...state,
    elements: [...state.elements]
      ?.filter((item) => item.id !== payload)
      ?.filter((item) => item.source !== payload)
      ?.filter((item) => item.target !== payload),
  };
}

function updateScripTemplateState(state: GlobalState, payload = {}) {
  return {
    ...state,
    ...payload,
  };
}

function clearScriptTemplateState(state: GlobalState, payload = {}) {
  return {
    ...state,
    name: "",
    description: "",
    elements: [],
  };
}

export default function ScriptTemplateForm({
  onCancel,
  defaultValues,
  onSubmit,
  loading,
}: {
  onCancel: () => void;
  onSubmit: (args: any, callback?: any) => void;
  defaultValues?: FlowTemplate;
  loading?: boolean;
}) {
  return (
    <StateMachineProvider>
      <Form onCancel={onCancel} onSubmit={onSubmit} defaultValues={defaultValues} loading={loading} />
    </StateMachineProvider>
  );
}

const Form = ({
  onCancel,
  defaultValues,
  onSubmit,
  loading,
}: {
  onCancel: () => void;
  onSubmit: (args: any, callback?: any) => void;
  defaultValues?: FlowTemplate;
  loading?: boolean;
}) => {
  // const [elements, setElements] = useState<any>(initialElements);
  const { actions, state } = useStateMachine({
    updateElements,
    updateScripTemplateState,
    clearScriptTemplateState,
  });
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValues?.name || defaultValues?.description) {
      actions.updateScripTemplateState(defaultValues);
    }
    if (defaultValues?.elements?.[0]) {
      actions.updateElements(defaultValues?.elements || []);
    }
  }, [actions, defaultValues]);

  const handleCancel = () => {
    actions.clearScriptTemplateState({});
    onCancel();
  };

  return (
    <>
      <div
        style={{
          marginBottom: 16,
          marginTop: 0,
          width: "100%",
          display: "flex",
          alignItems: "center",
          // justifyContent: "space-between",
        }}
      >
        <PageTitle>Create new template</PageTitle>

        <div>
          <Button grey style={{ marginRight: 16, marginLeft: 32 }} onClick={handleCancel} disabled={loading}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit(state, actions.clearScriptTemplateState({}));
            }}
            loading={loading}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </div>
      <Row gutter={16}>
        <Col xs={8}>
          <FormItem label="Name">
            <TextInput value={state?.name} onChange={(e) => actions?.updateScripTemplateState({ name: e.target.value })} />
          </FormItem>
        </Col>
        <Col xs={16}>
          <FormItem label="Description">
            <TextInput
              value={state?.description}
              onChange={(e) =>
                actions?.updateScripTemplateState({
                  description: e.target.value,
                })
              }
            />
          </FormItem>
        </Col>
        <Col xs={12}></Col>
        <Col xs={24}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              position: "relative",
              top: 64,
              right: 24,
              zIndex: 20,
            }}
          >
            <Button secondary onClick={() => setVisible(true)}>
              + New Block
            </Button>
          </div>
          <div style={{ height: 500 }}>
            <FlowEditor elements={state?.elements || []} onChange={actions.updateElements} />
          </div>
        </Col>
      </Row>
      <Drawer visible={visible} title="Add a block" onClose={() => setVisible(false)}>
        <ScriptDrawer onClose={() => setVisible(false)} />
      </Drawer>
    </>
  );
};

// const initialElements = [
//   {
//     id: "1",
//     type: "input",
//     data: {
//       label: (
//         <>
//           Welcome to <strong>React Flow!</strong>
//         </>
//       ),
//     },
//     position: { x: 250, y: 0 },
//   },
//   {
//     id: "2",
//     data: {
//       label: (
//         <>
//           This is a <strong>default node</strong>
//         </>
//       ),
//     },
//     position: { x: 100, y: 100 },
//   },
//   {
//     id: "3",
//     data: {
//       label: (
//         <>
//           This one has a <strong>custom style</strong>
//         </>
//       ),
//     },
//     position: { x: 400, y: 100 },
//     style: {
//       background: "#D6D5E6",
//       color: "#333",
//       border: "1px solid #222138",
//       width: 180,
//     },
//   },
//   {
//     id: "4",
//     position: { x: 250, y: 200 },
//     data: {
//       label: "Another default node",
//     },
//   },
//   {
//     id: "5",
//     data: {
//       label: "Node id: 5",
//     },
//     position: { x: 250, y: 325 },
//   },
//   {
//     id: "6",
//     type: "output",
//     data: {
//       label: (
//         <>
//           An <strong>output node</strong>
//         </>
//       ),
//     },
//     position: { x: 100, y: 480 },
//   },
//   {
//     id: "7",
//     type: "output",
//     data: { label: "Another output node" },
//     position: { x: 400, y: 450 },
//   },
//   {
//     id: "f9s8f9asf8as9",
//     source: "1",
//     target: "2",
//    label: "this is an edge label",
//   },
//   { id: "e1-3", source: "1", target: "3" },
//   {
//     id: "e3-4",
//     source: "3",
//     target: "4",
//     animated: true,
//     label: "animated edge",
//   },
//   {
//     id: "e4-5",
//     source: "4",
//     target: "5",
//     arrowHeadType: "arrowclosed",
//     label: "edge with arrow head",
//   },
//   {
//     id: "e5-6",
//     source: "5",
//     target: "6",
//     type: "smoothstep",
//     label: "smooth step edge",
//   },
//   {
//     id: "e5-7",
//     source: "5",
//     target: "7",
//     type: "step",
//     style: { stroke: "#f6ab6c" },
//     label: "a step edge",
//     animated: true,
//     labelStyle: { fill: "#f6ab6c", fontWeight: 700 },
//   },
// ];
