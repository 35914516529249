import {
  useGetOneEnvironmentQuery,
  useUpdateOneEnvironmentMutation,
} from "generated/graphql";
import Loading from "components/common/Loading";
import EnvironmentForm from "components/forms/EnvironmentForm";
import Text from "components/text/Text";
import { message } from "antd";

export default function EditEnvironment({
  environmentId,
  instances,
  onCancel,
}) {
  const { data, loading, error } = useGetOneEnvironmentQuery({
    variables: {
      environmentId,
    },
  });
  const [updateOneEnvironmentMutation, { loading: saving }] =
    useUpdateOneEnvironmentMutation();
  if (loading) return <Loading />;
  if (error) return <Text>Someting went wrong...</Text>;

  const environment = data?.getOneEnvironment;

  const deafultValues = {
    name: environment?.name,
    instanceIds: environment?.instances?.[0]
      ? (environment?.instances?.map((item) => item?.id) as string[])
      : [],
    environmentType: environment?.environmentType,
    vendor: environment?.vendor,
  };
  //
  return (
    <div>
      <EnvironmentForm
        onSubmit={async (values) => {
          console.log(values);
          try {
            await updateOneEnvironmentMutation({
              variables: {
                id: environmentId,
                params: {
                  ...values,
                  applicationId: environment?.applicationId,
                },
              },
            });
            message.success("Changes saved");
            onCancel();
          } catch (err) {
            console.log(err);
          }
        }}
        onCancel={onCancel}
        loading={saving}
        instances={instances}
        defaultValues={deafultValues}
      />
    </div>
  );
}
