// COMPONENTS
import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Tabs from "components/common/Tabs";
import BackButton from "components/common/BackButton";
import Loading from "components/common/Loading";
import Text from "components/text/Text";
import Credentials from "./Credentials";
import Details from "./Details";
import Documents from "./Documents";
import Users from "./Users";
import Applications from "./Applications";
import Instances from "./Instances";
import Reports from "./Reports";
// GRAPHQL
import { useGetOneOrganizationQuery } from "generated/graphql";

const { TabPane } = Tabs;

export default function OrganizationDetail({ organizationId }) {
  const { data, loading, error } = useGetOneOrganizationQuery({
    variables: {
      id: organizationId,
    },
  });

  const organization = data?.getOneOrganization;

  if (loading) return <Loading />;
  if (error) return <Text>Something went wrong...</Text>;
  if (!organization) return null;

  return (
    <PageContainer>
      <BackButton style={{ marginBottom: 40 }} label="Back to Organizations" />
      <PageTitle>{data?.getOneOrganization?.name}</PageTitle>
      <div style={{ marginTop: 24 }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Details" key="1">
            <Details organization={organization} />
          </TabPane>
          <TabPane tab="Users" key="3">
            <Users organizationId={organizationId} />
          </TabPane>
          <TabPane tab="Credentials & APIs" key="4">
            <Credentials organizationId={organizationId} />
          </TabPane>
          <TabPane tab="Documents" key="5">
            <Documents organizationId={organizationId} />
          </TabPane>
          <TabPane tab="Applications" key="6">
            <Applications organizationId={organizationId} />
          </TabPane>
          <TabPane tab="Instances" key="7">
            <Instances organizationId={organizationId} />
          </TabPane>
          {organization?.settings?.features?.reportsEnabled && (
            <TabPane tab="Reports" key="Reports">
              <Reports organization={organization} />
            </TabPane>
          )}
        </Tabs>
      </div>
    </PageContainer>
  );
}
