import storage from 'local-storage-fallback';
import iOS from './iOS';

export default function removeLocalStorage(valueName: string) {
    try {
        if (!iOS()) {
            return window.localStorage.removeItem(valueName);
        } else {
            return storage.removeItem(valueName);
        }
    } catch (err) {
        throw new Error(err.message);
    }
}