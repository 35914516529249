import { Layout, Menu } from "antd";
import { ReactChild, ReactChildren } from "react";
import {
  AppstoreOutlined,
  DashboardOutlined,
  WifiOutlined,
  AreaChartOutlined,
  SafetyCertificateOutlined,
  UserOutlined,
  ExceptionOutlined,
  FilePdfOutlined,
  ProfileOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import AvatarArea from "./AvatarArea";
import "antd/lib/menu/style/css";
import "antd/lib/layout/style/css";
import "antd/lib/breadcrumb/style/css";
import config from "lib/config";
import { UserProfile } from "generated/graphql";
import { useHistory, useLocation } from "react-router";
import luumenLogoPNG from "lib/media/luumen-logo.png";
import OrgSwitcher from "./OrgSwitcher";
import theme from "lib/theme";
// const {SubMenu} = Menu;
const { Header, Content, Sider, Footer } = Layout;

const Container = styled.div`
  min-height: 100vh;
  position: relative;
  .site-layout .site-layout-background {
    background: ${({ theme }) => theme.colors.background};
  }
  .ant-layout-sider-trigger {
    background: ${({ theme }) => theme.colors.neutral8};
  }
  .ant-layout-header,
  .ant-layout-footer,
  .ant-layout {
    background: ${({ theme }) => theme.colors.background};
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors.background2};
  }

  .ant-menu-item {
    color: ${({ theme }) => theme.colors.text2};

    border-radius: 5px;
    &:hover {
      background-color: ${({ theme }) => theme.colors.background3};
    }
  }

  .ant-menu-title-content {
    font-weight: 300;
    font-size: 15px !important;
    color: ${({ theme }) => theme.colors.text2};
  }

  .ant-menu {
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .anticon-left {
    svg {
      fill: ${({ theme }) => theme.colors.neutral3};
    }
  }

  .ant-layout-sider-children,
  .ant-menu {
    background: ${({ theme }) => theme.colors.background};
  }

  .ant-layout-sider {
    border-right: 1px solid ${({ theme }) => theme.colors.background4};
  }
`;

const ICON_STYLES = {
  fontSize: 16,
  marginRight: 4,
  color: theme.colors.text,
};

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  margin-top: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1.2px;
  margin-left: 26px;
`;

const Logo = styled.img`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 40%;
  margin-left: 24px;
  display: block;
  max-width: 80px;
`;

export default function AdminLayout({ children, currentUser }: { children: ReactChild | ReactChildren; currentUser: UserProfile }) {
  // const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const settings = currentUser?.activeOrganization?.settings?.features;
  const reportSettings = currentUser?.activeOrganization?.settings?.reports;

  console.log(settings);

  const NAV_ITEMS = [
    {
      title: "Monitoring",
      items: [
        {
          title: "Instances",
          link: "/app/instances",
          icon: <AppstoreOutlined style={ICON_STYLES} />,
        },
        // {
        //   title: "Applications",
        //   link: "/app/applications",
        //   icon: <SlidersOutlined style={ICON_STYLES} />,
        // },
        ...(settings?.vpnEnabled
          ? [
              {
                title: "Connectivity",
                link: "/app/vpn",
                icon: <WifiOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
        ...(settings?.dataProtectionEnabled
          ? [
              {
                title: "Data Protection",
                link: "/app/data-protection",
                icon: <SafetyCertificateOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
      ],
    },

    {
      title: "Reports",
      items: [
        ...(reportSettings?.capacity?.enabled // has no toggle yet
          ? [
              {
                title: "Capacity",
                link: "/app/reports/capacity",
                icon: <AreaChartOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
        ...(reportSettings?.sla?.enabled // has no toggle yet
          ? [
              {
                title: "SLA",
                link: "/app/reports/slas",
                icon: <ExceptionOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
      ],
    },

    {
      title: "Communication",
      items: [
        ...(settings?.ticketsEnabled
          ? [
              {
                title: "Tickets",
                link: "/app/tickets",
                icon: <ProfileOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
        ...(settings?.documentsEnabled
          ? [
              {
                title: "Documents",
                link: "/app/documents",
                icon: <FilePdfOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
      ],
    },

    {
      title: "Utilities",
      items: [
        ...(settings?.billingEnabled
          ? [
              {
                title: "Billing",
                link: "/app/billing",
                icon: <DollarCircleOutlined style={ICON_STYLES} />,
              },
            ]
          : []),
        {
          title: "My Account",
          link: "/app/account",
          icon: <UserOutlined style={ICON_STYLES} />,
        },
      ],
    },
  ];

  return (
    <Container>
      <AvatarArea currentUser={currentUser} />
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          //collapsible
          // collapsed={collapsed}
          width={250}
          // onCollapse={(collapsed) => setCollapsed(collapsed)}
        >
          <Logo className="logo" src={luumenLogoPNG} />
          <OrgSwitcher value={currentUser?.activeOrganization?.id} organizations={currentUser?.organizations} />
          <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} onClick={() => history.push("/app/home")}>
            <Menu.Item key="/app/home" icon={<DashboardOutlined style={ICON_STYLES} />}>
              Dashboard
            </Menu.Item>
          </Menu>
          {NAV_ITEMS.map((section, i) => {
            if (!section?.items?.[0]) return null; // if this section has no menu items in it, don't show it
            return (
              <div key={section.title}>
                <SectionTitle style={{ marginTop: i === 0 ? 24 : 40 }}>{section.title}</SectionTitle>
                <Menu
                  theme="dark"
                  mode="inline"
                  selectedKeys={[location.pathname]}
                  onClick={({ key, keyPath, domEvent }) => {
                    const item = section.items.find((item) => item.link === key);
                    if (item?.link) {
                      history.push(item?.link);
                    }
                  }}
                >
                  {section?.items?.map((item, i) => {
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        {item.title}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
            );
          })}
        </Sider>
        <Layout>
          <Header style={{ padding: 0 }} />
          <Content style={{ margin: "0 16px" }}>
            {/* <Breadcrumb style={{margin: '16px 0'}}>
              {location.pathname.split('/').map((item) => {
                return (
                  <Breadcrumb.Item key={item}>
                    {item.toUpperCase()}
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb> */}
            <div className="site-layout-background" style={{ padding: 16, minHeight: 360 }}>
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center", color: "#999" }}>
            © {new Date().getFullYear()} {config.companyName} All rights reserved.
          </Footer>
        </Layout>
      </Layout>
    </Container>
  );
}
