// TOP LEVEL IMPORTS
import React from "react";
import styled from "styled-components";
import Row from "components/common/Row";
import Col from "components/common/Col";
// COMPONENTS

const Container = styled(Row)`
  border-top: 1px solid ${(p) => p.theme.colors.background2};
  padding-top: 16px;
  margin-top: 32px;
  margin-bottom: 25px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  @media only screen and (max-width: 375px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
`;

const TextContainer = styled.div`
  max-width: 100%;
`;

const Header = styled.h3`
  color: ${(p) => (p.color ? p.color : p.theme.colors.text3)};
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : "16px")};
  font-weight: 300;
  margin-bottom: 0px;
`;

const Body = styled.div`
  max-width: 100%;
  min-height: 84px;
  font-size: 15px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${(p) => (p.color ? p.color : p.theme.colors.text)};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.small}) {
    min-height: 0px;
  }
`;

const PageSection = ({ header, body }) => {
  return (
    <div>
      <Header>{header}</Header>
      <div />{" "}
      <TextContainer>
        <Body>{body}</Body>
      </TextContainer>
    </div>
  );
};

export default function SectionBreak({
  header,
  body,
  children,
}: {
  header: string;
  body?: string;
  children?: any;
}) {
  return (
    <div>
      <Container align="top" gutter={16}>
        <Col xs={24} md={8}>
          <PageSection header={header} body={body} />
        </Col>
        <Col xs={24} md={16}>
          {children}
        </Col>
      </Container>
    </div>
  );
}
