import { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
// components
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import ErrorBlock from "components/common/ErrorBlock/index";
// COMPONENTS
import Caption from "components/text/Caption";
import logo from "lib/media/luumen-logo.png";
import message from "components/common/message";
import useCheckPasswordErrors from "lib/hooks/useCheckPasswordErrors";
// GRAPHQL
import { useResetPasswordMutation, UserTypeEnum } from "generated/graphql";
import config from "lib/config";
import setLocalStorage from "lib/helpers/setLocalStorage";

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

const FormContainer = styled.div`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  padding: 32px 64px;
  border-radius: ${(p) => p.theme.borderRadius}px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  width: 100%;
  display: flex;
  height: 100vh;
`;

const Logo = styled.img`
  height: 50px;
  margin: auto;
`;

const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

export default function AuthResetPassword({ match, currentUser }) {
  /**
   *
   * HOOKS
   */
  const { checkPasswordErrors } = useCheckPasswordErrors();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<{
    confirmPassword?: string;
    password?: string;
    generalError?: string;
  }>({
    confirmPassword: undefined,
    password: undefined,
    generalError: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [resetPassword] = useResetPasswordMutation();
  /**
   *
   * METHODS
   */
  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setErrors({});

      if (!password) {
        return setErrors({ password: "Please provide a password" });
      }

      const error = checkPasswordErrors(password);
      if (error) {
        return setErrors({ password: error });
      }

      if (!confirmPassword) {
        return setErrors({ confirmPassword: "Please confirm your password" });
      }

      if (password !== confirmPassword) {
        return setErrors({
          confirmPassword: "Please make sure you passwords match",
        });
      }

      setLoading(true);

      // call mutation to reset password
      const res = await resetPassword({
        variables: {
          newPassword: password,
          token: match.params.token,
        },
      });

      // shoot off a success message
      message.success("Password reset. Logging you in...");

      // pull out the access token and refresh token
      const refreshToken = res.data?.resetPassword?.tokens?.refreshToken;
      const accessToken = res.data?.resetPassword?.tokens?.accessToken;

      if (refreshToken && accessToken) {
        // set items in local storage
        setLocalStorage(config.authTokenName, accessToken);
        setLocalStorage(config.refreshTokenName, refreshToken);
      }

      // reload the page
      window.location.reload();

      //   message.success('Password successfully set');
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      setErrors({ generalError: err.message });
    }
  };

  /**
   *
   * JSX
   */

  if (currentUser?.userType === UserTypeEnum.SuperAdmin) {
    return <Redirect to={`/${config.routePrefixes.admin}/home`} />;
  }

  if (
    currentUser?.userType === UserTypeEnum.Client ||
    currentUser?.userType === UserTypeEnum.Engineer
  ) {
    return <Redirect to={`/${config.routePrefixes.client}/home`} />;
  }

  const disabled =
    !password || !confirmPassword || password !== confirmPassword;

  return (
    <Container onSubmit={onSubmit}>
      <FormContainer>
        <TitleContainer>
          <Logo src={logo} />
          <Caption style={{ margin: "16px auto" }}>Set your password</Caption>
        </TitleContainer>
        <form onSubmit={onSubmit}>
          <FormItem
            error={errors.password}
            hint="Min 8 characters with number, uppercase & lowercase"
          >
            <TextInput
              value={password}
              onChange={(e) => {
                const password = e.target.value;
                setPassword(password);
              }}
              onBlur={() => {
                const error = checkPasswordErrors(password);
                if (error) {
                  setErrors({ password: error });
                } else {
                  setErrors({ password: undefined });
                }
              }}
              placeholder="new password"
              type="password"
            />
          </FormItem>
          <FormItem error={errors?.confirmPassword}>
            <TextInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="confirm new password"
              type="password"
              onBlur={() => {
                if (confirmPassword !== password) {
                  return setErrors({
                    confirmPassword: "Your passwords do not match",
                  });
                }
              }}
            />
          </FormItem>
          <FormItem>
            <ErrorBlock error={errors?.generalError} />
          </FormItem>

          <ButtonContainer>
            <Button
              disabled={disabled || loading}
              loading={loading}
              onClick={onSubmit}
              fullWidth
              type="submit"
            >
              Change password
            </Button>
          </ButtonContainer>
        </form>
      </FormContainer>
    </Container>
  );
}
