import styled from "styled-components";
import { useForm } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

export enum InstanceKeyFormModeEnum {
  editing = "editing",
  creating = "creating",
}

interface SkuFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  //   mode?: InstanceKeyFormModeEnum;
}

export default function InvoiceForm({
  onSubmit,
  onCancel,
  loading,
}: //   mode = InstanceKeyFormModeEnum.creating,
SkuFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <Row gutter={16}>
        <Col xs={8}>
          <FormItem label="SKU Code" required error={errors?.SKU_Code?.message}>
            <TextInput
              {...register("SKU_Code", {
                required: "a code is required.",
              })}
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
