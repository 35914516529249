import { UserTypeEnum } from "generated/graphql";
import { Redirect, Route } from "react-router-dom";

// EXPORTED COMPONENT
// ==============================
const AdminRoute = (props: any) => {
  const { currentUser, showHeader, showFooter, component: Component, path, backgroundColor, layout: Layout, ...rest } = props;

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  if (currentUser?.userType !== UserTypeEnum.SuperAdmin) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <Layout currentUser={currentUser} backgroundColor={backgroundColor} showHeader={showHeader} showFooter={showFooter}>
          <Component currentUser={currentUser} {...args} />
        </Layout>
      )}
    />
  );
};

// EXPORT
// ==============================
export default AdminRoute;
