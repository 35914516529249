import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import InstanceSystemTypeSelectInput from "components/inputs/InstanceSystemTypeSelectInput";
import Button from "components/common/Button";
import VendorSelectInput from "components/inputs/VendorSelectInput";
import { CloudVendorTypeEnum } from "generated/graphql";
import VendorRegionsInput from "components/inputs/VendorRegionsInput";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface InstanceFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  mode: InstanceFormModeEnum;
  defaultValues?: {
    customDeviceId?: string | null;
    instanceType?: string | null;
    resourceGroup?: string | null;
    pattern?: string | null;
    description?: string | null;
    name?: string | null;
    externalId?: string | null;
    region?: string | null;
    vendor?: string | null;
    dynatraceHostId?: string | null;
  };
}

export enum InstanceFormModeEnum {
  editing = "editing",
  creating = "creating",
}

export default function InstanceForm({ onSubmit, onCancel, loading, defaultValues, mode = InstanceFormModeEnum.creating }: InstanceFormProps) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      instanceType: defaultValues?.instanceType,
      customDeviceId: defaultValues?.customDeviceId,
      resourceGroup: defaultValues?.resourceGroup,
      pattern: defaultValues?.pattern,
      description: defaultValues?.description,
      name: defaultValues?.name,
      externalId: defaultValues?.externalId,
      region: defaultValues?.region,
      vendor: defaultValues?.vendor,
      dynatraceHostId: defaultValues?.dynatraceHostId,
    },
  });

  const vendor = watch("vendor");

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;

    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Name" required error={errors?.name?.message} hint="The name of the instance (should match cloud vendor's naming)">
        <TextInput {...register("name")} />
      </FormItem>
      {mode === InstanceFormModeEnum.creating && (
        <>
          <FormItem label="External ID" required error={errors?.externalId?.message} hint="The cloud-vendor provided ID for the instance">
            <TextInput {...register("externalId")} />
          </FormItem>
          <FormItem label="Vendor" error={errors?.vendor?.message} required>
            <Controller
              name="vendor"
              control={control}
              rules={{ required: "This is required." }}
              render={({ field }) => <VendorSelectInput {...field} onChange={(stateProvinceRegion) => field.onChange(stateProvinceRegion)} />}
            />
          </FormItem>
          {vendor === CloudVendorTypeEnum.Aws ? (
            <FormItem label="Region" required error={errors?.region?.message}>
              <Controller
                name="region"
                control={control}
                render={({ field }) => <VendorRegionsInput {...field} vendor={vendor} onChange={(vendor) => field.onChange(vendor)} />}
              />{" "}
            </FormItem>
          ) : (
            <FormItem label="Region" error={errors?.region?.message}>
              <TextInput {...register("region")} />
            </FormItem>
          )}
        </>
      )}
      <FormItem label="APM ID" error={errors?.customDeviceId?.message} hint="Dynatrace Custom Device ID (e.g. CUSTOM_DEVICE-44B7CD187C5E4GF)">
        <TextInput {...register("customDeviceId")} />
      </FormItem>
      <FormItem label="Dynatrace Host ID" error={errors?.dynatraceHostId?.message} hint="Dynatrace HOST ID (e.g. HOST-A2320247CFDB0ABC)">
        <TextInput {...register("dynatraceHostId")} />
      </FormItem>
      <FormItem label="System Type">
        <Controller
          name="instanceType"
          control={control}
          render={({ field }) => <InstanceSystemTypeSelectInput {...field} onChange={(instanceType) => field.onChange(instanceType)} />}
        />
      </FormItem>
      <FormItem label="Description" error={errors?.description?.message}>
        <TextInput {...register("description")} />
      </FormItem>
      <FormItem label="Resource Group" error={errors?.resourceGroup?.message} hint="Used for Azure instances">
        <TextInput {...register("resourceGroup")} />
      </FormItem>
      <FormItem label="SID" error={errors?.pattern?.message} hint="For SAP instances, this is the unique ID">
        <TextInput {...register("pattern")} />
      </FormItem>
      <FormItem>
        <Button type="button" onClick={onCancel} grey style={{ width: 100, marginRight: 16 }}>
          Cancel
        </Button>
        <Button style={{ width: 100 }} type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
