import {
  DataPoint,
  MetricUnitTypeEnum,
  RelativeTimeEnum,
} from "generated/graphql";
// COMPONETS
import moment from "moment";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { toNumber } from "lodash";
import numeral from "numeral";
import theme from "lib/theme";
import getXAxisTimeFormat from "lib/helpers/getXAxisTimeFormat";
import { AxisDomain } from "recharts/types/util/types";
import getYAxisDomain from "lib/helpers/getYAxisDomain";

interface ChartProps {
  title?: string;
  data?: DataPoint[] | any;
  yAxisDomain?: AxisDomain | undefined; // https://recharts.org/en-US/api/YAxis#domain
  valueKeys?: string[];
  color?: string;
  relativeTime?: RelativeTimeEnum;
  tickFormatter?: (tick: any) => string;
  yAxisTicks?: string[] | number[];
  metricType: MetricUnitTypeEnum;
}

export enum XAxisTimeFormat {
  "hours" = "h:mma",
  "days" = "M/D",
  "weeks" = "M/D",
}

const getTickCount = (relativeTime?: RelativeTimeEnum): number => {
  if (relativeTime === RelativeTimeEnum.Hour) return 60;
  if (relativeTime === RelativeTimeEnum.Day) return 24;
  if (relativeTime === RelativeTimeEnum.Week) return 7;
  if (relativeTime === RelativeTimeEnum.Month) return 5;
  return 5;
};

const getAdd = (relativeTime?: RelativeTimeEnum): any => {
  if (relativeTime === RelativeTimeEnum.Hour) return "minutes";
  if (relativeTime === RelativeTimeEnum.Day) return "hours";
  if (relativeTime === RelativeTimeEnum.Week) return "days";
  if (relativeTime === RelativeTimeEnum.Month) return "weeks";
  return "hours";
};

const getYAxisTickCount = (metricType: MetricUnitTypeEnum) => {
  // if (JSON.stringify(yAxisDomain) === "[0,100]") return 2;
  // if (JSON.stringify(yAxisDomain) === "[1]") return 2;
  // if (JSON.stringify(yAxisDomain) === "[0,1]") return 2;
  if (metricType === MetricUnitTypeEnum.Percent) {
    return 2;
  }
  if (metricType === MetricUnitTypeEnum.Ratio) {
    return 2;
  }
  if (metricType === MetricUnitTypeEnum.UpDown) {
    return 1;
  }
  return 4;
};

const getTicketFormatter = (metricType: MetricUnitTypeEnum) => {
  if (metricType === MetricUnitTypeEnum.Percent) {
    return (tick) => numeral(tick)?.format("0");
  }
  if (metricType === MetricUnitTypeEnum.Byte) {
    return (tick) => `${numeral(tick).format("0b")}`;
  }
  if (metricType === MetricUnitTypeEnum.Count) {
    return (tick) => `${numeral(tick).format("0a")}`;
  }
  return (tick) => numeral(toNumber(tick)).format("0");
};

const getLabelFormat = (value, metricType: MetricUnitTypeEnum) => {
  if (metricType === MetricUnitTypeEnum.Byte) {
    return numeral(value).format("0b");
  }
  if (metricType === MetricUnitTypeEnum.Count) {
    return numeral(value).format("0a");
  }
  if (metricType === MetricUnitTypeEnum.Milliseconds) {
    return numeral(value).format("0a");
  }
  if (metricType === MetricUnitTypeEnum.Percent) {
    return `${value}%`;
  }
  return numeral(value).format("0.0a");
};

export default function Chart({
  title,
  data,
  relativeTime,
  yAxisTicks,
  color = theme.colors.primary3,
  metricType,
  yAxisDomain,
}: ChartProps) {
  const formatted = data
    ?.map((item) => {
      return {
        time: moment(item.timestamp).valueOf(),
        value: toNumber(item.value || 0),
      };
    })
    // for some reason, or datapoints are not in order (in terms of time), so we sort them
    .sort((a, b) => {
      if (a.time < b.time) {
        return -1;
      }
      if (a.time > b.time) {
        return 1;
      }
      return 0;
    });

  const tickCount = getTickCount(relativeTime);
  const ticks: any = [];
  for (let i = 0; i < tickCount; i++) {
    ticks.push(
      moment(data?.[0]?.timestamp)?.add(i, getAdd(relativeTime))?.valueOf()
    );
  }

  const values = formatted?.map((item) => item.value);
  const isAllZero = values.every((item) => item === 0);
  const yAxisDom = yAxisDomain || getYAxisDomain(metricType, isAllZero);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart data={formatted}>
        <CartesianGrid stroke={theme.colors.background2} />
        <Area
          type="monotone"
          dataKey="value"
          dot={false}
          stroke={color}
          fillOpacity={0.3}
          fill={color}
        />
        <XAxis
          dataKey="time"
          scale="time"
          ticks={ticks}
          name="Time"
          tickFormatter={(unixTime) => {
            return moment(unixTime).format(getXAxisTimeFormat(relativeTime));
          }}
          type="number"
          tickSize={6}
          domain={["dataMin", "dataMax"]} // domain={['auto', 'auto']}
          tickLine={false}
          stroke={theme.colors.background3}
          tick={{ stroke: "#fff", strokeWidth: 0.5, fontSize: 10 }}
        />
        <YAxis
          dataKey="value"
          tick={{ stroke: "#fff", strokeWidth: 0.5, fontSize: 10 }}
          axisLine={false}
          tickSize={0}
          ticks={yAxisTicks}
          type="number"
          tickCount={getYAxisTickCount(metricType)}
          tickMargin={4}
          domain={yAxisDom}
          tickFormatter={getTicketFormatter(metricType)}
        />
        <Tooltip
          formatter={(value) => [getLabelFormat(value, metricType), title]}
          contentStyle={{
            backgroundColor: theme.colors.background,
            borderColor: theme.colors.background2,
            padding: 8,
          }}
          labelStyle={{
            color: theme.colors.text,
            fontSize: 10,
          }}
          itemStyle={{
            color: theme.colors.text,
            fontSize: 10,
          }}
          labelFormatter={(value) => moment(value).format("ddd, MMM DD, h:mma")}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
