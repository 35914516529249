import Modal from "components/common/Modal";
import styled from "styled-components";
import Papa from "papaparse";
import { get } from "lodash";
import moment from "moment";
import { useDropzone } from "react-dropzone";
// LIB
import config from "lib/config";
// COMPONENTS
import message from "components/common/message";
import { useState, useCallback } from "react";
import { CloudDownloadOutlined, CloseCircleFilled } from "@ant-design/icons";
import Button from "components/common/Button";
import ErrorBlock from "components/common/ErrorBlock";
// GRAPHQL
import {
  CloudVendorTypeEnum,
  GetManyInstancesDocument,
  GetManyInstanceTypesDocument,
  GetManyInstanceTypesQuery,
  UploadInstanceInput,
  useUploadInstancesMutation,
} from "generated/graphql";
import client from "ApolloClient/index";
import { ApolloQueryResult } from "@apollo/client";

interface CSVTemplateItem {
  customDeviceId: string;
  dynatraceDetectedName: string;
  name: string;
  SID: string;
  vendor: CloudVendorTypeEnum;
  instanceType: string;
  resourceGroup: string;
  description: string;
  region: string;
  externalId: string;
}

const CSV_TEMPLATE = [
  {
    externalId: "c64dda1c-520e-4041-b1c8-9a274534e431",
    customDeviceId: "CUSTOM_DEVICE-44B7CD187C5E4GF",
    dynatraceDetectedName: "abc-patchserver",
    name: "sazitabc",
    SID: "DCL",
    vendor: "aws",
    instanceType: "Application Server",
    resourceGroup: "rg-1-customerabc",
    description: "SAP Cloud Connector (NonPRD)",
    region: "USGov Virginia",
  },
];

const DragContainer = styled.div`
  border: 2px dashed ${(p) => p.theme.colors.neutral8};
  background: ${(p) => p.theme.colors.neutral10};
  padding: 32px 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    border: 2px dashed ${(p) => p.theme.colors.neutral7};
    background: ${(p) => p.theme.colors.neutral9};
  }
`;

const DownloadIcon = styled(CloudDownloadOutlined)`
  margin-right: 6px;
`;

const SelectedFileContainer = styled.div`
  padding: 16px;
  font-size: 18px;
  color: ${(p) => p.theme.colors.text};
`;

const DeleteIcon = styled(CloseCircleFilled)`
  cursor: pointer;
  margin-left: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

export default function UploadInstanceModal({ visible, organizationId, onCancel, onComplete }) {
  const [file, setFile] = useState<any>(null);
  const [errors, setErrors] = useState<string[] | []>([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const [uploadInstancesMutation, { loading: uploading }] = useUploadInstancesMutation();

  const onUploadCSV = async (results) => {
    try {
      setErrors([]);
      // @todo: add more validation for the uploader
      const instances: UploadInstanceInput[] = results.data.map((instance: CSVTemplateItem): UploadInstanceInput => {
        return {
          pattern: instance.SID,
          name: instance.name,
          instanceType: instance.instanceType,
          vendor: instance.vendor?.toLowerCase() as CloudVendorTypeEnum,
          resourceGroup: instance.resourceGroup,
          description: instance.description,
          region: instance.region,
          externalId: instance.externalId,
          customDeviceId: instance.customDeviceId,
          dynatraceDetectedName: instance.dynatraceDetectedName,
        };
      });

      const result: ApolloQueryResult<GetManyInstanceTypesQuery> = await client.query({
        query: GetManyInstanceTypesDocument,
      });

      const systemTypes = result?.data?.getManyInstanceTypes; //Object.keys();
      const validationErrors: string[] = [];
      const vendors = Object.values(CloudVendorTypeEnum);

      for (const index in instances) {
        const instance = instances[index];
        if (systemTypes && !systemTypes.includes(instance?.instanceType as string)) {
          validationErrors.push(`${instance.name}: ${instance?.instanceType} is not a valid instance type. The options are ${systemTypes}`);
        }

        if (!vendors?.includes(instance?.vendor as any)) {
          validationErrors.push(`${instance.name}: ${instance?.vendor} is not a valid vendor. The options are ${vendors}`);
        }
      }

      if (validationErrors?.[0]) {
        return setErrors(validationErrors);
      }

      await uploadInstancesMutation({
        variables: {
          organizationId,
          instances,
        },
        refetchQueries: [
          {
            query: GetManyInstancesDocument,
            variables: {
              organizationId,
            },
          },
        ],
      });

      // give user success feedback
      message.success("Clients successfully added");
      // close the modal
      onComplete();
    } catch (err) {
      console.log(err);
    }
  };

  const onDownloadCSVTemplate = () => {
    let rightNow = moment().format("MM/DD/YYYY");
    let exportFilename = `${config.appName}-Instance-Upload-Template-${rightNow}.csv`;
    // convert JSON to CSV and download it
    let data = Papa.unparse(CSV_TEMPLATE, { header: true });
    let csvData = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const msSaveBlob = get(navigator, "msSaveBlob");
    if (msSaveBlob) {
      msSaveBlob(csvData, exportFilename);
    } else {
      // In FF link must be added to DOM to be clicked
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(csvData);
      link.setAttribute("download", exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const onParseUploadCSV = () => {
    try {
      // 1. confirm it has correct headers and values

      // 2. convert to JSON and upload
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: onUploadCSV,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal visible={visible} footer={null} destroyOnClose onCancel={onCancel}>
      <p>1. Please use this CSV template:</p>
      <Button style={{ marginTop: 8, marginLeft: 16 }} onClick={onDownloadCSVTemplate} secondary>
        <DownloadIcon /> Download CSV Template
      </Button>
      <p style={{ marginTop: 24 }}>2. Upload your completed CSV:</p>
      {!file && (
        <DragContainer {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop a file here, or browse.</p>}
        </DragContainer>
      )}
      {file && (
        <SelectedFileContainer>
          {file && file.name}
          <DeleteIcon onClick={() => setFile(null)} />
        </SelectedFileContainer>
      )}
      <ErrorBlock errors={errors} />
      <ButtonContainer>
        <Button onClick={onCancel} grey style={{ marginRight: 16, width: 100 }}>
          Cancel
        </Button>
        <Button style={{ width: 130 }} type="submit" loading={uploading} disabled={!file || uploading} onClick={onParseUploadCSV}>
          Upload File
        </Button>
      </ButtonContainer>
    </Modal>
  );
}
