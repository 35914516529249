import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { validate } from "email-validator";
import queryString from "query-string";
// COMPONENST
import Caption from "components/text/Caption";
import FormItem from "components/common/FormItem";
import ErrorBlock from "components/common/ErrorBlock";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
// GRAPHQL
import {
  CurrentUserDocument,
  useLoginMutation,
  UserProfile,
} from "generated/graphql";
import client from "ApolloClient/index";
// LIB
import setLocalStorage from "lib/helpers/setLocalStorage";
import config from "lib/config";
import logo from "lib/media/luumen-logo.png";
// HOOKS
import useRerouteLoggedInUser from "lib/hooks/useRerouteLoggedInUser";

const Logo = styled.img`
  height: 50px;
  margin: auto;
`;

const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

const FormContainer = styled.div`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  padding: 32px 64px;
  border-radius: 5px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  height: 100vh;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export default function AuthLogin({
  currentUser,
  history,
  location,
}: {
  currentUser: UserProfile;
  history: any;
  location: any;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    serverError?: string;
    email?: string;
    password?: string;
  }>({
    serverError: undefined,
    email: undefined,
    password: undefined,
  });
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [loginMutation] = useLoginMutation();
  // will re-route the user if needed
  useRerouteLoggedInUser({ currentUser });
  const { redirectTo } = queryString.parse(location.search);

  // const [requestMagicLinkEmailMutation] = useRequestMagicLinkEmailMutation();

  const onSubmit = async (e) => {
    try {
      if (e.preventDefault) {
        e.preventDefault();
      }

      // check that user added an email
      if (!email) {
        return setErrors({ email: "Please provide an email" });
      }

      // await requestMagicLinkEmailMutation({
      //   variables: {
      //     email,
      //   },
      // });

      // check if its a valid email
      if (!validate(email)) {
        return setErrors({
          email: "That is not a valid email",
        });
      }
      // check that they give a password
      if (!password) {
        return setErrors({
          password: "Please provide a password",
        });
      }
      setLoading(true);
      const result = await loginMutation({
        variables: {
          params: {
            password,
            user: {
              email: email,
            },
          },
        },
      });

      if (result?.data?.login?.mfaRequired) {
        return history.push(`/mfa?email=${encodeURIComponent(email)}`);
      }

      if (
        result?.data?.login?.tokens?.accessToken &&
        result?.data?.login?.tokens?.refreshToken
      ) {
        setLocalStorage(
          config.authTokenName,
          result?.data.login.tokens.accessToken
        );
        setLocalStorage(
          config.refreshTokenName,
          result?.data.login.tokens.refreshToken
        );
      }

      await client.query({
        query: CurrentUserDocument,
        fetchPolicy: "network-only",
      });

      setTimeout(() => {
        setLoading(false);
        if (redirectTo) {
          console.log("the redirect ran");
          history.push(redirectTo);
        }
      }, 1000);
    } catch (err: any) {
      let errMessage = err.message.replace("GraphQL", "");
      if (err && err.message.includes("Incorrect password [403]")) {
        errMessage = "You have entered an invalid username or password";
      }
      setLoading(false);
      console.log([errMessage]);
      return setErrors({
        serverError: errMessage,
      });
    }
  };

  return (
    <Container>
      <FormContainer>
        <TitleContainer>
          <Logo src={logo} />
          <Caption style={{ margin: "16px auto" }}>Welcome back!</Caption>
        </TitleContainer>
        <form onSubmit={onSubmit}>
          <FormItem error={errors.email}>
            <TextInput
              value={email || ""}
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.toLowerCase()?.trim())}
              data-testid="login-email-input"
            />
          </FormItem>
          <FormItem error={errors.password}>
            <TextInput
              value={password || ""}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              data-testid="login-password-input"
            />
          </FormItem>
          <ButtonContainer>
            <Button
              disabled={loading}
              loading={loading}
              type="submit"
              onClick={onSubmit}
              fullWidth
              data-testid="login-submit-btn"
            >
              Login
            </Button>
          </ButtonContainer>
        </form>
        <FormItem>
          <ErrorBlock
            error={errors.serverError}
            data-testid="login-error-block"
          />
        </FormItem>
        <div>
          <Caption style={{ textAlign: "center", marginTop: 16 }}>
            <Link to="/forgot-password">
              {errors?.serverError?.includes("expired")
                ? "Reset your password"
                : "Forgot your password?"}
            </Link>
          </Caption>
        </div>
      </FormContainer>
    </Container>
  );
}
