import PageSubtitle from "components/text/PageSubtitle";
import styled from "styled-components";
import Row from "components/common/Row";
import Col from "components/common/Col";
import { toNumber } from "lodash";
import { FlagFilled } from "@ant-design/icons";
import Caption from "components/text/Caption";
import { RedoOutlined } from "@ant-design/icons";
import Tooltip from "components/common/Tooltip";
import message from "components/common/message";
import TruncateText from "components/common/TruncateText";
import Text from "components/text/Text";
import TruncateMarkup from "react-truncate-markup";
import TwoLineChart from "components/common/Chart/TwoLineChart";
import formatBytes from "lib/helpers/formatBytes";
// lib
import theme from "lib/theme";
import moment from "moment";
import numeral from "numeral";
import { Instance, useRefreshInstanceDataMutation } from "generated/graphql";

const DataContainer = styled.div`
  min-height: 400px;
  background-color: ${({ theme }) => theme.colors.background2};
  border-radius: ${({ theme }) => theme.borderRadius};
  margin-top: 16px;
  padding: 24px;
`;

const DataRowContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  max-width: 98%;
`;

const FlagFilledContainer = styled.div`
  background: ${({ theme }) => theme.colors.yellow10};
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ToolCaption = styled(Caption)`
  font-size: 11px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

const ChangeTipContent = ({ value, lastValue }) => (
  <>
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <div style={{ width: "50%" }}>
        <ToolCaption>from: </ToolCaption>
        <TruncateText text={lastValue?.oldValue}>
          <Text>{lastValue?.oldValue}</Text>
        </TruncateText>
      </div>
      <div style={{ width: "50%" }}>
        <ToolCaption>to: </ToolCaption>
        <TruncateText text={value}>
          <Text>{value}</Text>
        </TruncateText>
      </div>
    </div>
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        marginTop: 16,
      }}
    >
      <div style={{ width: "50%" }}>
        <ToolCaption>Changed on: </ToolCaption>
        <TruncateText text={lastValue?.dateOfChange}>
          <Text>{moment(parseInt(lastValue?.dateOfChange))?.format("MMM D, YYYY")}</Text>
        </TruncateText>
      </div>
      <div style={{ width: "50%" }}>
        <ToolCaption>Changed by: </ToolCaption>
        <TruncateText text={lastValue?.changedByString}>
          <Text>{lastValue?.changedByString}</Text>
        </TruncateText>
      </div>
    </div>
  </>
);

const DataRow = ({ label, value, lastValue }: { label?: string | null | undefined; value: string | null | undefined; lastValue?: any }) => {
  return (
    <DataRowContainer>
      <div
        style={{
          flex: 1,
          minWidth: 100,
          marginRight: 16,
          position: "relative",
        }}
      >
        <Tooltip title={label}>
          <div>
            <TruncateMarkup>
              <Caption style={{ fontSize: 12 }}>{label}</Caption>
            </TruncateMarkup>
          </div>
        </Tooltip>
        {lastValue?.oldValue && lastValue?.oldValue !== value && (
          <div
            style={{
              position: "absolute",
              right: -2,
              top: 0,
            }}
          >
            <Tooltip overlayInnerStyle={{ width: 260 }} title={<ChangeTipContent value={value} lastValue={lastValue} />}>
              <FlagFilledContainer>
                <FlagFilled style={{ color: theme.colors.yellow6, fontSize: 14 }} />
              </FlagFilledContainer>
            </Tooltip>
          </div>
        )}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Tooltip title={value}>
          <div>
            <TruncateMarkup>
              <Text
                style={{
                  fontSize: 12,
                  wordWrap: "break-word",
                }}
              >
                {value}
              </Text>
            </TruncateMarkup>
          </div>
        </Tooltip>
      </div>
    </DataRowContainer>
  );
};

const getLastValue = (changeLogs, key) => changeLogs?.find((i) => i?.key === key);

const SectionTitle = styled(PageSubtitle)`
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
`;

const fieldsToHide = [
  "OPERATING_SYSTEM",
  "KERNEL_VERSION",
  "LANDSCAPE",
  "DB_TYPE",
  "DB_VERSION",
  "SAP_PRODUCT",
  "DBSIZE",
  "KERNEL_PATCH",
  "MACHINE_TYPE",
  "OS_RELEASE",
  "HOSTNAME",
  "APPLICATION_VERSION",
  "CPU_COUNT",
  "KERNEL_TYPE",
  "BI_CONT",
  "SAP_ABA",
];

export default function InstanceDetail({ instance }: { instance: Instance }) {
  const [refreshInstanceDataMutation, { loading: refreshing }] = useRefreshInstanceDataMutation();
  const instanceSpecsKeys = instance?.instanceSpecs ? Object.keys(instance?.instanceSpecs) : [];
  return (
    <>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Caption style={{ marginTop: 4, marginBottom: 16 }}>
          {instance?.updatedAt && (
            <>
              Last updated {moment(parseInt(instance?.updatedAt)).format("MMM D, YYYY")} @ {moment(parseInt(instance?.updatedAt)).format("h:mma")}
            </>
          )}

          <Tooltip title="Refresh your data">
            <RedoOutlined
              style={{ fontSize: 16, color: "#fff", marginLeft: 8 }}
              onClick={async () => {
                await refreshInstanceDataMutation({
                  variables: {
                    instanceId: instance.id,
                  },
                });
                message.success("Data successfully refreshed");
              }}
              spin={refreshing}
            />
          </Tooltip>
        </Caption>
      </div>
      <Row style={{ marginTop: 24 }} gutter={24}>
        <Col xs={8}>
          <SectionTitle>System Configuration</SectionTitle>
          <DataContainer>
            <DataRow label="Architecture" value={instance.architecture} lastValue={getLastValue(instance?.changeLogs, "architecture")} />
            {instance?.instanceSpecs?.["KERNEL_TYPE"] && (
              <DataRow
                label="Operating System Type"
                value={instance?.instanceSpecs?.["KERNEL_TYPE"]}
                lastValue={getLastValue(instance?.changeLogs, "KERNEL_TYPE")}
              />
            )}
            <DataRow
              label="Operating System Kernel Release"
              value={instance?.osType || instance?.instanceSpecs?.["OS_RELEASE"]}
              lastValue={getLastValue(instance?.changeLogs, "OS_RELEASE")}
            />
            {(instance?.osVersion || instance?.instanceSpecs?.["OPERATING_SYSTEM"]) && (
              <DataRow
                label="Operating System"
                value={instance?.osVersion || instance?.instanceSpecs?.["OPERATING_SYSTEM"]}
                lastValue={getLastValue(instance?.changeLogs, "osVersion") || getLastValue(instance?.changeLogs, "OPERATING_SYSTEM")}
              />
            )}
            <DataRow label="vCPU" value={instance?.cpu} />
            <DataRow label="RAM" value={`${instance?.instanceSize} GB`} lastValue={getLastValue(instance?.changeLogs, "instanceSize")} />

            <DataRow
              label="Instance Type"
              value={instance?.vendorInstanceType}
              lastValue={getLastValue(instance?.changeLogs, "vendorInstanceType")}
            />
            {/* <DataRow
              label="Root Device Type"
              value={instance?.rootDeviceType}
            /> */}
            <DataRow label="Vendor" value={instance?.vendor} />
            <DataRow label="Region" value={instance?.region} />

            {instance?.customFields?.map((item) => {
              if (item?.value && item?.type === 1) {
                return <DataRow key={item.name} label={item?.name} value={item.value} />;
              }
              return null;
            })}
          </DataContainer>
        </Col>
        <Col xs={8}>
          <SectionTitle> Application Configuration</SectionTitle>
          <DataContainer style={{ height: "60vh", overflowY: "scroll" }}>
            <DataRow label="Tier Name" value={instance?.pattern} />
            <DataRow
              label="Application Name"
              value={instance?.application?.name || instance?.instanceSpecs?.["SAP_PRODUCT"]}
              lastValue={getLastValue(instance?.changeLogs, "SAP_PRODUCT")}
            />
            <DataRow
              label="Application Version"
              value={instance?.instanceSpecs?.["APPLICATION_VERSION"]}
              lastValue={getLastValue(instance?.changeLogs, "APPLICATION_VERSION")}
            />
            <DataRow label="System Type" value={instance?.instanceType} lastValue={getLastValue(instance?.changeLogs, "instanceType")} />
            <DataRow
              label="SAP Kernel Version"
              value={instance?.instanceSpecs?.["KERNEL_VERSION"]}
              lastValue={getLastValue(instance?.changeLogs, "KERNEL_VERSION")}
            />
            <DataRow
              label="SAP Landscape"
              value={instance?.instanceSpecs?.["LANDSCAPE"]}
              lastValue={getLastValue(instance?.changeLogs, "LANDSCAPE")}
            />

            <DataRow
              label="Database Version"
              value={instance?.instanceSpecs?.["DB_VERSION"]}
              lastValue={getLastValue(instance?.changeLogs, "DB_VERSION")}
            />
            <DataRow
              label="Database Size"
              value={formatBytes(instance?.instanceSpecs?.["DBSIZE"])}
              lastValue={getLastValue(instance?.changeLogs, "DBSIZE")}
            />
            <DataRow
              label="SAP Kernel Patch"
              value={instance?.instanceSpecs?.["KERNEL_PATCH"]}
              lastValue={getLastValue(instance?.changeLogs, "KERNEL_PATCH")}
            />
            <DataRow label="BI_CONT" value={instance?.instanceSpecs?.["BI_CONT"]} lastValue={getLastValue(instance?.changeLogs, "BI_CONT")} />
            <DataRow label="SAP_ABA" value={instance?.instanceSpecs?.["SAP_ABA"]} lastValue={getLastValue(instance?.changeLogs, "SAP_ABA")} />

            {instanceSpecsKeys
              ?.filter((i) => !fieldsToHide.includes(i))
              ?.map((key) => {
                return <DataRow key={key} label={key} value={instance?.instanceSpecs?.[key]} lastValue={instance?.lastInstanceSpecs?.[key]} />;
              })}
          </DataContainer>
        </Col>
        <Col xs={8}>
          <SectionTitle>Network</SectionTitle>
          <DataContainer>
            <DataRow
              label="Private IP Address"
              value={instance.privateIPAddress}
              lastValue={getLastValue(instance?.changeLogs, "privateIPAddress")}
            />
            <DataRow label="SubNet" value={instance.subnet} />
            <DataRow label="Recieved" value={instance?.networkIn?.mean ? `${numeral(instance?.networkIn?.mean)?.format("0,0.000")} kB/s` : "N/A"} />
            <DataRow label="Sent" value={instance?.networkOut?.mean ? `${numeral(instance?.networkOut?.mean)?.format("0,0.000")} kB/s` : "N/A"} />
            <DataRow
              label="Total"
              value={
                instance?.networkOut?.mean && instance?.networkIn?.mean
                  ? `${numeral(toNumber(instance?.networkOut?.mean) + toNumber(instance?.networkIn?.mean))?.format("0,0.000")} kB/s`
                  : "N/A"
              }
            />
            <div style={{ marginTop: 24 }}>
              <TwoLineChart
                title="Kilobytes"
                data={[instance?.networkOut?.data, instance?.networkIn?.data]}
                valueKeys={["Network Out", "Network In"]}
                yAxisDomain={instance?.networkOut?.[0]?.value === null ? [0] : [0, (dataMax) => dataMax * 1.75]}
              />
            </div>
          </DataContainer>
        </Col>
      </Row>
    </>
  );
}
