import styled from 'styled-components';

const Caption = styled.span`
  margin: 0px;
  bottom: 0;
  font-weight: 300;
  line-height: 130%;
  letter-spacing: .75px;
  font-family: ${(p) => p.theme.fontFamily};
  display: ${(p) => (p?.style?.display ? p?.style?.display : 'block')};
  color: ${(p) => (p.color ? p.color : p.theme.colors.text)};
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : '14px')};
`;

export default Caption;
