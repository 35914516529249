import { ApolloClient, HttpLink, ApolloLink, InMemoryCache } from "@apollo/client";
import authLink from "./links/authLink";
import apolloLogger from "apollo-link-logger";
import config, { NodeEnvironments } from "lib/config";

const httpLink = new HttpLink({ uri: config.GRAPHQL_API });
// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271

// https://stackoverflow.com/questions/47211778/cleaning-unwanted-fields-from-graphql-responses/51380645#51380645
const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) => (key === "__typename" ? undefined : value);
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }
  return forward(operation).map((data) => {
    return data;
  });
});

let links = [cleanTypeNameLink, authLink, httpLink];

if (config.NODE_ENV === NodeEnvironments.development) {
  links = [cleanTypeNameLink, authLink, apolloLogger, httpLink];
}

const client = new ApolloClient({
  name: "react-web-client",
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
});

export default client;
