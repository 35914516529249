import styled from "styled-components";
import FormLabel from "../FormLabel";

const HintText = styled(FormLabel)`
  color: ${(p) => p.theme.colors.text};
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 12px;
`;

export default HintText;
