import styled from "styled-components";
import Caption from "components/text/Caption";
import Text from "components/text/Text";
import { UserProfile } from "generated/graphql";

const DataContainer = styled.div`
  display: flex;
  margin-top: 24px;
  align-items: center;
`;

const DataListItem = ({ value, label }) => {
  return (
    <DataContainer>
      <div style={{ minWidth: 100 }}>
        <Caption style={{ marginRight: 16 }}>{label}</Caption>{" "}
      </div>
      <Text>{value}</Text>
    </DataContainer>
  );
};

export default function AccountDetailsBlock({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  return (
    <div>
      <DataListItem
        label="Name"
        value={
          currentUser.firstName && currentUser.lastName
            ? `${currentUser.firstName} ${currentUser.lastName}`
            : "N/a"
        }
      />
      <DataListItem label="Email" value={currentUser.email} />
      <DataListItem
        label="Organization"
        value={currentUser?.activeOrganization?.name}
      />
    </div>
  );
}
