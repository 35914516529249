import Select from 'antd/lib/select';
import 'antd/lib/select/style/css';
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function InstancesMultiSelect(props) {
  const { onChange, instances, value } = props;

  return (
    <Select
      style={{ width: '100%' }}
      {...props}
      size="large"
      mode="multiple"
      onChange={onChange}
      value={value || undefined}
      optionFilterProp="children"
    >
      {instances?.map(({ name, id }) => {
        if (!id) return null;
        return (
          <Option key={id} value={id}>
            {name}
          </Option>
        );
      })}
    </Select>
  );
}
