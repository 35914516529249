import ErrorBlock from "components/common/ErrorBlock";
import { useGetManyIncidentsQuery } from "generated/graphql";
import IncidentsTable from "./IncidentsTable";

export default function Incidents() {
  const { loading, error, data } = useGetManyIncidentsQuery();
  const incidents = data?.getManyIncidents;

  return (
    <div>
      {error && (
        <ErrorBlock
          error={
            "Something went wrong getting Incidents. Please try to refresh. If the problem persists, please reach out to us at support@apiphani.io."
          }
          style={{ marginTop: 16 }}
        />
      )}
      <IncidentsTable incidents={incidents} loading={loading} error={error} />
    </div>
  );
}
