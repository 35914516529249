import { useState } from "react";

import ReactFlow, { removeElements, addEdge, updateEdge, Controls, Background } from "react-flow-renderer";
import ButtonEdge from "./ButtonEdge";
import styled from "styled-components";
import CustomNode from "./CustomNode";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .edgebutton-foreignobject body {
    align-items: center;
    background: transparent;
    display: flex;
    height: 40px;
    justify-content: center;
    min-height: 40 px;
    width: 40px;
  }
`;

const nodeTypes = {
  selectorNode: CustomNode,
};

const OverviewFlow = ({ elements, onChange }) => {
  const [rfInstance, setRfInstance] = useState(null);
  console.log({
    elements,
  });

  if (!Array.isArray(elements)) return <>not an array</>;

  const onElementsRemove = (elementsToRemove) => {
    const els = removeElements(elementsToRemove, elements);

    onChange(els);
  };

  const onConnect = (params) => {
    // console.log("this ran");
    const els = addEdge({ ...params, type: "buttonedge", animated: true }, elements);
    // const flow = rfInstance.toObject();
    // console.log({
    //   flow,
    // });
    console.log({
      els,
    });
    onChange(els);
  };

  const onEdgeUpdate = (oldEdge, newConnection) => onChange((els) => updateEdge(oldEdge, newConnection, els));

  return (
    <Wrapper>
      <ReactFlow
        elements={elements}
        // onElementsRemove={onElementsRemove}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onNodeDragStop={(event, node) =>
          onChange([...elements?.filter((item) => item.id !== node.id), { ...elements?.find((item) => item.id === node.id), ...node }])
        }
        onLoad={(reactFlowInstance) => {
          console.log("flow loaded:", reactFlowInstance);
          reactFlowInstance.fitView();
          setRfInstance(reactFlowInstance);
        }}
        connectionLineType="bezier"
        // onEdgeUpdate={onEdgeUpdate}
        snapToGrid={true}
        snapGrid={[15, 15]}
        edgeTypes={{
          buttonedge: ButtonEdge,
        }}
      >
        {/* <MiniMap
        nodeStrokeColor={(n) => {
          if (n.style?.background) return n.style.background;
          if (n.type === "input") return "#0041d0";
          if (n.type === "output") return "#ff0072";
          if (n.type === "default") return "#1a192b";

          return "#eee";
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return "#fff";
        }}
        nodeBorderRadius={2}
      /> */}
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </Wrapper>
  );
};

export default OverviewFlow;
