import styled from "styled-components";
import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import BuildingSVG from "./buildingSVG";
import { useUpdateOneUserActiveOrgMutation } from "generated/graphql";
import { DownOutlined } from "@ant-design/icons";
import theme from "lib/theme";

const Option = Select.Option;

const Container = styled.div`
  margin: 16px;
  margin-bottom: 24px;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.background4};
  padding: 1px;
  border-radius: 5px;

  .ant-select-selector {
    background: ${({ theme }) => theme.colors.background3} !important;
    padding-left: 40px !important;
    height: 40px !important;
  }

  .ant-select-selection-item {
    font-weight: 300;
  }

  .ant-select,
  .ant-select-multiple,
  .ant-select-selector {
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.background} !important;
    border-color: ${({ theme }) => theme.colors.background} !important;
  }

  .ant-select-selection-search {
    background: ${({ theme }) => theme.colors.background} !important;
  }

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 12px;
  }
`;

const SvgContainer = styled.div`
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 100;
`;

export default function OrgSwitcher({ value, organizations }) {
  const [updateOneUserActiveOrgMutation] = useUpdateOneUserActiveOrgMutation();

  // if this user only has one organization, dont bother showing the org switcher component
  if (organizations.length === 1) return null;

  const handleOnChange = async (activeOrganizationId) => {
    try {
      await updateOneUserActiveOrgMutation({
        variables: {
          activeOrganizationId,
        },
      });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      <SvgContainer>
        <BuildingSVG />
      </SvgContainer>
      <Select
        style={{ width: "100%", height: 40 }}
        size="large"
        onChange={handleOnChange}
        optionFilterProp="children"
        value={value || organizations?.[0]?.id}
        suffixIcon={
          <DownOutlined style={{ color: theme.colors.text, fontSize: 10 }} />
        }
      >
        {organizations?.map(({ id, name }) => {
          if (!id) return null;
          return (
            <Option key={id} value={id}>
              {name}
            </Option>
          );
        })}
      </Select>
    </Container>
  );
}
