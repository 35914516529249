import passwordValidator from 'password-validator';

let passwordSchema = new passwordValidator();

passwordSchema
    .has()
    .uppercase(1)
    .has()
    .lowercase(1)
    .has()
    .digits(1)
    .has()
    .not()
    .spaces()
    .is()
    .min(8)
    .is()
    .max(100)
    .is()
    .not()
    .oneOf(['Passw0rd', 'Password123']);

export default passwordSchema;