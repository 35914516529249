import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import { GetManyUsersDocument, useCreateNewUserMutation, useGetManyUsersQuery, UserTypeEnum } from "generated/graphql";
import UsersTable from "components/common/UsersTable";
import Button from "components/common/Button";
import Drawer from "components/common/Drawer";
import UserForm from "components/forms/UserForm";
import { useState } from "react";
import message from "components/common/message";

const LIMIT = 5;

export default function AdminUsers({ location }) {
  const [addNewUser, setAddNewUser] = useState(false);
  const [createNewUserMutation, { loading: creating }] = useCreateNewUserMutation();
  const { data, loading, error } = useGetManyUsersQuery({
    variables: {
      userTypes: [UserTypeEnum.Client, UserTypeEnum.Engineer],
    },
  });
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });

  if (loading) return null;
  if (error) return null;
  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <PageTitle>Users</PageTitle>
        <Button onClick={() => setAddNewUser(true)}>Add New User</Button>
      </div>
      <div style={{ marginTop: 24 }}>
        <UsersTable
          dataSource={data?.getManyUsers || []}
          onChange={(pagination: any, filters: any, sorter: any) => {
            setFilters({
              ...filters,
              current: pagination?.current,
              limit: pagination?.pageSize,
              skip: pagination?.pageSize * (pagination.current - 1),
            });
          }}
          pagination={{
            pageSize: filters.limit,
            current: filters.current,
            total: data?.getManyUsers?.length,
          }}
        />
      </div>
      <Drawer
        title="Invite New User"
        placement="right"
        onClose={() => setAddNewUser(false)}
        width={600}
        visible={addNewUser ? true : false}
        height={200}
        getContainer={false}
        destroyOnClose
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        <UserForm
          showOrgInput
          loading={creating}
          onCancel={() => setAddNewUser(false)}
          onSubmit={async (values) => {
            try {
              await createNewUserMutation({
                variables: {
                  params: {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    permission: values?.permission,
                    organizationIds: values?.organizationIds,
                    customPermissions: values?.customPermissions,
                    userType: values?.userType,
                  },
                },
                refetchQueries: [
                  {
                    query: GetManyUsersDocument,
                    variables: {
                      userTypes: [UserTypeEnum.Client, UserTypeEnum.Engineer],
                    },
                  },
                ],
              });
              message.success(`An account for ${values.email} was successfully created. They will recieve an invite email shortly.`);
              setAddNewUser(false);
            } catch (err: any) {
              message.error(err?.message);
              console.log(err);
            }
          }}
        />
      </Drawer>
    </PageContainer>
  );
}
