import TruncateMarkup from "react-truncate-markup";
import Tooltip from "components/common/Tooltip";
import React from "react";

const showTooltip = (text, showCharCount: number | undefined) => {
  if (!showCharCount) return text;
  if (text.length > showCharCount) return text;
  return null;
};

export default function TruncateText({
  text,
  children,
  style,
  showCharCount,
}: {
  text: any;
  children: any;
  style?: React.CSSProperties;
  /** if you want to only show the tooltip if the word is larger than a certain character dount */
  showCharCount?: number;
}) {
  return (
    <Tooltip title={!showCharCount ? text : showTooltip(text, showCharCount)}>
      <div style={{ display: "inline", ...style }}>
        <TruncateMarkup lines={1}>{children}</TruncateMarkup>
      </div>
    </Tooltip>
  );
}
