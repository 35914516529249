import {
  AccessNameEnum,
  useGetAccessesBySectionQuery,
  AccessItem,
} from 'generated/graphql';
import Caption from 'components/text/Caption';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import styled from 'styled-components';

const SectionTitle = styled.h3`
  text-transform: uppercase;
  font-size: 12px;
  color: ${({theme}) => theme.colors.text2};
  margin-bottom: 16px;
  margin-top: 32px;
`;

export default function AccessesSelector({
  value,
  onChange,
}: {
  value: AccessNameEnum[] | [] | undefined;
  onChange: (args: AccessNameEnum[]) => void;
}) {
  const {data, loading, error} = useGetAccessesBySectionQuery();

  if (loading) return null;
  if (error) return <Caption>error</Caption>;

  const handleOnChange = (checked: boolean, key: AccessNameEnum) => {
    if (checked && value) {
      onChange([...value, key]);
    } else {
      if (Array.isArray(value)) {
        return onChange(value?.filter((item) => item !== key));
      } else {
        return onChange([]);
      }
    }
  };

  return (
    <div style={{marginTop: 16, marginBottom: 16}}>
      {data?.getAccessesBySection?.map((section) => {
        return (
          <div key={section?.sectionName}>
            <SectionTitle>{section?.sectionName}</SectionTitle>
            <Row>
              {section?.items?.map((item: AccessItem | null) => {
                if (!item?.key || typeof item.key !== 'string') return null;
                return (
                  <Col xs={24} sm={6} key={item?.key}>
                    <div style={{marginTop: 24}}>
                      <Caption>
                        <input
                          type="checkbox"
                          style={{marginRight: 6}}
                          onChange={(e) =>
                            handleOnChange(
                              e.target.checked,
                              item?.key as AccessNameEnum
                            )
                          }
                          checked={[...((value as string[]) || [])]?.includes(
                            item?.key
                          )}
                        />
                        {item?.name}
                      </Caption>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </div>
  );
}
