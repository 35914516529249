import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import VendorSelectInput from "components/inputs/VendorSelectInput";
import VendorRegionsInput from "components/inputs/VendorRegionsInput";
import InstanceKeyUseInput from "components/inputs/InstanceKeyUseInput";
import { CloudVendorTypeEnum, Instance } from "generated/graphql";
import InstancesMultiSelect from "components/inputs/InstancesMultiSelect";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

export enum InstanceKeyFormModeEnum {
  editing = "editing",
  creating = "creating",
}

interface InstanceKeyFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  mode?: InstanceKeyFormModeEnum;
  instances?: Instance[];
}

const getPrivateKeyLabel = (value?: CloudVendorTypeEnum): string => {
  if (value === CloudVendorTypeEnum.Aws) {
    return "Secret access key";
  }
  if (value === CloudVendorTypeEnum.Azure) {
    return "Client Secret";
  }
  return "Private key";
};

const getPublicKeyLabel = (value?: CloudVendorTypeEnum): string => {
  if (value === CloudVendorTypeEnum.Aws) {
    return "Access key ID";
  }
  if (value === CloudVendorTypeEnum.Azure) {
    return "Tenant ID";
  }
  return "Public key";
};

export default function InstanceKeyForm({ onSubmit, onCancel, loading, instances, mode = InstanceKeyFormModeEnum.creating }: InstanceKeyFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm();

  const vendor = watch("vendor");

  const instanceIds = watch("instanceIds");

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    if (data?.vendor === CloudVendorTypeEnum.Azure && !data?.resourceGroup) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Name" required error={errors?.name?.message}>
        <TextInput
          {...register("name", {
            required: "Name is required.",
          })}
        />
      </FormItem>
      <FormItem label="Description" required error={errors?.description?.message}>
        <TextInput {...register("description")} />
      </FormItem>
      <FormItem label="Use" error={errors?.user?.message} hint="Does this key have a specific use? (optional)">
        <Controller name="use" control={control} render={({ field }) => <InstanceKeyUseInput {...field} onChange={(use) => field.onChange(use)} />} />
      </FormItem>
      <FormItem label="Vendor" error={errors?.vendor?.message} required>
        <Controller
          name="vendor"
          control={control}
          rules={{ required: "This is required." }}
          render={({ field }) => <VendorSelectInput {...field} onChange={(stateProvinceRegion) => field.onChange(stateProvinceRegion)} />}
        />
      </FormItem>
      {mode === InstanceKeyFormModeEnum.creating && (
        <>
          <FormItem label={getPrivateKeyLabel(vendor)} required error={errors?.private_key?.message}>
            <TextInput
              {...register("private_key", {
                required: "Private Key is required.",
              })}
            />
          </FormItem>
          <FormItem label={getPublicKeyLabel(vendor)} required error={errors?.public_key?.message}>
            <TextInput
              {...register("public_key", {
                required: "Public Key is required.",
              })}
            />
          </FormItem>
          {vendor === CloudVendorTypeEnum.Azure && (
            <>
              <FormItem label="Application ID" required error={errors?.applicationId?.message}>
                <TextInput
                  {...register("applicationId", {
                    required: "Application ID is required for azure.",
                  })}
                />
              </FormItem>
              <FormItem label="Subscription ID" required error={errors?.subscriptionId?.message}>
                <TextInput
                  {...register("subscriptionId", {
                    required: "Subscription ID is required for azure.",
                  })}
                />
              </FormItem>
              <FormItem label="Resource Group" required error={errors?.resourceGroup?.message}>
                <TextInput
                  {...register("resourceGroup", {
                    required: "Resource group is required for azure.",
                  })}
                />
              </FormItem>
            </>
          )}
        </>
      )}
      {vendor === CloudVendorTypeEnum.Aws ? (
        <FormItem label="Region" required error={errors?.region?.message}>
          <Controller
            name="region"
            control={control}
            render={({ field }) => <VendorRegionsInput {...field} vendor={vendor} onChange={(vendor) => field.onChange(vendor)} />}
          />{" "}
        </FormItem>
      ) : (
        <FormItem label="Region" error={errors?.region?.message}>
          <TextInput {...register("region")} />
        </FormItem>
      )}
      <FormItem label="Instances" hint="If this key is for specific instances, add them here" error={errors?.instanceIds?.message}>
        <Controller
          name="instanceIds"
          control={control}
          render={({ field }) => (
            <InstancesMultiSelect {...field} instances={instances} values={instanceIds} onChange={(instanceIds) => field.onChange(instanceIds)} />
          )}
        />
      </FormItem>

      <FormItem>
        <Button type="button" onClick={onCancel} grey style={{ marginRight: 16 }}>
          Cancel
        </Button>
        <Button type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
