import { memo, useState } from "react";
import { Handle, Position } from "react-flow-renderer";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import { useStateMachine, GlobalState } from "little-state-machine";
import { onRemoveElement } from "../../index";
import styled from "styled-components";
import theme from "lib/theme";
import {
  WarningFilled,
  CheckCircleFilled,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Tooltip from "components/common/Tooltip";

const RemoveBtn = styled.button`
  position: absolute;
  top: -8px;
  left: -8px;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 50%;
  padding: 0px;
  color: ${({ theme }) => theme.colors.red2};
  background: ${({ theme }) => theme.colors.red10};
  cursor: pointer;
  font-size: 8px;
  &:hover {
    box-shadow: 0 0 6px 2px rgb(0 0 0 / 8%);
    transform: scale(1.1);
  }
`;

const CodeBlock = styled.div`
  border-radius: 5px;
  padding: 6px;
  background: #000;
  color: #fff;
  font-size: 6px;
  overflow-x: scroll;
  margin-top: 16px;
`;

export enum CustomNodeEdgeTypeEnum {
  success = "success",
  failure = "failure",
}

export default memo(({ id, data, isConnectable }: any) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { actions } = useStateMachine({ onRemoveElement });
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <div
      style={{ padding: 8, position: "relative" }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      {hovering && (
        <Tooltip title="Remove Step">
          <RemoveBtn onClick={() => actions?.onRemoveElement(id)}> ×</RemoveBtn>
        </Tooltip>
      )}
      <Handle
        type="target"
        position={Position.Left}
        style={{
          background: theme.colors.primary4,
          height: 14,
          width: 14,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          left: -12,
          border: 0,
        }}
        onConnect={(params) => console.log("handle onConnect", params)}
        isConnectable={isConnectable}
      >
        <ArrowRightOutlined
          style={{
            color: theme.colors.primary10,
            fontSize: 8,
            pointerEvents: "none",
          }}
        />
      </Handle>
      <div>
        <Text style={{ fontSize: 8 }}>{data?.script?.name}</Text>
        <Caption style={{ fontSize: 6 }}>{data?.script?.description}</Caption>
        {!expanded && <CodeBlock>{data?.script?.command}</CodeBlock>}
      </div>
      <Handle
        type="source"
        position={Position.Right}
        id={CustomNodeEdgeTypeEnum.failure}
        style={{
          top: 16,
          right: -10,
          background: theme.colors.red3,
          height: 16,
          width: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: 0,
        }}
        isConnectable={isConnectable}
      >
        <WarningFilled
          style={{
            fontSize: 10,
            color: theme.colors.red10,
            pointerEvents: "none",
          }}
        />
      </Handle>

      <Handle
        type="source"
        position={Position.Right}
        id={CustomNodeEdgeTypeEnum.success}
        style={{
          bottom: 0,
          right: -12,
          top: "auto",
          background: theme.colors.success3,
          height: 16,
          width: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: 0,
        }}
        isConnectable={isConnectable}
      >
        <CheckCircleFilled
          style={{
            fontSize: 10,
            pointerEvents: "none",
            color: theme.colors.success10,
          }}
        />
      </Handle>
    </div>
  );
});
