

const lightColors = {
    // primary
    primary1: '#002159',
    primary2: '#01337D',
    primary3: '#03449E',
    primary4: '#0552B5',
    primary5: '#0967D2',
    primary6: '#2186EB',
    primary7: '#47A3F3',
    primary8: '#7CC4FA',
    primary9: '#BAE3FF',
    primary10: '#E6F6FF',
    // Neutrals
    neutral1: '#1F2933',
    neutral2: '#323F4B',
    neutral3: '#3E4C59',
    neutral4: '#52606D',
    neutral5: '#616E7C',
    neutral6: '#7B8794',
    neutral7: '#9AA5B1',
    neutral8: '#CBD2D9',
    neutral9: '#E4E7EB',
    neutral10: '#F5F7FA',
    neutral11: '#F6F8FB',
    // Accent B
    supportB1: '#05606E',
    supportB2: '#07818F',
    supportB3: '#099AA4',
    supportB4: '#0FB5BA',
    supportB5: '#1CD4D4',
    supportB6: '#3AE7E1',
    supportB7: '#62F4EB',
    supportB8: '#92FDF2',
    supportB9: '#C1FEF6',
    supportB10: '#E1FCF8',
    // Red
    red1: '#610316',
    red2: '#8A041A',
    red3: '#AB091E',
    red4: '#CF1124',
    red5: '#E12D39',
    red6: '#EF4E4E',
    red7: '#F86A6A',
    red8: '#FF9B9B',
    red9: '#FFBDBD',
    red10: '#FFE3E3',
    // Yellow
    yellow1: '#513C06',
    yellow2: '#7C5E10',
    yellow3: '#A27C1A',
    yellow4: '#C99A2E',
    yellow5: '#E9B949',
    yellow6: '#F7D070',
    yellow7: '#F9DA8B',
    yellow8: '#F8E3A3',
    yellow9: '#FCEFC7',
    yellow10: '#FFFAEB',
    // success
    success1: '#014807', // darkest
    success2: '#07600E',
    success3: '#0E7817',
    success4: '#0F8613',
    success5: '#18981D',
    success6: '#31B237',
    success7: '#51CA58',
    success8: '#91E697',
    success9: '#C1F2C7',
    success10: '#E3F9E5' // lighest
}


const dark = {
    //
    "text3": "#F7F8F8", // lightest
    "text2": "#dadfe7", //"#d7d8db", // medium d7d8db
    "text": "#8a8f98", // very dark
    "background4": "#303236", // even lighter than lighets
    "background3": "#2a2b2e", // lightest
    "background2": "#27282b", // less dark
    "background": "#1f2023", // very dark  // light dark: 2d3748 // #4a5568
    // // primary blue
    // "primary1": "#E6F6FF",
    // "primary2": "#BAE3FF",
    // "primary3": "#7CC4FA",
    // "primary4": "#47A3F3",
    // "primary5": "#2186EB",
    // "primary6": "#0967D2",
    // "primary7": "#0552B5",
    // "primary8": "#03449E",
    // "primary9": "#01337D",
    // "primary10": "#002159",
    // primary purple
    "primary1": "#E0E8F9",
    "primary2": "#BED0F7",
    "primary3": "#98AEEB",
    "primary4": "#7B93DB",
    "primary5": "#647ACB",
    "primary6": "#4C63B6",
    "primary7": "#4055A8",
    "primary8": "#35469C",
    "primary9": "#2D3A8C",
    "primary10": "#19216C",
    // support
    "supportB1": "#E1FCF8",
    "supportB2": "#C1FEF6",
    "supportB3": "#92FDF2",
    "supportB4": "#62F4EB",
    "supportB5": "#3AE7E1",
    "supportB6": "#1CD4D4",
    "supportB7": "#0FB5BA",
    "supportB8": "#099AA4",
    "supportB9": "#07818F",
    "supportB10": "#05606E",
    // red
    "red1": "#FFE3E3",
    "red2": "#FFBDBD",
    "red3": "#FF9B9B",
    "red4": "#F86A6A",
    "red5": "#EF4E4E",
    "red6": "#E12D39",
    "red7": "#CF1124",
    "red8": "#AB091E",
    "red9": "#8A041A",
    "red10": "#610316",
    // yellow
    "yellow1": "#FFFAEB",
    "yellow2": "#FCEFC7",
    "yellow3": "#F8E3A3",
    "yellow4": "#F9DA8B",
    "yellow5": "#F7D070",
    "yellow6": "#E9B949",
    "yellow7": "#C99A2E",
    "yellow8": "#A27C1A",
    "yellow9": "#7C5E10",
    "yellow10": "#513C06",
    // success
    "success1": "#F8FFED",
    "success2": "#E6FFBF",
    "success3": "#CAFF84",
    "success4": "#AFF75C",
    "success5": "#8DED2D",
    "success6": "#6CD410",
    "success7": "#5CB70B",
    "success8": "#399709",
    "success9": "#2E7B06",
    "success10": "#1E5303"
}

const theme = {
    fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    // COLORS
    borderRadius: '12px',
    // colors: lightColors,
    mode: 'dark', // 'dark' | 'light'
    colors: dark || lightColors,
    breakpoints: {
        large: '1366px',
        medium: '1024px',
        mediumSmall: '600px',
        small: '414px', // iphone 6/7/8 plus
        xSmall: '375px', // iphone 7/7/8
        xxSmall: '320px', // iphone 5/SE
    },
};

export default theme;