// COMPONENTS
import OrganizationForm from "components/forms/OrganizationForm";
// LIB
import useUrlChange from "lib/hooks/useUrlChange";
// GRAPHQL
import {
  useCreateOrganizationMutation,
  OrganizationInput,
  GetManyOrganizationsDocument,
} from "generated/graphql";
import { useHistory } from "react-router";
import message from "components/common/message";

export default function AddNewOrganization() {
  const { onUrlChange } = useUrlChange();
  const [createOrganizationMutation, { loading: creating }] =
    useCreateOrganizationMutation();
  const history = useHistory();

  return (
    <OrganizationForm
      onCancel={() => onUrlChange({ addNewOrganization: undefined })}
      shortForm
      onSubmit={async (values: OrganizationInput) => {
        try {
          const response = await createOrganizationMutation({
            variables: {
              params: values,
            },
            refetchQueries: [
              {
                query: GetManyOrganizationsDocument,
              },
            ],
          });
          message.success(`Organization successfully crated`);
          history.push(
            `/admin/organizations?organizationId=${response?.data?.createOrganization?.id}`
          );
        } catch (err) {
          console.log(err);
        }
      }}
      loading={creating}
    />
  );
}
