// import {Application} from 'generated/graphql';
import styled from "styled-components";
import Tooltip from "components/common/Tooltip";
import StatusCircle from "components/common/StatusCircle";
import Caption from "components/text/Caption";
import Text from "components/text/Text";
import TruncateText from "components/common/TruncateText";
import { Environment, InstanceStateEnum } from "generated/graphql";
import theme from "lib/theme";
import environmentTypeHashmap from "lib/helpers/environmentTypeHashmap";

const Container = styled.div<{ active?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background2};
  border: 1px solid ${({ theme }) => theme.colors.background};
  border-radius: 7px;
  position: relative;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.background3};
  }
`;

export default function EnvironmentPreviewCard({
  environment,
  active,
  onClick,
}: {
  environment: Environment;
  active?: boolean;
  onClick: (environment: Environment) => void;
}) {
  return (
    <Container onClick={() => onClick(environment)} active={active}>
      <div style={{ marginRight: 8, maxWidth: 130, marginBottom: 8 }}>
        <TruncateText text={environment.name}>
          <Caption style={{ color: theme.colors.text3, marginBottom: 0 }}>
            {environment.name}
          </Caption>
        </TruncateText>
        <TruncateText text={environment.name}>
          <Caption style={{ fontSize: 12 }}>
            {environmentTypeHashmap[environment?.environmentType as string]}
          </Caption>
        </TruncateText>
      </div>
      <div>
        {environment?.instances?.map((instance) => {
          if (!instance?.id) return null;
          return (
            <Tooltip
              key={instance.id}
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StatusCircle
                    isRunning={instance?.state === InstanceStateEnum.Running}
                    fixed={false}
                    style={{ marginRight: 8 }}
                    size={14}
                  />
                  <Text>{instance.name}</Text>
                </div>
              }
            >
              <StatusCircle
                isRunning={instance?.state === InstanceStateEnum.Running}
                fixed={false}
                style={{ marginRight: 6 }}
                size={12}
              />
            </Tooltip>
          );
        })}
      </div>
    </Container>
  );
}
