import { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
// COMPONENTS
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
// LIB
import { useSearchQuery } from "generated/graphql";

const Input = styled.input<{ open: boolean }>`
  width: 100%;
  border-radius: 5px;
  padding-left: 32px;
  z-index: 5;
  position: relative;
  height: 40px;
  z-index: 1;
  .anticon {
    color: ${(p) => p.theme.colors.text3} !important;
  }
  border-radius: 35px;
  background: ${({ theme }) => theme.colors.background2};
  color: ${(p) => p.theme.colors.text3} !important;
  transition: width 0.3s ease, border-color 0.3s ease;
  ::placeholder {
    font-size: 14px !important;
    color: ${(p) => p.theme.colors.text} !important;
  }
  width: ${({ open }) => (!open ? "200px" : "420px")};
  border: ${({ open, theme }) => {
    return !open ? `0px` : `1px solid ${theme.colors.neutral8}`;
  }};
  &:focus {
    outline: 0;
  }
`;

const Container = styled.div`
  position: relative;
  margin-right: 24px;
`;

const ResultContainer = styled.div`
  max-height: 450px;
  min-height: 50px;
  background: #fff;
  position: absolute;
  z-index: 100;
  border: 1px solid ${(p) => p.theme.colors.neutral8};
  border-top: 0px;
  width: 100%;
  overflow-y: scroll;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 3;
  background: ${({ theme }) => theme.colors.background3};
`;

const ResultItemContainer = styled.div`
  padding: 8px;
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.colors.background};
  cursor: pointer;
  align-items: center;
  &:hover {
    background: ${(p) => p.theme.colors.background2};
  }
`;

const ResultItemTitle = styled.h2`
  font-weight: 600;
  margin: 0px;
  font-size: 16px;
  line-height: 18px;
  color: ${(p) => p.theme.colors.text3};
`;

const ResultItemCaption = styled.span`
  color: ${(p) => p.theme.colors.text};
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 20px;
  margin: 24px;
  color: ${(p) => p.theme.colors.neutral8};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
`;

const SearchIcon = styled(SearchOutlined)`
  position: absolute;
  left: 10px;
  top: 14px;
  z-index: 100;
  color: ${(p) => p.theme.colors.text};
`;

const SectionTitle = styled.h3`
  text-transform: uppercase;
  font-weight: 500;
  font-size: 11px;
  margin-bottom: 8px;
  letter-spacing: 1.2px;
  color: ${(p) => p.theme.colors.text};
`;

const Section = styled.div`
  padding: 16px;
`;

const InstanceCard = ({ instance, onClick }) => {
  return (
    <ResultItemContainer onClick={onClick}>
      <div>
        <ResultItemTitle>{instance.name}</ResultItemTitle>
        <ResultItemCaption>{instance.externalId}</ResultItemCaption>
      </div>
    </ResultItemContainer>
  );
};

export default function AutoCompleteSearch() {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const { data, loading } = useSearchQuery({
    variables: {
      searchText: searchText,
    },
    fetchPolicy: "cache-and-network",
    skip: !searchText?.[2],
  });

  const onClose = () => {
    setOpen(false);
    setSearchText(undefined);
  };

  const showResults = (loading || open) && searchText;

  const noResults = !loading && !data?.search?.instances?.[0];

  return (
    <Container>
      <Input
        placeholder="Search instances..."
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onBlur={() => {
          if (!searchText) {
            return setOpen(false);
          }
        }}
        onFocus={() => setOpen(true)}
        open={open}
        value={searchText || ""}
      />
      <SearchIcon />
      {/* {loading && <Loading />} */}
      {showResults && (
        <ResultContainer>
          {noResults && <div>No results</div>}
          {loading && <Loading />}
          {data?.search?.instances?.[0] && (
            <Section>
              <SectionTitle>Instances</SectionTitle>
              {data?.search?.instances.map((instance) => {
                if (!instance?.id) return null;
                return (
                  <InstanceCard
                    key={instance.id}
                    instance={instance}
                    onClick={() => {
                      history.replace(`/app/instances/${instance.id}`);
                      setOpen(false);
                    }}
                  />
                );
              })}
            </Section>
          )}
        </ResultContainer>
      )}
      {showResults && <Overlay onClick={onClose} />}
    </Container>
  );
}
