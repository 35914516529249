// TOP LEVEL IMPORTS
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const getBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  yellow,
  danger,
}) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (danger) {
    return theme.colors.red10;
  }
  if (grey) {
    return theme.colors.background2;
  }
  if (secondary) {
    return theme.colors.background2;
  }
  if (yellow) {
    return theme.colors.yellow10;
  }

  return theme.colors.primary7;
};

const getTextColor = ({ grey, disabled, danger, yellow, secondary, theme }) => {
  if (disabled) {
    return theme.colors.text;
  }
  if (grey) {
    return theme.colors.text;
  }
  if (secondary) {
    return theme.colors.primary3;
  }
  if (yellow) {
    return theme.colors.yellow1;
  }
  if (danger) {
    return theme.colors.red1;
  }
  return "#fff";
};

const getHoverBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  danger,
  yellow,
}) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (danger) {
    return theme.colors.red9;
  }
  if (grey) {
    return theme.colors.background3;
  }
  if (secondary) {
    return theme.colors.background3;
  }
  if (yellow) {
    return theme.colors.yellow9;
  }
  return theme.colors.primary6;
};

const PrimaryButtonContainer = styled.button`
  width: ${({ fullWidth, style }) => {
    if (fullWidth) return "100%";
    if (style?.width) return `${style?.width}px`;
  }};
  height: 36px;
  border-radius: 4px;
  background-color: ${(props) => getBackgroundColor(props)};
  border: 0px;
  line-height: 36px;
  padding: 0px 24px;
  letter-spacing: 0.025em;
  align-items: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  :hover {
    cursor: ${(p) => {
      if (p.disabled) {
        return "not-allowed";
      }
      return "pointer";
    }};
    background-color: ${(props) => getHoverBackgroundColor(props)};
  }
  :focus {
    outline: 0;
  }
  &:active {
    transform: translatey(1px);
  }
`;

const ButtonText = styled.span`
  height: 20px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  border-radius: 4px;
  flex-shrink: 0;
  margin: 0px;
  font-weight: 400;
  line-height: normal;
  font-size: 15px;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => getTextColor(props)};
`;

const PrimaryButton = (props) => (
  <PrimaryButtonContainer
    style={props.style}
    disabled={props.disabled}
    fullWidth={props.fullWidth}
    onClick={props.onClick}
    secondary={props.secondary}
    danger={props.danger}
    grey={props.grey}
    yellow={props.yellow}
    aria-label={props["aria-label"] || "Button"}
    type={props.type}
    data-testid={props["data-testid"]}
  >
    <ButtonText
      disabled={props.disabled}
      secondary={props.secondary}
      danger={props.danger}
      grey={props.grey}
      yellow={props.yellow}
    >
      {!props.loading ? props.children : <LoadingOutlined />}
    </ButtonText>
  </PrimaryButtonContainer>
);

export default PrimaryButton;
