import DarkTable from "components/common/DarkTable";
import { Invoice } from "generated/graphql";
import moment from "moment";
import formatCurrency from "lib/helpers/formatCurrency";
import InvoicePDF from "components/common/InvoicePDF";
import useDownloadPdf from "lib/hooks/useDownloadPdf";
import { DownloadOutlined } from "@ant-design/icons";
import Text from "components/text/Text";

const InvoicesTable = ({
  dataSource,
  loading,
  count,
  onChange,
  pagination,
}) => {
  const { downloadPdf, downloading } = useDownloadPdf();

  const onDownload = async (invoice: Invoice) => {
    try {
      if (downloading) return; // don't download again if it's already in the process of downloading
      await downloadPdf(
        <InvoicePDF invoice={invoice} downloading />,
        `000${invoice.Invoiceno}-Invoice.pdf`
      );
    } catch (err) {
      console.log(err);
    }
  };

  const LIMIT = 5;

  const columns = [
    {
      key: "id",
      title: "#",
      render: (record: Invoice) => <Text>{record?.Invoiceno}</Text>,
    },
    {
      title: "Total",
      render: ({ Invoice_amount }: Invoice) =>
        Invoice_amount && <Text>{formatCurrency(Invoice_amount)}</Text>,
    },
    {
      title: "Issue Date",
      render: (record: Invoice) =>
        record?.Invoice_Date ? (
          <Text>{moment(record?.Invoice_Date).format("MMM DD, YYYY")}</Text>
        ) : null,
    },
    {
      title: "Paid",
      render: (record: Invoice) =>
        record?.Amount_Paid && (
          <Text>{formatCurrency(record?.Amount_Paid)}</Text>
        ),
    },
    {
      title: "Balance",
      render: ({ balance }: Invoice) =>
        balance && <Text>{formatCurrency(balance)}</Text>,
    },
    {
      title: "",
      render: (record: Invoice) => (
        <DownloadOutlined onClick={() => onDownload(record)} />
      ),
    },
  ];

  return (
    <>
      <DarkTable
        dataSource={dataSource}
        loading={loading}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
      />
    </>
  );
};

export default InvoicesTable;
