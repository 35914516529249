import Loading from "components/common/Loading";
import {
  Backup,
  BackupStatusEnum,
  useGetInstanceBackupsQuery,
} from "generated/graphql";
import DarkTable from "components/common/DarkTable";
import StatusCircle from "components/common/StatusCircle";
import Text from "components/text/Text";
import { useState } from "react";

export default function DataProtection({ instance }) {
  const { data, loading, error } = useGetInstanceBackupsQuery({
    variables: {
      instanceId: instance?.id,
    },
  });

  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: 10,
  });

  if (loading) return <Loading />;
  if (error) return <Text>Something went wrong querying backups...</Text>;

  const columns = [
    {
      key: "id",
      title: "Method",
      render: (record: Backup) => record.detail,
    },
    {
      title: "Type",
      render: (record: Backup) => record.type,
    },
    {
      title: "Last update",
      render: (record: Backup) => record.state,
    },
    {
      title: "Status",
      render: (record: Backup) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <StatusCircle
            isRunning={record.status === BackupStatusEnum.Successful}
            fixed={false}
          />
        </div>
      ),
    },
    {
      title: "Duration",
      render: (record: Backup) =>
        `${record.durationHours} hrs ${record.durationMinutes} min ${record.durationSeconds} sec`,
    },
  ];

  return (
    <div>
      <DarkTable
        style={{ marginTop: 24 }}
        dataSource={data?.getOneInstance?.backups || []}
        columns={columns}
        pagination={{
          pageSize: filters?.limit,
          current: filters.current,
          total: data?.getOneInstance?.backups?.length,
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
      />
    </div>
  );
}
