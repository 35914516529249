import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function useUrlChange() {
    const location = useLocation();
    const history = useHistory();
    const options: any = {
        arrayFormat: 'index',
    }
    const onUrlChange = (newValues: any, baseUrl?: string, useReplace?: boolean) => {

        const existing = queryString.parse(location.search, options);

        let newUrl = {
            ...existing,
            ...newValues, // object
        };

        // the user of onUrlChange can pass in a baseUrl, or it will default to the curent base url
        history?.[useReplace ? 'replace' : 'push']({
            pathname: baseUrl || location.pathname,
            search: `?${queryString.stringify(newUrl, options)}`,
        });
    };

    return {
        onUrlChange,
        query: queryString.parse(location.search, options),
    };
}