import queryString from "query-string";
// COMPONENTS
import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Button from "components/common/Button";
import Drawer from "components/common/Drawer";
import AddNewOrganization from "./AddNewOrganization";
import OrganizationDetail from "./OrganizationDetail";
import OrganizationsTable from "./OrganizationsTable";
// LIB
import useUrlChange from "lib/hooks/useUrlChange";

interface AdminOrganiztionQueryParams {
  addNewOrganization?: "true";
  organizationId?: string;
}

export default function AdminOrganizations({ location }) {
  const { onUrlChange } = useUrlChange();
  const { addNewOrganization, organizationId }: AdminOrganiztionQueryParams = queryString.parse(location.search);

  if (organizationId) {
    return <OrganizationDetail organizationId={organizationId} />;
  }

  return (
    <PageContainer>
      {/* <Testing /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <PageTitle>Organizations</PageTitle>
        <Button onClick={() => onUrlChange({ addNewOrganization: true }, undefined, true)}>+ New Organization</Button>
      </div>
      <OrganizationsTable />
      <Drawer
        title="Create New Organization"
        onClose={() => onUrlChange({ addNewOrganization: undefined })}
        visible={addNewOrganization ? true : false}
      >
        <AddNewOrganization />
      </Drawer>
    </PageContainer>
  );
}
