import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DarkTable from "components/common/DarkTable";
import Button from "components/common/Button";
import Text from "components/text/Text";
import ScriptTemplateForm from "./ScriptTemplateForm";
import {
  useGetManyScriptTemplatesQuery,
  ScriptTemplate,
  useCreateOneScriptTemplateMutation,
} from "generated/graphql";
import { useState } from "react";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import mapScriptTemplateToFlow from "lib/helpers/mapScriptTemplateToFlow";
import mapFlowToScriptTemplate from "lib/helpers/mapFlowToScriptTemplate";
import message from "components/common/message";

const Center = styled.span`
  text-align: center;
  display: block;
`;

const LIMIT = 5;

export default function AdminScriptTemplates() {
  const [showForm, setShowForm] = useState<boolean>(false);
  const { data, loading } = useGetManyScriptTemplatesQuery();
  const [editScript, setEditScript] = useState<ScriptTemplate | false>(false);
  const [duplicateScript, setDuplicateScript] = useState<
    ScriptTemplate | false
  >(false);
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });

  const [createOneScriptTemplateMutation, { loading: creating }] =
    useCreateOneScriptTemplateMutation();

  const columns = [
    {
      key: "id",
      title: "Name",
      render: (record: ScriptTemplate) => <Text>{record?.name}</Text>,
    },
    {
      title: "Description",
      render: (record: ScriptTemplate) => <Text>{record?.description}</Text>,
    },
    {
      title: <Center>Duplicate</Center>,
      render: (record: ScriptTemplate) => (
        <Button
          onClick={() => setDuplicateScript(record)}
          grey
          style={{ margin: "auto", display: "block" }}
        >
          <CopyOutlined />
        </Button>
      ),
    },
    {
      title: <Center>Edit</Center>,
      render: (record: ScriptTemplate) => (
        <Button
          onClick={() => setEditScript(record)}
          grey
          style={{ margin: "auto", display: "block" }}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const onCreate = async (values, callback) => {
    try {
      await createOneScriptTemplateMutation({
        variables: {
          params: {
            ...mapFlowToScriptTemplate(values),
          },
        },
      });
      message.success("Your template was created");
      callback();
      setShowForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (duplicateScript) {
    return (
      <PageContainer>
        <ScriptTemplateForm
          defaultValues={mapScriptTemplateToFlow(duplicateScript)}
          onCancel={() => setDuplicateScript(false)}
          loading={creating}
          onSubmit={onCreate}
        />
      </PageContainer>
    );
  }

  if (showForm) {
    return (
      <PageContainer>
        <ScriptTemplateForm
          onCancel={() => setShowForm(false)}
          loading={creating}
          onSubmit={onCreate}
        />
      </PageContainer>
    );
  }

  if (editScript) {
    return (
      <PageContainer>
        <ScriptTemplateForm
          defaultValues={mapScriptTemplateToFlow(editScript)}
          onCancel={() => setEditScript(false)}
          onSubmit={(values) => console.log(mapFlowToScriptTemplate(values))}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle>Templates</PageTitle>
        <Button onClick={() => setShowForm(true)}>New Template</Button>
      </div>
      <div style={{ marginTop: 24 }}>
        <DarkTable
          dataSource={data?.getManyScriptTemplates}
          loading={loading}
          columns={columns}
          onChange={(pagination: any, filters: any, sorter: any) => {
            setFilters({
              ...filters,
              current: pagination?.current,
              limit: pagination?.pageSize,
              skip: pagination?.pageSize * (pagination.current - 1),
            });
          }}
          pagination={{
            pageSize: filters.limit,
            current: filters.current,
            total: data?.getManyScriptTemplates?.length,
          }}
        />
      </div>
    </PageContainer>
  );
}
