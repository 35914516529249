import {
    SupportTicketPriorityEnum,
} from 'generated/graphql';
import styled from 'styled-components';

const SupportTicketPriorityTag = styled.div<{ priority?: SupportTicketPriorityEnum | null }>`
  border-radius: 25px;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: .5px;
  font-weight: 400;
  max-width: 100%;
  min-width: 50px;
  background-color: ${({ theme, priority }) => {
        if (priority === SupportTicketPriorityEnum.Low)
            return theme.colors.yellow10;
        if (priority === SupportTicketPriorityEnum.Medium)
            return theme.colors.red10;
        return theme.colors.background;
    }};
  color: ${({ theme, priority }) => {
        if (priority === SupportTicketPriorityEnum.Low) return theme.colors.yellow2;
        if (priority === SupportTicketPriorityEnum.Medium) return theme.colors.red2;
        return theme.colors.background;
    }};
`;

export default SupportTicketPriorityTag
