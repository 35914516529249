import { ScriptTemplate } from "generated/graphql";
import { CustomNodeEdgeTypeEnum } from "pages/admin-script-templates/ScriptTemplateForm/FlowEditor/CustomNode";
import { FlowTemplate } from "./mapScriptTemplateToFlow";

export default function mapFlowToScriptTemplate(record: FlowTemplate): ScriptTemplate {
  const edges = record?.elements?.filter((ele) => ele?.source || ele?.target);
  console.log({
    record,
  });
  return {
    id: record?.id,
    name: record?.name,
    description: record?.description,
    steps:
      record?.elements
        ?.filter((ele) => !ele?.source || !ele?.target) // remove any edges
        ?.filter((ele) => ele?.id) // remove any empty objects
        ?.map((element) => {
          return {
            id: element?.data?.script?.id,
            name: element?.data?.script?.name,
            description: element?.data?.script?.description,
            command: element?.data?.script?.command,
            position: element?.position,
            successId: edges?.find((edge) => edge.sourceHandle === CustomNodeEdgeTypeEnum.success && edge.source === element.id)?.target,
            failureId: edges?.find((edge) => edge.sourceHandle === CustomNodeEdgeTypeEnum.failure && edge.source === element.id)?.target,
          };
        }) || [],
    // // elements: []
    // elements: record?.steps?.map((step, index) => {
    //     const nextStep = record?.steps?.[index + 1];
    //     return [{
    //         "id": step?.id,
    //         "type": "selectorNode",
    //         data: {
    //             "label": step?.name,
    //             script: step
    //         },
    //         "style": {
    //             "background": "#303236",
    //             "border": "1px solid #2a2b2e",
    //             "width": 140,
    //         },
    //         position: { x: 190 * index, y: 0 },
    //     },
    //     // if the record has a next step, then use that
    //     nextStep ? generateEdge(step?.id, nextStep?.id) : {}
    //     ]
    // })?.flat() || []
  };
}
