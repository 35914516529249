import styled from "styled-components";

const FormLabel = styled.label`
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: ${(p) => p.theme.colors.text3};
  display: block;
  margin-bottom: 3px;
  margin-left: 2px;
`;

export default FormLabel;
