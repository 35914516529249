import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import ChangePasswordForm from "components/forms/ChangePasswordForm";
import SectionBreak from "components/common/SectionBreak";
import AccountDetailsBlock from "./AccountDetailsBlock";
import Button from "components/common/Button";
import { useLogoutMutation } from "generated/graphql";
import removeLocalStorage from "lib/helpers/removeLocalStorage";
import config from "lib/config";
import message from "components/common/message";
import { useHistory } from "react-router-dom";
import client from "ApolloClient/index";

export default function ClientAccount({ currentUser }) {
  const [logout, { loading }] = useLogoutMutation();
  const history = useHistory();

  const handleOnLogout = async () => {
    try {
      await logout();
      removeLocalStorage(config.authTokenName);
      removeLocalStorage(config.refreshTokenName);
      await client.resetStore();
      // let user know
      message.info("You've successfully logged out...");
      history.push(`/login`);
    } catch (err) {}
  };

  return (
    <PageContainer>
      <PageTitle>My Account</PageTitle>
      <SectionBreak
        header="Account details"
        body="Here are a few details about your account"
      >
        <AccountDetailsBlock currentUser={currentUser} />
      </SectionBreak>
      <SectionBreak
        header="Change password"
        body="Reset your password by providing a new one."
      >
        <div style={{ width: 500, maxWidth: "100%" }}>
          <ChangePasswordForm />
        </div>
      </SectionBreak>
      <SectionBreak header="Other" body="Sign out of your account">
        <Button
          onClick={handleOnLogout}
          disabled={loading}
          loading={loading}
          style={{ width: 175, marginTop: 32 }}
        >
          Log Out
        </Button>
      </SectionBreak>
    </PageContainer>
  );
}
