// TOP LEVEL IMPORTS
import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';

export default function Loading({height = 500}) {
  return (
    <div
      style={{
        height,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
      }}
    >
      <LoadingOutlined />
    </div>
  );
}
