import styled from 'styled-components';
// COMPONENTS
import { ArrowLeftOutlined } from '@ant-design/icons';
// GRAPHQL
import { useHistory } from 'react-router';

const BackButtonContainer = styled.button`
  color: ${({ theme }) => theme.colors.text};
  background: transparent;
  border: 0px;
  padding: 0px;
  cursor: pointer;
`;

export default function BackButton({
  style,
  label,
  onClick,
}: {
  style?: React.CSSProperties;
  label?: string;
  onClick?: () => void;
}) {
  const history = useHistory();
  return (
    <BackButtonContainer
      style={style}
      onClick={() => {
        if (onClick) return onClick();
        history.goBack();
      }}
    >
      <ArrowLeftOutlined
        style={{ fontSize: 12, position: 'relative', top: -2 }}
      />{' '}
      {label || 'Go Back'}
    </BackButtonContainer>
  );
}
