import { Layout, Menu } from "antd";
import { ReactChild, ReactChildren } from "react";
import {
  AppstoreOutlined,
  SlidersOutlined,
  TeamOutlined,
  SnippetsOutlined,
  TagsOutlined,
  PieChartOutlined,
  SisternodeOutlined,
  WifiOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import AvatarDropdown from "./AvatarDropdown";
import styled from "styled-components";
import Tag from "components/common/Tag";
import "antd/lib/menu/style/css";
import "antd/lib/layout/style/css";
import "antd/lib/breadcrumb/style/css";
import config from "lib/config";
import { UserProfile } from "generated/graphql";
import { useHistory, useLocation } from "react-router";
import luumenLogoPNG from "lib/media/luumen-logo.png";

const { Header, Content, Sider, Footer } = Layout;

const Container = styled.div`
  position: relative;
  min-height: 100vh;
  .site-layout .site-layout-background {
    background: ${({ theme }) => theme.colors.background};
  }
  .ant-layout-sider-trigger {
    background: ${({ theme }) => theme.colors.neutral8};
  }
  .ant-layout-header,
  .ant-layout-footer,
  .ant-layout {
    background: ${({ theme }) => theme.colors.background};
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: ${({ theme }) => theme.colors.neutral2};
    background-color: ${({ theme }) => theme.colors.background2};
  }

  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
  .ant-menu-dark .ant-menu-item-selected .anticon {
    color: ${({ theme }) => theme.colors.primary3};
  }

  .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
  .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: ${({ theme }) => theme.colors.primary3};
  }

  .ant-menu-item {
    color: ${({ theme }) => theme.colors.text};
  }

  .anticon-left {
    svg {
      fill: ${({ theme }) => theme.colors.neutral3};
    }
  }

  .ant-layout-sider-children,
  .ant-menu {
    background: ${({ theme }) => theme.colors.background2};
  }
`;

const ICON_STYLES = { fontSize: 26, marginRight: 8 };

const NAV_ITEMS = [
  {
    title: "Clients",
    items: [
      {
        title: "Organizations",
        link: "/admin/organizations",
        icon: <AppstoreOutlined style={ICON_STYLES} />,
      },
      {
        title: "Users",
        link: "/admin/users",
        icon: <TeamOutlined style={ICON_STYLES} />,
      },
      {
        title: "Permissions",
        link: "/admin/permissions",
        icon: <SlidersOutlined style={ICON_STYLES} />,
      },
    ],
  },
  // {
  //   title: "Policies",
  //   items: [
  //     {
  //       title: "Summary",
  //       link: "/admin/policies",
  //       icon: <FileOutlined style={ICON_STYLES} />,
  //     },
  //   ],
  // },
  // {
  //   title: "Scripts",
  //   items: [
  //     {
  //       title: "Templates",
  //       link: "/admin/script-templates",
  //       icon: <PartitionOutlined style={ICON_STYLES} />,
  //     },
  //     {
  //       title: "Broadcasts",
  //       link: "/admin/script-broadcasts",
  //       icon: <WifiOutlined style={ICON_STYLES} />,
  //     },
  //   ],
  // }, <BuildOutlined /> <CloudSyncOutlined />
  {
    title: "Scripts",
    items: [
      {
        title: "Templates",
        link: "/admin/script-templates",
        icon: <SisternodeOutlined style={ICON_STYLES} />,
      },
      {
        title: "Broadcasts",
        link: "/admin/script-broadcasts",
        icon: <WifiOutlined style={ICON_STYLES} />,
      },
    ],
  },
  {
    title: "Service Desk",
    items: [
      {
        title: "Tickets",
        link: "/admin/service-desk",
        icon: <SolutionOutlined style={ICON_STYLES} />,
      },
    ],
  },
  // Hide these tabs on govcloud version
  ...(!config?.IS_GOVCLOUD
    ? [
        {
          title: "Billing",
          items: [
            {
              title: "Overview",
              link: "/admin/billing-overview",
              icon: <PieChartOutlined style={ICON_STYLES} />,
            },
            {
              title: "Invoices",
              link: "/admin/invoices",
              icon: <SnippetsOutlined style={ICON_STYLES} />,
            },
            {
              title: "Apiphani Skus",
              link: "/admin/apiphani-skus",
              icon: <TagsOutlined style={ICON_STYLES} />,
            },
          ],
        },
      ]
    : []),
  {
    title: "Settings",
    items: [
      {
        title: "Super Admins",
        link: "/admin/super-admins",
        icon: <TeamOutlined style={ICON_STYLES} />,
      },
    ],
  },
];

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.text};
  margin-top: 24px;
  margin-bottom: 16px;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  margin-left: 26px;
`;

const Logo = styled.img`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 40%;
  margin-left: 24px;
  display: block;
  max-width: 100px;
`;

const AvatarContainer = styled.div`
  height: 48px;
  width: 48px;
  position: absolute;
  right: 24px;
  top: 24px;
  border-radius: 50%;
  cursor: pointer;
`;

export default function AdminLayout({ children }: { children: ReactChild | ReactChildren; currentUser: UserProfile }) {
  const location = useLocation();
  const history = useHistory();

  return (
    <Container>
      <AvatarContainer>
        <AvatarDropdown />
      </AvatarContainer>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider width="17%">
          <div style={{ position: "relative" }}>
            <Logo className="logo" src={luumenLogoPNG} />

            <Tag
              color="orange"
              style={{
                position: "absolute",
                right: 40,
                top: 12,
              }}
            >
              Super Admin
            </Tag>
          </div>
          {NAV_ITEMS.map((section, i) => {
            return (
              <div key={section.title}>
                <SectionTitle style={{ marginTop: i === 0 ? 24 : 40 }}>{section.title}</SectionTitle>
                <Menu
                  theme="dark"
                  mode="inline"
                  selectedKeys={[location.pathname]}
                  onClick={({ key, keyPath, domEvent }) => {
                    const item = section.items.find((item) => item.link === key);
                    if (item?.link) {
                      history.push(item?.link);
                    }
                  }}
                >
                  {section?.items?.map((item, i) => {
                    return (
                      <Menu.Item key={item.link} icon={item.icon}>
                        {item.title}
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
            );
          })}
        </Sider>
        <Layout>
          <Header style={{ padding: 0 }} />
          <Content style={{ margin: "0 16px" }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center", color: "#999" }}>
            © {new Date().getFullYear()} {config.companyName} All rights reserved.
          </Footer>
        </Layout>
      </Layout>
    </Container>
  );
}
