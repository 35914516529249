// TOP LEVEL IMPORTS
import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// // COMPONENTS
import ForgotPasswordForm from "./ForgotPasswordForm";
import { CheckCircleFilled } from "@ant-design/icons";
import Caption from "components/text/Caption";
import logo from "lib/media/luumen-logo.png";
import { useSendResetPasswordEmailMutation } from "generated/graphql";
import ErrorBlock from "components/common/ErrorBlock";
import ItemTitle from "components/text/ItemTitle";

const Background = styled.div`
  width: 100%;
`;

const TitleContainer = styled.div`
  min-height: 95px;
`;

const Logo = styled.img`
  height: 50px;
  margin: auto;
  margin-bottom: 16px;
`;

const FormContainer = styled.div`
  width: 520px;
  padding: 16px;
  text-align: center;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  padding-top: 20px;
  padding: 32px 64px;
  border-radius: 5px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  height: 100vh;
`;

const GreenSuccess = styled(CheckCircleFilled)`
  color: ${(p) => p.theme.colors.primary6};
  font-size: 48px;
  margin-bottom: 16px;
`;

// STYLED-COMPONENTS
// ========================================
const SuccessCard = () => (
  <div style={{ height: 257 }}>
    <div style={{ textAlign: "center" }}>
      <GreenSuccess />
      <ItemTitle>Please Check Your Email!</ItemTitle>
      <h3 style={{ textAlign: "center", margin: 0, color: "#888" }}>
        A password reset link has been sent to your email
      </h3>
    </div>
  </div>
);

export default function ForgotPassword() {
  const [errors, setErrors] = useState<string[] | []>([]);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [sendResetPasswordEmailMutation, { loading }] =
    useSendResetPasswordEmailMutation();

  const onSucessFullyValidated = async (email) => {
    try {
      await sendResetPasswordEmailMutation({
        variables: {
          email,
        },
      });

      setEmailSent(true);
      //   message.success('A recovery email has been sent to your inbox!', 4);
    } catch (err: any) {
      console.log(err);
      let errMessage = err.message.replace("GraphQL", "");
      if (err && err.message.includes("Incorrect password [403]")) {
        errMessage = "You have entered an invalid username or password";
      }
      return setErrors([errMessage]);
    }
  };
  return (
    <Background>
      <Container>
        <FormContainer>
          {!emailSent ? (
            <>
              <TitleContainer>
                <Logo src={logo} />
                <Caption style={{ width: "90%", margin: "auto" }}>
                  Recover your password
                </Caption>
              </TitleContainer>
              <div>
                <ForgotPasswordForm
                  loading={loading}
                  onSucessFullyValidated={onSucessFullyValidated}
                />
                {errors?.[0] && <ErrorBlock errors={errors} />}
                <Caption style={{ marginTop: 16 }}>
                  <Link to="/login">Login</Link>
                </Caption>
              </div>
            </>
          ) : (
            <SuccessCard />
          )}
        </FormContainer>
      </Container>
    </Background>
  );
}
