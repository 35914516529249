import DarkTable from "components/common/DarkTable";
import Loading from "components/common/Loading";
import { useState } from "react";
import {
  GetManyInstancesDocument,
  GetManyInstancesQueryVariables,
  Instance,
  InstanceStateEnum,
  useCreateOneInstanceMutation,
  useGetManyInstancesQuery,
  useGetOneInstance_ShortQuery,
  useRefreshInstanceDataMutation,
  useUpdateOneInstanceMutation,
} from "generated/graphql";
import { EditOutlined, SyncOutlined, CloudUploadOutlined } from "@ant-design/icons";
import environmentTypeHashmap from "lib/helpers/environmentTypeHashmap";
import Text from "components/text/Text";
import StatusCircle from "components/common/StatusCircle";
import Caption from "components/text/Caption";
import styled from "styled-components";
import Button from "components/common/Button";
import message from "components/common/message";
import UploadInstanceModal from "./UploadInstanceModal";
import Drawer from "components/common/Drawer";
import { DocumentNode } from "graphql";
import InstanceForm, { InstanceFormModeEnum } from "components/forms/InstanceForm";
import TruncateText from "components/common/TruncateText";
// LIB
import theme from "lib/theme";

const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EditInstanceForm = ({ onClose, instanceId }) => {
  const [updateOneInstanceMutation, { loading: saving }] = useUpdateOneInstanceMutation();
  const { data, loading, error } = useGetOneInstance_ShortQuery({
    variables: {
      instanceId,
    },
    skip: !instanceId,
  });

  if (loading) return <Loading />;

  if (error) return <p>Error</p>;

  const onSaveChanges = async (values) => {
    try {
      await updateOneInstanceMutation({
        variables: {
          id: instanceId,
          params: {
            name: values?.name,
            customDeviceId: values?.customDeviceId,
            instanceType: values?.instanceType,
            resourceGroup: values?.resourceGroup,
            pattern: values?.pattern,
            description: values?.description,
            dynatraceHostId: values?.dynatraceHostId,
          },
        },
      });
      onClose();
      message.success("Instance was updated");
    } catch (err: any) {
      message.error(err?.message);
      console.log(err);
    }
  };

  return (
    <InstanceForm
      onSubmit={onSaveChanges}
      onCancel={onClose}
      loading={saving}
      mode={InstanceFormModeEnum.editing}
      defaultValues={{
        name: data?.getOneInstance?.name,
        customDeviceId: data?.getOneInstance?.customDeviceId,
        instanceType: data?.getOneInstance?.instanceType,
        resourceGroup: data?.getOneInstance?.resourceGroup,
        pattern: data?.getOneInstance?.pattern,
        description: data?.getOneInstance?.description,
        dynatraceHostId: data?.getOneInstance?.dynatraceHostId,
      }}
    />
  );
};

export default function Instances({ organizationId }) {
  const [createOneInstanceMutation, { loading: creating }] = useCreateOneInstanceMutation();
  const [showUploader, setShowUploader] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [editing, setEditing] = useState<false | string>(false); // is either false or holds the id of the instance you're tryign to edit
  const { data, loading } = useGetManyInstancesQuery({
    variables: {
      organizationId,
    },
  });

  const [refreshInstanceDataMutation, { loading: refreshing }] = useRefreshInstanceDataMutation({});

  if (loading) return <Loading />;

  const onCloseDrawer = () => {
    setAddNew(false);
    setEditing(false);
  };

  const columns = [
    {
      key: "id",
      title: "ID",
      render: (item: Instance) => (
        <div style={{ width: "100%" }}>
          <TruncateText text={item.externalId}>
            <Text>{item.externalId}</Text>
          </TruncateText>
        </div>
      ),
    },
    {
      title: "name",
      render: (item: Instance) => (
        <div style={{ width: "100%" }}>
          <TruncateText text={item.name}>
            <Text>{item?.name?.trim()}</Text>
          </TruncateText>
          {item.description && (
            <TruncateText text={item.description}>
              <Caption>{item.description}</Caption>
            </TruncateText>
          )}
        </div>
      ),
    },
    {
      title: "Availability",
      render: (item: Instance) => (
        <Center>
          <StatusCircle isRunning={item?.state === InstanceStateEnum.Running ? true : false} fixed={false} />
        </Center>
      ),
    },
    // {
    //   title: "Production",
    //   render: (item: Instance) => (
    //     <Center>
    //       <StatusCircle
    //         isRunning={false}
    //         fixed={false}
    //       />
    //     </Center>
    //   ),
    // },
    {
      title: "App/Env",
      render: (item: Instance) => {
        return (
          <div>
            {item?.application?.name && (
              <TruncateText text={item?.application?.name}>
                <Text>{item?.application?.name}</Text>
              </TruncateText>
            )}
            {item?.environment?.name && (
              <TruncateText text={item?.environment?.name}>
                <Caption>{item?.environment?.name || environmentTypeHashmap[item?.environment?.environmentType as string]}</Caption>
              </TruncateText>
            )}
          </div>
        );
      },
    },
    {
      title: "System",
      render: (item: Instance) => (
        <Center>
          <Text>{item.instanceType}</Text>
        </Center>
      ),
    },
    {
      title: "",
      render: (item: Instance) => (
        <Center>
          <EditOutlined style={{ color: theme.colors.primary4 }} onClick={() => setEditing(item.id)} />
        </Center>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            grey
            onClick={async () => {
              try {
                const refetchInstances: {
                  query: DocumentNode;
                  variables: GetManyInstancesQueryVariables;
                } = {
                  query: GetManyInstancesDocument,
                  variables: {
                    organizationId,
                  },
                };
                await refreshInstanceDataMutation({
                  variables: {
                    organizationId,
                  },
                  refetchQueries: [refetchInstances],
                });
                message.success("Instance data was updated");
              } catch (err) {
                console.log(err);
              }
            }}
            style={{ marginRight: 16 }}
            disabled={refreshing}
          >
            <SyncOutlined spin={refreshing} style={{ marginRight: 4 }} /> Refresh Instance Data
          </Button>
          <Button grey onClick={() => setShowUploader(true)}>
            <CloudUploadOutlined style={{ marginRight: 4 }} /> Upload Instances
          </Button>
        </div>
        <Button onClick={() => setAddNew(true)}>+ New Instace</Button>
        <UploadInstanceModal
          visible={showUploader}
          onCancel={() => setShowUploader(false)}
          onComplete={() => setShowUploader(false)}
          organizationId={organizationId}
        />
      </div>
      <DarkTable style={{ marginTop: 24 }} defaultPageSize={100} dataSource={data?.getManyInstances} columns={columns} />
      <Drawer
        placement="right"
        width={500}
        onClose={onCloseDrawer}
        visible={editing || addNew ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        {editing && <EditInstanceForm onClose={onCloseDrawer} instanceId={editing} />}
        {addNew && (
          <InstanceForm
            onSubmit={async (values) => {
              try {
                await createOneInstanceMutation({
                  variables: {
                    params: {
                      ...values,
                      organizationId,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GetManyInstancesDocument,
                      variables: {
                        organizationId,
                      },
                    },
                  ],
                });
                onCloseDrawer();
                message.success("Instance successfully added");
              } catch (err: any) {
                message.error(err?.message);
              }
            }}
            onCancel={onCloseDrawer}
            loading={creating}
            mode={InstanceFormModeEnum.creating}
          />
        )}
      </Drawer>
    </div>
  );
}
