import queryString from "query-string";
// COMPONENTS
import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Button from "components/common/Button";
import message from "components/common/message";
import SuperAdminsTable from "./SuperAdminsTable";
import UserForm from "components/forms/UserForm";
// LIB
import useUrlChange from "lib/hooks/useUrlChange";
import {
  useCreateSuperAdminUserMutation,
  UserProfileInput,
  GetManyUsersDocument,
  UserTypeEnum,
} from "generated/graphql";

interface AdminOrganiztionQueryParams {
  addNewUser?: "true";
}

export default function AdminOrganizations({ location }) {
  const { onUrlChange } = useUrlChange();
  const [createSuperAdminUserMutation, { loading: creating }] =
    useCreateSuperAdminUserMutation();
  const { addNewUser }: AdminOrganiztionQueryParams = queryString.parse(
    location.search
  );

  if (addNewUser) {
    return (
      <PageContainer>
        <PageTitle>Create New Apiphani User</PageTitle>
        <UserForm
          loading={creating}
          defaultValues={{ userType: UserTypeEnum.SuperAdmin }}
          onSubmit={async (values: UserProfileInput) => {
            try {
              await createSuperAdminUserMutation({
                variables: {
                  params: {
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    permission: values?.permission,
                    organizationIds: values?.organizationIds,
                    customPermissions: values?.customPermissions,
                  },
                },
                refetchQueries: [
                  {
                    query: GetManyUsersDocument,
                    variables: {
                      userType: UserTypeEnum.SuperAdmin,
                    },
                  },
                ],
              });
              onUrlChange({ addNewUser: undefined });
              message.success("Your new user was created");
            } catch (err: any) {
              console.log(err);
              if (err?.message?.includes("E11000")) {
                message.error("This user already exists");
              }
            }
          }}
          showOrgInput={false}
          onCancel={() => onUrlChange({ addNewUser: undefined })}
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <PageTitle>Apiphani Users</PageTitle>
        <Button onClick={() => onUrlChange({ addNewUser: true })}>
          New Super Admin
        </Button>
      </div>
      <SuperAdminsTable />
    </PageContainer>
  );
}
