import styled from 'styled-components';

const PageSubtitle = styled.h1`
  margin: 0px;
  bottom: 0;
  font-weight: 400;
  font-family: ${(p) => p.theme.fontFamily};
  display: ${(p) => (p?.style?.display ? p?.style?.display : 'block')};
  color: ${(p) => (p.color ? p.color : p.theme.colors.text2)};
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : '16px')};
`;

export default PageSubtitle;
