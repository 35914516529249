import { MetricUnitTypeEnum } from "generated/graphql";
import { AxisDomain } from "recharts/types/util/types";

export default function getYAxisDomain(
  metricType: MetricUnitTypeEnum,
  isAllZero: boolean
): AxisDomain | undefined {
  if (isAllZero) return [0];

  // if this, it's [0,100]
  if (metricType === MetricUnitTypeEnum.Percent) {
    return [0, 100];
  }

  if (metricType === MetricUnitTypeEnum.UpDown) {
    return [1];
  }

  if (metricType === MetricUnitTypeEnum.Ratio) {
    return [0, (dataMax) => dataMax];
  }

  return [0, (dataMax) => dataMax * 1.75];

  // return chart?.metricValues?.[0]?.value === null
  //   ? [0]
  //   : [0, (dataMax) => dataMax * 1.75];
}
