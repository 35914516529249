import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { Invoice, InvoiceLineItem } from "generated/graphql";
import theme from "lib/theme";
import moment from "moment";
import apiphaniLogoPng from "lib/media/apiphani-logo-dark.png";
import formatCurrency from "lib/helpers/formatCurrency";
import { FontFamily, registerPdfFont } from "lib/pdfs";

// Create styles
const s = StyleSheet.create({
  row: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  col: {
    flex: 1,
  },
  text: {
    fontSize: 9,
    marginBottom: 2,
    fontFamily: FontFamily.regular,
  },
  smText: {
    fontSize: 8,
    margin: 0,
    marginBottom: 2,
    color: theme.colors.background,
    fontFamily: FontFamily.regular,
  },
  page: {
    padding: 48,
  },
  whiteText: {
    color: theme.colors.primary10,
    fontSize: 11,
    margin: 0,
    fontFamily: FontFamily.regular,
  },
  smWhiteText: {
    color: "#fff",
    fontSize: 9,
    textTransform: "uppercase",
    fontFamily: FontFamily.regular,
  },
});

const LineItemsHeader = () => (
  <View
    style={{
      ...s.row,
      backgroundColor: theme.colors.primary1,
      marginTop: 32,
      padding: 8,
    }}
  >
    <View
      style={{
        ...s.col,
        width: 20,
      }}
    >
      <Text style={{ ...s.whiteText, fontSize: 10 }}>Unit</Text>
    </View>
    <View
      style={{
        ...s.col,
        flexGrow: 2,
      }}
    >
      <Text style={{ ...s.whiteText, fontSize: 10 }}>Description</Text>
    </View>
    <View
      style={{
        ...s.col,

        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Text style={{ ...s.whiteText, fontSize: 10 }}>Unit Price</Text>
    </View>
    <View
      style={{
        ...s.col,

        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Text style={{ ...s.whiteText, fontSize: 10 }}>Total</Text>
    </View>
  </View>
);

const LineItem = ({ item }: { item: InvoiceLineItem }) => (
  <View
    style={{
      ...s.row,
      marginTop: 8,
      padding: 8,
      borderBottomColor: theme.colors.text2,
      borderBottomWidth: 1,
    }}
  >
    <View
      style={{
        ...s.col,
        width: 20,
      }}
    >
      <Text style={s.text}>{item.sku}</Text>
    </View>
    <View
      style={{
        ...s.col,
        flexGrow: 2,
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Text style={s.text}>{item.description}</Text>
    </View>
    <View
      style={{
        ...s.col,
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Text style={s.text}> {formatCurrency(item.price || "")}</Text>
    </View>
    <View
      style={{
        ...s.col,
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Text style={s.text}>{formatCurrency(item.total || "")}</Text>
    </View>
  </View>
);

const ColoredBlock = ({ invoice }: { invoice: Invoice }) => (
  <View
    style={{
      ...s.row,
      backgroundColor: theme.colors.primary1,
      marginTop: 40,
      height: 40,
      padding: 8,
    }}
  >
    <View
      style={{
        ...s.col,
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
      }}
    >
      <Text style={s.whiteText}>INVOICE # 000{invoice.Invoiceno}</Text>
    </View>
    <View
      style={{
        ...s.col,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
      }}
    >
      <Text style={s.whiteText}>
        {invoice.Invoice_Date &&
          moment(invoice.Invoice_Date)?.format("MMM DD, YYYY")}
      </Text>
    </View>
  </View>
);

const BillToRow = ({ invoice }: { invoice: Invoice }) => (
  <>
    <View
      style={{
        ...s.row,
        marginTop: 24,
        borderBottomColor: theme.colors.text2,
        borderBottomWidth: 1,
      }}
    >
      <View style={{ ...s.col, width: 34 }}>
        <Text style={{ ...s.smText, fontFamily: FontFamily.bold }}>
          BILL TO
        </Text>
      </View>
      <View style={s.col}>
        <Text style={{ ...s.smText, fontFamily: FontFamily.bold }}>
          ATTENTION
        </Text>
      </View>
      <View style={s.col}>
        <Text style={{ ...s.smText, fontFamily: FontFamily.bold }}>
          INSTRUCTIONS
        </Text>
      </View>
      <View style={s.col}>
        <Text style={{ ...s.smText, fontFamily: FontFamily.bold }}>
          SERVICE PERIOD
        </Text>
      </View>
    </View>
    <View
      style={{
        ...s.row,
        marginTop: 8,
      }}
    >
      <View style={{ ...s.col, width: 40 }}>
        <Text style={s.text}>{invoice.organization?.name}</Text>
      </View>
      <View style={s.col}>
        <Text style={s.smText}></Text>
      </View>
      <View style={s.col}>
        <Text style={s.smText}>{invoice.organization?.netTerms}</Text>
      </View>
      <View style={s.col}>
        <Text style={s.smText}>
          {moment(invoice.Invoice_Date)?.startOf("month")?.format("MMMM D")} -{" "}
          {moment(invoice.Invoice_Date)?.endOf("month")?.format("D, YYYY")}
        </Text>
      </View>
    </View>
  </>
);

const PageTop = ({ invoice }: { invoice: Invoice }) => (
  <View style={s.row}>
    <View style={s.col}>
      <Text style={s.text}>{invoice?.billingDetails?.name}</Text>
      <Text style={s.text}>{invoice?.billingDetails?.address1}</Text>
      <Text style={s.text}>{invoice?.billingDetails?.address2}</Text>
      <Text style={s.text}>{invoice?.billingDetails?.phone}</Text>
      <Text style={s.text}>{invoice?.billingDetails?.email}</Text>
    </View>
    <View
      style={{
        ...s.col,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
      }}
    >
      <Image src={apiphaniLogoPng} style={{ width: 100 }} />
    </View>
  </View>
);

const InvoiceTotals = ({ invoice }: { invoice: Invoice }) => {
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          padding: 4,
          borderBottomColor: theme.colors.text2,
          borderBottomWidth: 1,
        }}
      >
        <Text style={s.text}>SUBTOTAL</Text>
        <Text style={s.text}>
          {formatCurrency(invoice?.Invoice_amount || "")}
        </Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          padding: 4,
          borderBottomColor: theme.colors.text2,
          borderBottomWidth: 1,
        }}
      >
        <Text style={s.text}>TAX</Text>
        <Text style={s.text}>N/A</Text>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: 4,
          borderBottomColor: theme.colors.text2,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{ ...s.text, fontFamily: FontFamily.bold }}>
          Total due by{" "}
          {invoice.Invoice_Date &&
            moment(invoice.Invoice_Date)?.format("MMM DD, YYYY")}
        </Text>
        <Text style={s.text}>
          {formatCurrency(invoice?.Invoice_amount || "")}
        </Text>
      </View>
    </>
  );
};

const BillingInformation = ({ invoice }: { invoice: Invoice }) => {
  return (
    <>
      <Text
        style={{
          ...s.text,
          marginBottom: 16,
          fontFamily: FontFamily.bold,
        }}
      >
        BILLING INFORMATION
      </Text>
      <Text style={s.text}>
        Supplier/DBA:{" "}
        <Text style={{ fontFamily: FontFamily.bold }}>
          {invoice?.billingDetails?.name}
        </Text>
      </Text>
      <Text style={s.text}>
        Company EIN:{" "}
        <Text style={{ fontFamily: FontFamily.bold }}>
          {invoice?.billingDetails?.ein}
        </Text>{" "}
      </Text>
      <Text style={{ ...s.text, marginBottom: 16 }}>
        A/R Email:{" "}
        <Text style={{ fontFamily: FontFamily.bold }}>
          {invoice?.billingDetails?.email}
        </Text>{" "}
      </Text>
      <Text style={{ ...s.text, fontFamily: FontFamily.italic }}>Wire to:</Text>
      <Text style={s.text}>
        ACCOUNT: {invoice?.billingDetails?.bankAccount}
      </Text>
      <Text style={s.text}>
        ROUTING: {invoice?.billingDetails?.bankRouting}
      </Text>
      <Text style={s.text}>SWIFT: {invoice?.billingDetails?.bankSwift}</Text>
      <Text
        style={{
          ...s.text,
          fontFamily: FontFamily.italic,
          marginTop: 16,
        }}
      >
        Send Checks to:
      </Text>
      <Text style={{ ...s.text, fontFamily: FontFamily.bold }}>
        {invoice?.billingDetails?.address1}
      </Text>
      <Text style={{ ...s.text, fontFamily: FontFamily.bold }}>
        {invoice?.billingDetails?.address2}
      </Text>
    </>
  );
};

// Create Document Component
export default function InvoicePDF({
  invoice,
  downloading,
}: {
  downloading?: boolean;
  invoice: Invoice;
}) {
  const [showPdf, setShowPdf] = useState(false);

  useEffect(() => {
    registerPdfFont();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowPdf(true);
    }, 200);
  });

  const PdfContent = (
    <Document key={invoice.id}>
      <Page style={s.page}>
        <PageTop invoice={invoice} />
        <ColoredBlock invoice={invoice} />
        <BillToRow invoice={invoice} />
        <LineItemsHeader />
        {invoice?.lineItems?.map((item) => {
          return <LineItem key={item?.id} item={item} />;
        })}
        <View style={{ ...s.row, marginTop: 16 }}>
          <View style={s.col}>
            <Text
              style={{
                ...s.text,
                marginBottom: 4,
                fontFamily: FontFamily.italic,
              }}
            >
              Notes:
            </Text>
            <Text style={s.text}>{invoice?.Invoice_Notes}</Text>
          </View>
        </View>
        <View
          style={{
            ...s.row,
            marginTop: 40,
          }}
        >
          <View
            style={{
              ...s.col,
              borderColor: theme.colors.text2,
              borderWidth: 1,
              marginRight: 24,
              padding: 8,
            }}
          >
            <BillingInformation invoice={invoice} />
          </View>
          <View style={s.col}>
            <InvoiceTotals invoice={invoice} />
          </View>
        </View>
      </Page>
    </Document>
  );

  if (downloading) return PdfContent;

  if (!showPdf) return null;

  return (
    <PDFViewer style={{ width: "100%" }} width="100%" height="600">
      {PdfContent}
    </PDFViewer>
  );
}
