import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import PageSubtitle from "components/text/PageSubtitle";
import {
  SlaDiskUtilResponseItem,
  SlaUptimeItem,
  SlaUptimeSummaryTableItem,
  useGetSla_DiskUtilizationQuery,
  useGetSla_SummaryTableQuery,
  useGetSla_UptimeQuery,
  UptimeTypeEnum,
} from "generated/graphql";
import { CloudDownloadOutlined } from "@ant-design/icons";
import DarkTable from "components/common/DarkTable";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Text from "components/text/Text";
import numeral from "numeral";
import { useState } from "react";
import RangePicker from "components/inputs/RangePicker";
import moment from "moment";
import styled from "styled-components";
import useDownloadAllSlaStats from "./useDownloadAllSlaStats";

const DiskUtil = ({ startDate, endDate, production = false }) => {
  const { data, loading } = useGetSla_DiskUtilizationQuery({
    variables: { startDate, endDate, production },
  });

  const columns = [
    {
      title: "Host",
      render: (record: SlaDiskUtilResponseItem) => <Text>{record.hostDisplayName}</Text>,
    },
    {
      title: "Disk allocated",
      render: (record: SlaDiskUtilResponseItem) => <Text>{numeral(record.totalAllocated)?.format("0 b")}</Text>,
    },
    {
      title: "Disk used",
      render: (record: SlaDiskUtilResponseItem) => <Text>{numeral(record.totalUsed)?.format("0 b")}</Text>,
    },
    {
      title: "% Used",
      render: (record: SlaDiskUtilResponseItem) =>
        record.totalAllocated && record.totalUsed && <Text>{numeral(record.totalUsed / record.totalAllocated)?.format("0%")}</Text>,
    },
  ];
  return <DarkTable rowKey="hostDisplayName" loading={loading} columns={columns} dataSource={data?.getSLA_DiskUtilization} />;
};

const Uptime = ({ startDate, endDate, production = false, uptimeType = UptimeTypeEnum.Host }) => {
  const { data, loading } = useGetSla_UptimeQuery({
    variables: { startDate, endDate, production, uptimeType },
  });
  const columns = [
    {
      title: "Host",
      render: (record: SlaUptimeItem) => <Text>{record.hostDisplayName}</Text>,
    },
    {
      title: "SID",
      render: (record: SlaUptimeItem) => <Text>{record.sid}</Text>,
    },
    {
      title: "Uptime",
      render: (record: SlaUptimeItem) => <Text>{numeral(record.uptime)?.format("0.0")}%</Text>,
    },
    {
      title: "Outage time",
      render: (record: SlaUptimeItem) => null,
    },
    {
      title: "Comments",
      render: (record: SlaUptimeItem) => null,
    },
  ];
  return <DarkTable rowKey="hostDisplayName" loading={loading} columns={columns} dataSource={data?.getSLA_Uptime} />;
};

const UptimeSummaryTable = ({ startDate, endDate }) => {
  const { data, loading } = useGetSla_SummaryTableQuery({
    variables: { startDate, endDate },
  });
  const columns = [
    {
      title: "SID",
      render: (record: SlaUptimeSummaryTableItem) => <Text>{record.sid}</Text>,
    },
    {
      title: "Host",
      render: (record: SlaUptimeSummaryTableItem) => <Text>{record.hostDisplayName}</Text>,
    },

    {
      title: "SAP %",
      render: (record: SlaUptimeSummaryTableItem) => record.sap && <Text>{numeral(record.sap)?.format("0.0")}%</Text>,
    },
    {
      title: "DB %",
      render: (record: SlaUptimeSummaryTableItem) => record.db && <Text>{numeral(record.db)?.format("0.0")}%</Text>,
    },
    {
      title: "Host %",
      render: (record: SlaUptimeSummaryTableItem) => record.host && <Text>{numeral(record.host)?.format("0.0")}%</Text>,
    },
    {
      title: "Comments",
      render: (record: SlaUptimeSummaryTableItem) => null,
    },
  ];
  return <DarkTable rowKey="hostDisplayName" loading={loading} columns={columns} dataSource={data?.getSLA_SummaryTable} />;
};

const BlockConainer = styled.div`
  min-height: 320px;
  margin-bottom: 16px;
`;

export default function ClientSla() {
  const [dates, setDates] = useState<{ startDate: number; endDate: number }>({
    startDate: moment().subtract(1, "month")?.startOf("month")?.valueOf(),
    endDate: moment().subtract(1, "month")?.endOf("month")?.valueOf(),
  });

  const [onDownloadSlaData] = useDownloadAllSlaStats();

  return (
    <PageContainer>
      <div style={{ marginTop: 40, marginBottom: 32, display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <PageTitle style={{ minWidth: 250 }}>SLA Report</PageTitle>
          <RangePicker defaultValue={dates} onChange={(newDates) => setDates({ ...dates, ...newDates })} />
        </div>
        <Button secondary onClick={async () => await onDownloadSlaData({ startDate: dates?.startDate, endDate: dates?.endDate })}>
          <CloudDownloadOutlined /> Download CSV
        </Button>
      </div>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Production Uptime (Hosts)</PageSubtitle>
            <Uptime startDate={dates?.startDate} endDate={dates?.endDate} production />
          </BlockConainer>
        </Col>
        <Col xs={24} sm={12}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Non-Production Uptime (Hosts)</PageSubtitle>
            <Uptime startDate={dates?.startDate} endDate={dates?.endDate} />
          </BlockConainer>
        </Col>
        <Col xs={24} sm={12}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Production Uptime (SAP)</PageSubtitle>
            <Uptime startDate={dates?.startDate} endDate={dates?.endDate} production uptimeType={UptimeTypeEnum?.Sap} />
          </BlockConainer>
        </Col>
        <Col xs={24} sm={12}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Non-Production Uptime (SAP)</PageSubtitle>
            <Uptime startDate={dates?.startDate} endDate={dates?.endDate} uptimeType={UptimeTypeEnum?.Sap} />
          </BlockConainer>
        </Col>
        <Col xs={24} sm={12}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Production Disk Utilization</PageSubtitle>
            <DiskUtil startDate={dates?.startDate} endDate={dates?.endDate} production />
          </BlockConainer>
        </Col>
        <Col xs={24} sm={12}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Non-Production Disk Utilization</PageSubtitle>
            <DiskUtil startDate={dates?.startDate} endDate={dates?.endDate} />
          </BlockConainer>
        </Col>
        <Col xs={24}>
          <BlockConainer>
            <PageSubtitle style={{ marginBottom: 8 }}>Summary Table</PageSubtitle>
            <UptimeSummaryTable startDate={dates?.startDate} endDate={dates?.endDate} />
          </BlockConainer>
        </Col>
      </Row>
    </PageContainer>
  );
}
