import ItemTitle from 'components/text/ItemTitle';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Caption from 'components/text/Caption';
import { PoweroffOutlined } from '@ant-design/icons';
import theme from 'lib/theme';

const AppCardContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.colors.background2};
  min-height: 240px;
  padding: 24px;
  margin-bottom: 24px;
`;

const InstanceTag = styled.button`
  border: 0px;
  background: ${({ theme }) => theme.colors.neutral8};
  color: ${({ theme }) => theme.colors.neutral2};
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.text};
    background: ${({ theme }) => theme.colors.text2};
  }
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 5px;
  display: block;
  margin-bottom: 16px;
`;

export default function ApplicationCard({ app }) {
  const history = useHistory();
  return (
    <AppCardContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <PoweroffOutlined
          style={{ marginRight: 16, fontSize: 40, color: theme.colors.text }}
        />
        <div>
          <ItemTitle>{app.name}</ItemTitle>
          <Caption>{app.name}</Caption>
        </div>
      </div>
      <div style={{ marginTop: 32 }}>
        {app?.instances?.map((instance) => {
          return (
            <InstanceTag
              key={instance.id}
              onClick={() => history.push(`/app/instances/${instance.id}`)}
            >
              {instance.name}
            </InstanceTag>
          );
        })}
      </div>
    </AppCardContainer>
  );
}
