import { useState } from "react";
import Text from "components/text/Text";
import { RelativeTimeEnum, useGetHanaDbMetricsQuery } from "generated/graphql";
import styled from "styled-components";
import Row from "components/common/Row";
import Chart from "components/common/Chart";
import Col from "components/common/Col";
import PageSubtitle from "components/text/PageSubtitle";
import Caption from "components/text/Caption";
import { InfoCircleFilled } from "@ant-design/icons";
import TimePeriodInput from "components/inputs/TimePeriodInput";
import Skeleton from "components/common/Skeleton";
import config from "lib/config";

const ChartContainer = styled.div`
  padding: 16px 32px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.background2};
  margin-top: 16px;
  position: relative;
`;

export const LoadingCards = ({ number = 6 }) => {
  const arr = Array.from({ length: number });

  return (
    <Row gutter={16}>
      {arr?.map((item, index) => {
        return (
          <Col xs={8} key={index}>
            <ChartContainer>
              <Skeleton paragraph={{ rows: 6 }} />
            </ChartContainer>
          </Col>
        );
      })}
    </Row>
  );
};

const YAxisText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 300;
  height: 8px;
  transform-origin: left top;
  transform: rotate(270deg) translateX(-100%);
  position: absolute;
  left: 6px;
  top: 115px;
`;

export default function DBMetrics({ instance }) {
  const [relativeTime, setRelativeTime] = useState<RelativeTimeEnum>(RelativeTimeEnum.Day);
  const { data, loading, error } = useGetHanaDbMetricsQuery({
    variables: {
      instanceId: instance.id,
      relativeTime,
    },
    skip: !instance.customDeviceId,
  });
  // show loader
  if (loading) {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Caption>
            <InfoCircleFilled
              style={{
                marginRight: 4,
                fontSize: 12,
                position: "relative",
                top: -1,
                textAlign: "right",
              }}
            />
            DB metrics coming from {instance?.customDeviceId?.[0] ? "Dynatrace" : instance?.vendor}
          </Caption>
          <div style={{ width: 150 }}>
            <TimePeriodInput value={relativeTime} onChange={(newValue: RelativeTimeEnum) => setRelativeTime(newValue)} />
          </div>
        </div>
        <LoadingCards />
      </>
    );
  }
  if (!instance.customDeviceId) {
    return <Text>No DB metrics for this instance...</Text>;
  }
  // show an error
  if (error) return <Text>Something went wrong...</Text>;
  // show an empty state
  if (!loading && !data?.getHanaDbMetrics?.[0]) {
    return <Text>No DB metrics for this instance...</Text>;
  }

  return (
    <>
      {data?.getHanaDbMetrics?.[0] && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Caption>
            <InfoCircleFilled
              style={{
                marginRight: 4,
                fontSize: 12,
                position: "relative",
                top: -1,
                textAlign: "right",
              }}
            />
            DB metrics coming from {instance?.customDeviceId?.[0] ? "Dynatrace" : instance?.vendor}
          </Caption>
          <div style={{ width: 170 }}>
            <TimePeriodInput value={relativeTime} onChange={(newValue: RelativeTimeEnum) => setRelativeTime(newValue)} />
          </div>
        </div>
      )}
      <Row gutter={16}>
        {data?.getHanaDbMetrics?.map((chart, index) => {
          const color = config.chartColors[index];
          return (
            <Col xs={8} key={chart?.metricName}>
              <ChartContainer>
                <YAxisText>{chart?.metricType}</YAxisText>

                <PageSubtitle style={{ marginBottom: 16 }}>{chart?.metricName?.trim()}</PageSubtitle>
                <div style={{ position: "relative", left: -24 }}>
                  <Chart
                    title={chart?.metricName?.trim()}
                    data={chart?.metricValues}
                    color={color}
                    relativeTime={relativeTime}
                    metricType={chart?.metricType}
                  />
                </div>
              </ChartContainer>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
