import queryString from "query-string";
import Loading from "components/common/Loading";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Tabs from "components/common/Tabs";
import DarkTable from "components/common/DarkTable";
import {
  BusinessDocument,
  BusinessDocumentTypeEnum,
  useGetBusinessDocumentsByOrganizationQuery,
} from "generated/graphql";
import { toString } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import TruncateMarkup from "react-truncate-markup";
import Caption from "components/text/Caption";
import Text from "components/text/Text";
import Tooltip from "components/common/Tooltip";
import BackButton from "components/common/BackButton";
import { useState } from "react";

const { TabPane } = Tabs;

const LIMIT = 5;

export default function DocumentsTable({
  organizationId,
}: {
  organizationId: string | undefined | null;
}) {
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });
  const history = useHistory();
  const location = useLocation();
  const { tab, subfolder } = queryString.parse(location.search);
  const { data, loading, error } = useGetBusinessDocumentsByOrganizationQuery({
    variables: {
      organizationId: toString(organizationId),
    },
    skip: !organizationId,
    onCompleted: (data) => {
      if (!tab && data?.getBusinessDocumentsByOrganization?.[0]?.id) {
        onTabChange({ tab: data?.getBusinessDocumentsByOrganization?.[0]?.id });
      }
    },
  });

  const onTabChange = (newValues: object) => {
    const obj = queryString.stringify({
      ...queryString.parse(location.search),
      ...newValues,
    });
    history.replace(`${location.pathname}?${obj}`);
  };

  if (!organizationId) return <Text>No organization ID was provided...</Text>;

  if (loading) return <Loading />;

  if (error)
    return (
      <Text>
        Something went wrong getting your documents... please check back later.
        If the problem persists, reach out to support.
      </Text>
    );

  if (data?.getBusinessDocumentsByOrganization?.length === 0)
    return <Text>We could not find documents for this organization</Text>;

  const dataSource = data?.getBusinessDocumentsByOrganization?.filter(
    (category) => category?.id === tab
  )?.[0]?.documents;

  const subfolders = dataSource?.filter(
    (document) => document?.id === subfolder
  )?.[0]?.documents;

  const columns = [
    {
      key: "id",
      title: "Name",
      render: ({ name }) => (
        <Tooltip title={name}>
          <div>
            <TruncateMarkup lines={1}>
              <Text>{name}</Text>
            </TruncateMarkup>
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Last Modified",
      render: ({ lastModified }) => <Caption>{lastModified}</Caption>,
    },
    {
      title: "Size",
      render: ({ size }) => <Caption>{size}</Caption>,
    },
    {
      title: "Type",
      render: ({ type }) => <Caption>{type}</Caption>,
    },
  ];

  return (
    <>
      <Row style={{ marginTop: 40 }}>
        <Col xs={4}>
          <Tabs
            onChange={(key) => onTabChange({ tab: key })}
            style={{ marginTop: 24 }}
            activeKey={tab as string}
            tabPosition="left"
          >
            {data?.getBusinessDocumentsByOrganization?.map((category) => {
              return <TabPane tab={category?.name} key={category?.id} />;
            })}
          </Tabs>
        </Col>
        <Col xs={20}>
          <div style={{ position: "relative", top: -24 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                height: 16,
              }}
            >
              {subfolder && (
                <>
                  <BackButton
                    onClick={() => onTabChange({ subfolder: undefined })}
                  />
                  <Text>
                    <Caption
                      style={{ display: "inline-block", marginRight: 8 }}
                    >
                      PROJECT:
                    </Caption>
                    <strong>{subfolder}</strong>
                  </Text>
                </>
              )}
            </div>
            <DarkTable
              style={{ marginTop: 24 }}
              loading={loading}
              dataSource={!subfolder ? dataSource : subfolders}
              columns={columns}
              onChange={(pagination: any, filters: any, sorter: any) => {
                setFilters({
                  ...filters,
                  current: pagination?.current,
                  limit: pagination?.pageSize,
                  skip: pagination?.pageSize * (pagination.current - 1),
                });
              }}
              pagination={{
                pageSize: filters.limit,
                current: filters.current,
                total: dataSource?.length || 0,
              }}
              onRow={(record: BusinessDocument) => {
                return {
                  onClick: () => {
                    if (record?.type === BusinessDocumentTypeEnum.Directory) {
                      // do something
                      return onTabChange({ subfolder: record?.id });
                    }
                    if (record?.type === BusinessDocumentTypeEnum.File) {
                      console.log("this ran");
                      let link = document.createElement("a");
                      link.setAttribute("download", record?.name || "");
                      link.setAttribute("href", record?.url || "");
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }
                  },
                };
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
