import passwordSchema from 'lib/helpers/passwordSchema';

export default function useCheckPasswordErrors() {
    const checkPasswordErrors = (password: string): string | null => {
        let errors: any = passwordSchema.validate(password, { list: true });
        if (errors?.length > 0) {
            if (errors?.includes('min')) {
                return 'Minimum of 8 characters';
            }
            if (errors?.includes('uppercase')) {
                return 'Requires an uppercase letter';
            }
            if (errors?.includes('digits')) {
                return 'Requires at least one number';
            }
            if (errors?.includes('oneOf')) {
                return 'Can not be Password123';
            }
        }
        return null
    };

    return { checkPasswordErrors, passwordSchema };
}