import { OrganizationFragmentFragment, useDeactivateOrganizationMutation, useUpdateOneOrganizationMutation } from "generated/graphql";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Button from "components/common/Button";
import OrganizationForm from "components/forms/OrganizationForm";
import { useState } from "react";
import message from "components/common/message";
import DataItem from "components/common/DataItem";
import FormSectionBreak from "components/common/FormSectionBreak";
import Modal, { ModalButtonRow } from "components/common/Modal";
import TextInput from "components/inputs/TextInput";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import { useHistory } from "react-router-dom";

export default function Details({ organization }: { organization: OrganizationFragmentFragment }) {
  const [updateOneOrganizationMutation, { loading: updating }] = useUpdateOneOrganizationMutation();
  const history = useHistory();
  const [editing, setEditing] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeactivateText, setConfirmDeactivateText] = useState<string | undefined>(undefined);

  const [deactivateOrganizationMutation, { loading: deactivating }] = useDeactivateOrganizationMutation();

  const onConfirmDeactivate = async () => {
    try {
      if (confirmDeactivateText !== organization.name) return;
      await deactivateOrganizationMutation({
        variables: {
          organizationId: organization.id,
        },
        refetchQueries: ["getManyOrganizations"],
      });
      setConfirmDeactivateText(undefined);
      setConfirmDelete(false);
      message.success(`${organization.name} has been deactivated`);
      history.push(`/admin/organizations`);
    } catch (err) {
      console.log(err);
    }
  };

  if (editing) {
    return (
      <OrganizationForm
        onCancel={() => setEditing(false)}
        onSubmit={async (values) => {
          try {
            if (!organization?.id) return;
            await updateOneOrganizationMutation({
              variables: {
                id: organization.id,
                params: {
                  name: values?.name,
                  billingId: values?.billingId,
                  customer_tla: values?.customer_tla,
                  backupHistoryLocation: values?.backupHistoryLocation,
                  backupHistoryBucketName: values?.backupHistoryBucketName,
                  documentsLocation: values?.documentsLocation,
                  documentsBucketName: values?.documentsBucketName,
                  configurationType: values?.configurationType,
                  settings: values?.settings,
                  apmService: values?.apmService,
                  apmApiUrl: values?.apmApiUrl,
                  cmdbBucketName: values?.cmdbBucketName,
                  cmdbBucketLocation: values?.cmdbBucketLocation,
                  ticketingService: values?.ticketingService,
                  // only update the smanage key if
                  apmApiKey: values?.apmApiKey?.length > 1 ? values?.apmApiKey : undefined,
                  // only update the smanage key if
                  ticketingServiceApiKey: values?.ticketingServiceApiKey?.length > 1 ? values?.ticketingServiceApiKey : undefined,
                },
              },
            });
            setEditing(false);
            message.success("Your changes were saved");
          } catch (err: any) {
            message.error(err?.message);
          }
        }}
        loading={updating}
        defaultValues={{
          name: organization?.name,
          billingId: organization?.billingId,
          customer_tla: organization?.customer_tla,
          backupHistoryLocation: organization?.backupHistoryLocation,
          backupHistoryBucketName: organization?.backupHistoryBucketName,
          documentsLocation: organization?.documentsLocation,
          documentsBucketName: organization?.documentsBucketName,
          configurationType: organization?.configurationType,
          settings: organization?.settings,
          apmApiKey: null,
          cmdbBucketName: organization?.cmdbBucketName,
          cmdbBucketLocation: organization?.cmdbBucketLocation,
          apmService: organization?.apmService,
          apmApiUrl: organization?.apmApiUrl || null,
          ticketingService: organization?.ticketingService || null,
        }}
      />
    );
  }

  return (
    <Row>
      <Col xs={16}>
        <FormSectionBreak title="General" caption="basic details about this organization" />
        <Row>
          <Col xs={12}>
            <DataItem label="ID" value={organization?.id} />
          </Col>
          <Col xs={12}>
            <DataItem label="Name" value={organization?.name} />
          </Col>
          <Col xs={12}>
            <DataItem label="Billing ID" value={organization?.billingId} />
          </Col>
          <Col xs={12}>
            <DataItem label="TLA" value={organization?.customer_tla} />
          </Col>
        </Row>
        <FormSectionBreak title="Features" caption="Which features are toggled on or off for this organization" />
        <Row>
          {Object.keys(organization?.settings?.features || {})
            ?.filter((f) => f !== "__typename")
            ?.map((feature) => {
              return (
                <Col key={feature} xs={12}>
                  <DataItem
                    label={feature
                      ?.replace(/([A-Z])/g, " $1")
                      ?.replace(/^./, (str) => str.toUpperCase())
                      ?.trim()}
                    value={organization?.settings?.features?.[feature] ? "ON" : "OFF"}
                  />
                </Col>
              );
            })}
        </Row>
        <FormSectionBreak title="Configuration Options" caption="Details about how this organization's options are configured" />
        <div style={{ display: "flex" }}>
          <DataItem label="Ticketing Service" value={organization?.ticketingService} style={{ width: 350 }} />

          <DataItem label="Ticketing API Key" value={organization?.ticketingApiKeyExists} />
        </div>
        <div style={{ display: "flex" }}>
          <DataItem label="Backup history bucket" value={organization?.backupHistoryBucketName} style={{ width: 350 }} />
          <DataItem label="Backup location" value={organization?.backupHistoryLocation} />
        </div>

        <div style={{ display: "flex" }}>
          <DataItem label="Documents bucket" value={organization?.documentsBucketName} style={{ width: 350 }} />
          <DataItem label="Documents location" value={organization?.documentsLocation} />
        </div>
        <div style={{ display: "flex" }}>
          <DataItem label="CMDB location" value={organization?.cmdbBucketLocation} style={{ width: 350 }} />
          <DataItem label="CMDB Bucket Name" value={organization?.cmdbBucketName} />
        </div>
        <div style={{ display: "flex" }}>
          <DataItem label="APM Service" value={organization?.apmService} style={{ width: 350 }} />
          <DataItem label="APM API URL" value={organization?.apmApiUrl} />
        </div>
        <div style={{ display: "flex" }}>
          <DataItem label="APM API Key" value={organization?.apmApiKeyExists} />
        </div>
      </Col>
      <Col xs={8}>
        <Button style={{ marginRight: 24 }} danger onClick={() => setConfirmDelete(true)}>
          Deactivate
        </Button>
        <Button onClick={() => setEditing(true)}>Edit Details</Button>
      </Col>
      <Modal onCancel={() => setConfirmDelete(false)} visible={confirmDelete}>
        <Text style={{ marginTop: 16 }}>Are you sure you want to deactivate this organization?</Text>
        <Caption style={{ marginTop: 8 }}>Please type the name of the organization to confirm.</Caption>
        <TextInput
          onChange={(e) => setConfirmDeactivateText(e.target.value)}
          style={{ marginTop: 24 }}
          placeholder={`Please type "${organization.name}" here...`}
        />
        <ModalButtonRow>
          <Button onClick={() => setConfirmDelete(false)} grey style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button onClick={() => onConfirmDeactivate()} loading={deactivating} disabled={confirmDeactivateText !== organization?.name?.trim()}>
            Deactivate
          </Button>
        </ModalButtonRow>
      </Modal>
    </Row>
  );
}
