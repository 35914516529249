import PageTitle from "components/text/PageTitle";
import Caption from "components/text/Caption";
import PageContainer from "components/common/PageContainer";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Loading from "components/common/Loading";
import ApplicationCard from "components/common/ApplicationCard";
import { useGetManyApplicationsQuery } from "generated/graphql";

export default function ClientApplications() {
  const { data, loading, error } = useGetManyApplicationsQuery();

  if (loading) return <Loading />;

  if (error) return <>error</>;

  return (
    <PageContainer>
      <PageTitle>Applications</PageTitle>
      <Caption>
        Last metrics data update on Tuesday, June 8th 2021 02:20:02 pm
      </Caption>
      <Row gutter={24} style={{ marginTop: 40 }}>
        {data?.getManyApplications?.map((app) => {
          if (!app?.id) return null;
          return (
            <Col xs={8} key={app.id}>
              <ApplicationCard app={app} />
            </Col>
          );
        })}
      </Row>
    </PageContainer>
  );
}
