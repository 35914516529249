import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import {
  useGetVpnMetricsQuery,
  useGetVpNsQuery,
  VpnDataItem,
  RelativeTimeEnum,
  MetricUnitTypeEnum,
} from "generated/graphql";
import Loading from "components/common/Loading";
import VpnPreviewCard from "components/common/VpnPreviewCard";
// COMPONETS
import Row from "components/common/Row";
import Col from "components/common/Col";
import { toString } from "lodash";
import Chart from "components/common/Chart";

import PageSubtitle from "components/text/PageSubtitle";
import theme from "lib/theme";

// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';

// var backcolor = '#131313';
// var color = '#E0E0E3';
// var gridColor = '#303030';

// const Chart = ({ data, title }) => {
//   const options: any = {
//     title: {
//       text: title,
//     },
//     series: [
//       {
//         type: 'line',
//         data: data || [1, 2, 3, 5, 7, 4, 3, 9],
//       },
//     ],
//     yAxis: {
//       gridLineColor: gridColor,
//       tickColor: gridColor,
//       labels: {
//         style: {
//           color,
//         },
//       },
//     },
//     legend: {
//       itemStye: {
//         color,
//       },
//     },
//   };
//   return <HighchartsReact highcharts={Highcharts} options={options} />;
// };

/**
 * chartist
 * highcharts
 * highcharts-more
 * react-chartist
 * react-highcharts / Charts.HighchartBasicLine
 * recharts
 * react-sparklines
 * react-timeseries-charts / recharts / pondjs
 */

//  "resolutions": {
//   "pondjs/moment": "^2.29.1",
//   "pretty-error": "^3.0.3",
//   "antd/rc-picker/moment": "^2.29.1",
//   "react-timeseries-charts/moment": "^2.29.1"
// },

const VpnRow = ({ item }: { item: VpnDataItem }) => {
  const { data, loading } = useGetVpnMetricsQuery({
    variables: {
      organizationId: toString(item.organizationId),
      tunnelIpAddress: toString(item.ipAddress),
    },
    skip: !item.ipAddress || !item.organizationId,
  });
  const data1 = data?.getVpnMetrics?.[0];
  const data2 = data?.getVpnMetrics?.[1];
  const data3 = data?.getVpnMetrics?.[2];

  return (
    <Row gutter={8} style={{ marginTop: 56 }} key={item.id}>
      <Col xs={6}>
        <VpnPreviewCard
          key={item.id}
          item={item}
          onClick={() => {}}
          showIpAddress
          showLastUpdated
        />
      </Col>
      <Col xs={6}>
        {!loading && data1 ? (
          <>
            <PageSubtitle
              style={{
                marginBottom: 8,
                marginLeft: 56,
                textAlign: "center",
                color: theme.colors.text,
              }}
            >
              Availability
            </PageSubtitle>
            <Chart
              title={data1.label?.replace(/([A-Z])/g, " $1").trim()}
              data={data1?.datapoints?.map((item) => {
                return {
                  ...item,
                  value: item?.value === 1 ? 100 : item?.value,
                };
              })}
              metricType={MetricUnitTypeEnum.Percent}
              relativeTime={RelativeTimeEnum.Hour}
              yAxisTicks={[0, 100]}
              yAxisDomain={[0, 125]}
            />
          </>
        ) : (
          <Loading height={100} />
        )}
      </Col>
      <Col xs={6}>
        {!loading ? (
          <>
            <PageSubtitle
              style={{
                marginBottom: 8,
                marginLeft: 56,
                textAlign: "center",
                color: theme.colors.text,
              }}
            >
              Traffic In
            </PageSubtitle>
            <Chart
              title={data2?.label
                ?.replace(/([A-Z])/g, " $1")
                .trim()
                ?.replace("Tunnel ", "")}
              data={data2?.datapoints}
              metricType={MetricUnitTypeEnum.Byte}
              relativeTime={RelativeTimeEnum.Hour}
              yAxisDomain={
                data2?.datapoints?.[0]?.value === null
                  ? [0]
                  : ["dataMin", (dataMax) => dataMax * 1.5]
              }
            />
          </>
        ) : (
          <Loading height={100} />
        )}
      </Col>
      <Col xs={6}>
        {!loading && data3 ? (
          <>
            <PageSubtitle
              style={{
                marginBottom: 8,
                marginLeft: 56,
                textAlign: "center",
                color: theme.colors.text,
              }}
            >
              Traffic Out
            </PageSubtitle>
            <Chart
              title={data3?.label?.replace(/([A-Z])/g, " $1").trim()}
              data={data3?.datapoints}
              relativeTime={RelativeTimeEnum.Hour}
              metricType={MetricUnitTypeEnum.Byte}
              yAxisDomain={
                data3?.datapoints?.[0]?.value === null
                  ? [0]
                  : ["dataMin", (dataMax) => dataMax * 1.5]
              }
            />
          </>
        ) : (
          <Loading height={100} />
        )}
      </Col>
    </Row>
  );
};

function VPNs() {
  const { data, loading, error } = useGetVpNsQuery();
  if (loading) return <Loading />;
  if (error) return <div>error</div>;

  return (
    <>
      {data?.getVPNs?.map((item) => {
        if (!item?.id) return null;
        return <VpnRow key={item.id} item={item} />;
      })}
    </>
  );
}

export default function ClientVPNs() {
  return (
    <PageContainer>
      <PageTitle>Link Status</PageTitle>
      <VPNs />
    </PageContainer>
  );
}
