import { ScriptTemplate, Maybe } from "generated/graphql";
import { CustomNodeEdgeTypeEnum } from "pages/admin-script-templates/ScriptTemplateForm/FlowEditor/CustomNode";


export interface FlowTemplate {
    id: string
    name?: string | undefined | Maybe<string>
    description?: string | undefined | Maybe<string>
    elements?: any[]
}

const generateEdge = (sourceId, targetId, type = CustomNodeEdgeTypeEnum.success) => {
    return {
        "source": sourceId,
        "sourceHandle": type,
        "target": targetId,
        "targetHandle": null,
        "type": "buttonedge",
        "animated": true,
        "id": `reactflow__edge-${sourceId}${type}-${targetId}null`
    }
}


export default function mapScriptTemplateToFlow(record: ScriptTemplate): FlowTemplate {
    return {
        id: record.id,
        name: record?.name,
        description: record?.description,
        // elements: []
        elements: record?.steps?.map((step, index) => {
            const nextStep = record?.steps?.[index + 1];
            return [{
                "id": step?.id,
                "type": "selectorNode",
                data: {
                    "label": step?.name,
                    script: step
                },
                "style": {
                    "background": "#303236",
                    "border": "1px solid #2a2b2e",
                    "width": 140,
                },
                position: step?.position || { x: 190 * index, y: 0 },
            },
            // if the record has a next step, then use that
            nextStep ? generateEdge(step?.id, nextStep?.id) : {}
            ]
        })?.flat() || []
    }
}


// {
//     "elements": [
//         {
//             "id": "5e84e35073a68000217a67fa",
//             "type": "selectorNode",
//             "data": {
//                 "label": "StartWiley",
//                 "script": {
//                     "__typename": "ScriptBlock",
//                     "id": "5e84e35073a68000217a67fa",
//                     "name": "StartWiley",
//                     "command": "sudo -u {sidadm} -i /usr/sap/ccms/apmintroscopeEMCtrl.sh start",
//                     "description": "Start Wiley"
//                 }
//             },
//             "style": {
//                 "background": "#303236",
//                 "border": "1px solid #2a2b2e",
//                 "width": 270
//             },
//             "position": {
//                 "x": -15,
//                 "y": 0
//             }
//         },
//         {
//             "id": "6144d274d39670002118205c",
//             "type": "selectorNode",
//             "data": {
//                 "label": "hostname",
//                 "script": {
//                     "__typename": "ScriptBlock",
//                     "id": "6144d274d39670002118205c",
//                     "name": "hostname",
//                     "command": "sudo hostname",
//                     "description": "Echo hostname"
//                 }
//             },
//             "style": {
//                 "background": "#303236",
//                 "border": "1px solid #2a2b2e",
//                 "width": 270
//             },
//             "position": {
//                 "x": 450.00000000000006,
//                 "y": 5
//             }
//         },
//         {
//             "source": "5e84e35073a68000217a67fa",
//             "sourceHandle": "success",
//             "target": "6144d274d39670002118205c",
//             "targetHandle": null,
//             "type": "buttonedge",
//             "animated": true,
//             "id": "reactflow__edge-5e84e35073a68000217a67fasuccess-6144d274d39670002118205cnull"
//         }
//     ]
// }