import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import BillingUnitSelectInput from "components/inputs/BillingUnitSelectInput";
import BillingTypeSelectInput from "components/inputs/BillingTypeSelectInput";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

export enum InstanceKeyFormModeEnum {
  editing = "editing",
  creating = "creating",
}

interface SkuFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  //   mode?: InstanceKeyFormModeEnum;
}

export default function SkuForm({
  onSubmit,
  onCancel,
  loading,
}: //   mode = InstanceKeyFormModeEnum.creating,
SkuFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  //   id
  //   SKU_Code
  //   SKU_desc
  //   Rollup_code
  //   Rollup_desc
  //   Price
  //   Cost
  //   Margin
  //   Billing_unit
  //   Billing_type

  return (
    <Form onSubmit={beforeOnSubmit}>
      <Row gutter={16}>
        <Col xs={8}>
          <FormItem label="SKU Code" required error={errors?.SKU_Code?.message}>
            <TextInput
              {...register("SKU_Code", {
                required: "a code is required.",
              })}
            />
          </FormItem>
        </Col>
        <Col xs={16}>
          <FormItem
            label="SKU Description"
            required
            error={errors?.SKU_desc?.message}
          >
            <TextInput {...register("SKU_desc")} />
          </FormItem>
        </Col>
        <Col xs={8}>
          <FormItem
            label="Rollup Code"
            required
            error={errors?.Rollup_code?.message}
          >
            <TextInput {...register("Rollup_code")} />
          </FormItem>
        </Col>
        <Col xs={16}>
          <FormItem
            label="Rollup Description"
            required
            error={errors?.Rollup_desc?.message}
          >
            <TextInput {...register("Rollup_desc")} />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label="Price" required error={errors?.Price?.message}>
            <TextInput {...register("Price")} />
          </FormItem>
        </Col>
        <Col xs={12}>
          {" "}
          <FormItem label="Cost" required error={errors?.Cost?.message}>
            <TextInput {...register("Cost")} />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            label="Billing Unit"
            required
            error={errors?.Billing_unit?.message}
          >
            <Controller
              name="Billing_unit"
              control={control}
              render={({ field }) => (
                <BillingUnitSelectInput
                  {...field}
                  onChange={(Billing_unit) => field.onChange(Billing_unit)}
                />
              )}
            />{" "}
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            label="Billing Type"
            required
            error={errors?.Billing_type?.message}
          >
            <Controller
              name="Billing_type"
              control={control}
              render={({ field }) => (
                <BillingTypeSelectInput
                  {...field}
                  onChange={(Billing_type) => field.onChange(Billing_type)}
                />
              )}
            />{" "}
          </FormItem>
        </Col>
      </Row>

      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
