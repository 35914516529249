import {
    SupportTicketStateEnum,
} from 'generated/graphql';
import styled from 'styled-components';

const SupportTicketStateTag = styled.div<{ state?: SupportTicketStateEnum | null }>`
  border-radius: 25px;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: .5px;
  font-weight: 400;
  max-width: 100%;
  min-width: 50px;
  background-color: ${({ theme, state }) => {
        if (
            state === SupportTicketStateEnum.Closed ||
            state === SupportTicketStateEnum.ClosedCompleted
        )
            return theme.colors.background;
        if (state === SupportTicketStateEnum.InProgress)
            return theme.colors.yellow10;
        return theme.colors.background;
    }};
  color: ${({ theme, state }) => {
        if (
            state === SupportTicketStateEnum.Closed ||
            state === SupportTicketStateEnum.ClosedCompleted
        )
            return theme.colors.text2;
        if (state === SupportTicketStateEnum.InProgress)
            return theme.colors.yellow2;
        return theme.colors.background;
    }};
`;

export default SupportTicketStateTag
