import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DarkTable from "components/common/DarkTable";
import Caption from "components/text/Caption";
import {
  ScriptBroadcast,
  useGetManyScriptBroadcastsQuery,
} from "generated/graphql";
import { useState } from "react";

const LIMIT = 5;

export default function AdminScriptBroadcasts() {
  const { data } = useGetManyScriptBroadcastsQuery();
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });
  const columns = [
    {
      key: "id",
      title: "name",
      width: 200,
      render: (record: ScriptBroadcast) => record.name,
    },
    {
      title: "Description",
      width: 250,
      render: (record: ScriptBroadcast) => (
        <Caption>{record.description}</Caption>
      ),
    },
    {
      title: "Status",
      render: (record: ScriptBroadcast) => null,
    },
    {
      title: "Last Run",
      render: (record: ScriptBroadcast) => null,
    },
    {
      title: "Duration",
      render: (record: ScriptBroadcast) => null,
    },
    {
      title: "Edit",
      render: (record: ScriptBroadcast) => null,
    },
  ];
  return (
    <PageContainer>
      <PageTitle>Broadcasts</PageTitle>
      <div style={{ marginTop: 24 }}>
        <DarkTable
          dataSource={data?.getManyScriptBroadcasts}
          columns={columns}
          onChange={(pagination: any, filters: any, sorter: any) => {
            setFilters({
              ...filters,
              current: pagination?.current,
              limit: pagination?.pageSize,
              skip: pagination?.pageSize * (pagination.current - 1),
            });
          }}
          pagination={{
            pageSize: filters.limit,
            current: filters.current,
            total: data?.getManyScriptBroadcasts?.length,
          }}
        />
      </div>
    </PageContainer>
  );
}
