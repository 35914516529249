import { Organization, useUpdateOneOrganizationMutation } from "generated/graphql";
import FormSectionBreak from "components/common/FormSectionBreak";
import FormItem from "components/common/FormItem";
import SwitchInput from "components/inputs/SwitchInput";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import message from "components/common/message";

const ReportSettingForm = ({ label, enabled, onSubmit, updating }: any) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      enabled: enabled || false,
    },
  });

  // https://stackoverflow.com/questions/63466463/how-to-submit-react-form-fields-on-onchange-rather-than-on-submit-using-react-ho
  useEffect(() => {
    const subscription: any = watch(handleSubmit(onSubmit) as any);
    return () => subscription?.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);

  return (
    <FormItem label={label} error={errors?.enabled?.message}>
      <Controller
        name="enabled"
        control={control}
        render={({ field }) => (
          <SwitchInput
            {...field}
            loading={updating}
            checked={field?.value}
            defaultChecked={enabled ? true : false}
            onChange={(newvalue) => field.onChange(newvalue)}
          />
        )}
      />
    </FormItem>
  );
};

export default function SlaReports({ organization }: { organization: Organization }) {
  const [updateOneOrganizationMutation, { loading: updating }] = useUpdateOneOrganizationMutation();
  return (
    <>
      {Object.keys(organization?.settings?.reports || {})?.map((key) => {
        if (!organization?.settings?.reports?.[key]?.title) return null;
        console.log(organization?.settings?.reports?.[key]?.title);
        return (
          <div key={key}>
            <FormSectionBreak key={organization?.settings?.reports?.[key]?.title} title={organization?.settings?.reports?.[key]?.title} caption="" />
            <ReportSettingForm
              label="Enabled"
              updating={updating}
              enabled={organization?.settings?.reports?.[key]?.enabled}
              onSubmit={async (values) => {
                try {
                  if (!organization?.id) return;
                  const dataToUpdate = {
                    ...organization?.settings,
                    reports: {
                      ...organization?.settings?.reports,
                      [key]: {
                        enabled: values?.enabled,
                      },
                    },
                  };
                  await updateOneOrganizationMutation({
                    variables: {
                      id: organization.id,
                      params: {
                        settings: dataToUpdate as any,
                      },
                    },
                  });

                  message.success("Your changes were saved");
                } catch (err: any) {
                  message.error(err?.message);
                }
              }}
            />
          </div>
        );
      })}
    </>
  );
}
