import styled from "styled-components";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import { BellOutlined } from "@ant-design/icons";
import theme from "lib/theme";
import { UserProfile } from "generated/graphql";
import AutoCompleteSearch from "../AutoCompleteSearch";
import Avatar from "components/common/Avatar";
import { Menu, Dropdown, message } from "antd";
import removeLocalStorage from "lib/helpers/removeLocalStorage";
import { useHistory } from "react-router-dom";
import { useLogoutMutation } from "generated/graphql";
import client from "ApolloClient/index";
import config from "lib/config";

const Container = styled.div`
  min-width: 330px;
  position: absolute;
  right: 100px;
  top: 32px;
  display: flex;
`;

// const Avatar = styled.img`
//   border-radius: 50%;
//   height: 42px;
//   width: 42px;
//   margin-right: 16px;
//   border: 1px solid ${({ theme }) => theme.colors.background3};
// `;

export default function AvatarArea({ currentUser }: { currentUser: UserProfile }) {
  const [logout] = useLogoutMutation();
  const history = useHistory();

  const handleOnLogout = async () => {
    try {
      await logout();
      removeLocalStorage(config.authTokenName);
      removeLocalStorage(config.refreshTokenName);
      await client.resetStore();
      // let user know
      message.info("You've successfully logged out...");
      history.push(`/login`);
    } catch (err) {}
  };
  const menu = (
    <div style={{ width: 200 }}>
      <Menu theme="dark">
        <Menu.Item key="logout" onClick={handleOnLogout}>
          Log out
        </Menu.Item>
      </Menu>
    </div>
  );

  return (
    <Container>
      <AutoCompleteSearch />
      <Dropdown overlay={menu}>
        <div style={{ display: "flex", cursor: "pointer" }}>
          <Avatar
            size="large"
            style={{
              marginRight: 8,
              cursor: "pointer",
              background: "#7265e6", //colors[Math.floor(Math.random() * colors.length)],
            }}
          >
            {currentUser.firstName && currentUser.lastName
              ? `${currentUser?.firstName?.[0]}${currentUser?.lastName?.[0]}`
              : `${currentUser?.email?.[0]}${currentUser?.email?.[1]}`}
          </Avatar>

          <div>
            <Text style={{ marginBottom: 2 }}>
              {currentUser.firstName && currentUser.lastName ? `${currentUser.firstName} ${currentUser.lastName[0]}.` : currentUser.email}
            </Text>
            <Caption>{currentUser.activeOrganization?.name}</Caption>
          </div>
        </div>
      </Dropdown>
      <BellOutlined
        style={{
          color: theme.colors.text,
          fontSize: 24,
          marginLeft: 24,
        }}
      />
    </Container>
  );
}
