import DateInput from "components/inputs/DateInput";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  .ant-picker {
    background: ${({ theme }) => theme.colors.background3};
    border-color: ${({ theme }) => theme.colors.background3};
  }
  .ant-picker-clear {
    background: ${({ theme }) => theme.colors.background3};
    color: ${({ theme }) => theme.colors.text};
  }
  .ant-picker-input > input {
    color: ${({ theme }) => theme.colors.text3};
  }

  .ant-picker-suffix {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const Spacer = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text};
`;

const RangePicker = ({
  defaultValue,
  onChange,
  style,
}: {
  style?: React.CSSProperties;
  onChange?: any;
  defaultValue?: { startDate: any; endDate: any };
}) => {
  return (
    <Wrapper style={{ ...style }}>
      <DateInput
        defaultValue={moment.isMoment(defaultValue?.startDate) ? defaultValue?.startDate : moment(defaultValue?.startDate)}
        onChange={(startDate) => onChange({ startDate: startDate?.valueOf() })}
      />
      <Spacer>-</Spacer>
      <DateInput
        defaultValue={moment.isMoment(defaultValue?.endDate) ? defaultValue?.endDate : moment(defaultValue?.endDate)}
        onChange={(endDate) => onChange({ endDate: endDate?.valueOf() })}
      />
    </Wrapper>
  );
};

export default RangePicker;
