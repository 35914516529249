import PageContainer from "components/common/PageContainer";
import PageTitle from "components/text/PageTitle";
import Tabs from "components/common/Tabs";
import Incidents from "./Incidents";

const { TabPane } = Tabs;

const TICKET_TYPES = [
  {
    label: "Incidents",
    snowTable: "incidents",
    Component: Incidents,
  },
];

export default function AdminServiceDesk() {
  return (
    <PageContainer>
      <PageTitle>Service Desk</PageTitle>
      <Tabs>
        {TICKET_TYPES.map(({ label, snowTable, Component }) => (
          <TabPane tab={label} key={snowTable}>
            <Component />
          </TabPane>
        ))}
      </Tabs>
    </PageContainer>
  );
}
