import { XAxisTimeFormat } from "components/common/Chart";
import { RelativeTimeEnum } from "generated/graphql";

const getXAxisTimeFormat = (relativeTime?: RelativeTimeEnum) => {
  if (relativeTime === RelativeTimeEnum.Hour) {
    return XAxisTimeFormat.hours;
  }
  if (relativeTime === RelativeTimeEnum.Day) {
    return XAxisTimeFormat.hours;
  }
  if (relativeTime === RelativeTimeEnum.Week) {
    return XAxisTimeFormat.days;
  }
  if (relativeTime === RelativeTimeEnum.Month) {
    return XAxisTimeFormat.weeks;
  }
  return XAxisTimeFormat.hours;
};

export default getXAxisTimeFormat;
