import { useState } from "react";
import { pdf } from "@react-pdf/renderer";
import { get } from "lodash";

export default function useDownloadPdf() {
  // State for keeping track of whether key is pressed
  const [downloading, setDownloading] = useState(false);

  const downloadPdf = async (pdfDoc: any, title: string) => {
    try {
      setDownloading(true);
      const newBlob = await pdf(pdfDoc).toBlob();

      console.log(newBlob);

      let exportFilename = title;
      const msSaveBlob = get(navigator, "msSaveBlob");
      if (msSaveBlob) {
        msSaveBlob(newBlob, exportFilename);
      } else {
        // In FF link must be added to DOM to be clicked
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(newBlob);
        link.setAttribute("download", exportFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log(err);
      console.log("on download error");
    }
  };

  return {
    downloadPdf,
    downloading,
  };
}
