import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Row from "components/common/Row";
import Col from "components/common/Col";
import { UserProfile } from "generated/graphql";
import PageSubtitle from "components/text/PageSubtitle";
import InvoicesTable from "./InvoicesTable";
import moment from "moment";
import CostAnalysisChart from "./CostAnalysisChart";
import { useGetManyInvoicesQuery } from "generated/graphql";
import { useState } from "react";

// Cost Analysis: https://github.com/apiphani/client-portal/blob/0ba29928b6c081a9f186917cdda58c0a908ccad4/src/routes/Pages/Billing/Overview.js#L220

// Current Charges: https://github.com/apiphani/client-portal/blob/0ba29928b6c081a9f186917cdda58c0a908ccad4/src/routes/Pages/Billing/Overview.js#L181
// https://github.com/apiphani/admin-api/blob/cbaf9b44538f69c206e71466f3bf2d553b52d8f4/logic/third-parties/billings/BillingDB.js#L23

export default function ClientDataBilling({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: 5,
  });
  const { data, loading } = useGetManyInvoicesQuery({
    variables: {
      organizationId: currentUser?.activeOrganization?.id,
    },
    skip: currentUser?.activeOrganization?.id ? false : true,
  });

  return (
    <PageContainer>
      <PageTitle>Billing</PageTitle>
      <Row style={{ marginTop: 32 }} gutter={16}>
        <Col xs={12}>
          <PageSubtitle style={{ marginTop: 16, marginBottom: 16 }}>
            Cost Analysis
          </PageSubtitle>
          <div style={{ height: 275, marginTop: 32, marginBottom: 16 }}>
            <CostAnalysisChart
              organizationId={currentUser?.activeOrganization?.id}
            />
          </div>
        </Col>
        <Col xs={12} />
        <Col xs={12}>
          <PageSubtitle style={{ marginTop: 16, marginBottom: 16 }}>
            Current Charges {moment()?.format("MMMM")}
          </PageSubtitle>
          <InvoicesTable
            dataSource={data?.getManyInvoices?.results}
            count={data?.getManyInvoices?.count}
            loading={loading}
            onChange={(pagination: any, filters: any, sorter: any) => {
              setFilters({
                ...filters,
                current: pagination?.current,
                limit: pagination?.pageSize,
                skip: pagination?.pageSize * (pagination.current - 1),
              });
            }}
            pagination={{
              pageSize: filters.limit,
              current: filters.current,
              total: data?.getManyInvoices?.count || 0,
            }}
          />
        </Col>
        <Col xs={12}>
          <div style={{ marginTop: 24 }}>
            <PageSubtitle style={{ marginBottom: 16 }}>
              Invoice History
            </PageSubtitle>
            <InvoicesTable
              dataSource={data?.getManyInvoices?.results}
              count={data?.getManyInvoices?.count}
              loading={loading}
              onChange={(pagination: any, filters: any, sorter: any) => {
                setFilters({
                  ...filters,
                  current: pagination?.current,
                  limit: pagination?.pageSize,
                  skip: pagination?.pageSize * (pagination.current - 1),
                });
              }}
              pagination={{
                pageSize: filters.limit,
                current: filters.current,
                total: data?.getManyInvoices?.count || 0,
              }}
            />
          </div>
        </Col>
      </Row>
    </PageContainer>
  );
}
