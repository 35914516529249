import { Application, InstanceStateEnum, useGetManyApplicationsQuery } from "generated/graphql";
import styled from "styled-components";
import Loading from "components/common/Loading";
// import StatusCircle from "components/common/StatusCircle";
import TruncateText from "components/common/TruncateText";
import ItemTitle from "components/text/ItemTitle";
import EnvironmentPreviewCard from "./EnvironmentPreviewCard";
import useUrlChange from "lib/hooks/useUrlChange";
import { flatten } from "lodash";
import Row from "components/common/Row";
import Col from "components/common/Col";
import { useState } from "react";

const Container = styled.div<{ active?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background2};
  border: 1px solid
    ${({ theme, active }) => {
      if (active) return "#fff";
      return theme.colors.background4;
    }};
  border-radius: ${({ theme }) => theme.borderRadius};
  min-height: 336px;
  position: relative;
  padding: 24px;
  margin-top: 16px;
  width: 420px;
  max-width: 100%;
  padding-top: 32px;
`;

const ShowBtn = styled.button`
  background: transparent;
  padding: 4px 8px;
  border: 0px;
  color: ${({ theme }) => theme.colors.text2};
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  &:hover {
    background: ${({ theme }) => theme.colors.background};
  }
`;

const ApplicationCard = ({ app }: { app: any | Application }) => {
  const { onUrlChange } = useUrlChange();

  const [showAll, setShowAll] = useState<boolean>(false);
  return (
    <Container>
      <div style={{ display: "block", alignItems: "flex-start" }}>
        <div
          style={{
            marginRight: 24,
            width: 330,
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <StatusCircle
            isRunning={app.isRunning}
            fixed={false}
            style={{ marginRight: 8 }}
            size={18}
          /> */}
          <TruncateText text={app.name}>
            <ItemTitle>{app.name}</ItemTitle>
          </TruncateText>
        </div>
        <Row
          gutter={8}
          style={{
            width: "100%",
            minHeight: 150,
            marginTop: 24,
            //overflowY: "scroll",
          }}
        >
          {app?.environments?.slice(0, !showAll ? 4 : app?.environments.length)?.map((environment) => {
            if (!environment?.id) return null;
            return (
              <Col xs={12} key={environment?.id}>
                <EnvironmentPreviewCard
                  environment={environment}
                  onClick={(env) =>
                    onUrlChange(
                      {
                        instanceIds: env?.instances?.map((inst) => inst?.id),
                      },
                      `/app/instances`
                    )
                  }
                />
              </Col>
            );
          })}
        </Row>
        {app?.environments?.[4] && (
          <ShowBtn onClick={() => setShowAll((state) => !state)} style={{ marginTop: 8 }}>
            {!showAll
              ? `Show 
              ${Array.from(app?.environments)?.slice(4, app?.environments?.length)?.length} 
              more`
              : "Show less"}
          </ShowBtn>
        )}
      </div>
    </Container>
  );
};

export default function SystemsColumn(props: any) {
  const { data, loading, error } = useGetManyApplicationsQuery();
  if (loading) return <Loading height={200} />;
  if (error) return <div>error</div>;

  const apps = data?.getManyApplications
    // we want to look through all the environments and their state
    ?.map((app) => {
      const isRunningArray = app?.environments?.map((env) => env?.instances?.map((inst) => inst?.state === InstanceStateEnum.Running));
      const isRunning = !flatten(isRunningArray)?.includes(false);
      return {
        ...app,
        isRunning,
      };
    })
    // sort the array so that "non-running" applicaitons show up first (since they should be a higher prioirty)
    ?.sort((a, b) => (a.isRunning as any) - (b.isRunning as any));

  return (
    <Row gutter={16}>
      {/* <div style={{ height: 400, overflowY: "scroll", padding: `16px 32px` }}> */}

      {apps?.map((app) => {
        if (!app?.id) return null;
        return (
          <Col xs={8} key={app?.id}>
            <ApplicationCard app={app} />{" "}
          </Col>
        );
      })}

      {/* </div> */}
    </Row>
  );
}
