import { useState } from "react";
// GRAPHQL
import { useCreateNewUserMutation, useGetUsersByOrganizationQuery, UserProfileInput, GetUsersByOrganizationDocument } from "generated/graphql";
// COMPONENTS
import Button from "components/common/Button";
import NewUserForm from "components/forms/UserForm";
import message from "components/common/message";
import Drawer from "components/common/Drawer";
import EditUser from "components/common/EditUser";
import UsersTable from "components/common/UsersTable";

const LIMIT = 5;

export default function Users({ organizationId }) {
  const [addNewKey, setAddNewKey] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<false | string>(false);
  const [createNewUserMutation, { loading: creating }] = useCreateNewUserMutation();
  const { data, loading, error } = useGetUsersByOrganizationQuery({
    variables: {
      organizationId,
    },
  });

  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });

  if (loading) return null;

  if (error) return null;

  const users = data?.getUsersByOrganization || [];

  const getDrawerTitle = () => {
    if (addNewKey) {
      return "Create a New User";
    }
    if (editUserId) {
      return "Edit User";
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => setAddNewKey(true)} style={{ margin: 8, marginLeft: 0 }}>
          + New User
        </Button>
      </div>
      <UsersTable
        dataSource={users || []}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: data?.getUsersByOrganization?.length,
        }}
      />
      <Drawer
        title={getDrawerTitle()}
        placement="right"
        width={500}
        onClose={() => {}}
        visible={addNewKey || editUserId ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        {addNewKey && (
          <NewUserForm
            loading={creating}
            onSubmit={async (values: UserProfileInput) => {
              try {
                await createNewUserMutation({
                  variables: {
                    params: {
                      email: values.email,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      organizationId,
                      permission: values?.permission,
                      organizationIds: values?.organizationIds,
                      customPermissions: values?.customPermissions,
                    },
                  },
                  refetchQueries: [
                    {
                      query: GetUsersByOrganizationDocument,
                      variables: {
                        organizationId,
                      },
                    },
                  ],
                });
                setAddNewKey(false);
                message.success("Your new user was created");
              } catch (err: any) {
                message.error(err?.message);
                console.log(err);
              }
            }}
            onCancel={() => setAddNewKey(false)}
          />
        )}
        {editUserId && <EditUser userId={editUserId} onCancel={() => setEditUserId(false)} />}
      </Drawer>
    </div>
  );
}
