import styled from "styled-components";
import Table from "components/common/Table";
import Caption from "components/text/Caption";
import { useState } from "react";
import React from "react";
import { toNumber } from "lodash";
import Loading from "components/common/Loading";
import theme from "lib/theme";
import { Empty, TablePaginationConfig } from "antd";

interface DarkTableProps {
  dataSource?: any[] | object[] | null | undefined;
  columns?: object[] | null;
  style?: React.CSSProperties;
  loading?: boolean;
  pagination?: TablePaginationConfig;
  onRow?: any;
  rowKey?: string;
  expandable?: any;
  onChange?: any;
  emptyText?: any;
  defaultPageSize?: number;
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background2};
  padding: 16px;
  border-radius: 5px;
  padding-bottom: 0px;

  .ant-pagination-options-size-changer {
    position: relative;
    top: -10px;
  }

  .ant-tag {
    background: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text2};
    border: 0px;
  }

  .ant-empty-img-simple-g {
    stroke: #656667;
  }

  .ant-empty-img-simple-path {
    fill: #4c4a4a !important;
  }

  .ant-empty-img-simple-ellipse {
    fill: #141414;
  }

  .ant-empty-description {
    color: #9aa5b1;
  }

  .ant-pagination-item {
    a {
      color: ${({ theme }) => theme.colors.text} !important;
    }
  }

  .ant-pagination-item-active {
    a {
      color: ${({ theme }) => theme.colors.primary5} !important;
    }
  }

  .ant-table-row-expand-icon {
    color: ${({ theme }) => theme.colors.primary5};
    background-color: ${({ theme }) => theme.colors.background};
    border-color: ${({ theme }) => theme.colors.background3};
  }

  .ant-table-row-expand-icon-expanded {
    background-color: ${({ theme }) => theme.colors.primary10} !important;
    border-color: ${({ theme }) => theme.colors.primary5} !important;
  }

  tr.ant-table-expanded-row > td {
    background-color: ${({ theme }) => theme.colors.background} !important;
    border-radius: ${({ theme }) => theme.colors.borderRadius}px;
  }

  /** TABLE STYLES */
  .ant-table-row {
    cursor: pointer;
  }
  .ant-pagination-item-link {
    background-color: ${({ theme }) => theme.colors.background3};
    border-color: ${({ theme }) => theme.colors.background3};
    color: ${({ theme }) => theme.colors.text};
  }
  .ant-pagination-item {
    background-color: ${({ theme }) => theme.colors.background3};
    border-color: ${({ theme }) => theme.colors.background3};
    color: ${({ theme }) => theme.colors.text};
  }

  .ant-spin-container::after {
    background-color: transparent !important;
  }

  .ant-table-pagination.ant-pagination {
    margin-bottom: 0px;
  }
`;

const TableContainer = styled.div``;

const BottomRow = styled.div`
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 16px; */
`;
const DEFAULT_LIMIT = 5;

export default function DarkTable({
  style,
  dataSource,
  columns,
  pagination,
  loading,
  onRow,
  rowKey,
  expandable,
  onChange,
  emptyText,
  defaultPageSize = DEFAULT_LIMIT,
}: DarkTableProps) {
  const [filters, setFilters] = useState({
    skip: 0,
    current: 0,
    limit: defaultPageSize || pagination?.pageSize || DEFAULT_LIMIT,
  });

  let paginationOpts = {
    pageSize: pagination?.pageSize || filters?.limit,
    total: pagination?.total || dataSource?.length || 0,
    current: pagination?.current || toNumber(filters?.current || 1),
    showSizeChanger: pagination?.showSizeChanger === false ? false : true,
    pageSizeOptions: pagination?.pageSizeOptions || [5, 10, 20, 50, 100],
    //...pagination, // overwrite with any pagination options the provide
  };

  const defaultOnChange = (pagination) => {
    setFilters({
      ...filters,
      current: pagination?.current,
      limit: pagination?.pageSize,
      skip: pagination?.pageSize * (pagination.current - 1),
    });
  };

  const lastResult = pagination?.current && pagination?.pageSize && pagination?.current * pagination?.pageSize;

  return (
    <Container style={style}>
      <TableContainer>
        <Table
          dataSource={dataSource as object[]}
          columns={columns as object[]}
          rowKey={rowKey || "id"}
          onRow={onRow}
          onChange={onChange || defaultOnChange}
          pagination={paginationOpts as TablePaginationConfig}
          loading={loading ? { indicator: <Loading height={200} /> } : false}
          expandable={expandable}
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText || "No Data"} /> }}
        />
      </TableContainer>
      <BottomRow>
        <div style={{ position: "relative", top: -36, left: 16 }}>
          {!!(!loading && pagination?.total && pagination?.pageSize && pagination?.current && lastResult && pagination?.total > 0) && (
            <Caption
              style={{
                fontSize: 14,
                color: theme.colors.text2,
              }}
            >
              {`${pagination?.current * pagination?.pageSize - pagination?.pageSize + 1}-${
                lastResult > pagination?.total ? pagination?.total : lastResult
              }`}{" "}
              of {pagination?.total} Results
            </Caption>
          )}
        </div>
      </BottomRow>
    </Container>
  );
}
