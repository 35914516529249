


const environmentTypeHashmap = {
    'production': 'Production',
    'preproduction': 'Pre-Production',
    'staging': 'Staging',
    'development': 'Development',
    'qa': 'Quality Assurance',
    'test': 'Test',
    'sandbox': 'Sandbox',
    'unknown': 'Unknown',
    'customizing': 'Customizing',
}

export default environmentTypeHashmap;