import styled from "styled-components";
import { ReactElement } from "react";

const DataLabel = styled.label`
  color: ${({ theme }) => theme.colors.text};
  font-size: 14px;
`;

const DataValue = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.text3};
`;

export default function DataItem({
  label,
  value,
  style,
}: {
  label?: string;
  value?: string | null | number | Element | ReactElement;
  style?: React.CSSProperties;
}) {
  return (
    <div style={{ marginBottom: 16, ...style }}>
      <DataLabel>{label}</DataLabel>
      <DataValue>{value || "-"}</DataValue>
    </div>
  );
}
