import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import OrganizationsMultiSelect from "components/inputs/OrganizationsMultiSelect";
import PermissionSelectInput from "components/inputs/PermissionSelectInput";
import AccessesSelector from "components/inputs/AccessesSelector";
import { useGetManyOrganizationsQuery, UserTypeEnum } from "generated/graphql";
import UserTypeSelectInput from "components/inputs/UserTypeSelectInput";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface UserFormProps {
  onSubmit: (values: any) => void;
  onCancel: any;
  loading?: boolean;
  showOrgInput?: boolean;
  defaultValues?: {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    userType?: UserTypeEnum | null;
    organizationIds?: string[] | [] | null | undefined;
    requiresCustomPermission?: any;
    permission?: string | null;
    customPermissions?: [];
  };
}

export default function UserForm({
  onSubmit,
  onCancel,
  loading,
  defaultValues,
  showOrgInput = true,
}: UserFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    defaultValues,
  });

  const { data: organizationsQuery } = useGetManyOrganizationsQuery({
    fetchPolicy: "cache-and-network",
  });

  const requiresCustomPermission = watch("requiresCustomPermission", false);

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    // @TODO: make sure that either customPermissions are set or a permission is selected. Throw an error if neither are set
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Email" required error={errors?.email?.message}>
        <TextInput
          type="email"
          {...register("email", {
            required: "Email is required.",
          })}
        />
      </FormItem>
      <FormItem label="First Name" required error={errors?.firstName?.message}>
        <TextInput
          type="firstName"
          {...register("firstName", {
            required: "First name is required.",
          })}
        />
      </FormItem>
      <FormItem label="Last Name" required error={errors?.lastName?.message}>
        <TextInput
          type="lastName"
          {...register("lastName", {
            required: "Last name is required.",
          })}
        />
      </FormItem>
      <FormItem label="User Type" required error={errors?.userType?.message}>
        <Controller
          name="userType"
          control={control}
          rules={{ required: "User type is required" }}
          render={({ field }) => (
            <UserTypeSelectInput
              {...field}
              onChange={(userType) => field.onChange(userType)}
            />
          )}
        />
      </FormItem>
      {showOrgInput && (
        <FormItem label="Organizations" required>
          <Controller
            name="organizationIds"
            control={control}
            render={({ field }) => (
              <OrganizationsMultiSelect
                {...field}
                organizations={organizationsQuery?.getManyOrganizations}
                onChange={(organizationIds) => field.onChange(organizationIds)}
              />
            )}
          />
        </FormItem>
      )}

      <FormItem label="Permission" error={errors?.permission?.message}>
        <Controller
          name="permission"
          control={control}
          render={({ field }) => (
            <PermissionSelectInput
              {...field}
              onChange={(stateProvinceRegion) =>
                field.onChange(stateProvinceRegion)
              }
            />
          )}
        />
      </FormItem>
      <FormItem
        label="Requires Custom Permissions"
        error={errors?.requiresCustomPermission?.message}
      >
        <Controller
          name="requiresCustomPermission"
          control={control}
          render={({ field }) => (
            <input
              type="checkbox"
              {...field}
              onChange={(e) => field.onChange(e.target.checked)}
            />
          )}
        />
      </FormItem>
      {requiresCustomPermission && (
        <div>
          <FormItem label="Custom preferences">
            <Controller
              name="customPermissions"
              control={control}
              render={({ field }) => (
                <AccessesSelector
                  {...field}
                  onChange={(values) => field.onChange(values || [])}
                />
              )}
            />
          </FormItem>
        </div>
      )}
      <FormItem>
        <Button
          type="button"
          onClick={onCancel}
          grey
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button type="submit" loading={loading} disabled={loading}>
          Submit
        </Button>
      </FormItem>
    </Form>
  );
}
