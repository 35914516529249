import Select from 'antd/lib/select';
import 'antd/lib/select/style/css';
import {useGetRegionsByVendorQuery} from 'generated/graphql';
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function VendorRegionsInput(props) {
  const {data, loading, error} = useGetRegionsByVendorQuery({
    variables: {
      vendor: props.vendor,
    },
    skip: !props.vendor,
  });
  const {onChange, value} = props;

  if (error) return null;
  if (loading) return null;

  return (
    <Select
      style={{width: '100%'}}
      {...props}
      size="large"
      onChange={onChange}
      value={value || undefined}
      optionFilterProp="children"
    >
      {data?.getRegionsByVendor?.map((label) => {
        if (!label) return null;
        return (
          <Option key={label} value={label}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
}
