import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { RelativeTimeEnum } from "generated/graphql";

// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

const labelHashMap = {
  hour: "Last Hour",
  day: "Last 24 Hours",
  week: "Last 7 Days",
  month: "Last 30 Days",
};

// EXPORTED COMPONENT
// ================================================================
export default function TimePeriodInput(props) {
  const { onChange, value } = props;

  return (
    <Select style={{ width: "100%" }} {...props} size="large" onChange={onChange} value={value || undefined} optionFilterProp="children">
      {Object.values(RelativeTimeEnum)
        ?.reverse()
        ?.map((label) => {
          if (!label) return null;
          return (
            <Option key={label} value={label}>
              {labelHashMap[label]}
            </Option>
          );
        })}
    </Select>
  );
}
