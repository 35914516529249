import {
  useGetOneApplicationQuery,
  useUpdateOneApplicationMutation,
} from "generated/graphql";
import Loading from "components/common/Loading";
import ApplicationForm from "components/forms/ApplicationForm";
import Text from "components/text/Text";
import { message } from "antd";

export default function EditApplication({ applicationId, onCancel }) {
  // query applicationId
  const { data, loading, error } = useGetOneApplicationQuery({
    variables: {
      applicationId,
    },
  });
  const [updateOneApplicationMutation, { loading: saving }] =
    useUpdateOneApplicationMutation();
  if (loading) return <Loading />;
  if (error) return <Text>Someting went wrong...</Text>;

  const application = data?.getOneApplication;

  console.log(application);
  //
  return (
    <div>
      <ApplicationForm
        onSubmit={async (values) => {
          console.log(values);
          try {
            await updateOneApplicationMutation({
              variables: {
                id: applicationId,
                params: {
                  ...values,
                },
              },
            });
            message.success("Changes saved");
            onCancel();
          } catch (err) {
            console.log(err);
          }
        }}
        onCancel={onCancel}
        loading={saving}
        defaultValues={{
          name: application?.name,
        }}
      />
    </div>
  );
}
