import { useGetManyPermissionsQuery, Permission } from "generated/graphql";
import Tag from "components/common/Tag";
import DarkTable from "components/common/DarkTable";

export default function OrganizationsTable() {
  const { data, loading, error } = useGetManyPermissionsQuery({
    variables: {
      adminOnly: true,
    },
  });

  const permissions = data?.getManyPermissions || [];

  const columns = [
    {
      title: "Name",
      render: (record: Permission) => <div>{record.name}</div>,
    },
    {
      title: "Accesses",
      render: (record: Permission) => (
        <div>
          {record.accesses?.map((tag) => {
            if (!tag?.id) return null;
            return (
              <Tag color="geekblue" key={tag.id}>
                {tag.name}
              </Tag>
            );
          })}
        </div>
      ),
    },
  ];

  if (loading) return null;

  if (error) return null;

  return (
    <div style={{ marginTop: 24 }}>
      <DarkTable
        rowKey="id"
        dataSource={permissions as Permission[]}
        columns={columns}
      />
    </div>
  );
}
