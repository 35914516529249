import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Loading from "components/common/Loading";
import BackButton from "components/common/BackButton";
import { Instance, InstanceStateEnum, useGetOneInstanceQuery, UserProfile } from "generated/graphql";
import Caption from "components/text/Caption";
import Tabs from "components/common/Tabs";
import StatusCircle from "components/common/StatusCircle";
import InstanceInfo from "./InstanceInfo";
import SystemMetrics from "./SystemMetrics";
import BasisMetrics from "./BasisMetrics";
import HanaMetrics from "./HanaMetrics";
import DataProtection from "./DataProtection";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import useUrlChange from "lib/hooks/useUrlChange";

const { TabPane } = Tabs;

enum TabString {
  INFO = "INFO",
  BASIS_METRICS = "BASIS_METRICS",
  SYSTEM_METRICS = "SYSTEM_METRICS",
  DATA_PROTECTION = "DATA_PROTECTION",
  DB_METRICS = "DB_METRICS",
}

export const DetailContent = ({ instance, featureSettings }: { instance: Instance; featureSettings: any }) => {
  const location = useLocation();
  const { tab } = queryString.parse(location.search);
  const { onUrlChange } = useUrlChange();

  if (!instance) {
    return <Caption>We could not find data for this instance</Caption>;
  }

  const onTabChange = (key) => {
    onUrlChange({ tab: key }, undefined, true);
  };

  const isHanaDb = instance?.customDeviceId && instance?.instanceType === "Database Server";

  const TABS = [
    <TabPane tab="Instance Info" key={TabString.INFO}>
      <InstanceInfo instance={instance} />
    </TabPane>,

    // Show/hide this tab based on if the feature is enabled
    ...(instance?.customDeviceId && !isHanaDb
      ? [
          <TabPane tab="Basis Metrics" key={TabString.BASIS_METRICS}>
            <BasisMetrics instance={instance} />
          </TabPane>,
        ]
      : []),
    // Show/hide this tab based on if the feature is enabled
    ...(instance?.customDeviceId && isHanaDb
      ? [
          <TabPane tab="DB Metrics" key={TabString.BASIS_METRICS}>
            <HanaMetrics instance={instance} />
          </TabPane>,
        ]
      : []),
    <TabPane tab="System Metrics" key={TabString.SYSTEM_METRICS}>
      <SystemMetrics instance={instance} />
    </TabPane>,

    // Show/hide this tab based on if the feature is enabled
    ...(featureSettings?.dataProtectionEnabled && instance?.instanceType
      ? [
          <TabPane tab="Data Protection" key={TabString.DATA_PROTECTION}>
            <DataProtection instance={instance} />
          </TabPane>,
        ]
      : []),
  ];

  return (
    <Tabs onChange={onTabChange} style={{ marginTop: 24 }} activeKey={(tab as string) || (TabString.INFO as string)}>
      {TABS?.map((tab) => tab)}
    </Tabs>
  );
};

export default function InstanceDetail({ match, currentUser }: { match: any; currentUser: UserProfile }) {
  const { data, loading, error } = useGetOneInstanceQuery({
    variables: {
      instanceId: match?.params?.id,
    },
  });
  const history = useHistory();
  if (loading) return <Loading />;

  if (error) return <div>error</div>;

  const instance = data?.getOneInstance;

  if (!instance) {
    return <Caption>We could not find data for this instance</Caption>;
  }

  return (
    <PageContainer>
      <BackButton
        style={{ marginBottom: 24 }}
        onClick={() => {
          console.log(history);
          if (history?.goBack) return history.goBack();
          return history.push("/app/instances");
        }}
      />
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <PageTitle>
            {instance?.name?.trim()}
            <StatusCircle style={{ marginLeft: 16 }} fixed={false} isRunning={instance.state === InstanceStateEnum.Running} />
          </PageTitle>
          <Caption>{instance?.externalId}</Caption>
        </div>
      </div>
      <DetailContent instance={instance} featureSettings={currentUser?.activeOrganization?.settings?.features} />
    </PageContainer>
  );
}
