import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { useGetManyPermissionsQuery } from "generated/graphql";
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function PermissionSelectInput(props) {
  const { data, loading, error } = useGetManyPermissionsQuery({
    variables: {
      adminOnly: true,
    },
  });
  const { onChange, value } = props;

  if (error) return null;
  if (loading) return null;

  return (
    <Select
      style={{ width: "100%" }}
      {...props}
      size="large"
      onChange={onChange}
      value={value || undefined}
      optionFilterProp="children"
    >
      {data?.getManyPermissions?.map((permission) => {
        if (!permission?.id) return null;
        const { id, name } = permission;
        return (
          <Option key={id} value={id}>
            {name}
          </Option>
        );
      })}
    </Select>
  );
}
