// COMPONENTS
import Button from "components/common/Button";
import message from "components/common/message";
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import styled from "styled-components";
// LIB
import { useState } from "react";
import { useChangePasswordMutation } from "generated/graphql";
import useCheckPasswordErrors from "lib/hooks/useCheckPasswordErrors";

const buttonStyle = {
  width: 200,
  marginTop: 20,
  marginRight: "auto",
  display: "block",
};

const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0px;
  height: 0px;
  width: 0px;
`;

export default function ChangePasswordForm() {
  const [changePasswordMutation, { loading }] = useChangePasswordMutation();
  const { checkPasswordErrors } = useCheckPasswordErrors();
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<
    string | undefined
  >(undefined);
  const [errors, setErrors] = useState<{
    confirmNewPassword?: string;
    newPassword?: string;
    generalError?: string;
  }>({
    confirmNewPassword: undefined,
    newPassword: undefined,
    generalError: undefined,
  });
  const passwordDontMatch =
    newPassword && confirmNewPassword && newPassword !== confirmNewPassword;
  const disabled =
    !oldPassword || !newPassword || !confirmNewPassword || passwordDontMatch;

  const handleChangePasswordSubmit = async () => {
    try {
      if (!oldPassword || !newPassword) return;

      await changePasswordMutation({
        variables: {
          oldPassword,
          newPassword,
        },
      });

      message.success("Your password was successfully changed");
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword(undefined);
    } catch (err: any) {
      return message.error(err?.message || "Something went wrong");
    }
  };

  return (
    <>
      {/*  
          For some reason chrome tries to autofill with an email 
          ANY text field that is above a password input... so we were 
          having the top nav's searchbar fil in with an email.... the temporary 
          solution is to have a fake unseen/unfillable email input here  
      */}
      <HiddenInput type="email" inputMode="email" autoComplete="off" />
      <FormItem label="Old Password">
        <TextInput
          onChange={(e) => setOldPassword(e.target.value)}
          value={oldPassword || ""}
          type="password"
          placeholder="* * * * * * * *"
        />
      </FormItem>
      <FormItem label="New Password">
        <TextInput
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword || ""}
          type="password"
          placeholder="* * * * * * * *"
          onBlur={() => {
            const error = checkPasswordErrors(newPassword);
            if (error) {
              setErrors({ newPassword: error });
            } else {
              setErrors({ newPassword: undefined });
            }
          }}
        />
      </FormItem>
      <FormItem label="Confirm New Password" error={errors?.confirmNewPassword}>
        <TextInput
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          value={confirmNewPassword || ""}
          type="password"
          placeholder="* * * * * * * *"
          onBlur={() => {
            if (confirmNewPassword !== newPassword) {
              return setErrors({
                confirmNewPassword: "Your passwords do not match",
              });
            }
          }}
        />
      </FormItem>
      <Button
        secondary
        onClick={handleChangePasswordSubmit}
        disabled={disabled || loading}
        style={buttonStyle}
      >
        {!loading ? `Change Password` : "..."}
      </Button>
    </>
  );
}
