import { UserTypeEnum } from "generated/graphql";
import { Route, Redirect } from "react-router-dom";

// EXPORTED COMPONENT
// ==============================
const ClientRoute = (props: any) => {
  const {
    currentUser,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  const isLoggedIn = currentUser;
  const isClient = currentUser?.userType === UserTypeEnum.Client;
  const isSuperAdmin =
    currentUser?.userType === UserTypeEnum.SuperAdmin &&
    currentUser?.activeOrganization
      ? true
      : false;

  const isEngineer = currentUser?.userType === UserTypeEnum.Engineer;

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  if (!isClient && !isSuperAdmin && !isEngineer) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      path={path}
      render={(args) => (
        <Layout
          currentUser={currentUser}
          backgroundColor={backgroundColor}
          showHeader={showHeader}
          showFooter={showFooter}
        >
          <Component currentUser={currentUser} {...args} />
        </Layout>
      )}
    />
  );
};

// EXPORT
// ==============================
export default ClientRoute;
