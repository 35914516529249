import PageTitle from "components/text/PageTitle";
import PageSubtitle from "components/text/PageSubtitle";
import PageContainer from "components/common/PageContainer";
import Row from "components/common/Row";
import Col from "components/common/Col";
import SystemsColumn from "./SystemsColumn";

import { UserProfile } from "generated/graphql";

export default function ClientHome({ currentUser }: { currentUser: UserProfile }) {
  return (
    <PageContainer>
      <PageTitle>Dashboard</PageTitle>

      <Row gutter={32} style={{ marginBottom: 40, marginTop: 32 }}>
        <Col xs={24}>
          <PageSubtitle>Systems</PageSubtitle>
          <SystemsColumn />
        </Col>
        {/* {featureSettings?.vpnEnabled && (
          <Col xs={8}>
            <PageSubtitle>VPN</PageSubtitle>
            <VPNsColumn />
          </Col>
        )} */}
        {/* {featureSettings?.ticketsEnabled && (
          <Col xs={8}>
            <PageSubtitle>My Tickets</PageSubtitle>
            <TicketSummaryColumn />
          </Col>
        )} */}
      </Row>
      {/* <PageSubtitle>What do you need?</PageSubtitle>
      <Row gutter={16} justify="start" style={{ width: 900, maxWidth: "100%" }}>
        {ACTIONS.map((action) => {
          return (
            <Col xs={24} sm={12} key={action.title}>
              <ActionButton
                title={action.title}
                icon={action.icon}
                link={action.link}
                description={action.description}
              />
            </Col>
          );
        })}
      </Row> */}
    </PageContainer>
  );
}
