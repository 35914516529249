import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import { Policy, useGetManyPoliciesQuery } from "generated/graphql";
import Table from "components/common/Table";
import { useHistory } from "react-router";

export default function AdminPolicies() {
  const { data, loading, error } = useGetManyPoliciesQuery();
  const history = useHistory();

  if (loading) return null;

  if (error) return null;

  const columns = [
    {
      title: "Name",
      render: (record: Policy) => (
        <div
          onClick={() => history.push(`/admin/policies?policyId=${record.id}`)}
        >
          {record.name}
        </div>
      ),
    },
    {
      title: "System",
      render: (record: Policy) => record.system,
    },
    {
      title: "Vendor",
      render: (record: Policy) => record.vendor,
    },
  ];

  const policies = data?.getManyPolicies || [];

  return (
    <PageContainer>
      <PageTitle>Policies</PageTitle>
      <div style={{ marginTop: 24 }}>
        <Table
          rowKey="id"
          dataSource={policies as Policy[]}
          columns={columns}
        />
      </div>
    </PageContainer>
  );
}
