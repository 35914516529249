import Fuse from "fuse.js";
import theme from "lib/theme";
import { useGetManyScriptBlocksQuery } from "generated/graphql";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import styled from "styled-components";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
import { useStateMachine } from "little-state-machine";
import { updateElements } from "../index";
import { useState } from "react";

const BlockRowContainer = styled.div`
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.colors.background2};
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.background2};
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.background};
  }
`;

const BlockRow = ({ script, onAdd }) => {
  return (
    <BlockRowContainer>
      <div style={{ width: "90%", marginRight: 8 }}>
        <Text>{script.name}</Text>
        <Caption>{script.description}</Caption>
      </div>
      <div
        style={{
          marginRight: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button grey onClick={onAdd}>
          +
        </Button>
      </div>
    </BlockRowContainer>
  );
};

const getUnique = (array: any[]) => {
  const ids = array.map((o) => o.id);
  const filtered = array.filter(
    ({ id }, index) => !ids.includes(id, index + 1)
  );
  return filtered;
};

const ScriptDrawer = ({ onClose }) => {
  const { data, loading } = useGetManyScriptBlocksQuery();
  const { actions, state } = useStateMachine({ updateElements });
  const [searchText, setSearchText] = useState<string>("");

  const fuse = new Fuse(
    !loading && data?.getManyScriptBlocks ? data?.getManyScriptBlocks : [],
    {
      includeScore: true,
      // Search in `author` and in `tags` array
      keys: ["name"],
    }
  );

  const result = !searchText?.[1]
    ? data?.getManyScriptBlocks
    : fuse.search(searchText)?.map((item) => item.item);

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <TextInput
          placeholder="Search blocks..."
          onChange={(e) => setSearchText(e.target.value)}
          style={{
            marginTop: 8,
            marginBottom: 8,
            borderRadius: 24,
            paddingLeft: 16,
          }}
        />
        <Caption style={{ textAlign: "right" }}>
          {result?.length} Blocks
        </Caption>
      </div>

      <div style={{ height: "75vh", overflowY: "scroll" }}>
        {!loading &&
          result?.map((script) => {
            return (
              <BlockRow
                key={script?.id}
                script={script}
                onAdd={() => {
                  actions?.updateElements(
                    getUnique([
                      ...state?.elements,
                      {
                        id: script?.id,
                        type: "selectorNode", //!state?.elements?.[0] ? "input" : "selectorNode",
                        data: {
                          label: script?.name,
                          script,
                        },
                        // data: {
                        //   label: (
                        //     <>
                        //       <Text>{script?.name}</Text>
                        //       <Caption>{script?.description}</Caption>
                        //     </>
                        //   ),
                        // },
                        style: {
                          background: theme.colors.background4,
                          border: `1px solid ${theme.colors.background3}`,
                          width: 270,
                        },
                        position: { x: 270, y: state?.elements?.length * 50 },
                      },
                    ])
                  );
                  onClose();
                }}
              />
            );
          })}
      </div>
    </>
  );
};

export default ScriptDrawer;
