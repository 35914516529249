// import {Application} from 'generated/graphql';
import styled from "styled-components";
import ItemTitle from "components/text/ItemTitle";
import StatusCircle from "components/common/StatusCircle";
import { VpnDataItem } from "generated/graphql";
import moment from "moment";
import Caption from "components/text/Caption";

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.background3};
  padding: 24px;
  margin-top: 40px;
  border-radius: ${({ theme }) => theme.borderRadius};
  min-height: 120px;
  position: relative;
  /* cursor: pointer; */
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  /* &:hover {
    background-color: ${({ theme }) => theme.colors.background2};
  } */
`;

export default function VpnPreviewCard({
  item,
  onClick,
  showIpAddress,
  showLastUpdated,
}: {
  item: VpnDataItem;
  onClick: any;
  showIpAddress?: boolean;
  showLastUpdated?: boolean;
}) {
  return (
    <Container onClick={onClick}>
      <div>
        <ItemTitle>{item.name}</ItemTitle>
        <StatusCircle isRunning={item?.isRunning || false} />
        {showIpAddress && (
          <Caption style={{ marginTop: 16 }}>{item.ipAddress}</Caption>
        )}
        {showLastUpdated && (
          <Caption style={{ marginTop: 8 }}>
            Updated {moment(item.lastUpdated).format("MMM D, YYYY")}
          </Caption>
        )}
      </div>
    </Container>
  );
}
