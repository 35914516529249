import styled from "styled-components";

const ItemTitle = styled.span`
  margin: 0px;
  bottom: 0;
  font-weight: 400;
  line-height: 125%;
  font-family: ${(p) => p.theme.fontFamily};
  display: ${(p) => (p?.style?.display ? p?.style?.display : "block")};
  color: ${(p) => (p.color ? p.color : p.theme.colors.text3)};
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : "20px")};
`;

export default ItemTitle;
