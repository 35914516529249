import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Tabs from "components/common/Tabs";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  SupportTicket,
  SupportTicketPriorityEnum,
  SupportTicketTypeEnum,
  useGetManySupportTicketsQuery,
} from "generated/graphql";
import DarkTable from "components/common/DarkTable";
import TruncateMarkup from "react-truncate-markup";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import Tooltip from "components/common/Tooltip";
import moment from "moment";
import styled from "styled-components";
import SupportTicketStateTag from "components/common/SupportTicketStateTag";
import SupportTicketPriorityTag from "components/common/SupportTicketPriorityTag";

const { TabPane } = Tabs;

const SmallText = styled(Text)`
  font-size: 14px;
`;

const LIMIT = 5;

export default function ClientTickets() {
  const location = useLocation();
  const history = useHistory();
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });
  const { tab = SupportTicketTypeEnum.Incidents } = queryString.parse(
    location.search
  );
  const { data, loading, error } = useGetManySupportTicketsQuery({
    variables: {
      type: tab as SupportTicketTypeEnum,
      page: filters?.current,
      perPage: filters?.limit,
    },
    skip: !tab,
  });

  if (error) return <Text>Something went wrong querying your tickets...</Text>;

  const onTabChange = (key) => {
    history.replace(location.pathname + `?tab=${key}`);
  };

  const columns = [
    {
      key: "id",
      title: "Number",
      render: (record: SupportTicket) => <SmallText>{record.id}</SmallText>,
    },
    {
      title: "Priority",
      render: (record: SupportTicket) => {
        if (
          !record.priority ||
          record.priority === SupportTicketPriorityEnum.None
        )
          return null;
        return (
          <SupportTicketPriorityTag priority={record?.priority}>
            {record.priority}
          </SupportTicketPriorityTag>
        );
      },
    },
    {
      title: "Subject",
      render: (record: SupportTicket) => (
        <>
          <Tooltip title={record.subject}>
            <div>
              <TruncateMarkup lines={1}>
                <Caption>{record.subject}</Caption>
              </TruncateMarkup>
            </div>
          </Tooltip>
        </>
      ),
    },
    // {
    //   title: 'Type',
    //   render: (record: SupportTicket) =>  <SmallText>{record.type}</SmallText>
    // },
    {
      title: "State",
      render: (record: SupportTicket) => {
        if (!record.state) return null;
        return (
          <SupportTicketStateTag state={record?.state}>
            {record.state.replace("_", " ")}
          </SupportTicketStateTag>
        );
      },
    },
    {
      title: "Requester",
      render: (record: SupportTicket) => (
        <SmallText>{record.requestedBy}</SmallText>
      ),
    },
    {
      title: "Created",
      render: (record: SupportTicket) => (
        <SmallText>{moment(record.createdAt).format("MMM D, YYYY")}</SmallText>
      ),
    },
  ];

  return (
    <PageContainer>
      <PageTitle>Tickets</PageTitle>
      <Tabs
        onChange={onTabChange}
        style={{ marginTop: 24 }}
        activeKey={
          (tab as string) || (SupportTicketTypeEnum.Incidents as string)
        }
        // tabBarExtraContent={{
        //   left: null,
        //   right: (
        //     <Button
        //       onClick={() => history.push(`/app/tickets/create`)}
        //       style={{ marginBottom: 8, width: 140 }}
        //     >
        //       + New Ticket
        //     </Button>
        //   ),
        // }}
      >
        <TabPane tab="Incidents" key={SupportTicketTypeEnum.Incidents} />
        <TabPane tab="Problems" key={SupportTicketTypeEnum.Problems} />
        <TabPane tab="Catalogs" key={SupportTicketTypeEnum.CatalogItems} />
        <TabPane tab="Changes" key={SupportTicketTypeEnum.Changes} />
        <TabPane tab="Solutions" key={SupportTicketTypeEnum.Solutions} />
      </Tabs>
      <DarkTable
        style={{ marginTop: 24 }}
        loading={loading}
        dataSource={data?.getManySupportTickets?.results}
        columns={columns}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: data?.getManySupportTickets?.pagination?.totalCount || 0,
        }}
        onRow={(record) => {
          return {
            onClick: () =>
              history.push(`/app/tickets/${record.id}?ticketType=${tab}`), // click row
          };
        }}
      />
    </PageContainer>
  );
}
