//
// import PageContainer from "components/common/PageContainer";
// import Row from "components/common/Row";
// import Col from "components/common/Col";
// import { useState } from "react";
// import RangePicker from "components/inputs/RangePicker";
// import moment from "moment";

// export default function ClientReportsCapacity() {
//   const [dates, setDates] = useState<{ startDate: number; endDate: number }>({
//     startDate: moment().subtract(3, "month")?.startOf("month")?.valueOf(),
//     endDate: moment().subtract(1, "month")?.endOf("month")?.valueOf(),
//   });

//   return (
//     <PageContainer>
//       <div style={{ marginTop: 40, marginBottom: 32, display: "flex", justifyContent: "space-between" }}>
//         <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
//           <PageTitle style={{ minWidth: 250 }}>Capacity Report</PageTitle>
//           <RangePicker defaultValue={dates} onChange={(newDates) => setDates({ ...dates, ...newDates })} />
//         </div>
//       </div>
//       <Row gutter={24}>
//         <Col xs={24} sm={12}>
//           test
//         </Col>
//       </Row>
//     </PageContainer>
//   );
// }

import Text from "components/text/Text";
import PageTitle from "components/text/PageTitle";
import { RelativeTimeEnum, useGetReport_CapacityQuery } from "generated/graphql";
import styled from "styled-components";
import Row from "components/common/Row";
import Chart from "components/common/Chart";
import { useState } from "react";
import Col from "components/common/Col";
import PageSubtitle from "components/text/PageSubtitle";
import Caption from "components/text/Caption";
import { InfoCircleFilled } from "@ant-design/icons";
import { LoadingCards } from "../client-instance-detail/BasisMetrics";
import config from "lib/config";
import TimePeriodInput from "components/inputs/TimePeriodInput";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Tooltip from "components/common/Tooltip";

const ChartContainer = styled.div`
  padding: 16px 32px;
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.background2};
  margin-top: 16px;
  position: relative;
`;

// const DiskUsedChart = ({ instance }) => {
//   const { data: diskQuery, loading: diskQueryLoading } =
//     useGetDiskUsedPercentQuery({
//       variables: {
//         instanceId: instance.id,
//       },
//     });

//   const formatted: any[] = [];
//   const keys: string[] = [];

//   diskQuery?.getDiskUsedPercent?.forEach((metric) => {
//     if (metric?.metric !== "/") {
//       keys.push(metric?.metric as string);
//       metric?.metricValues?.forEach((metricDataPoint, index) => {
//         formatted[index] = {
//           ...formatted[index],
//           [metric?.metric as string]: metricDataPoint?.value
//             ? metricDataPoint?.value.toFixed(2)
//             : 0,
//         };
//       });
//     }
//   });

//   console.log(formatted);

//   return (
//     <div style={{ width: 800, height: 500, border: "1px solid red" }}>
//       <ResponsiveStreamChart data={formatted} keys={keys} />
//     </div>
//   );
// };

const YAxisText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 300;
  height: 8px;
  transform-origin: left top;
  transform: rotate(270deg) translateX(-100%);
  position: absolute;
  left: 6px;
  top: 115px;
`;

const ToolTipContainer = styled.div`
  display: inline-flex;
  position: absolute;
  right: 16px;
  top: 16px;
  color: ${({ theme }) => theme.colors.text};
`;

export default function SystemMetrics({ instance }) {
  const [relativeTime, setRelativeTime] = useState<RelativeTimeEnum>(RelativeTimeEnum.Day);
  const { data, loading, error } = useGetReport_CapacityQuery({
    variables: {
      relativeTime,
    },
    fetchPolicy: "network-only",
  });

  // show loader
  if (loading) {
    return (
      <div style={{ marginTop: 56 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 60,
          }}
        />
        <LoadingCards />
      </div>
    );
  }
  //
  if (error) return <Text>Something went wrong...</Text>;
  // show an empty state
  if (!data?.getReport_Capacity?.[0]) {
    return <Text>No data for capacity report...</Text>;
  }

  return (
    <div style={{ marginTop: 56 }}>
      {data?.getReport_Capacity?.[0] && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              height: 60,
            }}
          >
            <div>
              <PageTitle style={{ minWidth: 250 }}>Capacity Report</PageTitle>
              <Caption style={{ marginTop: 8 }}>
                <InfoCircleFilled
                  style={{
                    marginRight: 4,
                    fontSize: 12,
                    position: "relative",
                    top: -1,
                    textAlign: "right",
                  }}
                />
                Report coming from Dynatrace
              </Caption>
            </div>
            <div style={{ width: 170 }}>
              <TimePeriodInput value={relativeTime} onChange={(newValue: RelativeTimeEnum) => setRelativeTime(newValue)} />
            </div>
          </div>
        </>
      )}
      <Row gutter={16}>
        {data?.getReport_Capacity?.map((chart, index) => {
          if (!chart?.metricValues?.[0]) return null;
          const color = config.chartColors[index];
          return (
            <Col xs={24} sm={8} key={chart?.metricName}>
              <ChartContainer>
                <YAxisText>{chart?.metricType}</YAxisText>
                <PageSubtitle style={{ marginBottom: 16 }}>{chart?.metricName?.trim()}</PageSubtitle>
                {chart?.metricDescription && (
                  <ToolTipContainer>
                    <Tooltip title={chart?.metricDescription} overlayInnerStyle={{ width: 350 }}>
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </ToolTipContainer>
                )}
                <div style={{ position: "relative", left: -24 }}>
                  <Chart
                    title={chart?.metricName?.trim()}
                    data={chart?.metricValues}
                    // metricDescription={chart?.metricDescription}
                    color={color}
                    relativeTime={relativeTime}
                    metricType={chart?.metricType}
                  />
                </div>
              </ChartContainer>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
