import { createGlobalStyle } from "styled-components";
import theme from "./theme";

export const GlobalStyle = createGlobalStyle`
  /* TOP LEVEL CSS */
  * {
    font-family: ${theme.fontFamily};
    /* color: ${theme?.colors?.text3}; */
  }
  div, a, h1, h2, h3, h4, h5, p, span {
    font-size: 16px;
    color: ${theme?.colors?.text3};
  }

 
 
  .ant-message-custom-content {
    div, span {
      color: #000;
    }
  }

  html, body {
    margin: 0px;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background: ${theme?.colors?.background} !important;
  }
  a {
    color: ${theme?.colors?.supportB3} !important;
  }

  input:-webkit-autofill {
      -webkit-text-fill-color: #fff;
  }
  input:-webkit-autofill:focus {
      -webkit-text-fill-color: #fff;
  }

  #root {
    height: 100%;
    width: 100vw;
  }

  .ant-modal-close-x {
    color: ${theme?.colors?.text} !important;
  }


  .ant-select-dropdown {
    background: #313236 !important;
    background-image: linear-gradient(136.61deg, rgb(39, 40, 43) 13.72%, rgb(45, 46, 49) 74.3%);
    padding: 8px;
    box-shadow: rgb(0 0 0 / 20%) 0px 4px 24px;
  }

  .ant-select-item-option-content {
    color: ${theme.colors.text} !important;
  }

  .ant-select-item:hover {
    background: ${theme.colors.background2} !important;
    border-radius: 5px;
  }

  .ant-select-item-option-selected {
    background: ${theme.colors.background3} !important;
  }
  .ant-select-item-option-active {
    background: ${theme.colors.background3} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: ${theme.colors.primary6} !important;
  }

  .ant-picker-today-btn {
    color: ${theme.colors.primary8} !important;
  }

  /** ANTD POPCONFIRM BUTTON */


  .ant-calendar-column-header-inner {
    color: #fff !important;
  }
  .ant-calendar-picker-input {
    height: 52px !important;
    background: ${theme.colors.background} !important;
    border: 0px !important;
  }
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
    background: ${theme.colors.primary5} !important;
    color: #fff !important;
  }

  .ant-modal-body {
    background: ${theme.colors.background2} !important;
    p {
      color: ${theme.colors.text3} !important;
    }
  }
  /* CALENDAR STYLES OVERWRITING ANTD CALENDAR DEFAULT STYLES */
  .ant-calendar-month-panel-tbody {
    font-family: ${theme.fontFamily} !important;
  }
  .ant-calendar-header a:hover {
    color: ${theme.colors.primary1} !important;
  }
  .ant-popover-buttons {
    .ant-btn:hover, .ant-btn:focus {
      color: ${theme.colors.primary10}  !important;
      background-color: #fff;
      border-color: ${theme.colors.primary10} !important;
    }
    .ant-btn.ant-btn-primary.ant-btn-sm{
      background: ${theme.colors.primary10} !important;
      border-color: ${theme.colors.primary10} !important;
      color: #fff !important;
    }
  }
  
  /* ================================================
   RANGE PICKER STYLES
  ==================================================== */
  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date {
    background: ${theme.colors.primary1} !important;
  }
  a.ant-calendar-month-select, a.ant-calendar-year-select {
    color: ${theme.colors.primary1} !important;
  }
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: ${theme.colors.primary1} !important;
  }
  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: ${theme.colors.primary10} !important;
  }
  .ant-calendar-today .ant-calendar-date {
    border-color: ${theme.colors.primary1} !important;
  }
  /* ================================================
    TABLE STYLES OVERWRITING ANTD TABLE DEFAULT STYLES 
  ==================================================== */

  .ant-empty-description {
    color: #fff;
  }
  .ant-table-tbody {
    background: ${theme.colors.background2} !important;
    color: ${theme.colors.text3};
  }  

  .ant-tabs-ink-bar {
    background: ${theme.colors.primary5};
  }
  
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: ${theme.colors.background3} !important;
  }
  .ant-table-thead > tr > th, .ant-table-thead > tr > th > span {
    color: ${theme.colors.text} !important;
    background: ${theme.colors.background2} !important;
    border-bottom: 1px solid ${theme.colors.background};
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.5px;
    font-weight: 400;
  } 
  .ant-table-thead > tr > th > div, .ant-table-thead > tr > th > span {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.5px;
    font-weight: 400;
  } 
 
  .ant-table-tbody > tr > td {
      border-bottom: 1px solid ${theme.colors.background};
  }
 
  /** ANTD TABS */
  .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid ${theme.colors.background2} !important;
  }
  .ant-tabs {
    .ant-tabs-tab-btn {
      color: ${theme.colors.text2} !important;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: ${theme.colors.primary4} !important;
    }
  }


  /**
    SELECT INPUT
  */


  .ant-select-selector {
    background: ${theme.colors.background2} !important;
    border: 1px solid ${theme.colors.background2} !important;
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      background: ${theme.colors.background} !important;
      color: ${theme.colors.text3} !important;
      border: 1px solid ${theme.colors.background} !important;
    }
    .ant-select-selection-item-remove {
      color: ${theme.colors.text} !important;
      font-size: 10px !important;
    }
  }


  /**
    DRAWER
  */

    .ant-tooltip-inner {
        background-color: ${theme.colors.background};
    }


  .ant-drawer-header {
    background: ${theme.colors.background} !important;
    border-bottom: 1px solid ${theme.colors.background} !important;
  }

  .ant-drawer-title {
    color:  ${theme.colors.text} !important;
  }

  .ant-drawer-content, .ant-drawer-body, .ant-drawer-header {
    background-color: ${theme.colors.background} !important;
  }

  /** SELECT INPUT */
  .ant-select,
  .ant-select-multiple,
  .ant-select-selector {
    min-height: 50px;
    background: ${theme.colors.background2};
    border: 1px solid ${theme.colors.background2};
    color: ${theme.colors.text};
    border-radius: 5px;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      background: #fff;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
    align-items: center;
    display: flex;
    border-radius: 5px;
    border: 1px solid ${theme.colors.background2};
    background: ${theme.colors.background2};
  }
  .ant-select-selection-search-input {
    height: 48px !important;
  }
  .ant-select-selection-placeholder {
    color: ${theme.colors.text2};
    opacity: 1;
  }

`;

export default theme;
