// TOP LEVEL IMPORTS
import { Route, Switch } from "react-router-dom";
// PUBLIC
import PublicRoute from "components/route-components/PublicRoute";
import ClientRoute from "components/route-components/ClientRoute";
import AdminRoute from "components/route-components/AdminRoute";
// COMPONENTS
import Loading from "components/common/Loading";
import ErrorState from "components/common/ErrorState";
// LAYOUTS
import PublicLayout from "components/layouts/PublicLayout";
import AdminLayout from "components/layouts/AdminLayout";
import ClientLayout from "components/layouts/ClientLayout";
// CLIENT
import ClientHome from "./client-home";
import ClientInstances from "./client-instances";
import ClientInstanceDetail from "./client-instance-detail";
import ClientApplications from "./client-applications";
import ClientVpn from "./client-vpn";
import ClientDataProtection from "./client-data-protection";
import ClientTickets from "./client-tickets";
import ClientDocuments from "./client-documents";
import ClientSlas from "./client-reports-slas";
import ClientReportsCapacity from "./client-reports-capacity";
import ClientBilling from "./client-billing";
import ClientAccount from "./client-account";
import ClientTicketDetail from "./client-ticket-detail";
import ClientTicketCreate from "./client-ticket-create";
// ADMIN
import AdminInvoices from "./admin-invoices";
import AdminPolicies from "./admin-policies";
import AdminPermissions from "./admin-permissions";
import AdminOrganizations from "./admin-organizations";
import AdminScriptTemplates from "./admin-script-templates";
import AdminScriptBroadcasts from "./admin-script-broadcasts";
import AdminSuperAdmins from "./admin-super-admins";
import AdminUsers from "./admin-users";
import AdminApiphaniSkus from "./admin-apiphani-skus";
import AdminServiceDesk from "./admin-service-desk";
// AUTH
import AuthLogin from "./auth-login";
import AuthForgotPassword from "./auth-forgot-password";
import AuthResetPassword from "./auth-reset-password";
import AuthMagicLink from "./auth-magiclink";
import AuthMfa from "./auth-mfa";
// GRAPHQL
import { useCurrentUserQuery } from "generated/graphql";
// LIB
import config from "lib/config";
import AdminBillingOverview from "./admin-billing-overview";

const NETWORK_ERRORS = ["Network error: Failed to fetch", "Failed to fetch"];

export default function AppRoutes() {
  const { data, loading, error } = useCurrentUserQuery({
    pollInterval: 30000, // poll every 30 seconds to see if the user is still logged in
  });

  if (loading) return <Loading />;

  if (error?.message) {
    return (
      <PublicLayout>
        <ErrorState
          title={NETWORK_ERRORS?.includes(error?.message || "") ? "We seem to be having trouble connecting..." : "Something went wrong..."}
          subtitle={`Please try to refresh. If the problem persists, please reach out to us at ${config.supportEmail}`}
        />
      </PublicLayout>
    );
  }

  const sharedProps = {
    currentUser: data?.currentUser,
  };

  return (
    <>
      <Switch>
        {/* CLIENT ROUTES */}
        <ClientRoute exact layout={ClientLayout} path={["/app/home", "/app"]} component={ClientHome} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/instances/:id"]} component={ClientInstanceDetail} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/instances"]} component={ClientInstances} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/applications"]} component={ClientApplications} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/vpn"]} component={ClientVpn} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/data-protection"]} component={ClientDataProtection} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/tickets/create"]} component={ClientTicketCreate} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/tickets/:id"]} component={ClientTicketDetail} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/tickets"]} component={ClientTickets} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/documents"]} component={ClientDocuments} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/billing"]} component={ClientBilling} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/account"]} component={ClientAccount} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/reports/capacity"]} component={ClientReportsCapacity} {...sharedProps} />
        <ClientRoute exact layout={ClientLayout} path={["/app/reports/slas"]} component={ClientSlas} {...sharedProps} />
        {/* PUBLIC ROUTES */}
        <PublicRoute exact layout={PublicLayout} path={["/login", "/"]} component={AuthLogin} {...sharedProps} />
        <PublicRoute exact layout={PublicLayout} path={["/magiclink/:token"]} component={AuthMagicLink} {...sharedProps} />
        <PublicRoute exact layout={PublicLayout} path={["/mfa"]} component={AuthMfa} {...sharedProps} />
        <PublicRoute exact layout={PublicLayout} path={["/forgot-password"]} component={AuthForgotPassword} {...sharedProps} />
        <PublicRoute exact layout={PublicLayout} path={["/reset-password/:token"]} component={AuthResetPassword} {...sharedProps} />
        {/* ADMIN ROUTES */}
        <AdminRoute
          exact
          layout={AdminLayout}
          path={["/admin/organizations", "/admin/home", "/admin"]}
          component={AdminOrganizations}
          {...sharedProps}
        />
        <AdminRoute exact layout={AdminLayout} path={["/admin/super-admins"]} component={AdminSuperAdmins} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/users"]} component={AdminUsers} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/permissions"]} component={AdminPermissions} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/invoices"]} component={AdminInvoices} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/billing-overview"]} component={AdminBillingOverview} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/apiphani-skus"]} component={AdminApiphaniSkus} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/policies"]} component={AdminPolicies} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/script-templates"]} component={AdminScriptTemplates} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/script-broadcasts"]} component={AdminScriptBroadcasts} {...sharedProps} />
        <AdminRoute exact layout={AdminLayout} path={["/admin/service-desk"]} component={AdminServiceDesk} {...sharedProps} />
        <Route
          render={() => (
            <PublicLayout>
              <ErrorState
                title="404 Page Not Found"
                subtitle={`We could not find this page. Please go back to the last page or if the problem persists, reach out to us at ${config.supportEmail}`}
              />
            </PublicLayout>
          )}
        />
      </Switch>
    </>
  );
}
