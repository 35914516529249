import {
  useGetOneUserQuery,
  useUpdateOneUserMutation,
} from "generated/graphql";
import Loading from "components/common/Loading";
import UserForm from "components/forms/UserForm";
import Text from "components/text/Text";
import { message } from "antd";

export default function EditUser({ userId, onCancel }) {
  const { data, loading, error } = useGetOneUserQuery({
    variables: {
      id: userId,
    },
  });
  const [updateOneUserMutation, { loading: saving }] =
    useUpdateOneUserMutation();
  if (loading) return <Loading />;
  if (error) return <Text>Someting went wrong...</Text>;

  const user = data?.getOneUser;

  return (
    <div>
      <UserForm
        onSubmit={async (values) => {
          console.log(values);
          try {
            if (!user?.id) return;
            await updateOneUserMutation({
              variables: {
                id: user.id,
                params: {
                  email: values?.email,
                  firstName: values?.firstName,
                  lastName: values?.lastName,
                  organizationIds: values?.organizationIds,
                },
              },
            });
            message.success("Changes saved");
            onCancel();
          } catch (err) {
            console.log(err);
          }
        }}
        onCancel={onCancel}
        loading={saving}
        defaultValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          userType: user?.userType,
          permission: user?.permission,
          organizationIds: user?.organizations?.[0]
            ? user?.organizations?.map((org) => org?.id)
            : ([] as any),
        }}
      />
    </div>
  );
}
