import { useState } from "react";
import {
  GetSla_DiskUtilizationDocument,
  GetSla_SummaryTableDocument,
  GetSla_SummaryTableQuery,
  GetSla_UptimeQuery,
  GetSla_UptimeDocument,
  GetSla_DiskUtilizationQuery,
  UptimeTypeEnum,
} from "generated/graphql";
import Papa from "papaparse";

import moment from "moment";
import client from "ApolloClient/index";
import useDownloadZip from "lib/hooks/useDownloadZip";

import { ApolloQueryResult } from "@apollo/client";

import message from "components/common/message";
import { omit } from "lodash";

const cleanUtilData = (data: any[]) => {
  return data?.map((item) => omit(data, ["__typename"]));
};

export default function useDownloadAllSlaStats() {
  const [downloadZip] = useDownloadZip();

  const [downloading, setDownloading] = useState<true | false>(false);

  const onDownloadSlaData = async (variables: any, fieldsToRemove?: string[]) => {
    try {
      setDownloading(true);

      const dataSources: any[] = [];

      const vars: any = {
        ...variables,
        skip: 0,
        limit: 1000,
      };

      /**
       *  PROD UTILIZATION
       */

      const utilProd: ApolloQueryResult<GetSla_DiskUtilizationQuery> = await client.query({
        query: GetSla_DiskUtilizationDocument,
        variables: {
          ...vars,
          production: true,
        },
      });

      dataSources.push({
        name: `ProdUtilization-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(utilProd?.data?.getSLA_DiskUtilization || []),
      });

      /**
       * NON PROD UTILIZATION
       */

      const utilNonProd: ApolloQueryResult<GetSla_DiskUtilizationQuery> = await client.query({
        query: GetSla_DiskUtilizationDocument,
        variables: {
          ...vars,
          production: false,
        },
      });

      dataSources.push({
        name: `NonProdUtilization-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(utilNonProd?.data?.getSLA_DiskUtilization || []),
      });

      /**
       *  PROD UPTIME SAP
       */

      const uptimeProd: ApolloQueryResult<GetSla_UptimeQuery> = await client.query({
        query: GetSla_UptimeDocument,
        variables: {
          ...vars,
          production: true,
          uptimeType: UptimeTypeEnum?.Sap,
        },
      });

      dataSources.push({
        name: `ProdUptimeSap-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(uptimeProd?.data?.getSLA_Uptime || []),
      });

      /**
       *  PROD UPTIME HOSTS
       */

      const uptimeProdHost: ApolloQueryResult<GetSla_UptimeQuery> = await client.query({
        query: GetSla_UptimeDocument,
        variables: {
          ...vars,
          production: true,
          uptimeType: UptimeTypeEnum?.Host,
        },
      });

      dataSources.push({
        name: `ProdUptimeHosts-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(uptimeProdHost?.data?.getSLA_Uptime || []),
      });

      /**
       *  NON PROD UPTIME SAP
       */

      const uptimeNonProd: ApolloQueryResult<GetSla_UptimeQuery> = await client.query({
        query: GetSla_UptimeDocument,
        variables: {
          ...vars,
          production: false,
          uptimeType: UptimeTypeEnum?.Sap,
        },
      });

      dataSources.push({
        name: `ProdUptimeSap-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(uptimeNonProd?.data?.getSLA_Uptime || []),
      });

      /**
       *  NON PROD UPTIME HOSTS
       */

      const uptimeNonProdHost: ApolloQueryResult<GetSla_UptimeQuery> = await client.query({
        query: GetSla_UptimeDocument,
        variables: {
          ...vars,
          production: false,
          uptimeType: UptimeTypeEnum?.Host,
        },
      });

      dataSources.push({
        name: `NonProdUptimeHosts-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(uptimeNonProdHost?.data?.getSLA_Uptime || []),
      });

      /**
       * UptimeSummaryTable
       */

      const uptimeSummary: ApolloQueryResult<GetSla_SummaryTableQuery> = await client.query({
        query: GetSla_SummaryTableDocument,
        variables: {
          ...vars,
          production: false,
          uptimeType: UptimeTypeEnum?.Host,
        },
      });

      dataSources.push({
        name: `UptimeSummaryTable-${moment()?.format("MMM DD YYYY")}.csv`,
        data: cleanUtilData(uptimeSummary?.data?.getSLA_SummaryTable || []),
      });

      // get steps

      //   const stepsResult: ApolloQueryResult<GetSla_SummaryTableQuery> = await client.query({
      //     query: GetSla_SummaryTableDocument,
      //     variables: vars,
      //   });

      //   dataSources.push({
      //     name: `Steps-${moment()?.format("MMM DD YYYY")}.csv`,
      //     data: stepsResult?.data?.getSLA_SummaryTable?.map((item) => formatOtherData(item)),
      //   });

      //   const respitoryResult: ApolloQueryResult<GetSla_UptimeQuery> = await client.query({
      //     query: GetSla_UptimeDocument,
      //     variables: vars,
      //   });

      //   dataSources.push({
      //     name: `SlaUptime-${moment()?.format("MMM DD YYYY")}.csv`,
      //     data: respitoryResult?.data?.getSLA_Uptime?.map((item) => formatOtherData(item)),
      //   });

      const files = dataSources?.map((dataSource) => {
        let data = Papa.unparse(dataSource.data, {
          header: true,
        });
        let csvData = new Blob([data], { type: "text/csv;charset=utf-8;" });
        return {
          name: dataSource.name,
          file: csvData,
        };
      });

      await downloadZip(files, "SLA_Summary.zip");

      setDownloading(false);
    } catch (err: any) {
      setDownloading(false);
      message.error(err.message);
      console.log(err);
    }
  };

  return [onDownloadSlaData, downloading] as const;
}
