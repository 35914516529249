import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { InstanceKeyUseEnum } from "generated/graphql";
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function InstanceKeyUseInput(props) {
  const { onChange, value } = props;

  return (
    <Select
      style={{ width: "100%" }}
      {...props}
      size="large"
      onChange={onChange}
      value={value || undefined}
      optionFilterProp="children"
    >
      {Object.values(InstanceKeyUseEnum)?.map((label) => {
        if (!label) return null;
        return (
          <Option key={label} value={label}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
}
