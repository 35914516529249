import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DarkTable from "components/common/DarkTable";
import Loading from "components/common/Loading";
import {
  TierBackup,
  useGetManyInstanceBackupsQuery,
  UserProfile,
} from "generated/graphql";
import { toString } from "lodash";
import theme from "lib/theme";
import { useHistory } from "react-router-dom";
import { useState } from "react";

export default function ClientDataProtection({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  const { data, loading } = useGetManyInstanceBackupsQuery({
    variables: {
      organizationId: toString(currentUser?.activeOrganization?.id),
    },
  });

  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: 10,
  });

  const history = useHistory();

  const dotStyle = {
    width: 6,
    marginRight: 3,
    height: 16,
    borderRadius: 2,
  };

  const columns = [
    {
      title: "Instance",
      key: "id",
      render: (record) => record?.instance?.externalId,
    },
    {
      title: "Tier",
      render: (record) => record.tier,
    },
    {
      title: "Last Updated",
      key: "id",
      render: (record) => record.last15BackupsOS?.[0]?.startTime,
    },
    {
      title: "Last 15 Backups (OS)",
      key: "id",
      render: (record: TierBackup) => (
        <div style={{ display: "flex" }}>
          {record.last15BackupsOS?.map((item) => {
            return item?.status === "successful" ? (
              <div
                style={{
                  ...dotStyle,
                  background: theme.colors.success7,
                }}
              />
            ) : (
              <div
                style={{
                  ...dotStyle,
                  background: theme.colors.red6,
                }}
              />
            );
          })}
        </div>
      ),
    },
    {
      title: "Last 15 Backups (DB)",
      key: "id",
      render: (record: TierBackup) => (
        <div style={{ display: "flex" }}>
          {record?.last15BackupsDB?.map((item) => {
            return item?.status === "successful" ? (
              <div
                style={{
                  ...dotStyle,
                  background: theme.colors.success7,
                }}
              />
            ) : (
              <div
                style={{
                  ...dotStyle,
                  background: theme.colors.red6,
                }}
              />
            );
          })}
        </div>
      ),
    },
  ];

  if (loading) return <Loading />;

  return (
    <PageContainer>
      <PageTitle style={{ marginBottom: 16 }}>Data Protection</PageTitle>
      <DarkTable
        dataSource={data?.getManyInstanceBackups}
        columns={columns}
        pagination={{
          pageSize: filters?.limit,
          current: filters.current,
          total: data?.getManyInstanceBackups?.length,
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              history.push(
                `/app/instances/${record?.instance?.id}?tab=DATA_PROTECTION`
              );
            },
          };
        }}
      />
    </PageContainer>
  );
}
