import { useGetCostAnalysisQuery } from "generated/graphql";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { toNumber } from "lodash";
import numeral from "numeral";
import theme from "lib/theme";
import formatCurrency from "lib/helpers/formatCurrency";
import config from "lib/config";

export default function CostAnalysisChart({ organizationId }: any) {
  const { data, loading } = useGetCostAnalysisQuery({
    variables: {
      organizationId,
    },
  });

  if (loading) return null;

  const keys = [
    ...(new Set(
      data?.getCostAnalysis
        ?.map((item) => item?.values?.map((i) => i?.key))
        ?.flat() as any
    ) as any),
  ];

  const chartData = data?.getCostAnalysis?.map((item) => {
    let dataPoint: any = {
      name: item?.id,
    };
    item?.values?.forEach((item) => {
      dataPoint = {
        ...dataPoint,
        [item?.key as string]: toNumber(item?.value),
      };
    });
    return dataPoint;
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData?.reverse()}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 32,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          dataKey="name"
          type="category"
          angle={-40}
          textAnchor="end"
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: 12 }}
        />
        <YAxis
          domain={[0, "dataMax"]}
          min={0}
          tick={{ fontSize: 12 }}
          tickLine={false}
          //tickCount={6}
          axisLine={false}
          tickFormatter={(value) => numeral(value).format("0a")}
        />
        <Tooltip
          formatter={(value, label) => [formatCurrency(value), label]}
          contentStyle={{
            backgroundColor: theme.colors.background2,
            borderColor: theme.colors.background2,
            padding: 8,
          }}
          cursor={{ fill: theme.colors.background3, width: 20 }}
          labelStyle={{
            color: theme.colors.text,
            fontSize: 10,
          }}
          itemStyle={{
            color: theme.colors.text,
            fontSize: 10,
          }}
        />
        {/* <Legend /> */}
        {keys?.map((key, index) => (
          <Bar dataKey={key} fill={config.chartColors[index] || "#8884d8"} />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
