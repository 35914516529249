import styled from "styled-components";

const Input = styled.input`
  color: ${(p) => p.theme.colors.text3};
  border: 0px;
  width: 100%;
  height: 56px;
  padding-left: 8px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid ${(p) => p.theme.colors.background};
  &:focus {
    outline: 0;
    background: unset;
    border: 2px solid ${(p) => p.theme.colors.primary7};
  }
  background: unset;
  &:disabled {
    cursor: not-allowed;
  }
  /** styles for chrome */
  box-shadow: inset 0 0 0 1000px ${(p) => p.theme.colors.background2} !important;

  ::placeholder {
    color: ${(p) => p.theme.colors.text};
    font-weight: 300;
    font-size: 15px;
  }
`;

export default Input;
