import Caption from "components/text/Caption";
import {
  useGetManyUsersQuery,
  UserProfile,
  UserTypeEnum,
  useResendInviteEmailMutation,
  GetManyUsersDocument,
} from "generated/graphql";
import styled from "styled-components";
import moment from "moment";
import Tooltip from "components/common/Tooltip";
import message from "components/common/message";
import theme from "lib/theme";
import DarkTable from "components/common/DarkTable";
import { useState } from "react";

const ActionsBtn = styled.button`
  background: transparent;
  border: 0px;
  color: ${(p) => p.theme.colors.text};
  cursor: pointer;
  padding: 0px;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.text2};
  }
`;

const LIMIT = 5;

export default function SuperAdminsTable() {
  const { data, loading, error } = useGetManyUsersQuery({
    variables: {
      userTypes: [UserTypeEnum.SuperAdmin],
    },
  });
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
  });
  const [resendInviteEmailMutation, { loading: resendingInvite }] =
    useResendInviteEmailMutation();

  if (loading) return <Caption>Loading...</Caption>;

  if (error) return <Caption>Error...</Caption>;

  const columns = [
    {
      key: "id",
      title: "ID",
      render: (record: UserProfile) => record.id,
    },
    {
      title: "Email",
      render: (record: UserProfile) => record.email,
    },
    {
      title: "First Name",
      render: (record: UserProfile) => record.firstName,
    },
    {
      title: "Last Name",
      render: (record: UserProfile) => record.lastName,
    },
    {
      title: "Invite",
      width: "22.5%",
      render: ({ id, acceptedInvite, recentlySentInvite }: UserProfile) => {
        if (recentlySentInvite) {
          return `Sent ${moment(parseInt(recentlySentInvite)).fromNow()}`;
        }

        return acceptedInvite ? (
          "Accepted"
        ) : (
          <Tooltip title="send another invite email">
            <ActionsBtn
              disabled={resendingInvite}
              style={{ color: theme.colors.primary4, fontWeight: 600 }}
              onClick={async () => {
                try {
                  if (!id) return;
                  await resendInviteEmailMutation({
                    variables: {
                      userId: id,
                    },
                    refetchQueries: [
                      {
                        query: GetManyUsersDocument,
                        variables: {
                          userType: UserTypeEnum.SuperAdmin,
                        },
                      },
                    ],
                  });
                  message.success("Invite resent");
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {!resendingInvite ? "Resend Invite" : "Sending..."}
            </ActionsBtn>
          </Tooltip>
        );
      },
    },
  ];

  const users = data?.getManyUsers || [];

  return (
    <div style={{ marginTop: 24 }}>
      <DarkTable
        rowKey="id"
        dataSource={users as UserProfile[]}
        columns={columns}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: data?.getManyUsers?.length,
        }}
      />
    </div>
  );
}
