import { ResponsiveLine } from "@nivo/line";
import theme from "lib/theme";
import moment from "moment";

export default function AreaChart({
  data,
  xTimeScale = "every week",
}: {
  data: any[];
  xTimeScale?: "every 6 hours" | "every day" | "every week";
}) {
  console.log(data);
  return (
    <ResponsiveLine
      data={data}
      enableSlices="x"
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%dT%H:%M:%S.%L%Z",
      }}
      yScale={{
        type: "linear",
        max: 100,
        min: 0,
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: "rgba(0,0,0,.4)",
              padding: "9px 12px",
              border: "0px",
            }}
          >
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  color: point.serieColor,
                  padding: "3px 0",
                }}
              >
                <strong>{point.serieId}</strong> {point.data.yFormatted}%
              </div>
            ))}
          </div>
        );
      }}
      theme={{
        fontSize: 11,
        textColor: theme.colors.text2,
        axis: {
          domain: {
            line: {
              stroke: theme.colors.background3,
              strokeWidth: 1,
            },
          },
          ticks: {
            line: {
              stroke: theme.colors.background3,
              strokeWidth: 1,
            },
          },
        },
        grid: {
          line: {
            stroke: theme.colors.background3,
            strokeWidth: 1,
            strokeDasharray: "4 4",
          },
        },
      }}
      colors={{ scheme: "set3" }}
      yFormat=" >-.2f"
      //xFormat="%d-%m-%Y"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        // tickValues: xTimeScale,
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (value) => moment(value).format("M/D"),
        legendOffset: 36,
        legendPosition: "middle",
      }}
      fill={[{ match: "*", id: "gradientA" }]}
      //xScale={{ format: "%Y-%m-%dT%H:%M:%S.%L%Z", type: "time" }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: "Percentage %",
        legendOffset: -40,
      }}
      enableArea={true}
      pointSize={0}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      // pointLabelYOffset={-12}
      // defs={[
      //  lin("gradientA", [
      //    { offset: 0, color: "inherit" },
      //    { offset: 100, color: "inherit", opacity: 0 },
      //  ]),
      //]}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          translateX: 100,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#fff",
              },
            },
          ],
        },
      ]}
    />
  );
}
