import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import FormSectionBreak from "components/common/FormSectionBreak";
import SwitchInput from "components/inputs/SwitchInput";
import ApmServiceSelectInput from "components/inputs/ApmServiceSelectInput";
import OrganizationConfigurationTypeSelectInput from "components/inputs/OrganizationConfigurationTypeSelectInput";
import VendorSelectInput from "components/inputs/VendorSelectInput";
import TicketingServiceInput from "components/inputs/TicketingServiceInput";

import { ApmServiceEnum, CloudVendorTypeEnum, OrganizationFeatureSettingsInput, OrganizationConfigurationTypeEnum } from "generated/graphql";

const Form = styled.form`
  width: 550px;
  max-width: 100%;
`;

interface OrganiaztionFormProps {
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
  shortForm?: boolean;
  defaultValues?: {
    name?: string | null;
    billingId?: string | null;
    customer_tla?: string | null;
    backupHistoryLocation?: CloudVendorTypeEnum | null;
    backupHistoryBucketName?: string | null;
    documentsLocation?: CloudVendorTypeEnum | null;
    documentsBucketName?: string | null;
    configurationType?: OrganizationConfigurationTypeEnum | null;
    apmService?: ApmServiceEnum | null;
    cmdbBucketName: string | null | undefined;
    cmdbBucketLocation: CloudVendorTypeEnum | null | undefined;
    apmApiKey: string | null;
    apmApiUrl: string | null;
    ticketingService: string | null;
    settings?: {
      features?: OrganizationFeatureSettingsInput | null;
    } | null;
  };
}

const FEATURES = [
  {
    name: "settings.features.documentsEnabled",
    key: "documentsEnabled",
    label: "Documents",
  },
  {
    name: "settings.features.vpnEnabled",
    key: "vpnEnabled",
    label: "VPNs",
  },
  {
    name: "settings.features.dataProtectionEnabled",
    key: "dataProtectionEnabled",
    label: "Data Protection",
  },
  {
    name: "settings.features.ticketsEnabled",
    key: "ticketsEnabled",
    label: "Ticktets",
  },
  {
    name: "settings.features.billingEnabled",
    key: "billingEnabled",
    label: "Billing",
  },
  {
    name: "settings.features.reportsEnabled",
    key: "reportsEnabled",
    label: "Reports",
  },
];

export default function OrganiationForm({ onSubmit, onCancel, loading, defaultValues, shortForm }: OrganiaztionFormProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      name: defaultValues?.name,
      billingId: defaultValues?.billingId,
      customer_tla: defaultValues?.customer_tla,
      backupHistoryLocation: defaultValues?.backupHistoryLocation || CloudVendorTypeEnum.Aws,
      backupHistoryBucketName: defaultValues?.backupHistoryBucketName,
      documentsLocation: defaultValues?.documentsLocation || CloudVendorTypeEnum.Aws,
      documentsBucketName: defaultValues?.documentsBucketName,
      configurationType: defaultValues?.configurationType,
      apmService: defaultValues?.apmService,
      apmApiKey: defaultValues?.apmApiKey,
      apmApiUrl: defaultValues?.apmApiUrl,
      ticketingService: defaultValues?.ticketingService,
      cmdbBucketName: defaultValues?.cmdbBucketName,
      cmdbBucketLocation: defaultValues?.cmdbBucketLocation,
      settings: {
        features: {
          documentsEnabled: defaultValues?.settings?.features?.documentsEnabled,
          vpnEnabled: defaultValues?.settings?.features?.vpnEnabled,
          dataProtectionEnabled: defaultValues?.settings?.features?.dataProtectionEnabled,
          ticketsEnabled: defaultValues?.settings?.features?.ticketsEnabled,
          billingEnabled: defaultValues?.settings?.features?.billingEnabled,
          reportsEnabled: defaultValues?.settings?.features?.reportsEnabled,
        },
      },
      ticketingServiceApiKey: undefined, // the ticketingServiceApiKey is never returned to the client once it's added... so its not editable only over-writeable
    },
  });

  const apmService = watch("apmService");

  const beforeOnSubmit = handleSubmit((data) => {
    if (!data) return;
    onSubmit(data);
  });

  return (
    <Form onSubmit={beforeOnSubmit}>
      <FormItem label="Organization Name" required error={errors?.name?.message}>
        <TextInput
          {...register("name", {
            required: "Name is required.",
          })}
        />
      </FormItem>
      <FormItem label="Billing ID" required error={errors?.billingId?.message}>
        <TextInput
          {...register("billingId", {
            required: "billing Id is required.",
          })}
        />
      </FormItem>
      <FormItem label="Customer TLA" required error={errors?.customer_tla?.message}>
        <TextInput
          {...register("customer_tla", {
            required: "Customer TLA is required.",
          })}
        />
      </FormItem>
      {!shortForm && (
        <>
          <FormSectionBreak title="Ticketing system" caption="Where is the ticketing system located?" />
          <FormItem label="Ticketing Service API" hint="e.g. a samanage api key" error={errors?.ticketingServiceApiKey?.message}>
            <TextInput {...register("ticketingServiceApiKey")} />
          </FormItem>
          <FormItem label="Ticketing Service" error={errors?.ticketingService?.message}>
            <Controller
              name="ticketingService"
              control={control}
              render={({ field }) => <TicketingServiceInput {...field} onChange={(configurationType) => field.onChange(configurationType)} />}
            />
          </FormItem>
          <FormSectionBreak title="Features" caption="Toggle features on/off for this client" />{" "}
          <Row>
            {FEATURES?.map((feature) => {
              return (
                <Col xs={12} key={feature.key}>
                  <FormItem label={feature.label} error={errors?.settings?.features?.[feature.key]?.message}>
                    <Controller
                      name={`${feature.name}` as any}
                      control={control}
                      render={({ field }) => (
                        <SwitchInput
                          {...field}
                          defaultChecked={defaultValues?.settings?.features?.[feature.key] ? true : false}
                          onChange={(newvalue) => field.onChange(newvalue)}
                        />
                      )}
                    />
                  </FormItem>
                </Col>
              );
            })}
          </Row>
          <FormSectionBreak
            title="Configuration Options"
            caption="Configure how and where certain details like documents, backup histories and other things are stored/retrieved"
          />
          <FormItem label="Configuration" hint="Where are the configuration files stored?" error={errors?.configurationType?.message}>
            <Controller
              name="configurationType"
              control={control}
              render={({ field }) => (
                <OrganizationConfigurationTypeSelectInput {...field} onChange={(configurationType) => field.onChange(configurationType)} />
              )}
            />
          </FormItem>
          <Row gutter={16}>
            <Col xs={8}>
              <FormItem
                label="Backup history location"
                hint="Which cloud provider are the backup histories located under?"
                error={errors?.backupHistoryLocation?.message}
              >
                <Controller
                  name="backupHistoryLocation"
                  control={control}
                  render={({ field }) => <VendorSelectInput {...field} onChange={(backupHistoryLocation) => field.onChange(backupHistoryLocation)} />}
                />
              </FormItem>
            </Col>
            <Col xs={16}>
              <FormItem
                label="Backup History Bucket Name"
                hint="What is the name of the bucket where backups are stored?"
                error={errors?.backupHistoryBucketName?.message}
              >
                <TextInput {...register("backupHistoryBucketName", {})} />
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                label="Documents location"
                hint="Which cloud provider are documents located under?"
                error={errors?.documentsLocation?.message}
              >
                <Controller
                  name="documentsLocation"
                  control={control}
                  render={({ field }) => <VendorSelectInput {...field} onChange={(documentsLocation) => field.onChange(documentsLocation)} />}
                />
              </FormItem>
            </Col>
            <Col xs={16}>
              <FormItem
                label="Documents Bucket Name"
                hint="What is the name of the bucket where documents are stored?"
                error={errors?.documentsBucketName?.message}
              >
                <TextInput {...register("documentsBucketName", {})} />
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem
                label="CMDB location"
                hint="Which cloud provider are cmdb data located under? (S3, Azure Blob storage, etc)"
                error={errors?.cmdbBucketLocation?.message}
              >
                <Controller
                  name="cmdbBucketLocation"
                  control={control}
                  render={({ field }) => <VendorSelectInput {...field} onChange={(cmdbBucketLocation) => field.onChange(cmdbBucketLocation)} />}
                />
              </FormItem>
            </Col>
            <Col xs={16}>
              <FormItem
                label="CMDB Bucket Name"
                hint="What is the name of the bucket where CMDB CSVs are stored?"
                error={errors?.cmdbBucketName?.message}
              >
                <TextInput {...register("cmdbBucketName", {})} />
              </FormItem>
            </Col>
            <Col xs={8}>
              <FormItem label="APM Service" error={errors?.apmService?.message}>
                <Controller
                  name="apmService"
                  control={control}
                  render={({ field }) => <ApmServiceSelectInput {...field} onChange={(apmService) => field.onChange(apmService)} />}
                />
              </FormItem>
            </Col>
            <Col xs={16}>
              <FormItem label="APM API Key" error={errors?.apmApiKey?.message} tooltip="e.g. the Dynatrace API key">
                <TextInput {...register("apmApiKey")} />
              </FormItem>
            </Col>

            {apmService === ApmServiceEnum?.Dynatrace?.toString() && (
              <Col xs={24}>
                <FormItem
                  label="APM API URL"
                  error={errors?.apmApiUrl?.message}
                  tooltip="e.g. the Dynatrace API URL (https://abc123.live.dynatrace.com)"
                >
                  <TextInput {...register("apmApiUrl")} />
                </FormItem>
              </Col>
            )}
          </Row>
        </>
      )}

      <div style={{ marginTop: 60, marginBottom: 32 }}>
        <FormItem>
          <Button type="button" onClick={onCancel} grey style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button type="submit" loading={loading} disabled={loading}>
            Save
          </Button>
        </FormItem>
      </div>
    </Form>
  );
}
