import Caption from "components/text/Caption";
import ItemTitle from "components/text/ItemTitle";
import styled from "styled-components";

const Container = styled.div`
  margin-top: 48px;
  margin-bottom: 32px;
  border-top: 1px solid ${({ theme }) => theme.colors.background2};
  padding-top: 16px;
`;

export default function FormSectionBreak({ title, caption }) {
  return (
    <Container>
      <ItemTitle style={{ marginBottom: 4 }}>{title}</ItemTitle>
      <Caption>{caption}</Caption>
    </Container>
  );
}
