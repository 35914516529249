import styled from "styled-components";

const Container = styled.div<{ active?: boolean; fullWidth?: boolean }>`
  background-color: ${({ theme }) => theme.colors.background2};
  border: 1px solid
    ${({ theme, active }) => {
      if (active) return "#fff";
      return theme.colors.background4;
    }};
  border-radius: ${({ theme }) => theme.borderRadius};
  min-height: 336px;
  position: relative;
  padding: 24px;
  margin-top: 16px;
  width: ${({ fullWidth }) => {
    if (fullWidth) return "100%";
    return "420px";
  }};
  max-width: 100%;
`;

export default function Card({
  children,
  fullWidth,
}: {
  children: any;
  fullWidth?: boolean;
}) {
  return <Container fullWidth={fullWidth}>{children}</Container>;
}
