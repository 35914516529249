import { useState } from "react";
// GRAPHQL
import {
  useCreateNewUserMutation,
  UserProfile,
  UserProfileInput,
  useResendInviteEmailMutation,
  useDeleteUserMutation,
} from "generated/graphql";
// COMPONENTS
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Tooltip from "components/common/Tooltip";
import NewUserForm from "components/forms/UserForm";
import message from "components/common/message";
import ButtonText from "components/common/ButtonText";
import Popconfirm from "components/common/Popconfirm";
import Drawer from "components/common/Drawer";
import moment from "moment";
import theme from "lib/theme";
import styled from "styled-components";
import EditUser from "components/common/EditUser";
import DarkTable from "components/common/DarkTable";
import Tag from "components/common/Tag";
import Text from "components/text/Text";
import Caption from "components/text/Caption";

const ActionsBtn = styled.button`
  background: transparent;
  border: 0px;
  color: ${(p) => p.theme.colors.text};
  cursor: pointer;
  padding: 0px;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.text2};
  }
`;

export default function UsersTable({ dataSource, onChange, pagination }) {
  const [addNewKey, setAddNewKey] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<false | string>(false);
  const [createNewUserMutation, { loading: creating }] =
    useCreateNewUserMutation();
  const [resendInviteEmailMutation, { loading: resendingInvite }] =
    useResendInviteEmailMutation();
  const [deleteUserMutation, { loading: deleting }] = useDeleteUserMutation();

  const handleDelete = async (userId) => {
    try {
      await deleteUserMutation({
        variables: {
          userId,
        },
        refetchQueries: ["getManyUsers", "getUsersByOrganization"],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getDrawerTitle = () => {
    if (addNewKey) {
      return "Create a New User";
    }
    if (editUserId) {
      return "Edit User";
    }
  };

  const columns = [
    {
      title: "Name",
      key: "id",
      render: (record: UserProfile) => {
        return (
          <>
            <Text>
              {record?.firstName && record?.lastName
                ? `${record?.firstName} ${record?.lastName}`
                : record?.firstName}
            </Text>
            <Caption>{record.email}</Caption>
          </>
        );
      },
    },
    {
      title: "Type",
      render: (record: UserProfile) => <Tag>{record.userType}</Tag>,
    },
    {
      title: "Organizations",
      render: (record: UserProfile) => {
        if (record.deactivated) return null;

        if (!record?.organizations?.length) return null;

        const MAX = 2;

        if (record?.organizations?.length > MAX) {
          return (
            <>
              {record?.organizations
                .slice(0, MAX)
                ?.map((org) => org?.id && <Tag key={org?.id}>{org.name}</Tag>)}
              <Tooltip
                title={
                  <>
                    {record?.organizations
                      .slice(MAX, record?.organizations?.length)
                      ?.map(
                        (org) => org?.id && <Tag key={org?.id}>{org.name}</Tag>
                      )}
                  </>
                }
              >
                <Tag>+ {record?.organizations?.length - MAX} more</Tag>
              </Tooltip>
            </>
          );
        }

        return record?.organizations?.map(
          (org) => org?.id && <Tag key={org?.id}>{org.name}</Tag>
        );
      },
    },
    {
      title: "Invite",
      width: "22.5%",
      render: ({
        id,
        deactivated,
        acceptedInvite,
        recentlySentInvite,
      }: UserProfile) => {
        if (deactivated) return null;

        if (recentlySentInvite) {
          return (
            <Text>Sent {moment(parseInt(recentlySentInvite)).fromNow()}</Text>
          );
        }

        return acceptedInvite ? (
          <Text>Accepted</Text>
        ) : (
          <Tooltip title="send another invite email">
            <ActionsBtn
              disabled={resendingInvite}
              style={{ color: theme.colors.primary4, fontWeight: 600 }}
              onClick={async () => {
                try {
                  if (!id) return;
                  await resendInviteEmailMutation({
                    variables: {
                      userId: id,
                    },
                  });
                  message.success("Invite resent");
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {!resendingInvite ? "Resend Invite" : "Sending..."}
            </ActionsBtn>
          </Tooltip>
        );
      },
    },
    {
      title: "Edit",
      render: (record: UserProfile) => {
        if (record?.deactivated) return;
        return (
          <EditOutlined
            onClick={() => record?.id && setEditUserId(record?.id)}
            style={{ color: "#fff", cursor: "pointer" }}
          />
        );
      },
    },
    {
      title: "Remove",
      render: (record: UserProfile) => {
        if (record?.deactivated) return <Text>deactivated</Text>;
        return (
          <Popconfirm
            title="Are you sure you want to remove this user?"
            okText="yes, I'm sure"
            onConfirm={() => handleDelete(record.id)}
          >
            <ButtonText disabled={deleting}>
              <DeleteOutlined />
            </ButtonText>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      <DarkTable
        rowKey="id"
        dataSource={dataSource as UserProfile[]}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
      />
      <Drawer
        title={getDrawerTitle()}
        placement="right"
        width={500}
        onClose={() => {}}
        visible={addNewKey || editUserId ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        {addNewKey && (
          <NewUserForm
            loading={creating}
            onSubmit={async (values: UserProfileInput) => {
              try {
                await createNewUserMutation({
                  variables: {
                    params: {
                      email: values.email,
                      firstName: values.firstName,
                      lastName: values.lastName,
                      organizationIds: values?.organizationIds,
                      permission: values?.permission,
                      customPermissions: values?.customPermissions,
                      userType: values?.userType,
                    },
                  },
                });
                setAddNewKey(false);
                message.success("Your new user was created");
              } catch (err) {
                console.log(err);
              }
            }}
            onCancel={() => setAddNewKey(false)}
          />
        )}
        {editUserId && (
          <EditUser userId={editUserId} onCancel={() => setEditUserId(false)} />
        )}
      </Drawer>
    </div>
  );
}
