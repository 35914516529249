import theme from "lib/theme";

export enum NodeEnvironments {
  production = "production",
  development = "development",
}

interface Config {
  appName: string;
  companyName: string;
  supportEmail: string;
  GRAPHQL_API: string;
  NODE_ENV: NodeEnvironments;
  authTokenName: string;
  refreshTokenName: string;
  SENTRY_DSN?: string;
  IS_GOVCLOUD?: boolean;
  routePrefixes: {
    client: string;
    admin: string;
  };
  chartColors: string[];
}

const getEnv = (): NodeEnvironments => {
  if (process.env.NODE_ENV === NodeEnvironments.production) return NodeEnvironments.production;
  return NodeEnvironments.development;
};

const config: Config = {
  appName: "Luumen",
  companyName: "Apiphani, Inc.",
  supportEmail: "support@apiphani.io",
  authTokenName: process.env.AUTH_TOKEN_NAME || "luumen_staging_access_token",
  GRAPHQL_API: process.env.REACT_APP_API_HOST || "http://localhost:8080/graphql",
  NODE_ENV: getEnv(),
  refreshTokenName: process.env.REFRESH_TOKEN_NAME || "luumen_staging_refresh_token",
  routePrefixes: {
    client: "app",
    admin: "admin",
  },
  IS_GOVCLOUD: process.env.IS_GOVCLOUD === "true",
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  chartColors: [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#bc5090",
    "#ff6361",
    theme.colors.primary3,
    "#003f5c",
    "#F8CB2E",
    "#CCF3EE",
    "#F9F3EE",
    "#EE5007",
    "#AB46D2",
    "#FF6FB5",
    "#FCF69C",
    "#55D8C1",
  ],
};

export default config;
