import queryString from "query-string";
// COMPONENTS
import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Button from "components/common/Button";
import Drawer from "components/common/Drawer";
import PermissionsTable from "./PermissionsTable";
import CreatePermissionForm from "./CreatePermissionForm";
// LIB
import useUrlChange from "lib/hooks/useUrlChange";

interface AdminOrganiztionQueryParams {
  addNewPermission?: "true";
  permissionId?: string;
}

export default function AdminPermissions({ location }) {
  const { onUrlChange } = useUrlChange();
  const { addNewPermission }: AdminOrganiztionQueryParams = queryString.parse(
    location.search
  );

  return (
    <PageContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        <PageTitle>Permissions</PageTitle>
        <Button onClick={() => onUrlChange({ addNewPermission: true })}>
          New Permission
        </Button>
      </div>
      <PermissionsTable />
      <Drawer
        title="Add New Permission"
        placement="right"
        width={500}
        onClose={() => onUrlChange({ addNewPermission: undefined })}
        visible={addNewPermission ? true : false}
        height={200}
        getContainer={false}
        style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      >
        <CreatePermissionForm
          onCancel={() => onUrlChange({ addNewPermission: undefined })}
          onComplete={() => onUrlChange({ addNewPermission: undefined })}
        />
      </Drawer>
    </PageContainer>
  );
}
