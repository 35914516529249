import AntdModal, { ModalProps } from "antd/lib/modal";
import "antd/lib/modal/style/css";
import styled from "styled-components";

export const ModalButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

export default function Modal(props: ModalProps | any) {
  return (
    <AntdModal footer={null} destroyOnClose {...props}>
      {props?.children}
    </AntdModal>
  );
}
