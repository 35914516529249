import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DocumentsTable from "components/common/DocumentsTable";
import { UserProfile } from "generated/graphql";

export default function ClientDocuments({
  currentUser,
}: {
  currentUser: UserProfile;
}) {
  return (
    <PageContainer>
      <PageTitle>Documents</PageTitle>
      <DocumentsTable organizationId={currentUser.activeOrganization?.id} />
    </PageContainer>
  );
}
