import { useState } from "react";

import { useGetAllDueInvoicesQuery, Invoice } from "generated/graphql";
import DarkTable from "components/common/DarkTable";
import ErrorBlock from "components/common/ErrorBlock";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import formatCurrency from "lib/helpers/formatCurrency";
import formatDate from "lib/helpers/formatDate";
import theme from "lib/theme";

const DEFAULT_LIMIT = 5;

export default function DueInvoices() {
  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: DEFAULT_LIMIT,
  });

  const { data, loading, error } = useGetAllDueInvoicesQuery({
    variables: {
      skip: filters.skip,
      limit: filters.limit,
    },
  });

  const columns = [
    {
      title: "Invoice #",
      key: "id",
      render: (record: Invoice) => <Text>{record.Invoiceno}</Text>,
    },
    {
      title: "Client",
      render: (record: Invoice) => <Text>{record?.organization?.name}</Text>,
    },
    {
      title: <div style={{ textAlign: "right" }}>Amount</div>,
      width: 120,
      render: (record: Invoice) => <Caption style={{ textAlign: "right" }}>{formatCurrency(record.Invoice_amount || "")}</Caption>,
    },
    {
      title: <div style={{ textAlign: "right" }}>Paid</div>,
      width: 120,
      render: (record: Invoice) => (
        <Caption
          style={{
            textAlign: "right",
            color: record.Amount_Paid === "0.00" ? theme.colors.red6 : undefined,
          }}
        >
          {formatCurrency(record.Amount_Paid || "")}
        </Caption>
      ),
    },
    {
      title: "Due Date",
      render: (record: Invoice) => <Caption style={{ color: theme.colors.red6 }}>{formatDate(record?.Invoice_due_date || "")}</Caption>,
    },
    {
      title: "Date",
      render: (record: Invoice) => <Caption>{formatDate(record?.Invoice_Date || "")}</Caption>,
    },
  ];

  return (
    <>
      {error && <ErrorBlock style={{ marginTop: 16 }} error={error?.message} />}
      <DarkTable
        style={{ paddingInline: 0, maxWidth: "100%", overflowX: "auto", whiteSpace: "nowrap" }}
        loading={loading}
        dataSource={data?.getAllDueInvoices?.results}
        columns={columns}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: data?.getAllDueInvoices?.count || data?.getAllDueInvoices?.results?.length,
        }}
        emptyText="No Due Invoices"
      />
    </>
  );
}
