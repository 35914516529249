import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import DarkTable from "components/common/DarkTable";
import { useState } from "react";
import { EnvironmentTypeEnum, Instance, InstanceStateEnum, useGetManyInstancesQuery } from "generated/graphql";
import StatusCircle from "components/common/StatusCircle";
import Text from "components/text/Text";
import TruncateText from "components/common/TruncateText";
import Caption from "components/text/Caption";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import useUrlChange from "lib/hooks/useUrlChange";
import { StarFilled } from "@ant-design/icons";
import InstanceTypeMultiSelect from "components/inputs/InstanceTypeMultiSelect";

const Center = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProdTag = styled.div`
  border-radius: 25px;
  background: ${({ theme }) => theme.colors.primary9};
  color: ${({ theme }) => theme.colors.primary1};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0px 6px 0px 3px;
  span {
    letter-spacing: 0.6px;
    font-size: 10px;
    font-weight: 300;
  }
`;

const AlignCenter = styled.span`
  text-align: center;
  display: block;
  border: 0px !important;
`;

const Circle = styled.div`
  background: ${({ theme }) => theme.colors.primary9};
  border-radius: 50%;
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 1px;
`;

const LIMIT = 100;

const TopRow = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
`;

export default function ClientInstances({ location }) {
  const { query } = useUrlChange();

  const [filters, setFilters] = useState({
    skip: 0,
    current: 1,
    limit: LIMIT,
    instanceTypes: [],
  });

  const { data, loading } = useGetManyInstancesQuery({
    variables: {
      instanceIds: query.instanceIds || ([] as any),
      instanceTypes: filters?.instanceTypes,
    },
  });

  const history = useHistory();

  const columns = [
    {
      title: "name",
      render: (item: Instance) => (
        <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            {/* {item?.environment?.name &&
              item?.environment?.environmentType ===
                EnvironmentTypeEnum.Production && (
                <div style={{ position: "absolute", left: -40, top: 0 }}>
                  <Tooltip title="Production Instance">
                    <Circle>
                      <StarFilled style={{ fontSize: 14 }} />
                    </Circle>
                  </Tooltip>
                </div>
              )} */}
            <TruncateText text={item?.name} showCharCount={25}>
              <Text
                style={{
                  marginBottom: 4,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item?.name?.trim()}
              </Text>
            </TruncateText>
            {item?.environment?.name && item?.environment?.environmentType === EnvironmentTypeEnum.Production && (
              <ProdTag style={{ position: "relative", left: 0, top: 0 }}>
                <Circle>
                  <StarFilled style={{ fontSize: 8 }} />
                </Circle>
                <span>Production</span>
              </ProdTag>
            )}
            {item.description && (
              <TruncateText text={item.description} showCharCount={25}>
                <Caption>{item.description}</Caption>
              </TruncateText>
            )}
          </div>
        </div>
      ),
    },
    {
      title: "App/Env",
      render: (item: Instance) => {
        return (
          <div>
            {item?.application?.name && (
              <TruncateText text={item?.application?.name} showCharCount={25}>
                <Text>{item?.application?.name}</Text>
              </TruncateText>
            )}
            {/* {item?.environment?.name &&
              item?.environment?.environmentType ===
                EnvironmentTypeEnum.Production && (
                <Tag color="green">
                  <Circle>
                    <CloudServerOutlined style={{ fontSize: 15 }} />
                  </Circle>
                  Production
                </Tag>
              )} */}
            {item?.environment?.name && (
              <TruncateText text={item?.environment?.name} showCharCount={25}>
                <Caption>{item?.environment?.name}</Caption>
              </TruncateText>
            )}
          </div>
        );
      },
    },
    {
      title: "Type",
      render: (item: Instance) => (
        <div style={{ width: "100%" }}>
          <Text>{item.instanceType}</Text>
        </div>
      ),
    },
    {
      title: <AlignCenter>Vendor</AlignCenter>,
      render: (item: Instance) => (
        <Center>
          <Text>{item.vendor}</Text>
        </Center>
      ),
    },
    {
      title: <AlignCenter>Available</AlignCenter>,
      render: (item: Instance) => (
        <Center>
          <StatusCircle isRunning={item.state === InstanceStateEnum.Running ? true : false} fixed={false} />
        </Center>
      ),
    },
    // {
    //   title: "Production",
    //   render: (item: Instance) => (
    //     <Center>
    //       <StatusCircle
    //         isRunning={
    //           item?.environment?.environmentType ===
    //           EnvironmentTypeEnum.Production
    //             ? true
    //             : false
    //         }
    //         offColor={theme.colors.background2}
    //         fixed={false}
    //       />
    //     </Center>
    //   ),
    // },
    // {
    //   key: "id",
    //   title: "ID",
    //   render: (item: Instance) => (
    //     <div style={{ width: "100%" }}>
    //       <TruncateText text={item.externalId}>
    //         <Text>{item.externalId}</Text>
    //       </TruncateText>
    //     </div>
    //   ),
    // },
    {
      title: <AlignCenter>Metrics</AlignCenter>,
      render: (item: Instance) => (
        <Center>
          <Text>{item.numberOfMetrics}</Text>
        </Center>
      ),
    },
  ];

  return (
    <PageContainer>
      <TopRow>
        <PageTitle>Instances</PageTitle>
        <InstanceTypeMultiSelect
          style={{ width: 400 }}
          value={filters.instanceTypes}
          onChange={(instanceTypes) => {
            setFilters({
              ...filters,
              instanceTypes,
            });
          }}
        />
      </TopRow>

      <DarkTable
        style={{ marginTop: 24 }}
        loading={loading}
        dataSource={data?.getManyInstances}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: (event) => history.push(`/app/instances/${record.id}`), // click row
          };
        }}
        onChange={(pagination: any, filters: any, sorter: any) => {
          setFilters({
            ...filters,
            current: pagination?.current,
            limit: pagination?.pageSize,
            skip: pagination?.pageSize * (pagination.current - 1),
          });
        }}
        pagination={{
          pageSize: filters.limit,
          current: filters.current,
          total: data?.getManyInstances?.length,
        }}
      />
    </PageContainer>
  );
}
