import DocumentsTable from "components/common/DocumentsTable";

export default function Documents({ organizationId }) {
  return (
    <div>
      {" "}
      <DocumentsTable organizationId={organizationId} />
    </div>
  );
}
