import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { useGetManyInstanceTypesQuery } from "generated/graphql";
// import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function InstanceTypeMultiSelect(props) {
  const { onChange, value } = props;
  const { data } = useGetManyInstanceTypesQuery();
  return (
    <Select
      placeholder="Filter by type"
      style={{ width: "100%" }}
      {...props}
      size="large"
      mode="multiple"
      onChange={onChange}
      value={value || []}
      optionFilterProp="children"
      showSearch={false}
    >
      {data?.getManyInstanceTypes?.map((label) => {
        if (!label) return null;
        return (
          <Option key={label} value={label}>
            {label}
          </Option>
        );
      })}
    </Select>
  );
}
