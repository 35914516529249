import moment from "moment";
import queryString from "query-string";
import { toNumber } from "lodash";
import styled from "styled-components";
// GRAPHQL
import {
  SupportTicketComment,
  SupportTicketTypeEnum,
  useGetOneSupportTicketQuery,
} from "generated/graphql";
// COMPONENTS
import SupportTicketStateTag from "components/common/SupportTicketStateTag";
import BackButton from "components/common/BackButton";
import SupportTicketPriorityTag from "components/common/SupportTicketPriorityTag";
import Row from "components/common/Row";
import Col from "components/common/Col";
import DataItem from "components/common/DataItem";
import Tag from "components/common/Tag";
import Text from "components/text/Text";
import PageTitle from "components/text/PageTitle";
import Loading from "components/common/Loading";
import PageSubtitle from "components/text/PageSubtitle";
import Caption from "components/text/Caption";
// LIB
import formatDate from "lib/helpers/formatDate";
import stripHtml from "lib/helpers/stripHtml";
import PageContainer from "components/common/PageContainer";

const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.background2};
  border-radius: 5px;
  padding: 16px;
  margin-top: 8px;
  margin-bottom: 24px;
`;

const CommentContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.colors.background3};
  padding: 16px;
  margin-top: 8px;
`;

const AvatarSquare = styled.div<{
  backgroundColor?: string | null | undefined;
}>`
  height: 40px;
  width: 48px;
  margin-right: 16px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.background3};
`;

const Comment = ({ comment }: { comment: SupportTicketComment }) => {
  return (
    <CommentContainer>
      <div style={{ display: "flex" }}>
        <div>
          <AvatarSquare backgroundColor={comment?.user?.avatar?.color}>
            <Text>{comment?.user?.avatar?.initials}</Text>
          </AvatarSquare>
        </div>
        <div>
          <Text>{comment?.user?.name}</Text>
          <Caption>
            {comment?.createdAt && formatDate(comment?.createdAt)}
          </Caption>
        </div>
      </div>
      <Text style={{ marginTop: 16, fontSize: 14 }}>{comment?.body}</Text>
    </CommentContainer>
  );
};

export default function ClientTicketDetail({ match, location }) {
  const { ticketType } = queryString.parse(location?.search);
  const { data, loading } = useGetOneSupportTicketQuery({
    variables: {
      id: toNumber(match?.params?.id),
      type: ticketType as SupportTicketTypeEnum,
    },
  });

  const ticket = data?.getOneSupportTicket;

  if (loading) return <Loading />;

  return (
    <PageContainer>
      <BackButton style={{ marginBottom: 40 }} label="Back to Tickets" />
      <PageTitle>Ticket #{ticket?.id}</PageTitle>
      <PageSubtitle style={{ marginBottom: 16 }}>
        {ticket?.subject}
      </PageSubtitle>
      {ticket?.priority && (
        <SupportTicketPriorityTag priority={ticket?.priority}>
          {ticket?.priority}
        </SupportTicketPriorityTag>
      )}
      {ticket?.state && (
        <SupportTicketStateTag state={ticket?.state}>
          {ticket?.state.replace("_", " ")}
        </SupportTicketStateTag>
      )}
      <Row style={{ marginTop: 48 }} gutter={24}>
        <Col xs={8}>
          <Caption>Resume</Caption>
          <Content>
            <Row gutter={24}>
              <Col xs={24}>
                <DataItem label="Number" value={ticket?.id} />
                <DataItem
                  label="Created At"
                  value={`${moment(ticket?.createdAt).format(
                    "MMM DD, YYYY"
                  )} @ ${moment(ticket?.createdAt).format("h:mma")}`}
                />
                <DataItem
                  label="Last Updated"
                  value={`${moment(ticket?.updatedAt).format(
                    "MMM DD, YYYY"
                  )} @ ${moment(ticket?.updatedAt).format("h:mma")}`}
                />
                {ticket?.dueAt && (
                  <DataItem
                    label="Due"
                    value={`${moment(ticket?.dueAt).format("MMM DD, YYYY")}`}
                  />
                )}
              </Col>
            </Row>
          </Content>
          <Caption>Comments</Caption>
          {ticket?.comments?.map((comment) => {
            if (!comment?.id) return null;
            return <Comment key={comment?.id} comment={comment} />;
          })}
        </Col>
        <Col xs={16}>
          <Caption>Details</Caption>
          <Content>
            <Row gutter={24}>
              <Col xs={12}>
                <DataItem label="Requested By" value={ticket?.requestedBy} />
              </Col>
              <Col xs={12}>
                <DataItem label="Assigned To" value={ticket?.assignedTo} />
              </Col>
              <Col xs={24}>
                {ticket?.description && (
                  <DataItem
                    label="Description"
                    value={stripHtml(ticket?.description)}
                  />
                )}
                {ticket?.changePlan && (
                  <DataItem
                    label="Change Plan"
                    value={stripHtml(ticket?.changePlan)}
                  />
                )}
                {ticket?.rootCause && (
                  <DataItem
                    label="Root Cause"
                    value={stripHtml(ticket?.rootCause)}
                  />
                )}
                {ticket?.category && (
                  <DataItem
                    label="Category"
                    value={stripHtml(ticket?.category)}
                  />
                )}
                {ticket?.subcategory && (
                  <DataItem
                    label="Sub Category"
                    value={stripHtml(ticket?.subcategory)}
                  />
                )}
                {ticket?.subcategory && (
                  <DataItem
                    label="Sub Category"
                    value={
                      <>
                        {ticket?.tags?.map((tag) => {
                          if (!tag?.id) return null;
                          return (
                            <Tag color="gray" key={tag.id}>
                              {tag?.name?.toUpperCase()}
                            </Tag>
                          );
                        })}
                      </>
                    }
                  />
                )}
              </Col>
            </Row>
          </Content>
          <Caption>Attachments</Caption>
          <Content>No attachments...</Content>
        </Col>
      </Row>
    </PageContainer>
  );
}
