import PageTitle from "components/text/PageTitle";
import PageContainer from "components/common/PageContainer";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Card from "components/common/Card";
import ItemTitle from "components/text/ItemTitle";
import moment from "moment";
import DueInvoices from "./DueInvoices";

export default function AdminBillingOverview() {
  return (
    <PageContainer>
      <PageTitle>Overview</PageTitle>
      <div style={{ marginTop: 24 }}>
        <Row gutter={16}>
          <Col xs={8}>
            <Card fullWidth>
              <ItemTitle>{moment()?.format("MMMM")}</ItemTitle>
            </Card>
          </Col>
          <Col xs={8}>
            <Card fullWidth>
              <ItemTitle>By Client</ItemTitle>
            </Card>
          </Col>
          <Col xs={8}>
            <Card fullWidth>
              <ItemTitle>By Invoice Type</ItemTitle>
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={12}>
            <Card fullWidth>
              <ItemTitle>Due Invoices</ItemTitle>
              <DueInvoices />
            </Card>
          </Col>
          <Col xs={12}>
            <Card fullWidth>
              <ItemTitle>Late Invoices</ItemTitle>
            </Card>
          </Col>
        </Row>
      </div>
    </PageContainer>
  );
}
