import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import { EnvironmentTypeEnum } from "generated/graphql";
import environmentTypeHashmap from "lib/helpers/environmentTypeHashmap";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
export default function EnvironmentTypeSelectInput(props) {
  const { onChange, value } = props;

  return (
    <Select
      style={{ width: "100%" }}
      {...props}
      size="large"
      onChange={onChange}
      value={value || undefined}
      optionFilterProp="children"
    >
      {Object.values(EnvironmentTypeEnum)?.map((label) => {
        if (!label) return null;
        return (
          <Option key={label} value={label}>
            {environmentTypeHashmap[label]}
          </Option>
        );
      })}
    </Select>
  );
}
