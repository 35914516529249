import theme from "lib/theme";

function BuildingSVG(props) {
  return (
    <svg
      height={18}
      width={18}
      aria-hidden="true"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2a1 1 0 011-1h5a1 1 0 011 1v13H6v-2H4v2H1v-4h4.5a.5.5 0 000-1H1V8h4.5a.5.5 0 000-1H1V5h4.5a.5.5 0 000-1H1V2zm8 9h4.5a.5.5 0 000-1H9V8h4.5a.5.5 0 000-1H9V5a1 1 0 011-1h5a1 1 0 011 1v10h-2v-2h-2v2H9v-4z"
        fill={"#5b5b5b" || theme.colors.background}
      />
    </svg>
  );
}

export default BuildingSVG;
