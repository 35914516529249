// GRAPHQL
import { CurrentUserDocument, useAuthenticateMutation } from "generated/graphql";
import client from "ApolloClient/index";
import queryString from "query-string";
// LIB
import setLocalStorage from "lib/helpers/setLocalStorage";
import config from "lib/config";

// HOOKS
import useRerouteLoggedInUser from "lib/hooks/useRerouteLoggedInUser";
import { useEffect, useCallback, useState } from "react";
import message from "components/common/message";
import Loading from "components/common/Loading";

export default function AuthMagicLink({ match, location, history, currentUser }: any) {
  const [authenticate] = useAuthenticateMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const { redirectTo } = queryString.parse(location.search);
  useRerouteLoggedInUser({ currentUser });
  const handleAuthenticate = useCallback(async () => {
    try {
      const res = await authenticate({
        variables: {
          // serviceName: "magicLink",
          params: {
            token: match?.params?.token,
          },
        },
      });

      if (res?.data?.authenticate?.tokens?.accessToken && res?.data?.authenticate?.tokens?.refreshToken) {
        setLocalStorage(config.authTokenName, res?.data.authenticate.tokens.accessToken);
        setLocalStorage(config.refreshTokenName, res?.data.authenticate.tokens.refreshToken);
      }

      await client.query({
        query: CurrentUserDocument,
        fetchPolicy: "network-only",
      });

      setTimeout(() => {
        setLoading(false);
        message.success("You've been successfully logged in");
        if (redirectTo) {
          console.log("the redirect ran");
          history.push(redirectTo as string);
        }
      }, 1000);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  }, [history, redirectTo, authenticate, match?.params?.token]);

  useEffect(() => {
    if (match?.params?.token) {
      setLoading(true);
      setTimeout(() => {
        handleAuthenticate();
      }, 2000);
    }
  }, [match?.params?.token, handleAuthenticate]);

  return <div>{loading && <Loading />}</div>;
}
