import { useCreatePermissionMutation } from "generated/graphql";
import PermissionForm from "components/forms/PermissionForm";

export default function CreatePermissionForm({ onCancel, onComplete }) {
  const [createPermissionMutation, { loading }] = useCreatePermissionMutation();

  const handleOnSubmit = async (values) => {
    try {
      await createPermissionMutation({
        variables: {
          params: {
            name: values?.name,
            description: values?.description,
            accesses: values?.accesses,
            admin_only: true,
          },
        },
        refetchQueries: ["getManyPermissions"],
      });
      onComplete();
    } catch (err) {}
  };

  return (
    <div>
      <PermissionForm
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
        loading={loading}
      />
    </div>
  );
}
